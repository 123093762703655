import React, { FC, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { motion } from "framer-motion";

import { MdArrowDropDown } from "react-icons/md";
import { Select } from "@chakra-ui/react";

export const LanguageSwitcher: FC = () => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState("ru");

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const localLang = localStorage.getItem("i18nextLng");
    if (localLang) {
      changeLanguage(localLang);
    }
  }, []);

  useEffect(() => {
    changeLanguage(language);
  }, [language]);

  return (
    <>
      <motion.div
        animate={{ rotate: 360 }}
        transition={{
          duration: 2,
          delay: 3,
          repeat: 1,
          repeatDelay: 1,
          repeatType: "mirror",
        }}
      >
        <Select
          onChange={(e) => setLanguage(e.target.value)}
          icon={<MdArrowDropDown color="red.500" />}
          w="80px"
          h="40px"
          borderRadius="5px"
          variant="outline"
          border="1px"
          borderColor="#fff"
          fontSize="1.5rem"
          color="#fff"
          focusBorderColor="#158AFF"
          _hover={{
            borderColor: "#158AFF",
          }}
          cursor="pointer"
          filter={"drop-shadow(0px 2px 4px #158AFF)"}
        >
          <option style={{ backgroundColor: "#158AFF" }} value="ru">
            RU
          </option>
          <option style={{ backgroundColor: "#158AFF" }} value="en">
            EN
          </option>
        </Select>
      </motion.div>
    </>
  );
};
