import React from "react";

import { useTranslation } from "react-i18next";

import { FastMoney } from "./FastMoney";
import { TravelMoney } from "./TravelMoney";
import { AutoMoney } from "./AutoMoney";

import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
} from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";
import { Interface } from "readline";
import NewMatrixUniversal from "./NewMatrixUniversal";

export interface IMatrix {
  id: number;
  sum: number;
  sumAccumCommon?: number;
  sumToSponsor?: number;
  sumForWithdrawal?: number;
}
export interface INewProgram {
  id: number;
  name: string;
  info: string[];
  in: number;
  matrixes: IMatrix[];
}

const Marketing = () => {
  const { t } = useTranslation();

  const newPrograms: INewProgram[] = [
    {
      id: 3,
      name: "SUPER START",
      info: [
        `${t("NewProgram.enter")} 20$`,
        `3 ${t("NewProgram.binar_matrixes")}`,
        t("NewProgram.Referral_rewards"),
        `${t("NewProgram.Income")}: 120$`,
      ],
      in: 20,
      matrixes: [
        { id: 1, sum: 20, sumAccumCommon: 40 },
        { id: 2, sum: 40, sumAccumCommon: 80 },
        { id: 3, sum: 80, sumToSponsor: 20, sumForWithdrawal: 60 },
      ],
    },
    {
      id: 4,
      name: "SPRINT",
      info: [
        `${t("NewProgram.enter")} 100$`,
        `3 ${t("NewProgram.binar_matrixes")}`,
        t("NewProgram.Referral_rewards"),
        `${t("NewProgram.Income")}: 700$`,
      ],
      in: 100,
      matrixes: [
        { id: 1, sum: 100, sumAccumCommon: 200 },
        { id: 2, sum: 200, sumAccumCommon: 400 },
        { id: 3, sum: 400, sumToSponsor: 50, sumForWithdrawal: 350 },
      ],
    },
    {
      id: 5,
      name: "DRIVE",
      info: [
        `${t("NewProgram.enter")} 500$`,
        `3 ${t("NewProgram.binar_matrixes")}`,
        t("NewProgram.Referral_rewards"),
        `${t("NewProgram.Income")}: 3600$`,
      ],
      in: 500,
      matrixes: [
        { id: 1, sum: 500, sumAccumCommon: 1000 },
        { id: 2, sum: 1000, sumAccumCommon: 2000 },
        { id: 3, sum: 2000, sumToSponsor: 200, sumForWithdrawal: 1800 },
      ],
    },
    {
      id: 6,
      name: "JACKPOT",
      info: [
        `${t("NewProgram.enter")} 3000$`,
        `3 ${t("NewProgram.binar_matrixes")}`,
        t("NewProgram.Referral_rewards"),
        `${t("NewProgram.Income")}: 22 000$`,
      ],
      in: 3000,
      matrixes: [
        { id: 1, sum: 3000, sumAccumCommon: 6000 },
        { id: 2, sum: 6000, sumAccumCommon: 12000 },
        { id: 3, sum: 12000, sumToSponsor: 1000, sumForWithdrawal: 11000 },
      ],
    },
  ];

  return (
    <>
      <Meta
        title="Marketing"
        description="Уникальные партнерские программы Fast money, Travel money, Auto Money."
      />

      <section className="section__padding marketing">
        <div className="container">
          <div className="marketing__inner">
            <h2 className="title title__center">
              <span className="black blue">{t("Marketing.title1")}</span>{" "}
              {t("Marketing.title2")}
            </h2>
          </div>
        </div>

        <div className="mark">
          <div className="container">
            <div className="mark__inner">
              <Tabs
                variant="soft-rounded"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <Center w="100%">
                  <TabList
                    style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
                  >
                    <Tab
                      _selected={{
                        color: "white",
                        bg: "#3182CE",
                        borderRadius: "5xl",
                        borderColor: "#fff",
                        border: "1px",
                      }}
                      m={2}
                    >
                      <button className="button mark__tab">Fast money</button>
                    </Tab>

                    <Tab
                      m={2}
                      _selected={{
                        color: "white",
                        bg: "#3182CE",
                        borderRadius: "5xl",
                        borderColor: "#fff",
                        border: "1px",
                      }}
                    >
                      <button className="button mark__tab">Travel money</button>
                    </Tab>

                    <Tab
                      m={2}
                      _selected={{
                        color: "white",
                        bg: "#3182CE",
                        borderRadius: "5xl",
                        borderColor: "#fff",
                        border: "1px",
                      }}
                    >
                      <button className="button mark__tab">Auto money</button>
                    </Tab>

                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {newPrograms.map((el) => (
                        <Tab
                          key={el.id}
                          m={el.id}
                          _selected={{
                            color: "white",
                            bg: "#3182CE",
                            borderRadius: "5xl",
                            borderColor: "#fff",
                            border: "1px",
                          }}
                        >
                          <button className="button mark__tab">
                            {el.name}
                          </button>
                        </Tab>
                      ))}
                    </div>
                  </TabList>
                </Center>

                <TabPanels>
                  <TabPanel>
                    <FastMoney />
                  </TabPanel>
                  <TabPanel>
                    <TravelMoney />
                  </TabPanel>
                  <TabPanel>
                    <AutoMoney />
                  </TabPanel>
                  {newPrograms.map((el) => (
                    <TabPanel key={el.id}>
                      <NewMatrixUniversal program={el} />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Marketing;
