import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { store } from "./store.ts";

import { HelmetProvider } from "react-helmet-async";
import "./i18n";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider>
        <CSSReset />
        <Provider store={store}>
          <HelmetProvider>
            <Suspense fallback="loading">
              <App />
            </Suspense>
          </HelmetProvider>
        </Provider>
      </ChakraProvider>
    </BrowserRouter>
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById("root")
);
