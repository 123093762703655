import React, { useEffect, useState } from "react";
import { OrgChartComponent } from "./OrgChartComponent";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Flex,
  Select,
  Spacer,
} from "@chakra-ui/react";
import $api from "../../../http/apiService";
import { GlobalSpinner } from "../GlobalSpinner/GlobalSpinner";
import { AddIcon } from "@chakra-ui/icons";

export const Structure = (props) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const [loading, setLoading] = useState(false);
  const [parentInput, setParentInput] = useState("");
  const [parents, setParents] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  // const [dataForMatrix, setData] = useState(null);
  let addNodeChildFunc = null;

  function addNode(node) {
    // console.log("ADD NODE FUNCTION IN STR");
    addNodeChildFunc(node);
  }

  const onNodeClickHandler = async (realId, placeId) => {
    await getPlace(realId, placeId);
  };

  const getPlaces = async () => {
    // const rd1 = {
    //   program: props.chosenProgram,
    //   matrix: props.chosenMatrix,
    // };
    props.setDataForMatrix([]);
    setParents([]);
    const rd2 = {
      program: props.chosenProgram,
      matrix: props.chosenMatrix,
      skip: 0,
      take: 10,
    };
    if (props?.userId) {
      rd2.userId = props?.userId;
      try {
        // props.setDataForMatrix([]);
        // setParents([]);
        setLoading(true);
        const res2 = await $api.post(
          "/api/admin/Marketing/places",
          rd2,
          config
        );
        console.log("res2 /api/admin/Marketing/places:", res2);
        // const res3 = await $api.post("/api/Marketing/count", rd1, config);
        if (res2.status == 200 && res2?.data?.length > 0) {
          props.setMyClones(res2?.data);
          replaceMatrixById(res2?.data[0]?.id);
        } else {
          props.setDataForMatrix([]);
        }
      } catch (e) {
        props.setDataForMatrix([]);
        console.error(e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        props.setDataForMatrix([]);
        setLoading(true);
        const res2 = await $api.post("/api/Marketing/places", rd2, config);
        console.log("structure res2 /api/Marketing/places:", res2);
        if (res2.status == 200 && res2?.data?.length > 0) {
          props.setMyClones(res2?.data);
          replaceMatrixById(res2?.data[0]?.id);
        } else {
          props.setDataForMatrix([]);
        }
      } catch (e) {
        props.setDataForMatrix([]);
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const checkForBlock = (data) => {
    if (!data) return;
    const childMatrix = [];
    data?.children.forEach((elem) => {
      if (data?.isBlocked === false && data?.isUnderBlocked === false) {
        childMatrix.push(elem);
      } else if (data?.isBlocked === true || data?.isUnderBlocked === true) {
        if (elem?.pType?.id) {
          childMatrix.push(elem);
        }
      }
    });
    return childMatrix;
  };

  const getPlace = async (realId, placeId) => {
    if (!realId) return;
    const rd = {
      program: props.chosenProgram,
      matrix: props.chosenMatrix,
      placeId: realId,
    };
    if (props?.userId) {
      rd.userId = props?.userId;
      try {
        setLoading(true);
        const res = await $api.post("/api/admin/Marketing/place", rd, config);
        console.log("res.data /api/admin/Marketing/place:", res.data);
        if (res.status == 200) {
          const childMatrix = checkForBlock(res?.data);
          childMatrix?.length > 0 &&
            childMatrix.forEach((elem, i) => {
              elem.realId = elem.id;
              elem.id = elem?.hashCode;
              elem.realParentId = elem.parentId;
              elem.parentId = placeId;
              elem._expanded = true;
              if (childMatrix.length >= 2 && i === 1) {
                elem._centered = true;
              } else if (i === 0) {
                elem._centered = true;
              }
              try {
                addNode(elem);
              } catch (e) {
                console.error(e);
              }
            });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const res = await $api.post("/api/Marketing/place", rd, config);
        console.log("res.data /api/Marketing/place:", res.data);
        if (res.status == 200) {
          const childMatrix = checkForBlock(res?.data);
          childMatrix?.length > 0 &&
            childMatrix.forEach((elem, i) => {
              elem.realId = elem.id;
              elem.id = elem?.hashCode;
              elem.realParentId = elem.parentId;
              elem.parentId = placeId;
              elem._expanded = true;
              if (childMatrix.length >= 2 && i === 1) {
                elem._centered = true;
                // } else if (i === 0 && !isStart) {
              } else if (i === 0) {
                elem._centered = true;
              }
              // elem.expanded = true;
              // if (!isStart) {
              try {
                addNode(elem);
              } catch (e) {
                console.error(e);
              }
              // }
            });
          // if (isStart || isFinding) {
          // if (isFinding) {
          //   return childMatrix;
          // }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    console.log("props.chosenProgram", props.chosenProgram);
    if (!props.chosenProgram) return;
    getPlaces();
  }, [props.chosenMatrix, props.chosenProgram, props?.userId]);

  useEffect(() => {
    console.log("props.shouldUpdate", props.shouldUpdate);
    if (props.shouldUpdate === true) {
      getPlaces();
    }
    props.setShouldUpdate(false);
  }, [props.shouldUpdate]);

  const findNextPlace = async () => {
    console.log("findNextPlace");
    const rd = {
      program: props.chosenProgram,
      matrix: props.chosenMatrix,
    };
    if (props?.userId) {
      rd.userId = props?.userId;
      try {
        setLoading(true);
        const res = await $api.post("/api/admin/Marketing/next", rd, config);
        console.log("res /api/admin/Marketing/next:", res);
        if (res.status == 200) {
          getMatrixFromData(res.data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const res = await $api.post("/api/Marketing/next", rd, config);
        console.log("res /api/Marketing/next:", res);
        if (res.status == 200) {
          getMatrixFromData(res.data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
    props.setShouldFindNext(false);
  };

  useEffect(() => {
    if (props.shouldFindNext === true) {
      findNextPlace();
    }
  }, [props.shouldFindNext]);

  const replaceMatrixById = async (realId) => {
    const rd = {
      program: props.chosenProgram,
      matrix: props.chosenMatrix,
      placeId: realId,
    };
    if (props?.userId) {
      rd.userId = props?.userId;
      try {
        setLoading(true);
        const res = await $api.post("/api/admin/Marketing/place", rd, config);
        if (res.status == 200) {
          getMatrixFromData(res.data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const res = await $api.post("/api/Marketing/place", rd, config);
        if (res.status == 200) {
          getMatrixFromData(res.data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const getMatrixFromData = (data) => {
    setParents([]);
    props.setDataForMatrix([]);
    const matrix = [];
    matrix.push(data);
    // const childrenMatrix = data?.children;
    const childrenMatrix = checkForBlock(data);
    childrenMatrix.forEach((elem, i) => {
      matrix.push(elem);
    });

    matrix.forEach((elem, i) => {
      elem.realId = elem?.id;
      elem.id = elem?.hashCode;
      elem.realParentId = elem.parentId;
      if (i === 0) {
        elem.parentId = null;
      } else {
        elem.parentId = matrix[0]?.hashCode;
      }
      if((window.location.origin.includes("localhost")|| window.location.origin.includes("vercel")) && elem?.user?.imageUrl){
        const newImage = elem.user.imageUrl.replace("api.dct-trips.com","dev.dct-trips.com"); 
        elem.user.imageUrl = newImage;
      }
    });
    console.log("getMatrixFromData matrix1", matrix);
    console.log("getMatrixFromData props.posForSearch", props.posForSearch);
    console.log("getMatrixFromData props.shouldFindNext", props.shouldFindNext);

    if (props.posForSearch) {
      matrix[props.posForSearch]._centered = true;
    } else if (props.shouldFindNext === true) {
      const item = matrix.find((elem) => elem?.nextPos === true);
      if (item) item._centered = true;
    } else {
      matrix[0]._centered = true;
    }

    if (data?.parents?.length > 0) {
      setParents(data?.parents);
    }

    console.log("matrix2", matrix);
    try {
      props.setDataForMatrix(matrix);
    } catch (e) {
      console.error(e);
    }
    if (props.posForSearch) {
      props.setChosenPlace(matrix[props.posForSearch]);
    } else if (props.shouldFindNext === true) {
      const item = matrix.find((elem) => elem?.nextPos === true);
      if (item) props.setChosenPlace(item);
    } else {
      props.setChosenPlace(matrix[0]);
    }
    props.setPosForSearch(null);
  };

  useEffect(() => {
    if (props.idForSearch !== "") {
      console.log("props.idForSearch", props.idForSearch);
      replaceMatrixById(props.idForSearch);
      props.setIdForSearch("");
    }
  }, [props.idForSearch]);

  return (
    <>
      {loading && <GlobalSpinner />}
      <Flex>
        <IconButton
          ml={4}
          mt={4}
          w={[12, 14, 16]}
          h={[12, 14, 16]}
          colorScheme="blue"
          aria-label="Disclose modal"
          icon={<AddIcon w={8} h={8} />}
          // icon={<PhoneIcon />}
          onClick={onOpen}
        />
        <Spacer />
        {parents?.length > 0 && (
          <Select
            h="55px"
            w={300}
            border="1px"
            borderColor="gray.400"
            fontSize={18}
            mx={4}
            my={2}
            id="clones"
            placeholder="Выберите родителя"
            value={parentInput}
            onChange={(e) => {
              setParentInput(e.target.value);
              replaceMatrixById(+e.target.value);
            }}
          >
            {parents.map((elem) => (
              <option key={elem.id} value={elem.id}>
                Id:{elem.id} {elem.userLogin}
              </option>
            ))}
          </Select>
        )}
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent
        // overflowY={"hidden"}
        >
          <ModalHeader bg="gray.200"></ModalHeader>
          <ModalCloseButton colorScheme="blue" />

          <ModalBody bg="gray.200">
            {parents?.length > 0 && (
              <Select
                h="55px"
                w={300}
                border="1px"
                borderColor="gray.400"
                fontSize={18}
                mx={4}
                my={2}
                id="clones"
                placeholder="Выберите родителя"
                value={parentInput}
                onChange={(e) => {
                  setParentInput(e.target.value);
                  replaceMatrixById(+e.target.value);
                }}
              >
                {parents.map((elem) => (
                  <option key={elem.id} value={elem.id}>
                    Id:{elem.id} {elem.userLogin}
                  </option>
                ))}
              </Select>
            )}

            {props.dataForMatrix?.length > 0 && (
              <OrgChartComponent
                setClick={(click) => {
                  addNodeChildFunc = click;
                }}
                data={props.dataForMatrix}
                onNodeClick={onNodeClickHandler}
                chosenPlace={props.chosenPlace}
                setChosenPlace={props.setChosenPlace}
                shouldFindNext={props.shouldFindNext}
                setShouldFindNext={props.setShouldFindNext}
                setCloneForBlock={props.setCloneForBlock}
                chosenMatrix={props.chosenMatrix}
                chosenProgram={props.chosenProgram}
                refresh={props.refresh}
                setRefresh={props.setRefresh}
                isNew={props.isNew}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <OrgChartComponent
        setClick={(click) => {
          addNodeChildFunc = click;
        }}
        data={props.dataForMatrix}
        onNodeClick={onNodeClickHandler}
        chosenPlace={props.chosenPlace}
        setChosenPlace={props.setChosenPlace}
        shouldFindNext={props.shouldFindNext}
        setShouldFindNext={props.setShouldFindNext}
        setCloneForBlock={props.setCloneForBlock}
        chosenMatrix={props.chosenMatrix}
        chosenProgram={props.chosenProgram}
        refresh={props.refresh}
        setRefresh={props.setRefresh}
        isNew={props.isNew}
      />
    </>
  );
};
