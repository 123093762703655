import {
  Avatar,
  Box,
  Button,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Text,
  useClipboard,
  useMediaQuery,
  Wrap,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./DashBoard.scss";

import { AiFillCloseCircle } from "react-icons/ai";
import { Search2Icon } from "@chakra-ui/icons";
import { AboutUser } from "../../Components/LK/AboutUser/AboutUser";
import { AccountBalance } from "../../Components/LK/AccountBalance/AccountBalance";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { RiVipCrownLine } from "react-icons/ri";
import $api from "../../http/apiService";
import {
  IUserData,
  IUserStatistic,
} from "../../features/userInfo/userInfoSlice";
import { IoIosRibbon } from "react-icons/io";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { FaRegClone } from "react-icons/fa";

const ProfileDash: FC = () => {
  const [userData, setUserData] = useState<IUserData | null>(null);
  const [userStat, setUserStat] = useState<IUserStatistic | null>(null);
  const [loginForSearch, setLoginForSearch] = useState("");
  const params = useParams();
  // Translation i18next
  const { t } = useTranslation();
  const [fullPath, setFullPath] = useState("");
  const [shortPath, setShortPath] = useState("");

  const { onCopy } = useClipboard(fullPath);

  const [isLargerThan770] = useMediaQuery("(min-width: 770px)");

  const [mainBalance, setMainBalance] = useState(
    userData?.balances.find((elem) => elem.account.id == 1)
  );
  const [bonusBalance, setBonusBalance] = useState(
    userData?.balances.find((elem) => elem.account.id == 2)
  );
  const [cloneBalance, setCloneBalance] = useState(
    userData?.balances.find((elem) => elem.account.id == 3)
  );
  const [frozenBalance, setFrozenBalance] = useState(
    userData?.balances.find((elem) => elem.account.id == 4)
  );

  const copyToClipboard = () => {
    onCopy();
    toast("Скопировано в буфер обмена!");
  };

  useEffect(() => {
    if (params?.login) {
      setLoginForSearch(params?.login);
    }
  }, []);

  useEffect(() => {
    setUserData(null);
    if (loginForSearch.length > 4) {
      getUserInfo();
    }
  }, [loginForSearch]);

  useEffect(() => {
    setMainBalance(userData?.balances.find((elem) => elem.account.id == 1));
    setBonusBalance(userData?.balances.find((elem) => elem.account.id == 2));
    setCloneBalance(userData?.balances.find((elem) => elem.account.id == 3));
    setFrozenBalance(userData?.balances.find((elem) => elem.account.id == 4));
  }, [userData]);

  const getUserInfo = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const rd = {
      login: loginForSearch,
    };
    try {
      const res = await $api.post("/api/admin/User/details", rd, config);
      if (res.status === 200) {
        setUserData(res.data);
      }
      const info: IUserData = res.data;
      console.log(res);
    } catch (e) {
      console.log(e);
    }
    try {
      const res = await $api.post("/api/admin/User/stat", rd, config);
      if (res.status === 200) {
        setUserStat(res.data);
      }
      const info: IUserData = res.data;
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Box
        m={8}
        fontSize={[12, 8, 10, 12, 14]}
        boxShadow="lg"
        p="8"
        rounded="md"
        bg="white"
        // font-family="DM Sans"
      >
        <Wrap fontSize={[12, 8, 10, 12, 14]}>
          <Box ml={4}>
            <Text fontSize={[8, 12]}>Поиск по логину</Text>
            <Box>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  placeholder="Поиск по логину"
                  // w={[80, 60, 50]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={loginForSearch}
                  onChange={(e) => setLoginForSearch(e.target.value)}
                />
                {loginForSearch && (
                  <InputRightElement mr="10" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setLoginForSearch("")}
                    />
                  </InputRightElement>
                )}
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Box>
          </Box>

          <Spacer />
        </Wrap>
      </Box>
      <Flex m={8}>
        <Box>
          <Heading as="h2" mt={6} size="xl" mb="4" color="#6484AA">
            Профиль
          </Heading>
        </Box>
        {/* <Spacer />
        <Box
          boxShadow="lg"
          p="6"
          rounded="md"
          bg="white"
          width={80}
          height={20}
          alignSelf="end"
        >
          <Center>
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`Найдено: `}
            </Text>
          </Center>
        </Box> */}
      </Flex>
      {userData?.id && (
        <Box
          m={8}
          fontSize={[12, 8, 10, 12, 14]}
          boxShadow="lg"
          p="8"
          rounded="md"
          bg="white"
          // font-family="DM Sans"
          // w="30%"
        >
          {/* <img src={}/> */}
          {/* <AboutUser /> */}
          {/* <AccountBalance /> */}

          <div className="profile__content content__margin flex w100">
            <div className="profile__content--item w100 flexdef">
              <div className="profile__content--info--inner flexdef">
                {/* <input
                style={{ display: "none" }}
                id="image-input"
                type="file"
                accept="image/jpeg,image/png"
                multiple={false}
                ref={fileInputRef}
                onChange={(e) => onUploadFile(e)}
              /> */}
                <Avatar
                  as="button"
                  // onClick={() => fileInputRef.current?.click()}
                  size={"2xl"}
                  mx="auto"
                  m={4}
                  // name={userData.name ? userData.name : userData.login}
                  src={userData?.imageUrl}
                />

                <div className="flex">
                  {/* {!userData?.isVerified ? (
                  <Link to="/admin/settings">
                    <Text color="red.400">{t("AboutUser.get_verified")}!</Text>
                  </Link>
                ) : (
                  <p className="profile__content--info--img--change flexdef">
                    <img className="icon__def" src={ShieldIcon} alt="Почта" />
                    {t("AboutUser.verified")}
                  </p>
                )} */}

                  <p className="profile__content--info--name">
                    {userData?.name ? userData?.name : userData?.login}
                  </p>

                  <a
                    href="mailto:alexanderpetrov@ya.ru"
                    className="profile__content--info--mail admin flexdef"
                  >
                    <img
                      className="icon__def"
                      src="/assets/img/mail-admin.svg"
                      alt="Почта"
                    />
                    {userData?.contacts?.email
                      ? userData.contacts.email
                      : t("AboutUser.your_mail_address")}
                    !
                  </a>
                </div>
              </div>

              <Box m={2}>
                <CircularProgress
                  value={userData?.profileCompleteness}
                  color="#61D64A"
                  thickness="10px"
                  size="100px"
                  trackColor="#A3ABB7"
                  // capIsRound
                >
                  <CircularProgressLabel>
                    <Text color="white" fontSize="20px" fontWeight="bold">
                      {userData?.profileCompleteness}%
                    </Text>
                  </CircularProgressLabel>
                </CircularProgress>
              </Box>

              {/* {isLargerThan770 ? (
                <Box ml={2}>
                  <Link to="/admin/settings">
                    <p className="profile__info--text">{t("AboutUser.open")}</p>
                    <p className="profile__info--text">
                      {t("AboutUser.profile")}
                    </p>
                  </Link>
                </Box>
              ) : (
                <></>
              )} */}

              {userData?.marketingStatus?.rank == "" ? (
                <></>
              ) : (
                <Flex direction="column" textAlign="center" m={2} p={2}>
                  <Center>
                    <RiVipCrownLine
                      size={60}
                      color={
                        userData?.marketingStatus?.rank === "platinum"
                          ? "#892c2c"
                          : "" || userData?.marketingStatus?.rank === "gold"
                          ? "#FFCB00"
                          : "" || userData?.marketingStatus?.rank === "silver"
                          ? "#c0c0c0"
                          : ""
                      }
                    />
                  </Center>

                  <Text fontSize="14px" mt={1} color="#FFCB00">
                    {t("AboutUser.status")}
                    {userData?.marketingStatus?.rank?.toUpperCase()}
                  </Text>
                </Flex>
              )}

              <div className="flexdef profile__text--wrapper">
                <p className="profile__info--text">
                  {t("AboutUser.total_referrals")}:
                </p>
                <p className="profile__info--value">{userData?.refCount}</p>
              </div>

              {/* <div className="flexdef profile__text--wrapper">
                <p className="profile__info--text">
                  {t("AboutUser.interest_on_balance")}:
                </p>
                <p className="profile__info--value">
                  {userData?.marketingStatus?.partnerRate}%
                </p>
              </div> */}
            </div>

            {/* <Divider />

          <div className="profile__content--item w100 flexdef">
            <p className="profile__info--text profile__ref--text">
              {t("AboutUser.referral_link")}:
            </p>

            <input
              className="input profile__ref--input admin"
              value={fullPath}
              readOnly
              placeholder="Реферальная ссылка"
            />

            <Center w="100%">
              <Button
                onClick={copyToClipboard}
                colorScheme="blue"
                borderRadius="xl"
                w={["70%", "50%", "40%"]}
                h={24}
                boxShadow="dark-lg"
                m={4}
              >
                <Text fontSize="18px" color="white" fontFamily="Graphik">
                  {t("AboutUser.copy")}
                </Text>
              </Button>
            </Center>
          </div> */}

            <Divider />

            <div className="profile__content--item w100 flexdef">
              <Flex
                direction={isLargerThan770 ? "row" : "column"}
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                mt={8}
                // fontFamily="Graphik"
                fontSize="14px"
                fontWeight="medium"
                color="#ACAFBE"
              >
                <Flex
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  w={isLargerThan770 ? "45%" : "100%"}
                  m={2}
                >
                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text fontSize="18px" fontWeight="bold" color="white">
                      {t("AboutUser.finance")}
                    </Text>
                    <Text fontSize="18px" fontWeight="bold" color="#61D64A">
                      {userData?.balances && userData?.balances[2]?.value} $
                    </Text>
                  </Flex>

                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text color="white">{t("AboutUser.article")}</Text>
                    <Text color="white">{t("AboutUser.amount_in_MTB")}</Text>
                  </Flex>

                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text>{t("AboutUser.earned")}</Text>
                    <Text>{userStat?.financeStat?.interest}</Text>
                  </Flex>

                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text>{t("AboutUser.spend")}</Text>
                    <Text>{userStat?.financeStat?.expenses}</Text>
                  </Flex>

                  {/* <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text>{t("AboutUser.accrued")} %</Text>
                    <Text>{userStat?.financeStat?.interest}</Text>
                  </Flex> */}

                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text>{t("AboutUser.available_for_withdrawal")}</Text>
                    <Text>{userStat?.financeStat?.available}</Text>
                  </Flex>
                </Flex>

                <Flex
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  w={isLargerThan770 ? "45%" : "100%"}
                  m={2}
                >
                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text fontSize="18px" fontWeight="bold" color="white">
                      {t("AboutUser.partners")}
                    </Text>
                    <Text fontSize="18px" fontWeight="bold" color="#61D64A">
                      {userData?.refCount} {t("AboutUser.partners")}
                    </Text>
                  </Flex>

                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text color="white">{t("AboutUser.active")}</Text>
                    <Text color="white">
                      {userData?.activeRefCount} {t("AboutUser.partners")}
                    </Text>
                  </Flex>

                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text>{t("AboutUser.inactive")}</Text>
                    <Text>
                      {userData?.inactiveRefCount} {t("AboutUser.partners")}
                    </Text>
                  </Flex>

                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text>Fast Travel</Text>
                    <Text>
                      {userStat?.partnerStat?.fastMoney}
                      {t("AboutUser.partners")}
                    </Text>
                  </Flex>

                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text>Travel Money</Text>
                    <Text>
                      {userStat?.partnerStat?.travelMoney}
                      {t("AboutUser.partners")}
                    </Text>
                  </Flex>

                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text>Auto Money</Text>
                    <Text>
                      {userStat?.partnerStat?.autoMoney}
                      {t("AboutUser.partners")}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </div>
          </div>

          <div className="w100 flex default__margin">
            <h3 className="admin__title--inner w100">
              <img
                className="admin__title--icon"
                src="/assets/img/wallet-red.svg"
                alt="Иконка"
              />
              Баланс счетов
            </h3>

            <div className="content__margin admin__wallet w100">
              <div className="admin__wallet--item orange flex">
                <img
                  className="admin__wallet--bg"
                  src="/assets/img/wallet-bg.svg"
                  alt="Картинка"
                />

                <div className="flexdef w100 admin__wallet--text--inner">
                  <div className="flexdef">
                    <div className="admin__wallet--coin--inner flexs">
                      <MdOutlineAccountBalanceWallet
                        size="60%"
                        color="#fd6770"
                      />
                    </div>

                    <p className="admin__wallet--text ml1">Баланс основной</p>
                  </div>

                  <div className="flexend ml1 flexs">
                    <p className="admin__wallet--text">
                      ${mainBalance && +mainBalance?.usd?.toFixed(2)}
                    </p>

                    {/* <p className="admin__wallet--text mt1">
                      {mainBalance?.value} МТВ
                    </p> */}
                  </div>
                </div>

                {/* <div className="flexdef w100 admin__wallet--text--inner">
                  <p className="admin__wallet--text mr1">
                    1 МТВ = {mainBalance?.rate?.rate}$
                  </p>

                  <p className="admin__wallet--text">
                    +{mainBalance && +mainBalance?.rate?.change?.toFixed(2)}%
                  </p>
                </div> */}
              </div>

              <div className="admin__wallet--item blue flex">
                <img
                  className="admin__wallet--bg"
                  src="/assets/img/people-bg.svg"
                  alt="Картинка"
                />

                <div className="flexdef w100 admin__wallet--text--inner">
                  <div className="flexdef">
                    <div className="admin__wallet--coin--inner flexs">
                      <FaRegClone size="50%" color="#5433ff" />
                    </div>

                    <p className="admin__wallet--text ml1">Баланс клонов</p>
                  </div>

                  <div className="flexend ml1 flexs">
                    <p className="admin__wallet--text">
                      ${cloneBalance && +cloneBalance?.usd?.toFixed(2)}
                    </p>

                    {/* <p className="admin__wallet--text mt1">
                      {cloneBalance?.value} МТВ
                    </p> */}
                  </div>
                </div>

                <div className="flexdef w100 admin__wallet--text--inner">
                  <p className="admin__wallet--text mr1">
                    {cloneBalance?.rate?.rate}$
                  </p>

                  <p className="admin__wallet--text">
                    +{cloneBalance && +cloneBalance?.rate?.change?.toFixed(2)}%
                  </p>
                </div>
              </div>

              <div className="admin__wallet--item green flex">
                <img
                  className="admin__wallet--bg"
                  src="/assets/img/beach-bg.svg"
                  alt="Картинка"
                />

                <div className="flexdef w100 admin__wallet--text--inner">
                  <div className="flexdef">
                    <div className="admin__wallet--coin--inner flexs">
                      <IoIosRibbon size="60%" color="#1a8f18" />
                    </div>

                    <p className="admin__wallet--text ml1">Баланс бонусов</p>
                  </div>

                  <div className="flexend ml1 flexs">
                    <p className="admin__wallet--text">
                      ${bonusBalance && +bonusBalance?.usd?.toFixed(2)}
                    </p>

                    {/* <p className="admin__wallet--text mt1">
                      {bonusBalance?.value} МТВ
                    </p> */}
                  </div>
                </div>

                {/* <div className="flexdef w100 admin__wallet--text--inner">
                  <p className="admin__wallet--text mr1">
                    1 МТВ = {bonusBalance?.rate?.rate}$
                  </p>

                  <p className="admin__wallet--text">
                    +{bonusBalance && +bonusBalance?.rate?.change?.toFixed(2)}%
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default ProfileDash;
