import {
  AddIcon,
  DragHandleIcon,
  EditIcon,
  ExternalLinkIcon,
  HamburgerIcon,
  InfoOutlineIcon,
  RepeatIcon,
  UnlockIcon,
} from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import exp from "constants";
import React, { FC, useEffect, useState } from "react";
import { MdAllInbox } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { PaymentTypeInRD } from "./PaymentsList";

type Iprops = {
  item: PaymentTypeInRD;
  setChosenItem: any;
  setItem: any;
  onOpen: any;
  setLoginForSearch: React.Dispatch<React.SetStateAction<string>>;
};

const PaymentMenu = (props: Iprops) => {
  const navigate = useNavigate();
  return (
    <Box
    // filter="auto" blur="" zIndex="1000000"
    >
      <Menu
        onClose={() => {
          props.setChosenItem(undefined);
        }}
      >
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
          _hover={{ bg: "gray.400" }}
          _expanded={{ bg: "blue.400" }}
          onClick={() => {
            props.setChosenItem(props.item);
          }}
        />
        <MenuList ml="12">
          <MenuItem
            icon={<EditIcon />}
            onClick={() => {
              props.setItem(props.item);
              props.onOpen();
            }}
          >
            Редактировать платеж
          </MenuItem>
          <MenuItem
            icon={<MdAllInbox />}
            onClick={() => {
              props.setLoginForSearch(props.item.user?.login);
            }}
          >
            Посмотреть все платежи для пользователя
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default PaymentMenu;
