import React, { FC, useEffect, useLayoutEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";
import { getPublickNews } from "../../../features/news/newsSlice";

import { Link } from "react-router-dom";
import Moment from "react-moment";

import { GlobalSpinner } from "../../../Components/LK/GlobalSpinner/GlobalSpinner";

import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
} from "draft-js";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { LangEnum } from "../../Dashboard/NewsList";
import Meta from "../../../utils/seo/Meta";

export const PublickNews: FC = () => {
  const { t } = useTranslation();

  const { publicNews, isLoading } = useAppSelector((state) => state.news);
  const { userData } = useAppSelector((state) => state.userInfo);
  const dispatch = useAppDispatch();
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  // Scroll to top
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getPublickNews());
  }, []);

  const onEditorStateChange = (
    // editorState: React.SetStateAction<EditorState>
    editorState: any
  ) => {
    setEditorState(editorState);
  };
  useEffect(() => {
    let inMyLangItem = publicNews[0]?.content.find(
      (e) => e.lang === userData?.interfaceLang
    );
    if (!inMyLangItem?.text) {
      inMyLangItem = publicNews[0]?.content.find((e) => e.lang === LangEnum.Ru);
    }
    if (inMyLangItem?.text) {
      try {
        JSON.parse(inMyLangItem.text);
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(inMyLangItem.text))
          )
        );
      } catch (e) {
        console.error(e);
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(inMyLangItem.text)
          )
        );
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [publicNews[0]]);

  return (
    <>
      <Meta title="Public news" description="Новости платформы!" />

      <section className="section__padding news">
        <div className="container">
          <div className="news__inner">
            <h2 className="title title__center">
              <span className="black blue">{t("PublickNews.news")}</span>{" "}
              {t("PublickNews.world")}
            </h2>
          </div>
        </div>

        <div className="news__box">
          <div className="container news__contained">
            <img
              src={publicNews[0]?.imageUrl}
              alt="Картинка"
              className="news__box--img"
            />

            <div className="news__box--text--inner">
              <p className="news__box--text--title">
                {publicNews[0]?.content.find(
                  (e) => e.lang === userData?.interfaceLang
                )?.title ??
                  publicNews[0]?.content.find((e) => e.lang === LangEnum.Ru)
                    ?.title}
              </p>

              <p className="news__box--text--text">
                {publicNews[0]?.content.find(
                  (e) => e.lang === userData?.interfaceLang
                )?.preview ??
                  publicNews[0]?.content.find((e) => e.lang === LangEnum.Ru)
                    ?.preview}
              </p>

              <p className="news__box--text--text">
                <Editor
                  editorState={editorState}
                  readOnly
                  toolbarHidden
                  toolbarClassName="toolbarClassName"
                  onEditorStateChange={onEditorStateChange}
                />
                {/* {publicNews[0]?.content[0]?.text} */}
              </p>

              <div className="news__wrapper">
                <p className="text__date white">
                  <img
                    src="/assets/img/date-blue.svg"
                    alt="Календарь"
                    className="text__date--img"
                  />
                  <Moment format="DD MMMM YYYY">
                    {publicNews[0]?.createdAt}
                  </Moment>
                </p>

                {/* <div className="news__social--inner">
                <a
                  href="https://google.com"
                  className="news__social--link"
                  target="_Blanc"
                >
                  <img
                    className="news__social--icon"
                    src="/assets/img/facebook-white.svg"
                    alt="Соц сеть"
                  />
                </a>

                <a
                  href="https://google.com"
                  className="news__social--link"
                  target="_Blanc"
                >
                  <img
                    className="news__social--icon"
                    src="/assets/img/google-white.svg"
                    alt="Соц сеть"
                  />
                </a>

                <a
                  href="https://google.com"
                  className="news__social--link"
                  target="_Blanc"
                >
                  <img
                    className="news__social--icon"
                    src="/assets/img/playmarket-white.svg"
                    alt="Соц сеть"
                  />
                </a>

                <a
                  href="https://google.com"
                  className="news__social--link"
                  target="_Blanc"
                >
                  <img
                    className="news__social--icon"
                    src="/assets/img/insta-white.svg"
                    alt="Соц сеть"
                  />
                </a>

                <a
                  href="https://google.com"
                  className="news__social--link"
                  target="_Blanc"
                >
                  <img
                    className="news__social--icon"
                    src="/assets/img/twitter-white.svg"
                    alt="Соц сеть"
                  />
                </a>
              </div> */}
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <GlobalSpinner />
        ) : (
          <div className="container">
            <div className="news__inner">
              <div className="news__content">
                {publicNews.map((d, id) => (
                  <Link to={`${d.id}`} key={id}>
                    <div className="news__item">
                      <img
                        src={d.imageUrl}
                        alt="Картинка новости"
                        className="news__item--img"
                      />

                      {d.content.map((text, index) => (
                        <div className="news__item--text--inner" key={index}>
                          <p className="text__name">{text.title}</p>

                          <p className="medium__text news__text">
                            {text.preview}
                          </p>
                          <div className="news__wrapper">
                            <p className="text__date">
                              <img
                                src="/assets/img/date.svg"
                                alt="Календарь"
                                className="text__date--img"
                              />
                              <Moment format="DD MMMM YYYY">
                                {d.createdAt}
                              </Moment>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
