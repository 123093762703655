import {
  ArrowUpDownIcon,
  EditIcon,
  InfoIcon,
  Search2Icon,
  SettingsIcon,
  StarIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import "./DashBoard.scss";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { usePagination } from "../../hooks/pagination/usePagination";
import { MdOutlineDateRange, MdAdminPanelSettings } from "react-icons/md";
import { BsPeopleFill, BsPerson } from "react-icons/bs";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { AiOutlineRollback } from "react-icons/ai";
import { Container, keyframes, chakra } from "@chakra-ui/react";
import { motion, isValidMotionProp } from "framer-motion";
import Diagram from "./component/diagrams/diagramLine";
import AreaDiagram from "./component/diagrams/diagramArea";

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === "children",
});

const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); border-radius: 20%; }
  25% { transform: scale(2) rotate(0); border-radius: 20%; }
  50% { transform: scale(2) rotate(270deg); border-radius: 50%; }
  75% { transform: scale(1) rotate(270deg); border-radius: 50%; }
  100% { transform: scale(1) rotate(0); border-radius: 20%; }
`;

const animation = `${animationKeyframes} 4s ease-in-out 2s infinite`;

const testUserData = [
  {
    id: 1,
    name: "Baca",
    login: "krasa",
    email: "ksu@mail.ru",
    data: "2022-10-11",
    sum: 1000,
    referal: "test6",
    dataActive: "2022-10-11",
  },
  {
    id: 1,
    name: "Baca",
    login: "krasa",
    email: "ksu@mail.ru",
    data: "2022-10-11",
    sum: 1000,
    referal: "test6",
    dataActive: "2022-10-11",
  },
  {
    id: 1,
    name: "Baca",
    login: "krasa",
    email: "ksu@mail.ru",
    data: "2022-10-11",
    sum: 1000,
    referal: "test6",
    dataActive: "2022-10-11",
  },
  {
    id: 1,
    name: "Baca",
    login: "krasa",
    email: "ksu@mail.ru",
    data: "2022-10-11",
    sum: 1000,
    referal: "test6",
    dataActive: "2022-10-11",
  },
  {
    id: 1,
    name: "Baca",
    login: "krasa",
    email: "ksu@mail.ru",
    data: "2022-10-11",
    sum: 1000,
    referal: "test6",
    dataActive: "2022-10-11",
  },
  {
    id: 1,
    name: "Baca",
    login: "krasa",
    email: "ksu@mail.ru",
    data: "2022-10-11",
    sum: 1000,
    referal: "test6",
    dataActive: "2022-10-11",
  },
  {
    id: 1,
    name: "Baca",
    login: "krasa",
    email: "ksu@mail.ru",
    data: "2022-10-11",
    sum: 1000,
    referal: "test6",
    dataActive: "2022-10-11",
  },
  {
    id: 1,
    name: "Baca",
    login: "krasa",
    email: "ksu@mail.ru",
    data: "2022-10-11",
    sum: 1000,
    referal: "test6",
    dataActive: "2022-10-11",
  },
  {
    id: 1,
    name: "Baca",
    login: "krasa",
    email: "ksu@mail.ru",
    data: "2022-10-11",
    sum: 1000,
    referal: "test6",
    dataActive: "2022-10-11",
  },
  {
    id: 1,
    name: "Baca",
    login: "krasa",
    email: "ksu@mail.ru",
    data: "2022-10-11",
    sum: 1000,
    referal: "test6",
    dataActive: "2022-10-11",
  },
  {
    id: 1,
    name: "Baca",
    login: "krasa",
    email: "ksu@mail.ru",
    data: "2022-10-11",
    sum: 1000,
    referal: "test6",
    dataActive: "2022-10-11",
  },
  {
    id: 1,
    name: "Baca",
    login: "krasa",
    email: "ksu@mail.ru",
    data: "2022-10-11",
    sum: 1000,
    referal: "test6",
    dataActive: "2022-10-11",
  },
  {
    id: 1,
    name: "Baca",
    login: "krasa",
    email: "ksu@mail.ru",
    data: "2022-10-11",
    sum: 1000,
    referal: "test6",
    dataActive: "2022-10-11",
  },
];

const AdminDashboard: FC = () => {
  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: 5,
    count: testUserData.length,
  });
  return (
    <>
      <Grid
        templateAreas={`
                  "nav main"
                  "nav footer"`}
        gridTemplateRows={"180px 1fr"}
        gridTemplateColumns={"300px 1fr"}
        h="100vh"
        w="100vw"
        maxWidth={"1900px"}
        borderRadius="md"
        gap="0"
        color="blackAlpha.700"
        // bgGradient='linear(to-r, green.200, pink.500)'
        fontWeight="bold"
      >
        {/* <GridItem pl="2" bg="orange.300" area={"header"}>
          Header
        </GridItem> */}

        <GridItem
          pl="2"
          // bg="gray.500"
          bgGradient="linear(to-r, gray.700, blue.700)"
          area={"nav"}
        >
          <div className="dashBoard__nav">
            {/* <Box
              as={motion.div}
              animation={animation}
              // not work: transition={{ ... }}
              padding="2"
              bgGradient="linear(to-l, #553C9A, #2c5282)"
              width="12"
              height="12"
              display="flex"
            >
              <Center>
                <MdAdminPanelSettings />
              </Center>
            </Box> */}

            <ChakraBox
              animate={{
                scale: [1, 2, 2, 1, 1],
                rotate: [0, 0, 270, 270, 0],
                borderRadius: ["20%", "20%", "50%", "50%", "20%"],
              }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore no problem in operation, although type error appears.
              transition={{
                duration: 3,
                delay: 0,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "loop",
              }}
              padding="2"
              bgGradient="linear(to-l, #553C9A, #2c5282)"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="40px"
              height="40px"
            >
              <MdAdminPanelSettings />
            </ChakraBox>
            {/* <Image
              boxSize="60px"
              //   objectFit="cover"
              src="/assets/img/ava.png"
              alt=""
            /> */}
          </div>

          <Box
            as={motion.div}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition="0.2s linear"
            ml={10}
          >
            <Link to="/dashboard" className="dashBoard__navLink">
              <Search2Icon mr={"10px"} />
              Пользователи
            </Link>
          </Box>
          <Box
            as={motion.div}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition="0.2s linear"
            ml={10}
          >
            <Link to="/dashboard" className="dashBoard__navLink">
              <EditIcon mr={"10px"} />
              Заявки на вывод
            </Link>
          </Box>
          <Box
            as={motion.div}
            whileHover={{ scale: 1.05, color: "red" }}
            whileTap={{ scale: 0.9 }}
            // transition="0.2s linear"
            ml={10}
          >
            <Link to="/dashboard" className="dashBoard__navLink">
              <InfoIcon mr={"10px"} />
              Новости
            </Link>
          </Box>
          <Box
            as={motion.div}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition="0.2s linear"
            ml={10}
          >
            <Link to="/dashboard" className="dashBoard__navLink">
              <ArrowUpDownIcon mr={"10px"} />
              История платежей
            </Link>
          </Box>
          <Box
            as={motion.div}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition="0.2s linear"
            ml={10}
          >
            <Link to="/dashboard" className="dashBoard__navLink">
              <SettingsIcon mr={"10px"} />
              Статистика
            </Link>
          </Box>
          <Box
            as={motion.div}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition="0.2s linear"
            ml={10}
          >
            <Link to="/admin" className="dashBoard__navLink">
              <AiOutlineRollback />
            </Link>
          </Box>
        </GridItem>
        <GridItem pl="2" bg="gray.100" area={"main"}></GridItem>
        <GridItem pl="2" bg="gray.200" area={"footer"}>
          <TableContainer w="100%">
            <Table variant="simple" fontSize={16}>
              <TableCaption>
                <Center>
                  <div className="pagination">
                    <Button
                      as={motion.div}
                      whileHover={{ scale: 1.1 }}
                      onClick={prevPage}
                      w={24}
                      h={12}
                      colorScheme="blue"
                      borderRadius="xl"
                      isDisabled={page === 1}
                    >
                      Назад
                    </Button>
                    <button
                      onClick={() => setPage(1)}
                      className={`page ${page === 1 && "disabled"}`}
                    >
                      1
                    </button>
                    {gaps.before ? "..." : null}

                    {gaps.paginationGroup.map((el) => (
                      <button
                        onClick={() => setPage(el)}
                        key={el}
                        className={`page ${page === el ? "active" : ""}`}
                      >
                        {el}
                      </button>
                    ))}
                    {gaps.after ? "..." : null}
                    <button
                      onClick={() => setPage(totalPages)}
                      className={`page ${page === totalPages && "disabled"}`}
                    >
                      {totalPages}
                    </button>

                    <Button
                      as={motion.div}
                      whileHover={{ scale: 1.1 }}
                      onClick={nextPage}
                      w={24}
                      h={12}
                      colorScheme="blue"
                      borderRadius="xl"
                      isDisabled={page === totalPages}
                    >
                      Вперед
                    </Button>
                  </div>
                </Center>
              </TableCaption>

              <Thead>
                <Tr h={20}>
                  <Th
                    color="#174073"
                    fontSize={[10, 12, 14]}
                    overflowWrap="break-word"
                  >
                    <Center>
                      <Text fontWeight="bold">ID</Text>
                    </Center>
                  </Th>

                  <Th
                    color="#174073"
                    fontSize={[10, 12, 14]}
                    overflowWrap="break-word"
                  >
                    <Center>
                      {/* <Box mr={2}>
                        <BsPeopleFill size={20} />
                      </Box> */}
                      <Text fontWeight="bold">Логин</Text>
                    </Center>
                  </Th>

                  <Th
                    color="#174073"
                    fontSize={[10, 12, 14]}
                    overflowWrap="break-word"
                  >
                    <Center>
                      <Box mr={2}>
                        <BsPerson size={20} />
                      </Box>
                      <Text fontWeight="bold">Имя</Text>
                    </Center>
                  </Th>

                  <Th
                    color="#174073"
                    fontSize={[10, 12, 14]}
                    overflowWrap="break-word"
                  >
                    <Center>
                      <Box mr={2}>
                        <BsPeopleFill size={20} />
                      </Box>
                      <Text fontWeight="bold">Спонсор</Text>
                    </Center>
                  </Th>

                  <Th color="#174073" fontSize={[10, 12, 14]}>
                    <Center>
                      <Box mr={2}>
                        <MdOutlineDateRange size={20} />
                      </Box>
                      Дата регистрации
                    </Center>
                  </Th>

                  <Th color="#174073" fontSize={[10, 12, 14]}>
                    <Center>
                      <Box mr={2}>
                        <MdOutlineDateRange size={20} />
                      </Box>
                      Дата активности
                    </Center>
                  </Th>

                  <Th color="#174073" fontSize={[10, 12, 14]}>
                    <Center>
                      <Box mr={2}>
                        <FaRegMoneyBillAlt size={20} />
                      </Box>
                      Сумма
                    </Center>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {testUserData
                  .slice(firstContentIndex, lastContentIndex)
                  .map((user, index) => (
                    <Tr h={20} key={user.id}>
                      <Td>
                        <Center>{user.id}</Center>
                      </Td>
                      <Td color="#2b6cb0">
                        <Link to="/dashboard">
                          <Center>{user.login}</Center>
                        </Link>
                      </Td>
                      <Td>
                        <Center>{user.name}</Center>
                      </Td>
                      <Td color="#2b6cb0">
                        <Link to="/dashboard">
                          <Center>{user.referal}</Center>
                        </Link>
                      </Td>
                      <Td>
                        <Center>{user.data}</Center>
                      </Td>
                      <Td>
                        <Center>{user.dataActive}</Center>
                      </Td>
                      <Td>
                        <Center>{user.sum}</Center>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Box h={400}>
          {/* <AreaDiagram /> */}

          </Box>
            
        </GridItem>
      </Grid>
    </>
  );
};

export default AdminDashboard;
