import React from "react";
import "./Footer.css";

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import mtbIcon from "../../../assets/img/mtb-logo.png";
import usdtIcon from "../../../assets/img/usdt- icon.svg";
import travalaIcon from "../../../assets/img/travala-banner.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Box } from "@chakra-ui/react";
import { RiContactsBookLine } from "react-icons/ri";
import { BsNewspaper } from "react-icons/bs";

const Footer = () => {
  const { t } = useTranslation();

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="slick__dot ' + className + '"></span>';
    },
  };

  return (
    <>
      <Swiper
        className="slider"
        loop
        modules={[Pagination]}
        slidesPerView={5}
        pagination={pagination}
        breakpoints={{
          998: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 4,
          },
          480: {
            slidesPerView: 3,
          },
          0: {
            slidesPerView: 2,
          },
        }}
      >
        {/* <SwiperSlide className="slider__item">
          <div className="slider__item">
            <img src="/assets/img/slider1.png" alt="binance" />
          </div>
        </SwiperSlide> */}

        <SwiperSlide className="slider__item">
          <div className="slider__item">
            <img width="60" src={usdtIcon} alt="usdt money" />
          </div>
        </SwiperSlide>

        <SwiperSlide className="slider__item">
          <div className="slider__item">
            <img width="120" src={travalaIcon} alt="travala money" />
          </div>
        </SwiperSlide>

        <SwiperSlide className="slider__item">
          <div className="slider__item">
            <img width="200" src={mtbIcon} alt="mtb" />
          </div>
        </SwiperSlide>

        <SwiperSlide className="slider__item">
          <div className="slider__item">
            <img src="/assets/img/slider3.png" alt="visa" />
          </div>
        </SwiperSlide>

        <SwiperSlide className="slider__item">
          <div className="slider__item">
            <img src="/assets/img/slider4.png" alt="telegram" />
          </div>
        </SwiperSlide>

        <SwiperSlide className="slider__item">
          <div className="slider__item">
            <img src="/assets/img/slider5.png" alt="free kassa" />
          </div>
        </SwiperSlide>

        <SwiperSlide className="slider__item">
          <div className="slider__item">
            <img src="/assets/img/slider3.png" alt="visa" />
          </div>
        </SwiperSlide>
      </Swiper>

      <footer className="footer">
        <div className="container">
          <div className="footer__inner">
            <div className="footer__top">
              <div className="footer__logo--box">
                <Link to="/" className="footer__logo--inner">
                  <img
                    src="/assets/img/logo.png"
                    alt="Логотип"
                    className="img100"
                  />
                </Link>

                <p className="footer__title mt2">{t("Footer.about")}</p>

                <p className="footer__text">{t("Footer.title")}</p>
              </div>

              <div className="footer__nav--inner">
                <p className="footer__title">{t("Footer.documents")}</p>

                <Link to="public-offer" className="footer__nav--link">
                  {t("Footer.contract")}
                </Link>

                <a
                  href="https://find-and-update.company-information.service.gov.uk/company/13247736"
                  className="footer__nav--link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Footer.register")}
                </a>
              </div>

              <div className="footer__nav--inner">
                <p className="footer__title">{t("Footer.contacts")}</p>

                <p className="footer__nav--text">
                  First Floor Suite 6 High Street
                </p>

                <p className="footer__nav--text">
                  Sedgefield, Stockton-On-Tees, England, TS21 3AR
                </p>

                {/* <a href="tel:442039338117" className="footer__nav--link">
                  +442039338117
                </a> */}

                {/* <a
                  href="mailto:info@dct-trips.com"
                  className="footer__nav--link"
                >
                  info@dct-trips.com
                </a> */}
              </div>
            </div>

            <div className="footer__bottom">
              <div className="footer__social--inner">
                <a
                  href="https://t.me/+Nohi6R2WDIc1MGQy"
                  className="footer__social--link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="footer__social--icon"
                    alt="Иконка"
                    src="/assets/img/telegram.svg"
                  />
                </a>

                <a
                  href="https://www.youtube.com/channel/UCaG5gT5ZZN9rwzW6SIMo4ng"
                  className="footer__social--link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="footer__social--icon"
                    alt="Иконка"
                    src="/assets/img/youtube.svg"
                  />
                </a>
              </div>

              <div className="footer__wrapper">
                <Link to="/publick-news" className="footer__link">
                  <Box mr="1rem">
                    <BsNewspaper size={16} color="#158aff" />
                  </Box>
                  {t("Footer.news")}
                </Link>

                <Link to="/contact" className="footer__link">
                  <Box mr="1rem">
                    <RiContactsBookLine size={18} color="#158aff" />
                  </Box>
                  {t("Footer.contacts")}
                </Link>
              </div>
            </div>

            <p className="footer__copy">
              &copy; 2022 Dreams Come True.{t("Footer.rights")}
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
