import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Moment from "react-moment";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";
import { getChosenNews } from "../../../features/news/newsSlice";

import { CurrencyRates } from "../../../Components/LK/CurrencyRates/CurrencyRates";
import { GlobalSpinner } from "../../../Components/LK/GlobalSpinner/GlobalSpinner";

import { BsNewspaper } from "react-icons/bs";

import { Text, Flex, Image, Box } from "@chakra-ui/react";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { LangEnum } from "../../Dashboard/NewsList";
import Meta from "../../../utils/seo/Meta";

export const UserChosenNews: FC = () => {
  // Translation i18next
  const { t } = useTranslation();

  const { id } = useParams();
  const { userData } = useAppSelector((state) => state.userInfo);
  const { chosenNews, isLoading } = useAppSelector((state) => state.news);
  const dispatch = useAppDispatch();
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    const data = {
      id: id,
    };

    dispatch(getChosenNews(data));
  }, []);

  useEffect(() => {
    let inMyLangItem = chosenNews?.news?.content.find(
      (e) => e.lang === userData?.interfaceLang
    );
    if (!inMyLangItem?.text) {
      inMyLangItem = chosenNews?.news?.content.find(
        (e) => e.lang === LangEnum.Ru
      );
    }
    if (inMyLangItem?.text) {
      try {
        JSON.parse(inMyLangItem.text);
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(inMyLangItem.text))
          )
        );
      } catch (e) {
        console.error(e);
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(inMyLangItem.text)
          )
        );
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [chosenNews]);

  const onEditorStateChange = (
    // editorState: React.SetStateAction<EditorState>
    editorState: any
  ) => {
    setEditorState(editorState);
  };

  if (isLoading) {
    return <GlobalSpinner />;
  }

  return (
    <>
      <Meta title="Personal account" />
      <div className="admin__content--content flex w100">
        <div className="w100 flexcenter">
          <h3 className="admin__title--inner w100">
            <BsNewspaper
              size={24}
              className="admin__title--icon"
              color="#FF3158"
            />
            {t("UserNewsPage.news")} - &nbsp;
            <Moment format="DD.MM.YYYY">{chosenNews?.news?.createdAt}</Moment>
          </h3>

          <div className="w100 flexcenter draw__content ">
            <Flex direction="column" w="100%" mx="auto" fontSize={16}>
              <Box mt={2}>
                <Text></Text>
                <Image
                  mt={2}
                  src={chosenNews?.news?.imageUrl}
                  alt="News photo"
                  borderRadius="35px"
                />
              </Box>
              <Flex direction="column" justify="center">
                <Text mt={5} fontSize={32}>
                  {chosenNews?.news?.content.find(
                    (e) => e.lang === userData?.interfaceLang
                  )?.title ??
                    chosenNews?.news?.content.find(
                      (e) => e.lang === LangEnum.Ru
                    )?.title}
                </Text>
                <Text mt={5} fontSize={24}>
                  {chosenNews?.news?.content.find(
                    (e) => e.lang === userData?.interfaceLang
                  )?.preview ??
                    chosenNews?.news?.content.find(
                      (e) => e.lang === LangEnum.Ru
                    )?.preview}
                </Text>
                {/* <Text mt={5}> {chosenNews?.content[0]?.text}</Text> */}
                <Editor
                  editorState={editorState}
                  readOnly
                  toolbarHidden
                  toolbarClassName="toolbarClassName"
                  // wrapperClassName="wrapperClassName"
                  // editorClassName="editorClassName"
                  // {...register("text", {
                  //   required: "Поле обязательно к заполнению",
                  // })}
                  onEditorStateChange={onEditorStateChange}
                />
              </Flex>
            </Flex>
          </div>
        </div>
      </div>
      <div className="admin__content--right flex w100">
        <CurrencyRates />
      </div>
    </>
  );
};
