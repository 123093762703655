import React, { useState, useEffect, useLayoutEffect } from "react";
import $api from "../../../http/apiService";

import { useForm } from "react-hook-form";

import { getPublickReviews } from "../../../features/review/reviewSlice";
import { useAppSelector, useAppDispatch } from "../../../store";

import { usePagination } from "../../../hooks/pagination/usePagination";
import Moment from "react-moment";

import { Rating } from "../../../Components/LK/Rating/Rating";

import { GlobalSpinner } from "../../../Components/LK/GlobalSpinner/GlobalSpinner";

import { toast } from "react-toastify";

import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

export const reviewsPerPage = 4;

const Reviews = () => {
  const { accessToken } = useAppSelector((state) => state.auth);
  const { publicReviews, isLoading } = useAppSelector((state) => state.review);
  const dispatch = useAppDispatch();

  const [rate, setRate] = useState<number>(5);

  // Scroll to top
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Initialize React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
  });

  // Form handler to Backend
  const onSubmit = async (data: any) => {
    const fetchData = {
      name: data.name,
      text: data.text,
      rate: rate,
    };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post("/api/Review/create", fetchData, config);
      reset({
        name: "",
        text: "",
      });
      toast.success("Отзыв отправлен!");
    } catch (error) {
      reset({
        name: "",
        text: "",
      });
      toast.error("Что то пошло не так. Попробуйте позже!!");
      console.log(error);
    }
  };

  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: reviewsPerPage,
    count: publicReviews.totalCount,
  });

  useEffect(() => {
    const reviewData = {
      take: reviewsPerPage,
      skip: (page - 1) * reviewsPerPage,
    };
    dispatch(getPublickReviews(reviewData));
  }, [page]);

  if (isLoading) {
    return <GlobalSpinner />;
  }

  return (
    <>
      <Meta title="Reviews" description="Отзывы о платформе!" />
      <section className="section__padding auth">
        <img src="/assets/img/wave.png" alt="Фон" className="faq__img" />

        <div className="container">
          <Wrap w="100%" spacing="70px">
            {publicReviews?.items?.map((review, index) => (
              <WrapItem w={["100%", "100%", "44%"]} key={review?.id}>
                <Center w={60} h="100%">
                  <Avatar
                    w="120px"
                    h="120px"
                    mx={4}
                    name={
                      review?.user?.name
                        ? review?.user?.name
                        : review?.user?.login
                    }
                    src={review?.user?.imageUrl}
                  />
                </Center>
                <Flex direction="column" w={["100%", "60%"]} alignItems="start">
                  <p className="review__item--name">
                    {review?.user?.name
                      ? review?.user?.name
                      : review?.user?.login}
                  </p>

                  <p className="review__item--text">{review?.text}</p>
                  <Text color="#158aff" fontSize={16}>
                    <Moment format="DD MMMM YYYY, hh:mm:ss">
                      {review?.createdAt}
                    </Moment>
                  </Text>
                </Flex>
              </WrapItem>
            ))}
          </Wrap>
        </div>
      </section>

      {publicReviews.totalCount <= reviewsPerPage ? (
        <Box></Box>
      ) : (
        <Center w="100%">
          <div className="pagination">
            <Button
              onClick={prevPage}
              w={24}
              h={12}
              colorScheme="blue"
              borderRadius="xl"
              isDisabled={page === 1}
            >
              Назад
            </Button>
            <button
              onClick={() => setPage(1)}
              className={`page ${page === 1 && "disabled"}`}
            >
              1
            </button>
            {gaps.before ? "..." : null}

            {gaps.paginationGroup.map((el) => (
              <button
                onClick={() => setPage(el)}
                key={el}
                className={`page ${page === el ? "active" : ""}`}
              >
                {el}
              </button>
            ))}
            {gaps.after ? "..." : null}
            <button
              onClick={() => setPage(totalPages)}
              className={`page ${page === totalPages && "disabled"}`}
            >
              {totalPages}
            </button>

            <Button
              onClick={nextPage}
              w={24}
              h={12}
              colorScheme="blue"
              borderRadius="xl"
              isDisabled={page === totalPages}
            >
              Вперед
            </Button>
          </div>
        </Center>
      )}

      {/* {accessToken && (
        <div className="container">
          <Center w={"100%"} my={20}>
            <div className="contact__form">
              <p className="title">
                <span className="black blue">Напишите</span> отзыв:
              </p>

              <div className="auth__input--inner full contact__input--inner">
                <input
                  type="text"
                  placeholder="Ваше имя"
                  className="input auth__input"
                  {...register("name", {
                    required: "Поле обязательно к заполнению",
                    pattern: {
                      value: /^[a-zA-Za-яА-Я](.[a-zа-яA-ZА-Я0-9]*)$/,
                      message:
                        "Имя должно начинаться с буквы. Допустимы буквы и цифры!",
                    },
                    minLength: {
                      value: 4,
                      message: "Минимум 4 символа!",
                    },
                    maxLength: {
                      value: 20,
                      message: "Максимум 20 символов!",
                    },
                  })}
                />
                {errors?.name && (
                  <Text fontSize="xl" fontWeight="semibold" color="red.500">
                    {errors.name.message || "Error!"}
                  </Text>
                )}
              </div>

              <div className="auth__input--inner full contact__input--inner">
                <input
                  type="text"
                  placeholder="Текст сообщения"
                  className="input auth__input"
                  {...register("text", {
                    required: "Поле обязательно к заполнению",
                    pattern: {
                      value: /^[a-zа-яA-ZА-Я0-9](.[a-zа-яA-ZА-Я0-9. ]*)$/,
                      message: "Допустимы буквы,цифры и .",
                    },
                    minLength: {
                      value: 4,
                      message: "Минимум 4 символа!",
                    },
                    maxLength: {
                      value: 200,
                      message: "Максимум 200 символов!",
                    },
                  })}
                />
                {errors?.text && (
                  <Text fontSize="xl" fontWeight="semibold" color="red.500">
                    {errors.text.message || "Error!"}
                  </Text>
                )}
              </div>

              <Center w="100%">
                <Rating isEditable={true} rating={rate} setRating={setRate} />
              </Center>

              <button
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
                className="button default__button contact__button"
              >
                Отправить
              </button>
            </div>
          </Center>
        </div>
      )} */}
    </>
  );
};

export default Reviews;
