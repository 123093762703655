import React, { FC, useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Center,
  Input,
  Text,
  Select,
  Img,
  Box,
} from "@chakra-ui/react";
import "react-phone-number-input/style.css";
import { SubmitHandler, useForm } from "react-hook-form";
import $api from "../../http/apiService";
import { toast } from "react-toastify";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { ContentType, StatusWebinar, WebinarType } from "./WebinarList";
import { LangEnum } from "./NewsList";

type Iprops = {
  isOpen: boolean;
  onOpen: () => void;
  onCloseActionModal: () => void;
  webinar: WebinarType | undefined;
  isNeedUpdate: number;
  setIsNeedUpdate: any;
  setChosenItem: any;
  isDelete: boolean;
  setIsDelete: any;
};
type IFormInput = {
  id?: number | string;
  date: string;
  urlStream: string;
  urlRecord: string;
  "status.id"?: number | string;
  // content: ContentType[];
  "content[0].title"?: string;
  "content[0].desc"?: string;
  "content[1].title"?: string;
  "content[1].desc"?: string;
};
const emptyItem: IFormInput = {
  id: "",
  date: "",
  urlStream: "",
  urlRecord: "",
  "status.id": "",
};

const WebinarModal = ({
  isOpen,
  onOpen,
  onCloseActionModal,
  webinar,
  isNeedUpdate,
  setIsNeedUpdate,
  setChosenItem,
  isDelete,
  setIsDelete,
}: Iprops) => {
  const newsfileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<any>(null);
  const [fileUrl, setFileUrl] = useState<any>(null);
  const [contents, setContents] = useState<ContentType[]>(
    Object.keys(LangEnum).map((e: string) => {
      const newC: ContentType = { lang: e, title: "", desc: "" };
      return newC;
    })
  );
  // React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: "all",
  });

  // Default values hook form initialize
  useEffect(() => {
    if (!webinar) return;
    reset(webinar);
    const tempContents: ContentType[] = Object.keys(LangEnum).map(
      (e: string) => {
        const newC: ContentType = { lang: e, title: "", desc: "" };
        return newC;
      }
    );
    webinar?.content.forEach((element) => {
      const item = tempContents.find((elem) => elem.lang === element.lang);
      if (item) {
        item.title = element?.title;
        item.desc = element?.desc;
      }
    });
    setContents(tempContents);
  }, [webinar]);

  const onSubmitForm: SubmitHandler<IFormInput> = async (data: any) => {
    console.log("data", data);
    type RDType = {
      Date: string;
      UrlStream: string;
      UrlRecord: string;
      Status: number;
      Content: ContentType[];
      Image?: File;
    };
    let date: string = data?.date;
    if (date?.length == 0) {
      date = new Date().toISOString();
    } else if (date?.length < 19) {
      date = `${data?.date}:00`;
    }

    const rd: any = {
      Date: date,
      UrlStream: data?.urlStream,
      UrlRecord: data?.urlRecord,
      Status: data?.status.id,
      // Content: [
      //   {
      //     lang: 1,
      //     title: data.content[0].title,
      //     desc: data.content[0].desc,
      //   },
      //   {
      //     lang: 2,
      //     title: data.content[1].title,
      //     desc: data.content[1].desc,
      //   },
      // ],
      // "Content[0].lang": 1,
      // "Content[0].title": data.content[0].title,
      // "Content[0].desc": data.content[0].desc,
      // "Content[1].lang": 2,
      // "Content[1].title": data.content[1].title,
      // "Content[1].desc": data.content[1].desc,
    };
    let j = 0;
    contents?.forEach((element: ContentType, i: number) => {
      if (element.title?.length > 0) {
        rd[`Content[${j}].lang`] = Object.keys(LangEnum)[i];
        rd[`Content[${j}].title`] = element.title;
        rd[`Content[${j}].desc`] = element.desc;
        j++;
      }
    });
    if (file) {
      rd.Image = file;
    }
    // console.log("rd", rd);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    if (isDelete && webinar) {
      handleDelete();
    } else if (!webinar) {
      try {
        const response = await $api.post("/api/admin/Webinar", rd, config);
        toast("Вебинар успешно добавлен");
        setIsNeedUpdate(isNeedUpdate + 1);
        handleClose();
      } catch (e) {
        console.error("Ошибка добавления вебинара", e);
        toast.error("Ошибка добавления вебинара");
      }
    } else {
      if (rd.Image) {
        const response1 = await $api.put(
          `/api/admin/Webinar/${webinar.id}/image`,
          rd.Image,
          config
        );
      }
      try {
        const response2 = await $api.put(
          `/api/admin/Webinar/${webinar.id}`,
          rd,
          config
        );
        toast("Вебинар успешно обновлен");
        setIsNeedUpdate(isNeedUpdate + 1);
        handleClose();
      } catch (e) {
        console.error("Ошибка редактирования вебинара", e);
        toast.error("Ошибка редактирования вебинара");
      }
    }
  };

  // const onUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
  const onUploadFile = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setFileUrl(reader.result);
        // setOpenCrop(true);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const resetForm = () => {
    console.log("reset");
    setChosenItem(undefined);
    setIsDelete(false);
    reset(emptyItem);
    setContents(
      Object.keys(LangEnum).map((e: string) => {
        const newC: ContentType = { lang: e, title: "", desc: "" };
        return newC;
      })
    );
    setFile(null);
    setFileUrl(null);
  };

  const handleDelete = async () => {
    if (webinar) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await $api.delete(
          `/api/admin/Webinar/${webinar.id}`,
          config
        );
        toast("Вебинар успешно удален");
        setIsNeedUpdate(isNeedUpdate + 1);
        // resetForm();
        handleClose();
      } catch (e) {
        console.error("Ошибка удаления вебинара", e);
        toast.error("Ошибка удаления вебинара");
      }
    }
  };

  const handleClose = () => {
    resetForm();
    onCloseActionModal();
    // resetForm();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      size={"5xl"}
      // isCentered
    >
      <ModalOverlay
        backdropBlur="5px"
        bg="blackAlpha.300"
        backdropFilter="blur(10px) "
      />
      <ModalContent>
        <ModalHeader fontSize={24} color="blue.500" fontWeight="bold">
          <Center>
            {/* {isDelete.toString()}
            {webinar?.id} */}
            {isDelete && "Удалить вебинар"}
            {!isDelete && webinar?.id && "Редактирование вебинара"}
            {!isDelete && !webinar?.id && "Создание вебинара"}
          </Center>
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <ModalBody fontSize={16}>
            <Text fontSize={12} color="gray.500" mt={8}>
              Ссылка на стрим
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              type="text"
              disabled={isDelete}
              bg={isDelete ? "gray.300" : ""}
              {...register("urlStream", {
                // required: "Поле обязательно к заполнению",
              })}
            />

            <Text fontSize={12} color="gray.500" mt={8}>
              Ссылка на запись
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              type="text"
              disabled={isDelete}
              bg={isDelete ? "gray.300" : ""}
              {...register("urlRecord", {
                // required: "Поле обязательно к заполнению",
              })}
            />

            <Text fontSize={12} color="gray.500" mt={8}>
              Дата
            </Text>
            <Input
              type="datetime-local"
              // type="date"
              width="auto"
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              h={30}
              borderRadius="xl"
              disabled={isDelete}
              bg={isDelete ? "gray.300" : ""}
              {...register("date", {
                // required: "Поле обязательно к заполнению",
              })}
            />
            <Text fontSize={12} color="gray.500" mt={8}>
              Статус*
            </Text>
            <Select
              border="1px solid #3786e5"
              variant="primary"
              size="lg"
              color="gray.500"
              borderRadius="xl"
              fontSize={[12, 8, 10, 12, 14]}
              {...register("status.id")}
            >
              {/* <option value={-1}>Все</option> */}
              {StatusWebinar.map((elem) => (
                <option key={elem.id} value={elem.id}>
                  {elem.name}
                </option>
              ))}
            </Select>
            <Tabs mt={4} bg="blue.50">
              <TabList>
                {Object.keys(LangEnum).map((e, i) => (
                  <Tab key={i}>{e}</Tab>
                ))}
              </TabList>

              <TabPanels>
                {Object.keys(LangEnum).map((e, i) => (
                  <TabPanel key={i}>
                    <Box>
                      <Text fontSize={12} color="gray.500" mt={8}>
                        Название на {e}
                      </Text>
                      <Input
                        variant="primary"
                        fontSize={[12, 8, 10, 12, 14]}
                        borderRadius="xl"
                        h={30}
                        type="text"
                        disabled={isDelete}
                        bg={isDelete ? "gray.300" : ""}
                        // {...register(`content[${i}].title`, {
                        // })}
                        value={contents?.[i]?.title}
                        onChange={(event) => {
                          if (!contents) return;
                          const newContents = [...contents];
                          const content = newContents.find(
                            (el) => el.lang === e
                          );
                          if (content) {
                            content.title = event.target.value;
                            setContents(newContents);
                          } else {
                            const content: ContentType = {
                              lang: e,
                              title: event.target.value,
                              desc: "",
                            };
                            newContents.push(content);
                            setContents(newContents);
                          }
                        }}
                      />
                      <Text fontSize={12} color="gray.500" mt={8}>
                        Описание на {e}
                      </Text>
                      <Input
                        variant="primary"
                        fontSize={[12, 8, 10, 12, 14]}
                        borderRadius="xl"
                        h={30}
                        type="text"
                        disabled={isDelete}
                        bg={isDelete ? "gray.300" : ""}
                        // {...register(`content[${i}].title`, {
                        // })}
                        value={contents?.[i]?.desc}
                        onChange={(event) => {
                          if (!contents) return;
                          const newContents = [...contents];
                          const content = newContents.find(
                            (el) => el.lang === e
                          );
                          if (content) {
                            content.desc = event.target.value;
                            setContents(newContents);
                          } else {
                            const content: ContentType = {
                              lang: e,
                              desc: event.target.value,
                              title: "",
                            };
                            newContents.push(content);
                            setContents(newContents);
                          }
                        }}
                      />
                    </Box>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>

            <Text fontSize={12} color="gray.500" mt={8}>
              Изображение*
            </Text>
            <input
              style={{ display: "none" }}
              id="image-input"
              type="file"
              accept="image/jpeg,image/png"
              multiple={false}
              ref={newsfileInputRef}
              onChange={(e) => onUploadFile(e)}
            />
            {!fileUrl && webinar && <Img src={webinar?.imageUrl} w="200" />}
            {fileUrl && <Img src={fileUrl} w="100" />}
            <Button
              colorScheme="blue"
              borderRadius="xl"
              h={[16, 20]}
              mt={4}
              width="80"
              fontSize={[12, 8, 10, 12, 14]}
              onClick={() => newsfileInputRef.current?.click()}
            >
              <Text fontSize={[12, 16]}>Загрузить</Text>
            </Button>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={handleSubmit(onSubmitForm)}
              isDisabled={!isValid}
              type="submit"
              colorScheme="facebook"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="dark-lg"
            >
              {isDelete ? "Удалить" : "Сохранить"}
            </Button>
            <Button
              colorScheme="twitter"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="dark-lg"
              onClick={handleClose}
              // onClick={onCloseActionModal}
            >
              Закрыть
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default WebinarModal;
