import { Text } from "@chakra-ui/react";
import React from "react";
import { useTimer } from "react-timer-hook";

export const TimerSixtyMinutes = ({ expiryTimestamp }) => {
  const onExpire = async () => {
    await console.log("time is close");
  };

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => onExpire(),
  });

  return (
    <Text Text color="red.500" fontWeight="bold">
      <span>{minutes}</span>:<span>{seconds}</span>
    </Text>
  );
};
