import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/ru";

import { useAppSelector, useAppDispatch } from "../../../store";
import { getChosenNews } from "../../../features/news/newsSlice";

import { GlobalSpinner } from "../../../Components/LK/GlobalSpinner/GlobalSpinner";

import { Text, Flex, Image, Box } from "@chakra-ui/react";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { LangEnum } from "../../Dashboard/NewsList";
import Meta from "../../../utils/seo/Meta";

export const PublickChosenNews: FC = () => {
  const { id } = useParams();
  const { userData } = useAppSelector((state) => state.userInfo);
  const { chosenNews, isLoading } = useAppSelector((state) => state.news);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const data = {
      id: id,
    };

    dispatch(getChosenNews(data));
  }, []);

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const onEditorStateChange = (
    // editorState: React.SetStateAction<EditorState>
    editorState: any
  ) => {
    setEditorState(editorState);
  };
  useEffect(() => {
    let inMyLangItem = chosenNews?.news?.content.find(
      (e) => e.lang === userData?.interfaceLang
    );
    if (!inMyLangItem?.text) {
      inMyLangItem = chosenNews?.news?.content.find(
        (e) => e.lang === LangEnum.Ru
      );
    }
    if (inMyLangItem?.text) {
      try {
        JSON.parse(inMyLangItem.text);
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(inMyLangItem.text))
          )
        );
      } catch (e) {
        console.error(e);
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(inMyLangItem.text)
          )
        );
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [chosenNews]);

  return (
    <>
      <Meta title="Public news" description="Новость платформы!" />

      <div className="container">
        <div className="news__inner">
          <div>
            {isLoading ? (
              <GlobalSpinner />
            ) : (
              <Flex direction="column" w="100%" mx="auto" fontSize={16}>
                <Box mt={2}>
                  <Moment format="DD MMMM YYYY">
                    {chosenNews?.news?.createdAt}
                  </Moment>
                  <Image
                    mt={2}
                    src={chosenNews?.news?.imageUrl}
                    alt="page photo"
                    borderRadius="35px"
                  />
                </Box>
                <Flex direction="column" justify="center">
                  <Text mt={5} fontSize={32}>
                    {chosenNews?.news?.content.find(
                      (e) => e.lang === userData?.interfaceLang
                    )?.title ??
                      chosenNews?.news?.content.find(
                        (e) => e.lang === LangEnum.Ru
                      )?.title}
                  </Text>
                  <Text mt={5} fontSize={24}>
                    {chosenNews?.news?.content.find(
                      (e) => e.lang === userData?.interfaceLang
                    )?.preview ??
                      chosenNews?.news?.content.find(
                        (e) => e.lang === LangEnum.Ru
                      )?.preview}
                  </Text>
                  {/* <Text mt={5}> {chosenNews?.news?.content[0]?.text}</Text> */}
                  <Editor
                    editorState={editorState}
                    readOnly
                    toolbarHidden
                    toolbarClassName="toolbarClassName"
                    onEditorStateChange={onEditorStateChange}
                  />
                </Flex>
              </Flex>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
