import React, { FC, useEffect, useState } from "react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Center,
  Input,
  Text,
  Select,
  Box,
} from "@chakra-ui/react";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import { SubmitHandler, useForm } from "react-hook-form";
import $api from "../../http/apiService";
import { IdNameType } from "./UserList";
import { toast } from "react-toastify";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { PaymentTypeInRD } from "./PaymentsList";
import { DescriptionType } from "./OutputList";
import { LangEnum } from "./NewsList";

type Iprops = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  payment: PaymentTypeInRD | undefined;
  setPayment: any;
  isNeedUpdate: number;
  setIsNeedUpdate: any;
  statuses: any;
  accounts: IdNameType[];
  methods: IdNameType[];
  articles: IdNameType[];
};
type IFormInput = {
  date: string;
  sum: number | string; //сумма
  "descriptions[0].desc": string;
  "user.login": string;
  "article.id": number | string;
  "account.id": number | string; //счет
  transactionId: string;
  comment: string;
  // status: string;
  "status.id": number | string; // статус
  statusChangedAt: string;
  walletId: string; //кошелек
  externalTransactionId: string;
  "paymentSystem.id": number | string; //платежная система
  editor: string;
};
const emptyItem: IFormInput = {
  date: "",
  sum: "",
  "descriptions[0].desc": "",
  "user.login": "",
  "article.id": "",
  "account.id": "",
  transactionId: "",
  comment: "",
  // status: "",
  "status.id": "",
  statusChangedAt: "",
  walletId: "",
  externalTransactionId: "",
  "paymentSystem.id": "",
  editor: "",
};
const PaymentItem = (props: Iprops) => {
  const [isLoginTo, setIsLoginTo] = useState(props.payment ? true : false);
  const [descriptions, setDescriptions] = useState<DescriptionType[]>(
    Object.keys(LangEnum).map((e: string) => {
      const newD: DescriptionType = { lang: e, desc: "" };
      return newD;
    })
  );
  // React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: "all",
  });

  // useEffect(() => {
  //   console.log("descriptions", descriptions);
  // }, []);

  const handleClose = async () => {
    await props.setPayment(undefined);
    setDescriptions(
      Object.keys(LangEnum).map((e: string) => {
        const newD: DescriptionType = { lang: e, desc: "" };
        return newD;
      })
    );
    reset(emptyItem);
    props.onClose();
  };

  // Default values hook form initialize
  useEffect(() => {
    if (props?.payment) {
      const tempDescriptions: DescriptionType[] = Object.keys(LangEnum).map(
        (e: string) => {
          const newC: DescriptionType = { lang: e, desc: "" };
          return newC;
        }
      );
      props.payment?.descriptions.forEach((element) => {
        const item = tempDescriptions.find(
          (elem) => elem.lang === element.lang
        );
        if (item) {
          item.desc = element?.desc;
        }
      });
      setDescriptions(tempDescriptions);
      reset(props.payment);
      setIsLoginTo(true);
    } else setIsLoginTo(false);
  }, [props.payment]);

  const checkLogin = async (login: string) => {
    const rd = {
      login,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await $api.post(`api/User/is-login-free`, rd, config);
      // console.log("response", response);
      if (response.status === 200) {
        setIsLoginTo(!response.data.free);
      }
    } catch (e) {
      console.error("Ошибка проверки логина", e);
    }
  };

  const onSubmitForm: SubmitHandler<IFormInput> = async (data: any) => {
    console.log("data", data);
    const rd = {
      login: data.user.login ?? "",
      date: data.date ?? new Date().toISOString(),
      accountId: data.account.id,
      articleId: data.article.id,
      amount: data.sum,
      status: data.status.id,
      // descriptions: [
      //   {
      //     lang: 1,
      //     desc: data.descriptions[0].desc ?? "",
      //   },
      // ],
      descriptions: descriptions,
      comment: data.comment ?? "",
      external: {
        walletId: data.walletId ?? "",
        externalTransactionId: data.externalTransactionId ?? "",
        paymentSystemId: data.paymentSystem.id,
      },
    };

    const notEmptyDescriptions: DescriptionType[] = [];
    descriptions.forEach((e) => {
      if (e.desc.length > 0) {
        notEmptyDescriptions.push(e);
      }
    });
    rd.descriptions = notEmptyDescriptions;

    if (data.date?.length == 0) {
      rd.date = new Date().toISOString();
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (props.payment) {
      try {
        const response = await $api.put(
          `/api/admin/Payment/${props.payment.id}`,
          rd,
          config
        );
        if (response.status === 200) {
          toast(`Платеж успешно обновлен`);
        }
        props.setIsNeedUpdate(props.isNeedUpdate + 1);
        handleClose();
      } catch (e) {
        console.error("Ошибка редактирования платежа", e);
        toast.error("Ошибка редактирования платежа");
      }
    } else {
      try {
        const response = await $api.post("/api/admin/Payment", rd, config);
        if (response.status === 200) {
          toast(`Платеж успешно добавлен`);
        }
        props.setIsNeedUpdate(props.isNeedUpdate + 1);
        handleClose();
      } catch (e) {
        console.error("Ошибка добавления платежа", e);
        toast.error("Ошибка добавления платежа");
      }
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={handleClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      size={"5xl"}
      isCentered
    >
      <ModalOverlay
        backdropBlur="5px"
        bg="blackAlpha.300"
        backdropFilter="blur(10px) "
      />
      <ModalContent>
        <ModalHeader fontSize={[20, 24]} color="blue.500" fontWeight="bold">
          <Center>
            {props.payment
              ? `Редактирование платежа с ID:  ${props?.payment?.id}`
              : "Создание платежа"}
          </Center>
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <ModalBody fontSize={16}>
            {/* <Text fontSize={12} color="gray.500" mt={8}>
              Дата создания
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              disabled
              placeholder="Дата создания"
              bg="gray.100"
              {...register("date")}
            /> */}

            {/* <Text fontSize={12} color="gray.500" mt={8}>
              Дата редактирования
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              readOnly
              disabled
              bg="gray.100"
              placeholder="Дата редактирования"
              {...register("statusChangedAt")}
            /> */}

            <Text fontSize={12} color="gray.500" mt={[2, 8]}>
              Логин*
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              // readOnly
              // disabled
              // bg="gray.100"
              // placeholder="Логин"
              {...register("user.login", {
                required: "Поле обязательно к заполнению",
                onChange: (e) => checkLogin(e.target.value),
              })}
            />
            <Text fontSize={10} color={isLoginTo ? "blue.500" : "red.500"}>
              {isLoginTo ? "Логин существует" : "Введите валидный логин"}
            </Text>

            <Text fontSize={12} color="gray.500" mt={8}>
              Статус
            </Text>
            <Select
              border="1px solid #3786e5"
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              color="gray.500"
              {...register("status.id", {
                required: "Поле обязательно к заполнению",
              })}
            >
              {/* <option value={-1}>Все</option> */}
              {props?.statuses?.length > 0 &&
                props.statuses.map((elem: any) => (
                  <option key={elem.id} value={elem.id}>
                    {elem?.name}
                  </option>
                ))}
            </Select>

            {/* <Text fontSize={12} color="gray.500" mt={8}>
              Статус
            </Text>
            <Select
              border="1px solid #3786e5"
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              color="gray.500"
              {...register("status", {
                required: "Поле обязательно к заполнению",
              })}
            >
              {props?.statuses?.length > 0 &&
                props.statuses.map((elem: any) => (
                  <option key={elem.id} value={elem.name}>
                    {elem?.name}
                  </option>
                ))}
            </Select> */}

            <Text fontSize={12} color="gray.500" mt={8}>
              Счет*
            </Text>
            <Select
              border="1px solid #3786e5"
              variant="primary"
              size="lg"
              color="gray.500"
              borderRadius="xl"
              fontSize={[12, 8, 10, 12, 14]}
              {...register("account.id", {
                required: "Поле обязательно к заполнению",
              })}
            >
              {/* <option value={-1}>Все</option> */}
              {props?.accounts?.length > 0 &&
                props.accounts.map((elem) => (
                  <option key={elem.id} value={elem.id}>
                    {elem.name}
                  </option>
                ))}
            </Select>

            <Text fontSize={12} color="gray.500" mt={8}>
              Метод оплаты*
            </Text>
            <Select
              border="1px solid #3786e5"
              variant="primary"
              size="lg"
              color="gray.500"
              borderRadius="xl"
              fontSize={[12, 8, 10, 12, 14]}
              {...register("paymentSystem.id", {
                required: "Поле обязательно к заполнению",
              })}
            >
              {/* <option value={-1}>Все</option> */}
              {props.methods.length > 0 &&
                props.methods.map((elem) => (
                  <option key={elem.id} value={elem.id}>
                    {elem.name}
                  </option>
                ))}
            </Select>

            <Text fontSize={12} color="gray.500" mt={8}>
              Статья*
            </Text>
            <Select
              border="1px solid #3786e5"
              variant="primary"
              size="lg"
              color="gray.500"
              borderRadius="xl"
              fontSize={[12, 8, 10, 12, 14]}
              {...register("article.id", {
                required: "Поле обязательно к заполнению",
              })}
            >
              {/* <option value={-1}>Все</option> */}
              {props.articles &&
                props.articles.length > 0 &&
                props.articles?.map((elem) => (
                  <option key={elem.id} value={elem.id}>
                    {elem.name}
                  </option>
                ))}
            </Select>

            <Text fontSize={12} color="gray.500" mt={8}>
              Кошелек
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              type="text"
              // placeholder="Кошелек"
              {...register("walletId")}
            />

            <Text fontSize={12} color="gray.500" mt={8}>
              Сумма*
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              type="number"
              // placeholder="Сумма"
              {...register("sum", {
                required: "Поле обязательно к заполнению",
              })}
            />

            {/* <Text fontSize={12} color="gray.500" mt={8}>
              Описание
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              type="text"
              // placeholder="Описание"
              {...register(
                "descriptions[0].desc"
                // , {
                //   required: "Поле обязательно к заполнению",
                // }
              )}
            /> */}
            <Tabs mt={4} bg="blue.50">
              <TabList>
                {Object.keys(LangEnum).map((e, i) => (
                  <Tab key={i}>{e}</Tab>
                ))}
              </TabList>

              <TabPanels>
                {Object.keys(LangEnum).map((e, i) => (
                  <TabPanel key={i}>
                    <Box>
                      <Text fontSize={12} color="gray.500" mt={8}>
                        Описание {e}
                      </Text>
                      <Input
                        variant="primary"
                        fontSize={[12, 8, 10, 12, 14]}
                        borderRadius="xl"
                        h={30}
                        type="text"
                        value={descriptions[i].desc}
                        onChange={(event) => {
                          const newDescriptions = [...descriptions];
                          const description = newDescriptions.find(
                            (el) => el.lang === e
                          );
                          if (description) {
                            description.desc = event.target.value;
                            setDescriptions(newDescriptions);
                          } else {
                            const description: DescriptionType = {
                              lang: e,
                              desc: event.target.value,
                            };
                            newDescriptions.push(description);
                            setDescriptions(newDescriptions);
                          }
                        }}
                      />
                    </Box>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>

            <Text fontSize={12} color="gray.500" mt={8}>
              Комментарий
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              type="text"
              h={30}
              // placeholder="Комментарий"
              {...register("comment")}
            />

            <Text fontSize={12} color="gray.500" mt={8}>
              Автор
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              type="text"
              h={30}
              disabled
              readOnly
              bg="gray.300"
              // placeholder="Комментарий"
              {...register("editor")}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={handleSubmit(onSubmitForm)}
              isDisabled={!isValid}
              type="submit"
              // colorScheme="blue"
              colorScheme="facebook"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="dark-lg"
            >
              Сохранить
            </Button>
            <Button
              colorScheme="twitter"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="dark-lg"
              onClick={handleClose}
            >
              Закрыть
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default PaymentItem;
