import {
  AddIcon,
  DragHandleIcon,
  EditIcon,
  ExternalLinkIcon,
  HamburgerIcon,
  InfoOutlineIcon,
  RepeatIcon,
  UnlockIcon,
} from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import exp from "constants";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OutputType, WithdrawalInType } from "./OutputList";

type Iprops = {
  item: WithdrawalInType;
  setChosenItem: any;
  setItem: any;
  onOpen: any;
};

const OutputMenu = (props: Iprops) => {
  const navigate = useNavigate();
  return (
    <Box
    // filter="auto" blur="" zIndex="1000000"
    >
      <Menu
        onClose={() => {
          props.setChosenItem(undefined);
        }}
      >
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
          _hover={{ bg: "gray.400" }}
          _expanded={{ bg: "blue.400" }}
          // _focus={{ boxShadow: "outline" }}
          onClick={() => {
            props.setChosenItem(props.item);
          }}
        />
        <MenuList ml="12">
          <MenuItem
            icon={<EditIcon />}
            onClick={() => {
              props.setItem(props.item);
              props.onOpen();
            }}
            //   command="⌘T"
          >
            Редактировать вывод
          </MenuItem>
          <MenuItem
            icon={<ExternalLinkIcon />}
            onClick={() => {
              navigate(`/dashboard/payments/${props?.item.user?.login}`);
            }}
            //   command="⌘T"
          >
            Ведомость партнера
          </MenuItem>
          {/* <MenuItem
            icon={<ExternalLinkIcon />}
            onClick={() => {
              navigate(`/dashboard/payments/${props?.item.userId}`);
            }}
            //   command="⌘T"
          >
            Ведомость партнера
          </MenuItem> */}
          
         
        </MenuList>
      </Menu>
    </Box>
  );
};

export default OutputMenu;
