import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./utils/cropImage";
import { useAppDispatch } from "../../../store";
import { setUserAvatar } from "../../../features/userInfo/userInfoSlice";

import { toast } from "react-toastify";
import { BsCrop } from "react-icons/bs";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useMediaQuery,
  Box,
  Text,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";

export const CropEasy = ({
  file,
  setFile,
  setOpenCrop,
  openCrop,
  myCropShape,
  myAspect,
  setBlob,
}) => {
  const [isLargerThan770] = useMediaQuery("(min-width: 770px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    // setBlob(croppedImage);
  }, []);

  const cropImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        file,
        croppedAreaPixels,
        rotation
      );
      

      if (myCropShape === "round") {
        dispatch(setUserAvatar(croppedImage));
        setFile(null);
      }
      if (myCropShape === "rect") {
        // let croppedImg = URL.createObjectURL(file);
        let mimeType = file.type;
        // let reader = new FileReader();
        // reader.readAsArrayBuffer(croppedImage.url);

        // let croppedFile = new File([croppedImage], "image_file", { type: mimeType });
        // const croppedfile = new File(croppedImage, "image.png");

        // setFile(croppedImage);

        // const url = URL.createObjectURL(croppedImage);
        // console.log('url', url)

        setBlob(croppedImage);
        // setFile(croppedFile);
      }

      setOpenCrop(false);
    } catch (e) {
      toast.error(e.message);
    }
  }, [croppedAreaPixels, rotation]);

  const onCancelHandler = () => {
    setFile(null);
    setCroppedImage(null);
    setOpenCrop(false);
  };

  return (
    <>
      <Modal isOpen={openCrop} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Cropper
              image={file}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={myAspect / 1}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              cropShape={myCropShape}
            />
          </ModalBody>

          <ModalFooter>
            <Flex
              m={2}
              direction={isLargerThan770 ? "row" : "column"}
              justifyContent="space-between"
              w="100%"
              alignItems="center"
            >
              <Box w={["70%", "30%"]} m={2}>
                <Text fontSize={20} fontWeight="semibold">
                  Приблизить
                </Text>
                <Slider
                  aria-label="zoom"
                  min={1}
                  max={2}
                  step={0.1}
                  value={zoom}
                  onChange={(e) => setZoom(e, zoom)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Box>
              <Box w={["70%", "30%"]} m={2}>
                <Text fontSize={20} fontWeight="semibold">
                  Развернуть
                </Text>
                <Slider
                  aria-label="rotation"
                  min={0}
                  max={360}
                  step={1}
                  value={rotation}
                  onChange={(e, rotation) => setRotation(e, rotation)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Box>

              <Flex
                justifyContent="space-between"
                flexDirection="row"
                w={["70%", "60%", "30%"]}
                m={2}
              >
                <Button
                  w={[44, 60]}
                  h={20}
                  colorScheme="pink"
                  onClick={onCancelHandler}
                >
                  <Text fontSize={[16, 20]}>Отмена</Text>
                </Button>

                <Button
                  w={[44, 60]}
                  h={20}
                  leftIcon={<BsCrop size={20} />}
                  colorScheme="blue"
                  boxShadow="2xl"
                  onClick={cropImage}
                >
                  <Text fontSize={[16, 20]}>Обрезать</Text>
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
