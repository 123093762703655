import React, { useEffect, useState } from "react";
import "./DashBoard.scss";
import { useAppSelector, useAppDispatch } from "../../store";
import {
  getMessageList,
  deleteMessageById,
} from "../../features/globalChat/globalChatSlice";

import { usePagination } from "../../hooks/pagination/usePagination";

import { motion } from "framer-motion";
import Moment from "react-moment";
import { CheckIcon, SmallCloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { ExternalPaymentType, UserType } from "./PaymentsList";

const itemPerPage = 10;

import {
  Box,
  Button,
  Center,
  Heading,
  useMediaQuery,
  Wrap,
  extendTheme,
  Avatar,
  Spacer,
  Text,
} from "@chakra-ui/react";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-26px)",
};

export const OutputStatuses = [
  { id: 0, value: "Отменен", ingl: "Canceled" },
  { id: 1, value: "В ожидании", ingl: "Pending" },
  { id: 2, value: "Исполнен", ingl: "Executed" },
];

export type OutputType = {
  id: number;
  creationDate: string;
  editDate: string;
  login: string; // контрагент
  comments: string; // комментарии
  status: string; // статус
  account: string; //счет
  paymentSystem: string; //Платежная система
  wallet: string; //кошелек
  sum: number; //сумма
};

export type IdNameType = {
  id: number;
  name: string;
};

export const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
              fontSize: "xl",
            },
          },
        },
      },
    },
  },
});
export type DescriptionType = {
  lang: number;
  desc: string;
};
export type WithdrawalInType = {
  id: number;
  date?: string;
  sum?: number;
  desc?: DescriptionType;
  user?: UserType;
  article?: IdNameType;
  account?: IdNameType;
  transactionId?: string;
  comment?: string;
  status?: IdNameType;
  statusChangedAt?: string;
  externalPayment: ExternalPaymentType;
  editor: string;
  rate: number;
  fiatSum: number;
  // walletId?: string;
  // externalTransactionId?: string;
  // paymentSystem?: IdNameType;
};

enum ReasonsForModal {
  cancelWithdrawal = "cancelWithdrawal",
  payWithdrawal = "payWithdrawal",
  payPartlyWithdrawal = "payPartlyWithdrawal",
}

const messagePerPage = 10;

const GlobalChat = () => {
  const { messageList } = useAppSelector((state) => state.globalChat);

  const [newFetch, setNewFetch] = useState(false);

  const dispatch = useAppDispatch();

  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: messagePerPage,
    count: messageList.totalCount,
  });

  const deleteMessageHandler = (id: number) => {
    const fetchId = {
      id: id,
    };

    dispatch(deleteMessageById(fetchId));
    setTimeout(() => {
      setNewFetch(!newFetch);
    }, 500);
  };

  // Pagination request
  useEffect(() => {
    const data = {
      take: 10,
      skip: (page - 1) * messagePerPage,
    };
    dispatch(getMessageList(data));
  }, [page, newFetch]);

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");

  return (
    <Box w="100%">
      <Wrap m={8}>
        <Box minWidth={[220, 220]}>
          <Heading as="h2" mt={6} size="xl" mb="4" color="#6484AA">
            Глобальный чат
          </Heading>
        </Box>
        <Spacer />
      </Wrap>

      <Box
        m={8}
        color="#6484AA"
        fontSize={[12, 10, 12, 14, 14]}
        fontWeight="500"
      >
        <div className="dashBoard_content__margin dashBoard_w100 dashBoard_flex dashBoard_admin__table">
          {isLargerThan500 && (
            <div
              className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
              style={{ padding: "5px" }}
            >
              <p className="dashBoard_operation__item--column11min2">
                <Center>ID</Center>
              </p>

              <p className="dashBoard_operation__item--column8">
                <Center>Дата</Center>
              </p>

              <p className="dashBoard_operation__item--column11">
                <Center>Логин </Center>
              </p>

              <p className="dashBoard_operation__item--column11">
                <Center>Имя </Center>
              </p>

              <p className="dashBoard_operation__item--column11">
                <Center>Аватар </Center>
              </p>

              <p className="dashBoard_operation__item--column5">
                <Center>Сообщение</Center>
              </p>

              <p className="dashBoard_operation__item--column11">
                <Center>Статус </Center>
              </p>

              <p className="dashBoard_operation__item--column11">
                <Center>Верификация</Center>
              </p>

              <p className="dashBoard_operation__item--column11">
                <Center>Действия</Center>
              </p>
            </div>
          )}

          {messageList &&
            messageList?.items?.length > 0 &&
            messageList.items.map((message, index) => (
              <Box
                w="100%"
                key={message.id}
                fontSize={[12, 8, 10, 12, 14]}
                color="#000000"
                borderBottom="1px solid #dfdfdf"
              >
                <div
                  key={message.id}
                  className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
                >
                  <p className="dashBoard_operation__item--column11min2">
                    <Center fontWeight={"700"}>
                      {!isLargerThan500 && (
                        <Text color="blue.400" fontWeight="600">
                          ID:
                        </Text>
                      )}
                      &nbsp;
                      {message?.id}
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column8">
                    <Center filter="auto" ml="12">
                      <div
                        style={{
                          display: "flex",
                          margin: "0 avto",
                        }}
                      >
                        {!isLargerThan500 && (
                          <Text color="blue.400" fontWeight="600">
                            Дата:
                          </Text>
                        )}
                        &nbsp;
                        <Moment format="DD MMMM YYYY, HH:mm:ss">
                          {message?.createdAt}
                        </Moment>
                      </div>
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column11">
                    <Center filter="auto">
                      {!isLargerThan500 && (
                        <Text color="blue.400" fontWeight="600">
                          Логин:
                        </Text>
                      )}
                      &nbsp;
                      {message?.user?.login}
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column11">
                    <Center filter="auto">
                      {!isLargerThan500 && (
                        <Text color="blue.400" fontWeight="600">
                          Имя:
                        </Text>
                      )}
                      &nbsp;
                      {message?.user?.name ? message?.user?.name : "-"}
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column11">
                    <Center filter="auto">
                      <Avatar
                        size={isLargerThan500 ? "xl" : "lg"}
                        src={message?.user?.imageUrl}
                        name={
                          message?.user?.name
                            ? message?.user?.name
                            : message?.user?.login
                        }
                      />
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column5">
                    <Center my={2} mx={[3, 2, 1]} wordBreak="break-word">
                      {message?.text}
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column11">
                    <Center filter="auto">
                      {!isLargerThan500 && (
                        <Text color="blue.400" fontWeight="600">
                          Статус:
                        </Text>
                      )}
                      &nbsp;
                      <div
                        className={
                          message?.user?.isOnline
                            ? "point_admin_chat_green"
                            : "point_admin_chat_red"
                        }
                      ></div>
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column11">
                    <Center filter="auto">
                      {!isLargerThan500 && (
                        <Text color="blue.400" fontWeight="600">
                          Верификация:
                        </Text>
                      )}
                      &nbsp;
                      {message?.user?.isVerified ? (
                        <CheckIcon color="green.400" />
                      ) : (
                        <SmallCloseIcon color="red.500" />
                      )}
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column11">
                    <Center>
                      <Button
                        onClick={() => deleteMessageHandler(message.id)}
                        variant="ghost"
                      >
                        <DeleteIcon color="red.500" w={8} h={8} />
                      </Button>
                    </Center>
                  </p>
                </div>
              </Box>
            ))}

          {messageList.totalCount > itemPerPage && (
            <Center fontSize="12">
              <div className="pagination">
                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={prevPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === 1}
                >
                  Назад
                </Button>
                <button
                  onClick={() => setPage(1)}
                  className={`page ${page === 1 && "disabled"}`}
                >
                  1
                </button>
                {gaps.before ? "..." : null}

                {gaps.paginationGroup.map((el) => (
                  <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                  >
                    {el}
                  </button>
                ))}
                {gaps.after ? "..." : null}
                <button
                  onClick={() => setPage(totalPages)}
                  className={`page ${page === totalPages && "disabled"}`}
                >
                  {totalPages}
                </button>

                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1, cursor: "pointer" }}
                  onClick={nextPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === totalPages}
                >
                  Вперед
                </Button>
              </div>
            </Center>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default GlobalChat;
