import React, { FC, useRef } from "react";
import { Link } from "react-router-dom";

import LanguageSelect from "../../LK/LanguageSelect/LanguageSelect";

import { useAppSelector } from "../../../store";

import { useTranslation } from "react-i18next";

import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Text,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Box,
  Heading,
} from "@chakra-ui/react";

export const MobileSidebar: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);
  const { userData, userStat } = useAppSelector((state) => state.userInfo);

  // Translation i18next
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Button
          height={12}
          width={12}
          onClick={onOpen}
          ref={btnRef}
          as={IconButton}
          variant="solid"
          icon={<HamburgerIcon />}
        >
          Open
        </Button>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          size="md"
          closeOnEsc={true}
          closeOnOverlayClick={true}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <Box bgColor={"#1c3b6a"} overflowY="scroll" h="100%">
              {/* <Box m={2}>
                <LanguageSelect />
              </Box> */}

              <Heading
                onClick={onClose}
                mt={4}
                ml={8}
                fontFamily="Graphik"
                fontSize="18px"
                color="#FFFFFF"
              >
                <Link to="/admin/">{t("Sidebar.personal_area")}</Link>
              </Heading>

              <Heading
                mt={4}
                ml={8}
                fontFamily="Graphik"
                fontSize="18px"
                color="#FFFFFF"
              >
                {t("Sidebar.programme")}
              </Heading>

              <DrawerBody>
                <Flex
                  onClick={onClose}
                  ml={20}
                  direction="column"
                  justifyContent="space-between"
                  align="flex-start"
                >
                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/presentation">
                      {t("Sidebar.presentation")}
                    </Link>
                  </Text>

                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/partners-programs">
                      {t("Sidebar.partnership_programs")}
                    </Link>
                  </Text>

                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/partners-programs-new">
                      {t("Sidebar.partnership_programs_new")}
                    </Link>
                  </Text>


                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/struct">{t("Sidebar.structure")}</Link>
                  </Text>
                </Flex>
              </DrawerBody>

              <Heading
                mt={4}
                ml={8}
                fontFamily="Graphik"
                fontSize="18px"
                color="#FFFFFF"
              >
                {t("Sidebar.finance")}
              </Heading>
              <DrawerBody>
                <Flex
                  onClick={onClose}
                  ml={20}
                  direction="column"
                  justifyContent="space-between"
                  align="flex-start"
                >
                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/input-money">
                      {t("Sidebar.top_up_account")}
                    </Link>
                  </Text>

                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/output-money">
                      {t("Sidebar.withdrawals")}
                    </Link>
                  </Text>

                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/operation">
                      {t("Sidebar.operations_history")}
                    </Link>
                  </Text>
                </Flex>
              </DrawerBody>

              <Heading
                mt={4}
                ml={8}
                fontFamily="Graphik"
                fontSize="18px"
                color="#FFFFFF"
              >
                {t("Sidebar.platform")}
              </Heading>
              <DrawerBody>
                <Flex
                  onClick={onClose}
                  ml={20}
                  direction="column"
                  justifyContent="space-between"
                  align="flex-start"
                >
                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/user-news">{t("Sidebar.news")}</Link>
                  </Text>

                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/webinars">{t("Sidebar.webinars")}</Link>
                  </Text>

                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/partners">{t("Sidebar.our_partners")}</Link>
                  </Text>

                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/vacancies">{t("Sidebar.jobs")}</Link>
                  </Text>

                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/review">{t("Sidebar.review")}</Link>
                  </Text>

                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/adv">{t("Sidebar.promotional_materials")}</Link>
                  </Text>

                  {/* <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/travel">{t("Sidebar.spend_bonuses")}</Link>
                  </Text> */}

                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/faq">{t("Sidebar.question_answer")}</Link>
                  </Text>
                </Flex>
              </DrawerBody>

              <Heading
                mt={4}
                ml={8}
                fontFamily="Graphik"
                fontSize="18px"
                color="#FFFFFF"
              >
                {t("Sidebar.control")}
              </Heading>
              <DrawerBody>
                <Flex
                  onClick={onClose}
                  ml={20}
                  direction="column"
                  justifyContent="space-between"
                  align="flex-start"
                >
                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/settings">
                      {t("Sidebar.cabinet_settings")}
                    </Link>
                  </Text>

                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/tickets">
                      {t("Sidebar.those_support")}
                    </Link>
                  </Text>

                  <Text
                    m={"2"}
                    fontFamily="Graphik"
                    fontSize="16px"
                    color="#ACAFBE"
                  >
                    <Link to="/admin/notify">{t("Sidebar.notifications")}</Link>
                  </Text>

                  {userData?.isAdmin && (
                    <Heading
                      mt={4}
                      ml={8}
                      fontFamily="Graphik"
                      fontSize="18px"
                      color="#FFFFFF"
                    >
                      <Link to="/dashboard">AdminDasboard</Link>
                    </Heading>
                  )}
                </Flex>
              </DrawerBody>

              <Box
                position="relative"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                bg="#61D64A"
                maxW="300px"
                maxH="100px"
                mx="auto"
                my={2}
                borderRadius="10px"
                p={8}
                fontFamily="Graphik"
                fontSize="16px"
                fontWeight="400"
                color="#FFFFFF"
                zIndex={5}
              >
                <Box zIndex={10}>
                  <Flex justifyContent="space-around">
                    <Text fontSize="18px" fontWeight="700">
                      {t("Sidebar.earned")}
                    </Text>
                    <Text> {userStat.financeStat.income}$</Text>
                  </Flex>

                  <Flex justifyContent="space-around">
                    <Text fontSize="18px" fontWeight="700">
                      {t("Sidebar.brought_out")}
                    </Text>
                    <Text> {userStat.financeStat.expenses}$</Text>
                  </Flex>
                </Box>

                <Box
                  position="absolute"
                  // left={"14%"}
                  top={"5%"}
                  bg="rgba(124, 177, 63, 0.3)"
                  opacity={0.75}
                  width="90%"
                  height="100px"
                  borderRadius="10px"
                  zIndex={-10}
                >
                  <Box
                    position="absolute"
                    left={4}
                    top={6}
                    bg="rgba(124, 177, 63, 0.3)"
                    opacity={0.5}
                    width="80%"
                    height="90px"
                    borderRadius="10px"
                    zIndex={-5}
                  ></Box>
                </Box>
              </Box>

              <Box ml={12} my={4}>
                <Text color="#FFF" fontSize="14px" fontFamily="Graphik">
                  © 2020-{new Date().getFullYear()}{" "}
                  {t("Sidebar.all_rights_reserved")}
                </Text>
              </Box>
            </Box>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  );
};
