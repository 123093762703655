import React, { FC, useEffect } from "react";
import "./DashBoard.scss";
import { Link, useNavigate } from "react-router-dom";
import { isValidMotionProp, motion } from "framer-motion";
import { VscSettings, VscSymbolStructure } from "react-icons/vsc";
import { ImStatsDots } from "react-icons/im";
import {
  AiOutlineRollback,
  AiOutlineDown,
  AiFillHome,
  AiTwotoneSetting,
  AiOutlineEye,
  AiOutlineDollarCircle,
  AiOutlineMail,
  AiOutlineRead,
} from "react-icons/ai";
import {
  MdAdminPanelSettings,
  MdManageAccounts,
  MdOutlinePeople,
} from "react-icons/md";

import {
  Box,
  Center,
  chakra,
  Collapse,
  Divider,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { BsChatDots, BsCurrencyBitcoin, BsNewspaper } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === "children",
});

type Iprops = {
  onClose?: any;
};

const DashBoardNavBar = (props: Iprops) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isOpen: isOpen1Menu, onToggle: onToggle1Menu } = useDisclosure();
  const { isOpen: isOpen2Menu, onToggle: onToggle2Menu } = useDisclosure();
  useEffect(() => {
    onToggle1Menu();
  }, []);
  return (
    <>
      <div className="dashBoard__nav">
        <Center>
          <ChakraBox
            animate={{
              scale: [1, 2, 2, 1, 1],
              rotate: [0, 0, 270, 270, 0],
              borderRadius: ["20%", "20%", "50%", "50%", "20%"],
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore no problem in operation, although type error appears.
            transition={{
              duration: 3,
              delay: 0,
              ease: "easeInOut",
              repeat: Infinity,
              repeatType: "loop",
            }}
            padding="2"
            bgGradient="linear(to-l, #553C9A, #3786e5)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="40px"
            height="40px"
          >
            <MdAdminPanelSettings color="white" />
          </ChakraBox>
        </Center>

        <Flex m={10} color="white">
          <Box mr="4" mt="2">
            <AiFillHome />
          </Box>
          {t("Manager.admin_panel")}
        </Flex>
        <Flex ml={10} color="white" onClick={onToggle1Menu}>
          <Box mr="4" mt="2">
            <VscSettings />
          </Box>
          {t("Manager.main_part")}
          <Box ml="8" mt="2">
            <AiOutlineDown />
          </Box>
        </Flex>
        <Collapse in={isOpen1Menu} animateOpacity>
          <Flex>
            <Center w="4px" ml="16">
              {" "}
              <Divider orientation="vertical" mt="16" />
            </Center>

            <Box
              color="white"
              mt="4"
            >
              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition="0.2s linear"
                ml={10}
              >
                <Link
                  to="/dashboard"
                  className="dashBoard__navLink"
                  onClick={props?.onClose}
                >
                  {/* <Search2Icon mr={"10px"} /> */}
                  <Flex>
                    <MdOutlinePeople size={20} />
                    <Box ml={2}> {t("Manager.users")}</Box>
                  </Flex>
                </Link>
              </Box>
              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition="0.2s linear"
                ml={10}
              >
                <Link
                  to="/dashboard/outputs"
                  className="dashBoard__navLink"
                  onClick={props?.onClose}
                >
                  {/* <EditIcon mr={"10px"} /> */}
                  <Flex>
                    {/* <SunIcon mr={"10px"} /> */}
                    <AiOutlineDollarCircle size={20} />
                    <Box ml={2}> {t("Manager.withdrawals")}</Box>
                  </Flex>
                </Link>
              </Box>
              <Box
                as={motion.div}
                whileHover={{ scale: 1.05, color: "red" }}
                whileTap={{ scale: 0.9 }}
                // transition="0.2s linear"
                ml={10}
              >
                <Link
                  to="/dashboard/news"
                  className="dashBoard__navLink"
                  onClick={props?.onClose}
                >
                  {/* <InfoIcon mr={"10px"} /> */}
                  <Flex>
                    <BsNewspaper size={20} />
                    <Box ml={2}> {t("Manager.news")}</Box>
                  </Flex>
                </Link>
              </Box>
              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition="0.2s linear"
                ml={10}
              >
                <Link
                  to="/dashboard/payments"
                  className="dashBoard__navLink"
                  onClick={props?.onClose}
                >
                  {/* <ArrowUpDownIcon mr={"10px"} /> */}
                  <Flex>
                    <AiOutlineRead size={20} />
                    <Box ml={2}> {t("Manager.payments_history")}</Box>
                  </Flex>
                </Link>
              </Box>
              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition="0.2s linear"
                ml={10}
              >
                <Link
                  to="/dashboard/cryptotoken"
                  className="dashBoard__navLink"
                  onClick={props?.onClose}
                >
                  {/* <SettingsIcon mr={"10px"} /> */}
                  <Flex>
                    <BsCurrencyBitcoin size={20} />
                    <Box ml={2}> {t("Manager.crypto_tokens")}</Box>
                  </Flex>
                </Link>
              </Box>
              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition="0.2s linear"
                ml={10}
              >
                <Link
                  to="/dashboard/profile"
                  className="dashBoard__navLink"
                  onClick={props?.onClose}
                >
                  <Flex>
                    {/* <SunIcon mr={"10px"} /> */}
                    <AiOutlineEye size={20} />
                    <Box ml={2}>{t("Manager.profile")}</Box>
                  </Flex>
                </Link>
              </Box>
              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition="0.2s linear"
                ml={10}
              >
                <Link
                  to="/dashboard/referals"
                  className="dashBoard__navLink"
                  onClick={props?.onClose}
                >
                  {/* <SunIcon mr={"10px"} /> */}
                  <Flex>
                    <VscSymbolStructure size={20} />
                    <Box ml={2}> {t("Manager.referrals")}</Box>
                  </Flex>
                </Link>
              </Box>
            </Box>
          </Flex>
        </Collapse>

        <Flex ml={10} mt={4} color="white" onClick={onToggle2Menu}>
          <Box mr="4" mt="2">
            <AiTwotoneSetting />
          </Box>
          {t("Manager.settings")}
          <Box ml="8" mt="2">
            <AiOutlineDown />
          </Box>
        </Flex>
        <Collapse in={true} animateOpacity>
          <Flex>
            <Center w="4px" ml="16">
              {" "}
              <Divider orientation="vertical" mt="16" />
            </Center>

            <Box color="white" mt="4">
              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition="0.2s linear"
                ml={10}
              >
                <Link
                  to="/dashboard/sitesettings"
                  className="dashBoard__navLink"
                  onClick={props?.onClose}
                >
                  {/* <RepeatIcon mr={"10px"} /> */}
                  <Flex>
                    <MdManageAccounts size={20} />
                    <Box ml={2}> {t("Manager.administration")}</Box>
                  </Flex>
                </Link>
              </Box>
              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition="0.2s linear"
                ml={10}
              >
                <Link
                  to="/dashboard/feedbacks"
                  className="dashBoard__navLink"
                  onClick={props?.onClose}
                >
                  {/* <LinkIcon mr={"10px"} /> */}
                  <Flex>
                    <AiOutlineMail size={20} />
                    <Box ml={2}> {t("Manager.feedback")}</Box>
                  </Flex>
                </Link>
              </Box>

              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition="0.2s linear"
                ml={10}
              >
                <Link
                  to="/dashboard/chat"
                  className="dashBoard__navLink"
                  onClick={props?.onClose}
                >
                  {/* <ChatIcon mr={"10px"} /> */}
                  <Flex>
                    <BsChatDots size={20} />
                    <Box ml={2}> {t("Manager.chat")}</Box>
                  </Flex>
                </Link>
              </Box>

              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition="0.2s linear"
                ml={10}
              >
                <Link
                  to="/dashboard/ym"
                  className="dashBoard__navLink"
                  onClick={props?.onClose}
                >
                  {/* <ChatIcon mr={"10px"} /> */}
                  <Flex>
                    <ImStatsDots size={20} />
                    <Box ml={2}> {t("Manager.yandex_metrics")}</Box>
                  </Flex>
                </Link>
              </Box>

              {/* <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition="0.2s linear"
                ml={10}
              >
                <Link
                  to="/dashboard/"
                  className="dashBoard__navLink"
                  onClick={props?.onClose}
                >
                  <EditIcon mr={"10px"} />
                  Tampilan
                </Link>
              </Box>
              <Box
                as={motion.div}
                whileHover={{ scale: 1.05, color: "red" }}
                whileTap={{ scale: 0.9 }}
                // transition="0.2s linear"
                ml={10}
              >
                <Link
                  to="/dashboard/"
                  className="dashBoard__navLink"
                  onClick={props?.onClose}
                >
                  <InfoIcon mr={"10px"} />
                  GithubDB
                </Link>
              </Box> */}
            </Box>
          </Flex>
        </Collapse>

        {/* <Flex ml={10} mt={4} color="white">
          <Box mr="4" mt="2">
            <FaDatabase />
          </Box>
          DataBase
        </Flex> */}

        <Box
          as={motion.div}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition="0.2s linear"
          ml={10}
        >
          <Link to="/admin" className="dashBoard__navLink">
            <AiOutlineRollback />
          </Link>
        </Box>
      </div>
    </>
  );
};

export default DashBoardNavBar;
