import React, { useEffect, useState } from "react";

import $api from "../../../http/apiService";
import { useNavigate } from "react-router-dom";

import { takeCountReferrals } from "./ReferralStructure";
import Moment from "react-moment";

import { GlobalSpinner } from "../GlobalSpinner/GlobalSpinner";
import { motion } from "framer-motion";

import { UpDownIcon } from "@chakra-ui/icons";
import { GiAlarmClock } from "react-icons/gi";
import { BsBagCheck } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";
import { CgCornerDownRight } from "react-icons/cg";
import QuestionAnswerIcon from "../../../assets/img/question-answer-line.svg";

import StartIcon from "../../../assets/img/StartIcon.png";
import fastIcon from "../../../assets/img/fastIcon.png";
import sprintIcon from "../../../assets/img/sprintIcon.png";
import jackpotIcon from "../../../assets/img/jackpotIcon.png";

import {
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  Collapse,
  CircularProgress,
  CircularProgressLabel,
  Avatar,
  Center,
  Tooltip,
  Button,
  Img,
} from "@chakra-ui/react";

export const ReferralStructureUniversal = (props) => {
  const navigate = useNavigate();

  const [referrals, setReferrals] = useState([]);
  const [page, setPage] = useState(1);
  const [userLogin, setUserLogin] = useState("");
  const [isPageReset, setIsPageReset] = useState(false);
  const [level, setLevel] = useState(props?.level + 1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.data?.items?.length > 0) {
      const tempReferals = [...props.data.items];
      tempReferals.forEach((elem) => (elem.isOpen = false));
      setReferrals(tempReferals);
    }
  }, [props.data]);

  // Reset page when parent open or close
  useEffect(() => {
    if (props.isPageReset) {
      setPage(1);
    }
  }, [props.isPageReset]);

  const handleClick = async ({ item }) => {
    setLoading(true);
    setUserLogin(item.login);
    if (!(item?.children || item?.children?.items?.length > 0)) {
      const data = {
        parentLogin: item.login,
        programId: 3,
        take: takeCountReferrals,
        skip: 0,
        onlyActivated: props.onlyActivated,
      };
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await $api.post(
          "/api/Marketing/structure",
          data,
          config
        );
        const addingReferrals = response.data;
        if (addingReferrals?.items?.length > 0) {
          for (let i = 0; i < addingReferrals?.items?.length; i++) {
            addingReferrals.items[i].isOpen = false;
          }
          const newReferrals = [...referrals];
          newReferrals.forEach((elem) => {
            if (elem.login === item.login) {
              elem.children = addingReferrals;
              elem.isOpen = !elem.isOpen;
            }
          });
          setReferrals(newReferrals);
          setIsPageReset(false);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      const newReferrals = [...referrals];
      newReferrals.forEach((elem) => {
        if (elem.login === item.login) {
          elem.children = undefined;
          elem.isOpen = !elem.isOpen;
        }
      });
      setReferrals(newReferrals);
      setIsPageReset(true);
      setLoading(false);
    }
  };

  // Load more referrals
  const loadMoreHandler = async () => {
    setLoading(true);
    const data = {
      parentLogin: props.login,
      programId: 3,
      take: takeCountReferrals,
      skip: takeCountReferrals * page,
      onlyActivated: props.onlyActivated,
    };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post(
        "/api/Marketing/structure",
        data,
        config
      );
      const addingReferrals = response.data?.items;
      for (let i = 0; i < addingReferrals?.length; i++) {
        addingReferrals[i].isOpen = false;
      }
      setReferrals([...referrals, ...addingReferrals]);
      setPage(page + 1);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  if (loading) {
    return <GlobalSpinner />;
  }

  const ProgIcons = (item) => {
    // useEffect(() => {
    //   console.log(item);
    //   console.log(item?.item);
    //   console.log(item?.item?.invites);
    // }, [item]);

    if (item?.item && item?.item?.invites)
      return (
        <Center w="100%">
          {item?.item?.invites?.[0]?.activatedAt && (
            <Text color="blue.500" m={2}>
              <Tooltip
                fontSize="18px"
                mr={2}
                label={item?.item?.invites?.[0]?.program ?? ""}
                hasArrow
                bg="blue.500"
              >
                <Box>
                  <GiAlarmClock size={24} />
                </Box>
              </Tooltip>
            </Text>
          )}

          {item?.item?.invites?.[1]?.activatedAt && (
            <Text color="green.500" m={2}>
              <Tooltip
                fontSize="18px"
                mr={2}
                label={item?.item?.invites?.[1]?.program ?? ""}
                hasArrow
                bg="green.500"
              >
                <Box>
                  <BsBagCheck size={24} />
                </Box>
              </Tooltip>
            </Text>
          )}

          {item?.item?.invites?.[2]?.activatedAt && (
            <Text color="red.500" m={2}>
              <Tooltip
                fontSize="18px"
                mr={2}
                label={item?.item?.invites?.[2]?.program ?? ""}
                hasArrow
                bg="red.500"
              >
                <Box>
                  <AiOutlineSetting size={24} />
                </Box>
              </Tooltip>
            </Text>
          )}

          {item?.item?.invites?.[3]?.activatedAt && (
            <Text color="purple.500" m={2}>
              <Tooltip
                fontSize="18px"
                mr={2}
                label={item?.item?.invites?.[3]?.program ?? ""}
                hasArrow
                bg="purple.500"
              >
                <Box>
                  <img
                    src={StartIcon}
                    alt=""
                    style={{ minWidth: "12px", maxWidth: "20px" }}
                  />
                </Box>
              </Tooltip>
            </Text>
          )}

          {item?.item?.invites?.[4]?.activatedAt && (
            <Text color="purple.500" m={2}>
              <Tooltip
                fontSize="18px"
                mr={2}
                label={item?.item?.invites?.[4]?.program ?? ""}
                hasArrow
                bg="purple.500"
              >
                <Box>
                  <img
                    src={fastIcon}
                    alt=""
                    style={{ minWidth: "12px", maxWidth: "20px" }}
                  />
                </Box>
              </Tooltip>
            </Text>
          )}

          {item?.item?.invites?.[5]?.activatedAt && (
            <Text color="purple.500" m={2}>
              <Tooltip
                fontSize="18px"
                mr={2}
                label={item?.item?.invites?.[5]?.program ?? ""}
                hasArrow
                bg="purple.500"
              >
                <Box>
                  <img
                    src={sprintIcon}
                    alt=""
                    style={{ minWidth: "12px", maxWidth: "20px" }}
                  />
                </Box>
              </Tooltip>
            </Text>
          )}

          {item?.item?.invites?.[6]?.activatedAt && (
            <Text color="purple.500" m={2}>
              <Tooltip
                fontSize="18px"
                mr={2}
                label={item?.item?.invites?.[6].program ?? ""}
                hasArrow
                bg="purple.500"
              >
                <Box>
                  <img
                    src={jackpotIcon}
                    alt=""
                    style={{ minWidth: "12px", maxWidth: "20px" }}
                  />
                </Box>
              </Tooltip>
            </Text>
          )}
        </Center>
      );
    return <></>;
  };

  return (
    <>
      {referrals?.length > 0 &&
        referrals?.map((item, index) => (
          <>
            {level == 2 && (
              <>
                <Box
                  key={item.id}
                  p={2}
                  mx="auto"
                  w="100%"
                  color="black"
                  fontSize={["sm", "md", "xl", "2xl"]}
                  textAlign="center"
                  borderTop="1px"
                  borderColor="#DFDFDF"
                  bgColor={item.isActive && "green.100"}
                >
                  <Grid
                    templateRows={"repeat(1, 1fr)"}
                    templateColumns={"repeat(12, 1fr)"}
                    gap={2}
                    textAlign="center"
                    w="100%"
                  >
                    <GridItem
                      colStart={1}
                      colEnd={2}
                      textAlign="center"
                      my="auto"
                    >
                      <Center>
                        <CgCornerDownRight color="#E0E0E0" size={36} />
                      </Center>
                    </GridItem>

                    <GridItem
                      colStart={2}
                      colEnd={3}
                      textAlign="center"
                      my="auto"
                    >
                      <Center h="100%" w="100%">
                        <Avatar
                          size={"lg"}
                          name={item.name ? item.name : item.login}
                          src={item.imageUrl}
                        />
                      </Center>
                    </GridItem>

                    <GridItem
                      colStart={3}
                      colEnd={[8, 8, 5]}
                      textAlign="center"
                    >
                      <Center w="100%" h="100%">
                        <Text fontWeight="medium">{item?.login}</Text>
                      </Center>
                    </GridItem>

                    <GridItem colStart={[8, 8, 5]} colEnd={[13, 13, 6]}>
                      <Center w="100%" h="100%">
                        <Text fontWeight="medium">
                          {item?.financialStat?.interest}{" "}
                        </Text>
                      </Center>
                    </GridItem>

                    <GridItem colStart={[2, 2, 6]} colEnd={[7, 7, 7]}>
                      <Center w="100%" h="100%">
                        <Text fontWeight="medium">
                          {item?.contacts?.socialMediaTg === "" ? (
                            "-"
                          ) : (
                            <a
                              href={`https://t.me/${item?.contacts.socialMediaTg}`}
                              target="_Blanc"
                              className="header__social--link"
                            >
                              @{item?.contacts.socialMediaTg}
                            </a>
                          )}
                        </Text>
                      </Center>
                    </GridItem>

                    <GridItem
                      colStart={[7, 7, 7]}
                      colEnd={[13, 13, 9]}
                      my="auto"
                    >
                      <ProgIcons item={item} />
                    </GridItem>

                    <GridItem
                      colStart={[1, 1, 10]}
                      colEnd={[7, 7, 11]}
                      my="auto"
                    >
                      <Text fontWeight="medium">
                        <Moment format="DD/MM/YYYY">{item.createdAt}</Moment>
                      </Text>
                    </GridItem>

                    <Center
                      as={motion.div}
                      whileHover={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/admin/tickets/${item?.id}&${item?.login}`);
                      }}
                    >
                      <Img src={QuestionAnswerIcon} alt="" />
                    </Center>

                    <GridItem colStart={[7, 7, 12]} colEnd={13} my="auto">
                      {item?.refCount !== 0 && (
                        <IconButton
                          onClick={() => handleClick({ item })}
                          colorScheme="blue"
                          boxShadow="xl"
                          h="38px"
                          w="43px"
                          borderRadius="10px"
                          aria-label="Open structure"
                          icon={<UpDownIcon width="18px" h="12px" />}
                        />
                      )}
                    </GridItem>
                  </Grid>
                </Box>
                <GridItem colStart={1} colEnd={13} my="auto">
                  <Collapse in={item.isOpen} animateOpacity w="100%">
                    <ReferralStructureUniversal
                      data={item?.children}
                      onlyActivated={props.onlyActivated}
                      login={userLogin}
                      isPageReset={isPageReset}
                      level={level}
                    />
                  </Collapse>
                </GridItem>
              </>
            )}

            {level == 3 && (
              <>
                <Box
                  key={index}
                  p={2}
                  mx="auto"
                  w="100%"
                  color="black"
                  fontSize={["sm", "md", "xl", "2xl"]}
                  textAlign="center"
                  borderTop="1px"
                  borderColor="#DFDFDF"
                  bgColor={item.isActive && "green.100"}
                >
                  <Grid
                    templateRows={"repeat(1, 1fr)"}
                    templateColumns={"repeat(12, 1fr)"}
                    gap={2}
                    textAlign="center"
                    w="100%"
                  >
                    <GridItem
                      colStart={2}
                      colEnd={3}
                      textAlign="center"
                      my="auto"
                    >
                      <Center>
                        <CgCornerDownRight color="#E0E0E0" size={32} />
                      </Center>
                    </GridItem>

                    <GridItem
                      colStart={3}
                      colEnd={4}
                      textAlign="center"
                      my="auto"
                    >
                      <Center h="100%" w="100%">
                        <Avatar
                          size="md"
                          name={item.name ? item.name : item.login}
                          src={item.imageUrl}
                        />
                      </Center>
                    </GridItem>

                    <GridItem
                      colStart={[4, 4, 4]}
                      colEnd={[8, 8, 6]}
                      textAlign="center"
                    >
                      <Center w="100%" h="100%">
                        <Text fontWeight="medium">{item?.login}</Text>
                      </Center>
                    </GridItem>

                    <GridItem colStart={[8, 8, 6]} colEnd={[13, 13, 7]}>
                      <Center w="100%" h="100%">
                        <Text fontWeight="medium">
                          {item?.financialStat?.interest}
                        </Text>
                      </Center>
                    </GridItem>

                    <GridItem colStart={[1, 1, 7]} colEnd={[7, 7, 8]}>
                      {item?.contacts?.socialMediaTg === "" ? (
                        ""
                      ) : (
                        <a
                          href={`https://t.me/${item?.contacts.socialMediaTg}`}
                          target="_Blanc"
                          className="header__social--link"
                        >
                          @{item?.contacts.socialMediaTg}
                        </a>
                      )}
                    </GridItem>

                    <GridItem
                      colStart={[7, 7, 8]}
                      colEnd={[13, 13, 10]}
                      my="auto"
                    >
                      <ProgIcons item={item} />
                    </GridItem>

                    <GridItem
                      colStart={[1, 1, 10]}
                      colEnd={[7, 7, 12]}
                      my="auto"
                    >
                      <Text fontWeight="medium">
                        <Moment format="DD/MM/YYYY">{item.createdAt}</Moment>
                      </Text>
                    </GridItem>

                    <Center
                      as={motion.div}
                      whileHover={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/admin/tickets/${item?.id}&${item?.login}`);
                      }}
                    >
                      <Img src={QuestionAnswerIcon} alt="" />
                    </Center>

                    <GridItem colStart={[7, 7, 12]} colEnd={13} my="auto">
                      {item.refCount !== 0 && (
                        <IconButton
                          onClick={() => handleClick({ item })}
                          colorScheme="blue"
                          boxShadow="xl"
                          h="38px"
                          w="43px"
                          borderRadius="10px"
                          aria-label="Open structure"
                          icon={<UpDownIcon width="18px" h="12px" />}
                        />
                      )}
                    </GridItem>
                  </Grid>
                </Box>
                <GridItem colStart={1} colEnd={13} my="auto">
                  <Collapse in={item.isOpen} animateOpacity w="100%">
                    <ReferralStructureUniversal
                      data={item?.children}
                      onlyActivated={props.onlyActivated}
                      login={userLogin}
                      isPageReset={isPageReset}
                      level={level}
                    />
                  </Collapse>
                </GridItem>
              </>
            )}

            {level >= 4 && (
              <Box
                key={index}
                // p={1}
                mx="auto"
                w="100%"
                color="black"
                fontSize={["sm", "md", "xl", "2xl"]}
                textAlign="center"
                borderTop="1px"
                borderColor="#DFDFDF"
              >
                <Center>
                  <Grid
                    templateRows={"repeat(1, 1fr)"}
                    templateColumns={"repeat(12, 1fr)"}
                    gap={2}
                    textAlign="center"
                    w="100%"
                  >
                    <GridItem
                      colStart={3}
                      colEnd={4}
                      textAlign="center"
                      my="auto"
                    >
                      <CircularProgress size="35px" value={100} color="red.500">
                        <CircularProgressLabel
                          fontSize="14px"
                          fontWeight="bold"
                          color="red.400"
                        >
                          {/* {item.level} */}
                          {level}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </GridItem>

                    <GridItem
                      colStart={4}
                      colEnd={6}
                      textAlign="center"
                      my="auto"
                    >
                      <Center h="100%" w="100%">
                        <Avatar
                          size="md"
                          name={item.name ? item.name : item.login}
                          src={item.imageUrl}
                        />
                      </Center>
                    </GridItem>

                    <GridItem
                      colStart={6}
                      colEnd={[9, 9, 7]}
                      textAlign="center"
                    >
                      <Center w="100%" h="100%">
                        <Text fontWeight="medium">{item?.login}</Text>
                      </Center>
                    </GridItem>

                    <GridItem colStart={[9, 9, 7]} colEnd={[13, 13, 8]}>
                      <Center w="100%" h="100%">
                        {item?.financialStat?.interest}
                      </Center>
                    </GridItem>

                    <GridItem colStart={[3, 3, 8]} colEnd={[8, 8, 9]}>
                      <Center w="100%" h="100%">
                        <Text fontSize={["xl", "2xl"]} fontWeight="medium">
                          {item?.contacts?.socialMediaTg === "" ? (
                            "-"
                          ) : (
                            <a
                              href={`https://t.me/${item?.contacts.socialMediaTg}`}
                              target="_Blanc"
                              className="header__social--link"
                            >
                              @{item?.contacts.socialMediaTg}
                            </a>
                          )}
                        </Text>
                      </Center>
                    </GridItem>

                    <GridItem
                      colStart={[8, 8, 9]}
                      colEnd={[13, 13, 10]}
                      my="auto"
                    >
                      <ProgIcons item={item} />
                    </GridItem>

                    <GridItem
                      colStart={[4, 4, 10]}
                      colEnd={[8, 8, 12]}
                      my="auto"
                    >
                      <Text fontWeight="medium">
                        <Moment format="DD/MM/YYYY">{item.createdAt}</Moment>
                      </Text>
                    </GridItem>

                    <Center
                      as={motion.div}
                      whileHover={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/admin/tickets/${item?.id}&${item?.login}`);
                      }}
                    >
                      <Img src={QuestionAnswerIcon} alt="" />
                    </Center>

                    <GridItem colStart={[8, 8, 12]} colEnd={13}>
                      <Center w="100%" h="100%">
                        {item.refCount !== 0 && (
                          <IconButton
                            onClick={() => handleClick({ item })}
                            colorScheme="blue"
                            boxShadow="xl"
                            mt={2}
                            h="38px"
                            w="43px"
                            borderRadius="10px"
                            aria-label="Open structure"
                            icon={<UpDownIcon width="18px" h="12px" />}
                          />
                        )}
                      </Center>
                    </GridItem>

                    <GridItem colStart={1} colEnd={13} my="auto">
                      <Collapse in={item.isOpen} animateOpacity w="100%">
                        <ReferralStructureUniversal
                          data={item?.children}
                          onlyActivated={props.onlyActivated}
                          login={userLogin}
                          isPageReset={isPageReset}
                          level={level}
                        />
                      </Collapse>
                    </GridItem>
                  </Grid>
                </Center>
              </Box>
            )}
          </>
        ))}

      {/* <Divider /> */}

      {props.data?.totalCount > referrals.length && (
        <Center w="100%" m={4}>
          <Button
            onClick={() => loadMoreHandler()}
            colorScheme="blue"
            boxShadow="xl"
            h="38px"
            w={["40%", "20%"]}
            borderRadius="10px"
            fontSize={["sm", "md", "xl", "2xl"]}
          >
            Показать еще
          </Button>
        </Center>
      )}
    </>
  );
};
