import React, { useEffect, useState } from "react";

import { useAppSelector } from "../../../store";

import Moment from "react-moment";

import { MessageType } from "../../../pages/LK/Tickets/Tickets";
import $api from "../../../http/apiService";

import { useTranslation } from "react-i18next";

import { Avatar, Box, Button, Flex, Text } from "@chakra-ui/react";

const itemsPerPage = 3;

export const Chat = () => {
  const { accessToken } = useAppSelector((state) => state.auth);
  const { userData } = useAppSelector((state) => state.userInfo);

  const [messages, setMessages] = useState<MessageType[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [text, setText] = useState<string>("");
  const { id } = useAppSelector((state) => state.userInfo.userData);

  // Translation i18next
  const { t } = useTranslation();

  const getMessages = async () => {
    const rd = {
      skip: currentPage * itemsPerPage,
      take: itemsPerPage,
    };
    const res = await $api.post("api/Message/global-list", rd);

    if (res?.status === 200 && res.data?.items) {
      if (!currentPage) {
        setMessages(res.data.items);
        setTotalCount(res.data.totalCount);
      } else {
        setMessages([...messages, ...res.data.items]);
        setTotalCount(res.data.totalCount);
      }
    }
  };

  const sendMessage = async () => {
    const rd = {
      text: text,
    };
    const res = await $api.post("api/Message/global-send", rd);

    if (res?.status === 200) {
      setCurrentPage(0);
      setText("");
      getMessages();
    }
  };

  useEffect(() => {
    if (!accessToken) return;
    setTimeout(() => {
      getMessages();
    }, 900);
  }, [currentPage, accessToken]);

  return (
    <>
      <div className="w100 flex default__margin">
        <h3 className="admin__title--inner w100">
          <img
            className="admin__title--icon"
            src="/assets/img/message-red.svg"
            alt="Иконка"
          />
          {t("Admin.online_chat")}
        </h3>
        {/* ************************************************* общий чат */}
        <div className="admin__chat flex w100 content__margin">
          {messages?.length > 0 &&
            messages.map((elem) => (
              <Box key={elem.id} w="100%">
                <div
                  className={
                    elem?.user?.id === id
                      ? "w100 flexdef ticket__message--inner me"
                      : "w100 flexdef ticket__message--inner opponent"
                  }
                >
                  <div className="ticket__ava--inner">
                    {/* <img
                      className="ticket__ava"
                      src={
                        elem?.user?.imageUrl
                          ? elem?.user?.imageUrl
                          : "/assets/img/freeavatar.png"
                      }
                      alt="Аватарка"
                    /> */}
                    <Avatar
                      size="lg"
                      name={userData.name ? userData.name : userData.login}
                      src={
                        elem?.user?.imageUrl
                          ? elem?.user?.imageUrl
                          : "/assets/img/freeavatar.png"
                      }
                    />
                    <div
                      className={
                        elem?.user?.isOnline
                          ? "point_online_chat_green"
                          : "point_online_chat_red"
                      }
                    ></div>
                    <Flex justifyContent="space-between" alignItems="center">
                      {elem?.user?.login}
                      {/* <div
                        className="point_online"
                        style={{
                          background: elem?.user?.isOnline ? "#61d64a" : "red",
                        }}
                      ></div> */}
                    </Flex>
                  </div>

                  <p className="ticket__message">{elem?.text}</p>
                </div>
                <Box
                  textAlign={elem?.user?.id === id ? "end" : "start"}
                  fontSize={[8, 10]}
                  ml={elem?.user?.id === id ? "" : 40}
                  mb={8}
                >
                  <Moment format="HH:MM DD MMMM YYYY ">
                    {elem?.createdAt}
                  </Moment>
                </Box>
              </Box>
            ))}
          {/* <div className="admin__chat--item flexdef w100">
              <div className="admin__chat--item--img--inner">
                <img src="/assets/img/ava.png" className="img" alt="Аватарка" />
              </div>

              <div className="admin__chat--item--text w100">
                Привет, как сам?
              </div>
            </div> */}

          {totalCount > (currentPage + 1) * itemsPerPage && (
            <Button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={!(totalCount > (currentPage + 1) * itemsPerPage)}
              mb={4}
              variant="ghost"
              size="lg"
              bg="#61d64a"
              color="white"
              boxShadow="inset 0px -3px 0px rgba(0, 0, 0, 0.1)"
              alignSelf="end"
            >
              Загрузить еще
            </Button>
          )}

          {/* ************************************************* send message */}

          <div className="admin__chat--send flexdef w100">
            {userData?.isChatBlocked ? (
              <div className="input admin__chat--input w100 admin">
                <Text
                  fontSize="2xl"
                  fontWeight="medium"
                  color="red.500"
                  wordBreak="break-word"
                  textAlign="justify"
                >
                  Ваш чат заблокирован, для разблокировки обратитесь к
                  администратору.
                </Text>
              </div>
            ) : (
              <>
                <input
                  className="input admin__chat--input w100 admin"
                  placeholder="Ваше сообщение..."
                  value={text}
                  onChange={(e) => {
                    if (e.target.value?.length <= 300) {
                      setText(e.target.value);
                    }
                  }}
                />

                <img
                  className="admin__send--button"
                  src="/assets/img/send.svg"
                  alt="Отправить"
                  onClick={sendMessage}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
