import React, { FC, useEffect } from "react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Center,
  Input,
  Text,
  Select,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import { SubmitHandler, useForm } from "react-hook-form";
import $api from "../../http/apiService";
import { UserTypeForAdmin } from "./UserList";
import {
  IdNameType,
  OutputStatuses,
  OutputType,
  WithdrawalInType,
} from "./OutputList";
import { toast } from "react-toastify";

type Iprops = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  // output: WithdrawalInType | undefined;
  output: any;
  accounts: IdNameType[];
  methods: IdNameType[];
  isNeedUpdate: number;
  setIsNeedUpdate: any;
};
type IFormInput = {
  date: string;
  statusChangedAt: string;
  "user.login": string; // контрагент
  comment: string; // комментарии
  "status.id": number; // статус
  "account.id": number; //счет
  "paymentSystem.id": number; //платежная система
  "externalPayment.walletId": string; //кошелек
  sum: number; //сумма
  editor: string;
};
type RDType = {
  comment?: string;
  paymentStatus?: number;
  paymentAccountId?: number;
  paymentSystemId?: number | string;
  walletId?: string;
  sum?: number;
};

const OutputItem = (props: Iprops) => {
  //   const { onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  // React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: "all",
  });

  //   useEffect(() => {
  //     props.onOpen();
  //   }, []);

  const handleClose = () => {
    props.onClose();
    navigate("/dashboard/outputs");
  };

  // Default values hook form initialize
  useEffect(() => {
    if (props.output) {
      reset(props.output);
      console.log("props.output", props.output);
    }
  }, [props.output]);

  const onSubmitForm: SubmitHandler<IFormInput> = async (data: any) => {
    console.log("data", data);
    const rd: any = {
      // ...props.output,
      comment: data.comment,
      paymentStatus: data.status.id,
      paymentAccountId: data.account.id,
      paymentSystemId: data.paymentSystem.id,
      walletId: data.externalPayment.walletId,
      sum: data.sum,
    };
    // if (data.comment) {
    //   rd.comment = data.comment;
    // }
    // if (data.comment) {
    //   rd.comment = data.comment;
    // }
    const config = {
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };
    try {
      console.log("rdNews", rd);
      const response = await $api.put(
        `/api/admin/Withdrawal/${props.output.id}`,
        rd,
        config
      );
      toast(`Заявка успешно отредактирована`);
      props.setIsNeedUpdate(props.isNeedUpdate + 1);
      handleClose();
      // console.log("response", response);
    } catch (e: any) {
      console.error("Ошибка редактирования заявки", e);
      toast.error(`Ошибка редактирования заявки: ${e?.message}`);
    } finally {
      //   setIsEditOpen(false);
    }

    // reset();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      size={"5xl"}
      isCentered
    >
      <ModalOverlay
        backdropBlur="5px"
        bg="blackAlpha.300"
        backdropFilter="blur(10px) "
      />
      <ModalContent>
        <ModalHeader fontSize={24} color="blue.500" fontWeight="bold">
          <Center>
            {`Редактирование заявки на вывод с ID:  ${props?.output?.id} пользователя: ${props?.output?.user?.login}`}
          </Center>
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <ModalBody fontSize={16}>
            <Text fontSize={12} color="gray.500" mt={8}>
              Дата создания
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              disabled
              // placeholder="Дата создания"
              bg="gray.100"
              {...register("date")}
            />

            <Text fontSize={12} color="gray.500" mt={8}>
              Дата редактирования
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              readOnly
              disabled
              bg="gray.100"
              // placeholder="Дата редактирования"
              {...register("statusChangedAt")}
            />

            <Text fontSize={12} color="gray.500" mt={8}>
              Логин
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              readOnly
              disabled
              // placeholder="Логин"
              bg="gray.100"
              {...register("user.login")}
            />

            <Text fontSize={12} color="gray.500" mt={8}>
              Комментарий
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              type="text"
              h={30}
              // placeholder="Комментарий"
              {...register("comment")}
            />

            <Text fontSize={12} color="gray.500" mt={8}>
              Статус
            </Text>
            <Select
              border="1px solid #3786e5"
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              color="gray.500"
              {...register("status.id", {
                required: "Поле обязательно к заполнению",
              })}
            >
              {/* <option value={-1}>Все</option> */}
              {OutputStatuses?.length > 0 &&
                OutputStatuses.map((elem) => (
                  <option key={elem.id} value={elem.id}>
                    {elem.value}
                  </option>
                ))}
            </Select>

            {/* <Text fontSize={12} color="gray.500" mt={8}>
              Счет
            </Text>
            <Select
              border="1px solid #3786e5"
              variant="primary"
              size="lg"
              color="gray.500"
              borderRadius="xl"
              fontSize={[12, 8, 10, 12, 14]}
              {...register("account.id")}
            >
              <option value={-1}>Все</option>
              {props?.accounts?.length > 0 &&
                props.accounts.map((elem) => (
                  <option key={elem.id} value={elem.id}>
                    {elem.name}
                  </option>
                ))}
            </Select> */}

            <Text fontSize={12} color="gray.500" mt={8}>
              Метод оплаты
            </Text>
            <Select
              border="1px solid #3786e5"
              variant="primary"
              size="lg"
              color="gray.500"
              borderRadius="xl"
              fontSize={[12, 8, 10, 12, 14]}
              {...register("paymentSystem.id")}
            >
              {/* <option value={-1}>Все</option> */}
              {props.methods.length > 0 &&
                props.methods.map((elem) => (
                  <option key={elem.id} value={elem.id}>
                    {elem.name}
                  </option>
                ))}
            </Select>

            <Text fontSize={12} color="gray.500" mt={8}>
              Кошелек
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              type="text"
              // placeholder="Кошелек"
              {...register("externalPayment.walletId", {
                required: "Поле обязательно к заполнению",
              })}
            />

            <Text fontSize={12} color="gray.500" mt={8}>
              Сумма
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              type="number"
              // placeholder="Сумма"
              {...register("sum", {
                required: "Поле обязательно к заполнению",
              })}
            />

            <Text fontSize={12} color="gray.500" mt={8}>
              Автор
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              type="text"
              h={30}
              disabled
              readOnly
              bg="gray.300"
              // placeholder="Комментарий"
              {...register("editor")}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={handleSubmit(onSubmitForm)}
              isDisabled={!isValid}
              type="submit"
              // colorScheme="blue"
              colorScheme="facebook"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="dark-lg"
            >
              Сохранить
            </Button>
            <Button
              colorScheme="twitter"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="dark-lg"
              onClick={handleClose}
            >
              Закрыть
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default OutputItem;
