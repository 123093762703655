import {
  Box,
  Button,
  Center,
  Wrap,
  Text,
  Input,
  Flex,
  Checkbox,
  Spacer,
  Heading,
  useDisclosure,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { FC, useEffect, useState } from "react";
import { FcBullish, FcCalendar, FcDataConfiguration } from "react-icons/fc";
import { MdHideSource } from "react-icons/md";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { usePagination } from "../../hooks/pagination/usePagination";
import $api from "../../http/apiService";
import ReviewList from "./ReviewList";
import SiteSettingsRateModal from "./SiteSettingsRateModal";
import WebinarList from "./WebinarList";

const itemPerPage = 5;
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
export type RateType = {
  id: number;
  rate: number;
  date: string;
  change: number;
};

const SiteSettings = () => {
  const [rate, setRate] = useState<string>("");
  const [rates, setRates] = useState<RateType[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [previousRate, setPreviousRate] = useState<number>(0);
  const [dataChange, setDataChange] = useState<string>("");
  const [isOutRate, setIsOutRate] = useState<boolean>(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(1);
  const {
    isOpen: isOpenRateModal,
    onOpen: onOpenRateModal,
    onClose: onCloseRateModal,
  } = useDisclosure();
  const [chosenItem, setChosenItem] = useState<RateType>();
  const [isDelete, setIsDelete] = useState(false);

  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: itemPerPage,
    count: totalCount,
  });
  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");

  const getRate = async () => {
    try {
      const res = await $api.get("/api/Mtb/info");
      setRate(res.data.rate);
      // setPreviousRate(res.data.change);
      setDataChange(res.data.date);
    } catch (e) {
      console.error(e);
    }
    const rd = {
      take: itemPerPage,
      skip: 0,
    };
    if (page > 0) {
      rd.skip = (page - 1) * itemPerPage;
    }
    try {
      const res = await $api.post("/api/admin/Mtb/all", rd, config);
      setRates(res.data?.items);
      if (page === 1) {
        setPreviousRate(res.data?.items[1]?.rate);
        setDataChange(res.data?.items[1]?.date);
      }
      setTotalCount(res.data?.totalCount);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getRate();
  }, [isNeedUpdate, page]);

  const saveRate = async () => {
    const rd: any = {
      rate: rate,
      date: new Date().toISOString(),
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await $api.post(`/api/admin/Mtb`, rd, config);
      toast("Курс успешно добавлен");
      setIsNeedUpdate(isNeedUpdate + 1);
    } catch (e) {
      console.error("Ошибка добавления курса", e);
      toast.error("Ошибка добавления курса");
    }
  };

  return (
    <>
      <SiteSettingsRateModal
        isOpen={isOpenRateModal}
        onOpen={onOpenRateModal}
        onClose={onCloseRateModal}
        rate={chosenItem}
        isNeedUpdate={isNeedUpdate}
        setIsNeedUpdate={setIsNeedUpdate}
        setChosenItem={setChosenItem}
        isDelete={isDelete}
        setIsDelete={setIsDelete}
      />
      <Box
        m={8}
        boxShadow="lg"
        p="8"
        rounded="md"
        bg="white"
        // fontFamily="DM Sans"
      >
        {/* <Wrap fontSize={[12, 8, 10, 12, 14]}></Wrap> */}
        <Tabs size="xl">
          <TabList>
            <Tab>Финансы</Tab>
            <Tab ml="4">Вебинары</Tab>
            <Tab ml="4">Отзывы</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Wrap>
                <Flex direction="column">
                  <Text fontSize={[8, 12]}>Курс MTB</Text>
                  <Input
                    variant="primary"
                    fontSize={[12, 8, 10, 12, 14]}
                    type="number"
                    h={30}
                    // minWidth={50}
                    // maxWidth={100}
                    disabled={isOutRate ? true : false}
                    readOnly={isOutRate ? true : false}
                    bg={isOutRate ? "gray.200" : "white"}
                    w="150px"
                    borderRadius="xl"
                    mt="2"
                    mb="4"
                    value={rate}
                    onChange={(e) => {
                      if (!isOutRate) {
                        setRate(e.target.value);
                      }
                    }}
                  />
                  <Checkbox
                    size="lg"
                    color="#6484AA"
                    fontWeight="400"
                    onChange={(e) => {
                      setIsOutRate(!isOutRate);
                    }}
                  >
                    Внешний курс
                  </Checkbox>
                </Flex>
                <Center>
                  <Button
                    size="lg"
                    bg="#3786E5"
                    disabled={isOutRate ? true : false}
                    color="white"
                    ml="2"
                    mt="-6"
                    onClick={saveRate}
                  >
                    Сохранить
                  </Button>
                </Center>
                <Spacer />

                <Flex>
                  <Box>
                    <Text fontSize={[12, 14]}>
                      {`Предыдущее значение от `}
                      <Moment format="DD-MM-YY, hh:mm:ss">{dataChange}</Moment>
                    </Text>
                    <Box>
                      <Text
                        fontSize={[10, 12]}
                        fontWeight="700"
                        color="#6484AA"
                        mt={2}
                      >
                        {`Курс: ${previousRate} `}
                      </Text>
                      <Flex>
                        <Text
                          fontSize={[10, 12]}
                          fontWeight="700"
                          color="#6484AA"
                          mt={2}
                        >
                          {`Дельта: `}
                        </Text>
                        <Text
                          fontSize={[10, 12]}
                          fontWeight="700"
                          ml="1"
                          color={
                            +rate - previousRate > 0 ? "green.500" : "red.500"
                          }
                          mt={2}
                        >
                          {+rate - previousRate > 0 && " +"}
                          {`${(+rate - previousRate).toFixed(6)} `}
                        </Text>
                      </Flex>
                      <Flex>
                        <Text
                          fontSize={[10, 12]}
                          fontWeight="700"
                          color="#6484AA"
                          mt={2}
                        >
                          {`Дельта в %: `}
                        </Text>
                        <Text
                          fontSize={[10, 12]}
                          fontWeight="700"
                          ml="1"
                          color={
                            +rate / previousRate > 1 ? "green.500" : "red.500"
                          }
                          mt={2}
                        >
                          {`${((+rate / previousRate - 1) * 100).toFixed(2)} `}
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                </Flex>
              </Wrap>

              <Wrap mt={8} justify={isLargerThan500 ? "end" : "start"}>
                <Box minW={[150, 100]}>
                  <Heading as="h2" mt={6} size="xl" mb="4" color="#6484AA">
                    Курсы
                  </Heading>
                </Box>
                {isLargerThan500 && <Spacer />}

                <Box
                  boxShadow="lg"
                  p="6"
                  rounded="md"
                  bg="white"
                  minWidth={220}
                  minHeight={20}
                >
                  <Center>
                    <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
                      {`Найдено: `}
                    </Text>
                    <Text
                      fontSize={[10, 12]}
                      fontWeight="700"
                      color="#6484AA"
                      ml={2}
                    >
                      {` ${totalCount} `}
                    </Text>
                  </Center>
                </Box>
              </Wrap>

              <Box
                color="#6484AA"
                fontSize={[12, 10, 12, 14, 14]}
                fontWeight="500"
                mt="8"
              >
                <div className="dashBoard_content__margin dashBoard_w100 dashBoard_flex dashBoard_admin__table">
                  {isLargerThan500 && (
                    <div
                      className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
                      style={{ padding: "8px" }}
                    >
                      <p className="dashBoard_operation__item--column9">
                        <Center>ID</Center>
                      </p>

                      <p className="dashBoard_operation__item--column9">
                        <Center>
                          <Box mr={2}>
                            <FcDataConfiguration />
                          </Box>
                        </Center>
                      </p>

                      <p className="dashBoard_operation__item--column5">
                        <Center>Курс</Center>
                      </p>

                      <p className="dashBoard_operation__item--column5">
                        <Center>
                          <Box mr={2}>
                            <FcCalendar />
                          </Box>
                          Дата
                        </Center>
                      </p>

                      <p className="dashBoard_operation__item--column5">
                        <Center>
                          <Box mr={2}>
                            <FcBullish />
                          </Box>
                          Изменение
                        </Center>
                      </p>
                    </div>
                  )}

                  {rates &&
                    rates.length > 0 &&
                    rates.map((item, index) => (
                      <Box
                        w="100%"
                        key={item.id}
                        fontSize={[12, 8, 10, 12, 14]}
                        color="#000000"
                        borderBottom="1px solid #dfdfdf"
                        background={item.change > 0 ? "#00FF3833" : "#FF000033"}
                        position="relative"
                      >
                        <div
                          key={item.id}
                          className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
                        >
                          <p className="dashBoard_operation__item--column9">
                            <Center
                              color="blue.500"
                              as={motion.div}
                              whileHover={{ scale: 1.1, cursor: "pointer" }}
                              onClick={() => {
                                setChosenItem(item);
                                onOpenRateModal();
                              }}
                            >
                              {!isLargerThan500 && "ID: "}
                              {item?.id}
                            </Center>
                          </p>
                          <p
                            className={
                              isLargerThan500
                                ? "dashBoard_operation__item--column9"
                                : "myAbsoluteLeft"
                            }
                          >
                            <Center>
                              <Tooltip
                                fontSize={["12px", "16px"]}
                                label="Удалить"
                                hasArrow
                                bg="#ED64A6"
                                borderRadius="xl"
                              >
                                <Box
                                  m="2"
                                  as={motion.div}
                                  whileHover={{ scale: 1.1, cursor: "pointer" }}
                                  borderRadius="2xl"
                                  bg="#ED64A6"
                                  onClick={() => {
                                    setIsDelete(true);
                                    setChosenItem(item);
                                    onOpenRateModal();
                                  }}
                                >
                                  <MdHideSource size={27} color="white" />
                                </Box>
                              </Tooltip>
                            </Center>
                          </p>
                          <p className="dashBoard_operation__item--column5">
                            <Center>{item.rate}</Center>
                          </p>
                          <p className="dashBoard_operation__item--column5">
                            <Center>
                              {!isLargerThan500 && "Дата: "}
                              <Moment format="DD:MM:YY, hh:mm:ss">
                                {item?.date}
                              </Moment>
                            </Center>
                          </p>
                          <p className="dashBoard_operation__item--column5">
                            <Center>
                              {!isLargerThan500 && "Изменение: "}
                              {item?.change}
                            </Center>
                          </p>
                        </div>
                      </Box>
                    ))}

                  {totalCount > itemPerPage && (
                    <Center fontSize="12">
                      <div className="pagination">
                        <Button
                          as={motion.div}
                          whileHover={{ scale: 1.1 }}
                          onClick={prevPage}
                          w={24}
                          h={12}
                          colorScheme="blue"
                          borderRadius="xl"
                          isDisabled={page === 1}
                        >
                          Назад
                        </Button>
                        <button
                          onClick={() => setPage(1)}
                          className={`page ${page === 1 && "disabled"}`}
                        >
                          1
                        </button>
                        {gaps.before ? "..." : null}

                        {gaps.paginationGroup.map((el) => (
                          <button
                            onClick={() => setPage(el)}
                            key={el}
                            className={`page ${page === el ? "active" : ""}`}
                          >
                            {el}
                          </button>
                        ))}
                        {gaps.after ? "..." : null}
                        <button
                          onClick={() => setPage(totalPages)}
                          className={`page ${
                            page === totalPages && "disabled"
                          }`}
                        >
                          {totalPages}
                        </button>

                        <Button
                          as={motion.div}
                          whileHover={{ scale: 1.1 }}
                          onClick={nextPage}
                          w={24}
                          h={12}
                          colorScheme="blue"
                          borderRadius="xl"
                          isDisabled={page === totalPages}
                        >
                          Вперед
                        </Button>
                      </div>
                    </Center>
                  )}
                </div>
              </Box>
            </TabPanel>

            <TabPanel>
              <WebinarList />
            </TabPanel>

            <TabPanel>
              <ReviewList />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default SiteSettings;
