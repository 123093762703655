import { Routes, Route, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { PrivateRoute } from "./hooks/privateRoute/PrivateRoute";
import { PrivateAdminRoute } from "./hooks/privateAdminRoute/PrivateAdminRoute";

import { useAppDispatch, useAppSelector } from "./store.ts";
import { logoutUser } from "./features/auth/authSlice";
import { resetUserInfo } from "./features/userInfo/userInfoSlice";
import "./App.css";

import Layout from "./Components/Layout/Layout.jsx";
import LayoutMain from "./Components/Layout/LayoutMain.jsx";
import LayoutAdmin from "./Components/Layout/LayoutAdmin.jsx";

import Main from "./pages/Home/Main/Main.jsx";
import Faq from "./pages/Home/Faq/Faq.jsx";
import Partners from "./pages/LK/Partners/Partners";
import Adv from "./pages/Home/Adv/Adv.jsx";
import Payment from "./pages/Others/Payment/Payment.jsx";
import { Register } from "./pages/Home/Register/Register";
import { PublicOffer } from "./pages/Home/PublickOffer/PublicOffer";
import Reviews from "./pages/Home/Reviews/Reviews";
import { Login } from "./pages/Home/Login/Login";
import Recovery from "./pages/Home/Recovery/Recovery";
import { PublickNews } from "./pages/Home/PublicNews/PublickNews.tsx";
import { PublickChosenNews } from "./pages/Home/PublicNews/PublickChosenNews";
import Owners from "./pages/Others/Owners/Owners";
import Smi from "./pages/Others/Smi/Smi.jsx";
import Report from "./pages/Others/Report/Report.jsx";
import Contact from "./pages/Home/Contact/Contact.jsx";
import Mission from "./pages/Others/Mission/Mission.jsx";
import Career from "./pages/Others/Career/Career.jsx";
import Start from "./pages/Others/Start/Start.jsx";
import About from "./pages/Home/About/About.jsx";
import { AboutCertificate } from "./pages/Home/About/AboutCertificate";
import Marketing from "./pages/Home/Marketing/Marketing.tsx";

import { Admin } from "./pages/LK/Admin/Admin";
import Presents from "./pages/LK/Presents/Presents";
import { GeneralPresentation } from "./pages/LK/Presents/GeneralPresentation";
import Vacancies from "./pages/LK/Vacancies/Vacancies.jsx";
import Review from "./pages/LK/Review/Review";
import { InputMoney } from "./pages/LK/InputMoney/InputMoney.tsx";
import OutputMoney from "./pages/LK/OutputMoney/OutputMoney.tsx";
import Operation from "./pages/LK/Operation/Operation.jsx";
import Tickets from "./pages/LK/Tickets/Tickets.tsx";
import UserNews from "./pages/LK/UserNews/UserNews.tsx";
import { UserChosenNews } from "./pages/LK/UserNews/UserChosenNews.tsx";
import { Struct } from "./pages/LK/Struct/Struct";
import Notify from "./pages/LK/Notify/Notify.tsx";
import Balance from "./pages/Others/Balance/Balance.jsx";
import Webinars from "./pages/LK/Webinars/Webinars";
import Transaction from "./pages/Others/Transaction/Transaction.jsx";
import Travel from "./pages/Others/Travel/Travel.jsx";
import Settings from "./pages/LK/Settings/Settings";
import { PartnersPrograms } from "./pages/LK/PartnersPrograms/PartnersPrograms";
import AutoProgram from "./pages/Others/AutoProgram/AutoProgram";
import { PaymentSuccess } from "./pages/LK/PaymentDetails/PaymentSuccess";
import { PaymentError } from "./pages/LK/PaymentDetails/PaymentError";
import Exchange from "./pages/Others/Exchange/Exchange";

import { Structure } from "./Components/LK/PartnersStructure/Structure";

import AdminDashboard from "./pages/Dashboard/DashBoard";
import LayoutAdminDashboard from "./pages/Dashboard/LayoutDashBoard";
import UserList from "./pages/Dashboard/UserList";
import NewsList from "./pages/Dashboard/NewsList";
import PaymentList from "./pages/Dashboard/PaymentsList";
import OutputList from "./pages/Dashboard/OutputList";
import ProfileDash from "./pages/Dashboard/ProfileDash";
import SiteSettings from "./pages/Dashboard/SiteSettings";
import FeedbackList from "./pages/Dashboard/FeedbackList";
import StructureDash from "./pages/Dashboard/StructureDash";
import CryptoTokenInvoiceList from "./pages/Dashboard/CryptoTokenInvoiceList";
import GlobalChat from "./pages/Dashboard/GlobalChat";

import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
} from "@chakra-ui/react";
import YandexMetrics from "./pages/Dashboard/YandexMetrics";

const App = () => {
  const { userData } = useAppSelector((state) => state.userInfo);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onPrompt = () => {
    // Fire a Modal Prompt
    onOpen();
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    onClose();
    dispatch(logoutUser());
    navigate("/login");
    setTimeout(() => {
      dispatch(resetUserInfo());
    }, 2000);
  };

  const onActive = (event: any) => {
    // Close Modal Prompt
    onClose();
    reset();
    // Do some active action
  };

  const onAction = (event: any) => {
    // Do something when a user triggers a watched event
    reset();
  };

  const { reset } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 60 * userData.sessionTime,
    promptTimeout: 1000 * 60 * 3,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        size={"xl"}
        isCentered
      >
        <ModalOverlay
          backdropBlur="5px"
          bg="blackAlpha.300"
          backdropFilter="blur(10px) "
        />
        <ModalContent
          bgGradient={[
            "linear(to-tr, blue.300, yellow.400)",
            "linear(to-t, blue.800, teal.500)",
            "linear(to-b, orange.100, purple.300)",
          ]}
        >
          <ModalHeader textAlign="center">
            <Text fontSize={20} color="red.600" fontWeight="bold">
              Пользователь долгое время находиться без активности
            </Text>
          </ModalHeader>
          <ModalBody textAlign="center">
            <Text fontSize={20} color="cyan.900" fontWeight="medium">
              Инициирован автоматический выход из системы!
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mx="auto" size="lg" onClick={onClose}>
              Остановить выход из системы!
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Routes>
        {/* .................ПУБЛИЧНАЯ ЧАСТЬ........................ */}
        <Route path="/" element={<LayoutMain />}>
          <Route index element={<Main />} />
        </Route>

        <Route path="/" element={<Layout />}>
          <Route path="faq" element={<Faq />} />
          <Route path="adv" element={<Adv />} />
          <Route path="register" element={<Register />} />
          <Route path="register/:id" element={<Register />} />
          <Route path="public-offer" element={<PublicOffer />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="login" element={<Login />} />
          <Route path="recovery" element={<Recovery />} />
          <Route path="publick-news" element={<PublickNews />} />
          <Route path="publick-news/:id" element={<PublickChosenNews />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="about-certificate" element={<AboutCertificate />} />
          <Route path="marketing" element={<Marketing />} />
          <Route path="*" element={<Navigate to="/" />} />

          <Route path="partners" element={<PrivateRoute />}>
            <Route path="" element={<Partners />} />
          </Route>

          <Route path="webinars" element={<PrivateRoute />}>
            <Route path="" element={<Webinars />} />
          </Route>

          {/* Unknown routes */}
          {/* <Route path="start" element={<Start />} />
          <Route path="payment" element={<Payment />} />
          <Route path="owners" element={<Owners />} />
          <Route path="smi" element={<Smi />} />
          <Route path="report" element={<Report />} />
          <Route path="mission" element={<Mission />} />
          <Route path="career" element={<Career />} /> */}
          {/* Unknown routes */}
        </Route>

        {/* .................АДМИНКА........................ */}
        <Route path="/dashboard/" element={<LayoutAdminDashboard />}>
          <Route path="/dashboard/news" element={<PrivateAdminRoute />}>
            <Route index element={<NewsList />} />
          </Route>

          <Route path="/dashboard/payments" element={<PrivateAdminRoute />}>
            <Route index element={<PaymentList />} />
          </Route>

          <Route
            path="/dashboard/payments/:login"
            element={<PrivateAdminRoute />}
          >
            <Route index element={<PaymentList />} />
          </Route>

          <Route path="/dashboard/outputs" element={<PrivateAdminRoute />}>
            <Route index element={<OutputList />} />
          </Route>

          <Route path="/dashboard/chat" element={<PrivateAdminRoute />}>
            <Route index element={<GlobalChat />} />
          </Route>

          <Route
            path="/dashboard/profile/:login"
            element={<PrivateAdminRoute />}
          >
            <Route index element={<ProfileDash />} />
          </Route>

          <Route path="/dashboard/profile" element={<PrivateAdminRoute />}>
            <Route index element={<ProfileDash />} />
          </Route>

          <Route
            path="/dashboard/structure/:login"
            element={<PrivateAdminRoute />}
          >
            <Route index element={<Structure />} />
          </Route>

          <Route path="/dashboard/structure" element={<PrivateAdminRoute />}>
            <Route index element={<Structure />} />
          </Route>

          <Route path="/dashboard/sitesettings" element={<PrivateAdminRoute />}>
            <Route index element={<SiteSettings />} />
          </Route>

          <Route path="/dashboard/feedbacks" element={<PrivateAdminRoute />}>
            <Route index element={<FeedbackList />} />
          </Route>

          <Route path="/dashboard/cryptotoken" element={<PrivateAdminRoute />}>
            <Route index element={<CryptoTokenInvoiceList />} />
          </Route>

          <Route
            path="/dashboard/referals/:login"
            element={<PrivateAdminRoute />}
          >
            <Route index element={<StructureDash />} />
          </Route>

          <Route path="/dashboard/referals" element={<PrivateAdminRoute />}>
            <Route index element={<StructureDash />} />
          </Route>

          <Route path="/dashboard/ym" element={<PrivateAdminRoute />}>
            <Route index element={<YandexMetrics />} />
          </Route>

          <Route path="/dashboard/" element={<PrivateAdminRoute />}>
            <Route index element={<UserList />} />
          </Route>
        </Route>

        {/* .................ЛИЧНЫЙ КАБИНЕТ........................ */}
        <Route path="/admin/" element={<LayoutAdmin />}>
          <Route path="/admin/" element={<PrivateRoute />}>
            <Route index element={<Admin />} />
          </Route>

          <Route path="presentation" element={<PrivateRoute />}>
            <Route path="" element={<Presents />} />
          </Route>

          <Route path="presentation/:id" element={<PrivateRoute />}>
            <Route path="" element={<GeneralPresentation />} />
          </Route>

          <Route path="partners-programs" element={<PrivateRoute />}>
            <Route path="" element={<PartnersPrograms isNew={false} initProgId={1}/>} />
          </Route>

          <Route path="partners-programs-new" element={<PrivateRoute />}>
            <Route path="" element={<PartnersPrograms isNew={true} initProgId={4}/>} />
          </Route>

          <Route path="struct" element={<PrivateRoute />}>
            <Route path="" element={<Struct />} />
          </Route>

          <Route path="input-money" element={<PrivateRoute />}>
            <Route path="" element={<InputMoney />} />
          </Route>

          <Route path="output-money" element={<PrivateRoute />}>
            <Route path="" element={<OutputMoney />} />
          </Route>

          <Route path="operation" element={<PrivateRoute />}>
            <Route path="" element={<Operation />} />
          </Route>

          <Route path="user-news" element={<PrivateRoute />}>
            <Route path="" element={<UserNews />} />
          </Route>

          <Route path="user-news/:id" element={<PrivateRoute />}>
            <Route path="" element={<UserChosenNews />} />
          </Route>

          <Route path="vacancies" element={<PrivateRoute />}>
            <Route path="" element={<Vacancies />} />
          </Route>

          <Route path="review" element={<PrivateRoute />}>
            <Route path="" element={<Review />} />
          </Route>

          <Route path="settings" element={<PrivateRoute />}>
            <Route path="" element={<Settings />} />
          </Route>

          <Route path="settings/:id" element={<PrivateRoute />}>
            <Route path="" element={<Settings />} />
          </Route>

          <Route path="tickets" element={<PrivateRoute />}>
            <Route path="" element={<Tickets />} />
          </Route>

          <Route path="tickets/:id" element={<PrivateRoute />}>
            <Route path="" element={<Tickets />} />
          </Route>

          <Route path="payment/success" element={<PrivateRoute />}>
            <Route path="" element={<PaymentSuccess />} />
          </Route>

          <Route path="payment/error" element={<PrivateRoute />}>
            <Route path="" element={<PaymentError />} />
          </Route>

          <Route path="notify" element={<PrivateRoute />}>
            <Route path="" element={<Notify />} />
          </Route>

          {/* Unknown routes */}
          {/* <Route path="auto-program" element={<AutoProgram />} /> */}
          {/* <Route path="travel" element={<Travel />} /> */}
          {/* <Route path="balance" element={<Balance />} /> */}
          {/* <Route path="investment-cases" element={<InvestmentСases />} /> */}
          {/* <Route path="transaction" element={<Transaction />} /> */}
          {/* <Route path="exchange" element={<Exchange />} /> */}
          {/* Unknown routes */}
        </Route>
      </Routes>
    </>
  );
};

export default App;
