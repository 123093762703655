import { Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { FC, useState } from "react";
import { MdOutlineStar } from "react-icons/md";

export interface RatingProps {
  isEditable?: boolean;
  rating: number;
  setRating: (rating: number) => void;
}

const Raiting = ({ isEditable = false, rating, setRating }: RatingProps) => {
  const array = [];
  for (let i = 1; i <= 5; i++) {
    array.push(i);
  }

  //   const [value, setValue] = useState(1);
  return (
    <>
      <Flex as={motion.div} whileHover={{ cursor: "pointer" }}>
        {array.map((elem) => (
          <MdOutlineStar
            key={elem}
            color={elem <= rating ? "red" : "gray"}
            size={20}
            onClick={() => {
              //   console.log(elem);
              //   setValue(elem);
              if (isEditable) {
                setRating(elem);
              }
            }}
          />
        ))}
      </Flex>
    </>
  );
};

export default Raiting;
