import React from "react";
import $ from "jquery";

import { useTranslation } from "react-i18next";

import "./Faq.css";
import Meta from "../../../utils/seo/Meta";

const Faq = () => {
  const { t } = useTranslation();

  const companyData = [
    {
      question: t("Faq.question1"),
      answer: t("Faq.answer1"),
    },
    {
      question: t("Faq.question2"),
      answer: t("Faq.answer2"),
    },
    // {
    //   question: t("Faq.question3"),
    //   answer: t("Faq.answer3"),
    // },
    {
      question: t("Faq.question4"),
      answer: t("Faq.answer4"),
    },
    {
      question: t("Faq.question5"),
      answer: t("Faq.answer5"),
    },
    {
      question: t("Faq.question6"),
      answer: t("Faq.answer6"),
    },
    {
      question: t("Faq.question7"),
      answer: t("Faq.answer7"),
    },
    {
      question: t("Faq.question8"),
      answer: t("Faq.answer8"),
    },
    {
      question: t("Faq.question9"),
      answer: t("Faq.answer9"),
    },
  ];

  const lkData = [
    {
      question: t("Faq.question10"),
      answer: t("Faq.answer10"),
    },
    {
      question: t("Faq.question11"),
      answer: t("Faq.answer11"),
    },
    {
      question: t("Faq.question12"),
      answer: t("Faq.answer12"),
    },
    {
      question: t("Faq.question13"),
      answer: t("Faq.answer13"),
    },
    {
      question: t("Faq.question14"),
      answer: t("Faq.answer14"),
    },
    {
      question: t("Faq.question15"),
      answer: t("Faq.answer15"),
    },
    {
      question: t("Faq.question16"),
      answer: t("Faq.answer16"),
    },
    // {
    //   question: t("Faq.question17"),
    //   answer: t("Faq.answer17"),
    // },
    // {
    //   question: t("Faq.question18"),
    //   answer: t("Faq.answer18"),
    // },
    {
      question: t("Faq.question19"),
      answer: t("Faq.answer19"),
    },
    {
      question: t("Faq.question20"),
      answer: t("Faq.answer20"),
    },
    {
      question: t("Faq.question21"),
      answer: t("Faq.answer21"),
    },
  ];

  const partnersData = [
    {
      question: t("Faq.question22"),
      answer: t("Faq.answer22"),
    },
    {
      question: t("Faq.question23"),
      answer: t("Faq.answer23"),
    },
    {
      question: t("Faq.question24"),
      answer: t("Faq.answer24"),
    },
    {
      question: t("Faq.question25"),
      answer: t("Faq.answer25"),
    },
    {
      question: t("Faq.question26"),
      answer: t("Faq.answer26"),
    },
  ];

  const questionData = [
    {
      question: t("Faq.question27"),
      answer: t("Faq.answer27"),
    },
    {
      question: t("Faq.question28"),
      answer: t("Faq.answer28"),
    },
    {
      question: t("Faq.question29"),
      answer: t("Faq.answer29"),
    },
  ];

  React.useEffect(() => {
    $(".faq__item--left").on("click", function () {
      $(this).children(".faq__item--arrow").toggleClass("disable");
      $(this)
        .siblings(".faq__item--content")
        .children(".faq__item--answer")
        .slideToggle(200);
    });

    $(".faq__tab").on("click", function () {
      $(".faq__tab").removeClass("active");
      $(this).addClass("active");
      $(".faq__content").removeClass("active");
      $(".faq__content[data-tab=" + $(this).attr("data-tab") + "]").addClass(
        "active"
      );
    });
  }, []);

  return (
    <>
      <Meta
        title="Faq"
        description="Ответы на самые распространенные вопросы по платформе!"
      />

      <section className="section__padding faq">
        <img src="/assets/img/wave.png" alt="Фон" className="faq__img" />

        <div className="container">
          <div className="faq__inner">
            <h2 className="title title__center">
              <span className="black blue">{t("Faq.quest")}</span>{" "}
              {t("Faq.and")} {t("Faq.answ")}
            </h2>

            <div className="faq__tabs">
              <button className="button faq__tab" data-tab="account">
                {t("Faq.about")}
              </button>

              <button className="button faq__tab active" data-tab="invest">
                {t("Faq.lk")}
              </button>

              <button className="button faq__tab" data-tab="given">
                {t("Faq.partner")}
              </button>

              <button className="button faq__tab" data-tab="program">
                {t("Faq.freq")}
              </button>
            </div>

            <div className="faq__content" data-tab="account">
              {companyData.map((d, id) => (
                <div key={id} className="faq__item">
                  <div className="faq__item--left">
                    <img
                      src="/assets/img/faq-arrow.svg"
                      alt="Стрелка"
                      className="faq__item--arrow disable"
                    />
                  </div>

                  <div className="faq__item--content">
                    <p className="faq__item--question">{d.question}</p>

                    <p className="faq__item--answer">{d.answer}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="faq__content active" data-tab="invest">
              {lkData.map((d, id) => (
                <div key={id} className="faq__item">
                  <div className="faq__item--left">
                    <img
                      src="/assets/img/faq-arrow.svg"
                      alt="Стрелка"
                      className="faq__item--arrow disable"
                    />
                  </div>

                  <div className="faq__item--content">
                    <p className="faq__item--question">{d.question}</p>

                    <p className="faq__item--answer">{d.answer}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="faq__content" data-tab="given">
              {partnersData.map((d, id) => (
                <div key={id} className="faq__item">
                  <div className="faq__item--left">
                    <img
                      src="/assets/img/faq-arrow.svg"
                      alt="Стрелка"
                      className="faq__item--arrow disable"
                    />
                  </div>

                  <div className="faq__item--content">
                    <p className="faq__item--question">{d.question}</p>

                    <p className="faq__item--answer">{d.answer}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="faq__content" data-tab="program">
              {questionData.map((d, id) => (
                <div key={id} className="faq__item">
                  <div className="faq__item--left">
                    <img
                      src="/assets/img/faq-arrow.svg"
                      alt="Стрелка"
                      className="faq__item--arrow disable"
                    />
                  </div>

                  <div className="faq__item--content">
                    <p className="faq__item--question">{d.question}</p>

                    <p className="faq__item--answer">{d.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
