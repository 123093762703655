import React, { useLayoutEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";

import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import presentsEN from "../../../assets/documents/EN.pdf";
import presentsFR from "../../../assets/documents/FR.pdf";
import presentsUA from "../../../assets/documents/UA.pdf";
import presentsUZ from "../../../assets/documents/UZ.pdf";
import presentsTR from "../../../assets/documents/TR.pdf";
import presentsKZ from "../../../assets/documents/KZ.pdf";
import presentsKG from "../../../assets/documents/KG.pdf";
import presentsDE from "../../../assets/documents/DE.pdf";
import presentsRU from "../../../assets/documents/RU.pdf";
import presentsNEW from "../../../assets/documents/Dreams_Come_True_22_compressed.pdf";
import presentsNEWMatrix from "../../../assets/documents/Dreams_Come_True_2_compressed.pdf";

import Meta from "../../../utils/seo/Meta";

export const GeneralPresentation = () => {
  // Scroll to top
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();

  const getPresents = (id: string | undefined) => {
    switch (id) {
      case "ru":
        return presentsNEW;
        break;
      case "ru_matrix":
        return presentsNEWMatrix;
        break;
      case "en":
        return presentsEN;
        break;
      case "de":
        return presentsDE;
        break;
      case "fr":
        return presentsFR;
        break;
      case "tr":
        return presentsTR;
        break;
      case "ua":
        return presentsUA;
        break;
      case "kz":
        return presentsKZ;
        break;
      case "kg":
        return presentsKG;
        break;
      case "uz":
        return presentsUZ;
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Meta title="Personal account" />
      <div className="admin__content--content flex w100">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
          <Viewer fileUrl={getPresents(id)} />
        </Worker>
      </div>
    </>
  );
};
