import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import $api from "../../http/apiService";

interface IAccount {
  id: number;
  name: string;
}

interface IArticle {
  id: number;
  name: string;
}

interface ILastIncome {
  id: number;
  date: string;
  sum: number;
  desc: string;
  userId: number;
  article: {
    id: number;
    name: string;
  };
  account: {
    id: number;
    name: string;
  };
  transactionId: string;
  comment: string;
  status: number;
  statusChangedAt: string;
}

export interface IWithdrawals {
  id: number;
  date: string;
  sum: number;
  desc: string;
  user: {
    id: number;
    name: string;
    login: string;
  };
  article: {
    id: number;
    name: string;
  };
  account: {
    id: number;
    name: string;
  };
  transactionId: string;
  comment: string;
  status: string;
  statusChangedAt: string;
  externalPayment: {
    walletId: string;
    externalTransactionId: string;
    paymentSystem: {
      id: number;
      name: string;
    };
  };
}

export interface IReplenishments {
  id: number;
  date: string;
  sum: number;
  desc: string;
  user: {
    id: number;
    name: string;
    login: string;
  };
  article: {
    id: number;
    name: string;
  };
  account: {
    id: number;
    name: string;
  };
  transactionId: string;
  comment: string;
  status: string;
  statusChangedAt: string;
  externalPayment: {
    walletId: string;
    externalTransactionId: string;
    paymentSystem: {
      id: number;
      name: string;
    };
  };
}

export interface IWithdrawMethods {
  id: number;
  name: string;
}

interface IState {
  paymentAccount: IAccount[];
  paymentArticle: IArticle[];
  paymentHistory: Record<string, unknown>;
  lastIncomes: ILastIncome[];
  withdrawals: {
    items: IWithdrawals[];
    totalSum: number;
    totalCount: number;
  };
  withdrawMethods: IWithdrawMethods[];
  replenishments: {
    items: IReplenishments[];
    totalSum: number;
    totalCount: number;
  };
  isLoadingPayment: boolean;
  isErrorPayment: boolean;
  message: any;
}

const initialState: IState = {
  paymentAccount: [
    {
      id: 1,
      name: "Физический",
    },
  ],
  paymentArticle: [
    {
      id: 1,
      name: "Перевод средств",
    },
  ],
  paymentHistory: {},
  lastIncomes: [],
  withdrawals: {
    items: [],
    totalSum: 0,
    totalCount: 0,
  },
  withdrawMethods: [],
  replenishments: {
    items: [],
    totalSum: 0,
    totalCount: 0,
  },
  isLoadingPayment: false,
  isErrorPayment: false,
  message: "",
};

// Get payment account
export const getPaymentAccount = createAsyncThunk(
  "paymentAccount/get",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.get("/api/payment-account", config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get payment article
export const getPaymentArticle = createAsyncThunk(
  "paymentArticle/get",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.get("/api/payment-article", config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get payment history
export const getPaymentHistory = createAsyncThunk(
  "paymentHistory/get",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post("/api/Payment/history", data, config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get last incomes
export const getLastIncomes = createAsyncThunk(
  "lastIncomes/get",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.get("/api/Payment/last-incomes", config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get withdrawals history
export const getWithdrawalsHistory = createAsyncThunk(
  "withdrawalsHistory/get",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post(
        "/api/Withdrawal/history",
        data,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get replenishments history
export const getReplenishmentsHistory = createAsyncThunk(
  "replenishmentsHistory/get",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post(
        "/api/Payment/replenishments",
        data,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Send payment to withdraw
export const sendPaymentToWithdraw = createAsyncThunk(
  "withdrawPayment/post",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post("/api/Withdrawal", data, config);
      toast.success("Заявка успешно создана!");
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      toast.error("Что то пошло не так! Попробуйте позже!");
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get available withdraw methods
export const getWithdrawMethods = createAsyncThunk(
  "withdrawMethods/get",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.get("/api/payment-system", config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reducer
export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentAccount.pending, (state) => {
        state.isLoadingPayment = true;
        state.message = "";
      })
      .addCase(getPaymentAccount.fulfilled, (state, action) => {
        state.isLoadingPayment = false;
        state.paymentAccount = action.payload;
      })
      .addCase(getPaymentAccount.rejected, (state, action) => {
        state.isLoadingPayment = false;
        state.isErrorPayment = true;
        state.message = action.payload;
      })
      .addCase(getPaymentArticle.pending, (state) => {
        state.isLoadingPayment = true;
        state.message = "";
      })
      .addCase(getPaymentArticle.fulfilled, (state, action) => {
        state.isLoadingPayment = false;
        state.paymentArticle = action.payload;
      })
      .addCase(getPaymentArticle.rejected, (state, action) => {
        state.isLoadingPayment = false;
        state.isErrorPayment = true;
        state.message = action.payload;
      })
      .addCase(getPaymentHistory.pending, (state) => {
        state.isLoadingPayment = true;
        state.message = "";
      })
      .addCase(getPaymentHistory.fulfilled, (state, action) => {
        state.isLoadingPayment = false;
        state.paymentHistory = action.payload;
      })
      .addCase(getPaymentHistory.rejected, (state, action) => {
        state.isLoadingPayment = false;
        state.isErrorPayment = true;
        state.message = action.payload;
      })
      .addCase(getLastIncomes.pending, (state) => {
        state.isLoadingPayment = true;
        state.message = "";
      })
      .addCase(getLastIncomes.fulfilled, (state, action) => {
        state.isLoadingPayment = false;
        state.lastIncomes = action.payload;
      })
      .addCase(getLastIncomes.rejected, (state, action) => {
        state.isLoadingPayment = false;
        state.isErrorPayment = true;
        state.message = action.payload;
      })
      .addCase(getWithdrawalsHistory.pending, (state) => {
        state.isLoadingPayment = true;
        state.message = "";
      })
      .addCase(getWithdrawalsHistory.fulfilled, (state, action) => {
        state.isLoadingPayment = false;
        state.withdrawals = action.payload;
      })
      .addCase(getWithdrawalsHistory.rejected, (state, action) => {
        state.isLoadingPayment = false;
        state.isErrorPayment = true;
        state.message = action.payload;
      })
      .addCase(getReplenishmentsHistory.pending, (state) => {
        state.isLoadingPayment = true;
        state.message = "";
      })
      .addCase(getReplenishmentsHistory.fulfilled, (state, action) => {
        state.isLoadingPayment = false;
        state.replenishments = action.payload;
      })
      .addCase(getReplenishmentsHistory.rejected, (state, action) => {
        state.isLoadingPayment = false;
        state.isErrorPayment = true;
        state.message = action.payload;
      })
      .addCase(sendPaymentToWithdraw.pending, (state) => {
        state.isLoadingPayment = true;
        state.message = "";
      })
      .addCase(sendPaymentToWithdraw.fulfilled, (state, action) => {
        state.isLoadingPayment = false;
        // state.withdrawals = action.payload;
      })
      .addCase(sendPaymentToWithdraw.rejected, (state, action) => {
        state.isLoadingPayment = false;
        state.isErrorPayment = true;
        state.message = action.payload;
      })
      .addCase(getWithdrawMethods.pending, (state) => {
        state.isLoadingPayment = true;
        state.message = "";
      })
      .addCase(getWithdrawMethods.fulfilled, (state, action) => {
        state.isLoadingPayment = false;
        state.withdrawMethods = action.payload;
      })
      .addCase(getWithdrawMethods.rejected, (state, action) => {
        state.isLoadingPayment = false;
        state.isErrorPayment = true;
        state.message = action.payload;
      });
  },
});

// export const {} = paymentSlice.actions;
export default paymentSlice.reducer;
