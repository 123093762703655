import React, { useEffect, useLayoutEffect } from "react";
import "./Contact.scss";

import $api from "../../../http/apiService";

import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Text } from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

const Contact = () => {
  const { t } = useTranslation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Initialize React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
  });

  // Form handler to Backend
  const onSubmit = async (data) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post("/api/Feedback", data, config);
      reset({
        name: "",
        email: "",
        message: "",
      });
      toast.success("Сообщение отправлено!");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Meta
        title="Contacts"
        description="Контакты платформы. Свяжитесь я нами!"
      />

      <section className="section__padding contact">
        <img src="/assets/img/dots.png" alt="Фон" className="dots" />

        <div className="container">
          <div className="contact__inner">
            <h2 className="title title__center">
              <span className="black blue">{t("Contact.our")}</span>{" "}
              {t("Contact.contacts")}
            </h2>
            <div className="team__content"></div>
          </div>
        </div>

        <div className="contact__box">
          <div className="container news__contained">
            <img
              src="/assets/img/contact-img.png"
              alt="Картинка"
              className="news__box--img"
            />

            <div className="report__box--text--inner">
              <p className="contact__title">{t("Contact.office")}</p>

              <div className="rep__box--text--inner">
                <p className="rep__box--text">{t("Contact.title1")}.</p>

                <p className="rep__box--text">{t("Contact.title2")}.</p>

                <p className="rep__box--text">{t("Contact.title3")}.</p>
              </div>
            </div>
          </div>
        </div>

        <img
          src="/assets/img/wave.png"
          alt="Картинка"
          className="contact__img"
        />

        <div className="container">
          <div className="contact__inner">
            <div className="contact__content">
              <div className="contact__form">
                <p className="title">
                  <span className="black blue">{t("Contact.write")}</span>{" "}
                  {t("Contact.us")}:
                </p>

                <div className="auth__input--inner full contact__input--inner">
                  <input
                    type="text"
                    placeholder="Name"
                    className="input auth__input"
                    {...register("name", {
                      required: "Поле обязательно к заполнению",
                      pattern: {
                        value: /^[a-zA-Za-яА-Я](.[a-zа-яA-ZА-Я0-9]*)$/,
                        message:
                          "Имя должно начинаться с буквы. Допустимы буквы и цифры!",
                      },
                      minLength: {
                        value: 4,
                        message: "Минимум 4 символа!",
                      },
                      maxLength: {
                        value: 10,
                        message: "Максимум 10 символов!",
                      },
                    })}
                  />
                  {errors?.name && (
                    <Text fontSize="xl" fontWeight="semibold" color="red.500">
                      {errors.name.message || "Error!"}
                    </Text>
                  )}
                </div>

                <div className="auth__input--inner full contact__input--inner">
                  <input
                    type="email"
                    placeholder="Email"
                    className="input auth__input"
                    {...register("email", {
                      required: "Поле обязательно к заполнению",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Введите верный email!",
                      },
                      minLength: {
                        value: 8,
                        message: "Минимум 8 символов!",
                      },
                      maxLength: {
                        value: 30,
                        message: "Максимум 30 символов!",
                      },
                    })}
                  />
                  {errors?.email && (
                    <Text
                      m={1}
                      fontSize="lg"
                      fontWeight="semibold"
                      color="red.500"
                    >
                      {errors.email.message || "Error!"}
                    </Text>
                  )}
                </div>

                <div className="auth__input--inner full contact__input--inner">
                  <input
                    type="text"
                    placeholder="Text"
                    className="input auth__input"
                    {...register("message", {
                      required: "Поле обязательно к заполнению",
                      pattern: {
                        value: /^[a-zа-яA-ZА-Я0-9](.[a-zа-яA-ZА-Я0-9. ]*)$/,
                        message: "Допустимы буквы,цифры и .",
                      },
                      minLength: {
                        value: 4,
                        message: "Минимум 4 символа!",
                      },
                      maxLength: {
                        value: 100,
                        message: "Максимум 100 символов!",
                      },
                    })}
                  />
                  {errors?.message && (
                    <Text fontSize="xl" fontWeight="semibold" color="red.500">
                      {errors.message.message || "Error!"}
                    </Text>
                  )}
                </div>

                <button
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid}
                  className="button default__button contact__button"
                >
                  {t("Contact.send")}
                </button>
              </div>

              <div className="contact__map">
                <iframe
                  title="googlemap"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Suite%206%20High%20Street%20%20Sedgefield,%20Stockton-On-Tees,%20England,%20TS21%203AR+(Dreams%20Come%20Trues)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  className="contact__map--map"
                  frameBorder="0"
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
