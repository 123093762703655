import React from "react";

import { useAppSelector, useAppDispatch } from "../../../store";
import { getInvoiceById } from "../../../features/cryptoInvoice/cryptoInvoiceSlice";

import { useTimer } from "react-timer-hook";
import { Text } from "@chakra-ui/react";

export const TimerSixtySecond = ({ expiryTimestamp }) => {
  const { invoice } = useAppSelector((state) => state.cryptoInvoice);
  const dispatch = useAppDispatch();

  const onExpire = async () => {
    const time = await new Date();
    time.setSeconds(time.getSeconds() + 60);
    dispatch(getInvoiceById(invoice?.id));

    restart(time, true);
    resume;
  };

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => onExpire(),
    autoStart: true,
  });

  return (
    <Text Text color="red.500" fontWeight="bold" fontSize={["5xl", "8xl"]}>
      <span>{minutes}</span>:<span>{seconds}</span>
    </Text>
  );
};
