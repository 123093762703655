import React, { FC, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../store";

import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { FaRegClone } from "react-icons/fa";
import { IoIosRibbon } from "react-icons/io";
import { Box } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

export const AccountBalance: FC = () => {
  // Translation i18next
  const { t } = useTranslation();

  const { userData } = useAppSelector((state) => state.userInfo);

  const [mainBalance, setMainBalance] = useState(
    userData?.balances.find((elem) => elem.account.id == 1)
  );

  const [bonusBalance, setBonusBalance] = useState(
    userData?.balances.find((elem) => elem.account.id == 2)
  );

  const [cloneBalance, setCloneBalance] = useState(
    userData?.balances.find((elem) => elem.account.id == 3)
  );

  const [frozenMainBalance, setFrozenMainBalance] = useState(
    userData?.balances.find((elem) => elem.account.id == 4)
  );

  const [frozenBonusBalance, setFrozenBonusBalance] = useState(
    userData?.balances.find((elem) => elem.account.id == 5)
  );

  useEffect(() => {
    setMainBalance(userData?.balances.find((elem) => elem.account.id == 1));
    setBonusBalance(userData?.balances.find((elem) => elem.account.id == 2));
    setCloneBalance(userData?.balances.find((elem) => elem.account.id == 3));

    setFrozenMainBalance(
      userData?.balances.find((elem) => elem.account.id == 4)
    );

    setFrozenBonusBalance(
      userData?.balances.find((elem) => elem.account.id == 5)
    );
  }, [userData]);

  return (
    <div className="w100 flex default__margin">
      <h3 className="admin__title--inner w100">
        <img
          className="admin__title--icon"
          src="/assets/img/wallet-red.svg"
          alt="Иконка"
        />
        {t("AccountBalance.account_balance")}
      </h3>

      <div className="content__margin admin__wallet w100">
        <div className="admin__wallet--item orange flex">
          <img
            className="admin__wallet--bg"
            src="/assets/img/wallet-bg.svg"
            alt="Картинка"
          />

          <div className="flexdef w100 admin__wallet--text--inner">
            <div className="flexdef">
              <div className="admin__wallet--coin--inner flexs">
                <MdOutlineAccountBalanceWallet size="60%" color="#fd6770" />
              </div>

              <p className="admin__wallet--text ml1">
                {t("AccountBalance.main_balance")}
              </p>
            </div>

            <div className="flexend ml1 flexs">
              <p className="admin__wallet--text">
                {mainBalance && +mainBalance?.usd?.toFixed(2)} $
              </p>

              {/* <p className="admin__wallet--text mt1">
                {mainBalance?.value} МТВ
              </p> */}
            </div>
          </div>

          <Box color="white">
            {t("AccountBalance.frozen")}: {frozenMainBalance?.value} $
          </Box>

          {/* <div className="flexdef w100 admin__wallet--text--inner">
            <p className="admin__wallet--text mr1">
              1 МТВ = {mainBalance?.rate?.rate}$
            </p>

            <p className="admin__wallet--text">
              {mainBalance && +mainBalance?.rate?.change?.toFixed(2)}%
            </p>
          </div> */}
        </div>

        <div className="admin__wallet--item blue flex">
          <img
            className="admin__wallet--bg"
            src="/assets/img/people-bg.svg"
            alt="Картинка"
          />

          <div className="flexdef w100 admin__wallet--text--inner">
            <div className="flexdef">
              <div className="admin__wallet--coin--inner flexs">
                <FaRegClone size="50%" color="#5433ff" />
              </div>

              <p className="admin__wallet--text ml1">
                {t("AccountBalance.clone_balance")}
              </p>
            </div>

            <div className="flexend ml1 flexs">
              <p className="admin__wallet--text">
                {cloneBalance && +cloneBalance?.usd?.toFixed(2)} $
              </p>

              {/* <p className="admin__wallet--text mt1">
                {cloneBalance?.value} МТВ
              </p> */}
            </div>
          </div>

          {/* <div className="flexdef w100 admin__wallet--text--inner">
            <p className="admin__wallet--text mr1">
              1 МТВ = {cloneBalance?.rate?.rate}$
            </p>

            <p className="admin__wallet--text">
              {cloneBalance && +cloneBalance?.rate?.change?.toFixed(2)}%
            </p>
          </div> */}
        </div>

        {/* <div className="admin__wallet--item green flex">
          <img
            className="admin__wallet--bg"
            src="/assets/img/beach-bg.svg"
            alt="Картинка"
          />

          <div className="flexdef w100 admin__wallet--text--inner">
            <div className="flexdef">
              <div className="admin__wallet--coin--inner flexs">
                <IoIosRibbon size="60%" color="#1a8f18" />
              </div>

              <p className="admin__wallet--text ml1">
                {t("AccountBalance.bonus_balance")}
              </p>
            </div>

            <div className="flexend ml1 flexs">
              <p className="admin__wallet--text">
                {bonusBalance && +bonusBalance?.usd?.toFixed(2)} $
              </p>
            </div>
          </div>

          <Box color="white">
            {" "}
            {t("AccountBalance.frozen")}: {frozenBonusBalance?.value} $
          </Box>
        </div> */}
      </div>
    </div>
  );
};
