import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../http/apiService";

export interface IMessage {
  id: number;
  createdAt: string;
  text: string;
  user: {
    id: number;
    login: string;
    name: string;
    imageUrl: string;
    isOnline: boolean;
    isVerified: boolean;
  };
}

interface IState {
  messageList: {
    items: IMessage[];
    totalCount: number;
  };
  isLoadingMessage: boolean;
  isError: boolean;
}

const initialState: IState = {
  messageList: {
    items: [
      {
        id: 0,
        createdAt: "",
        text: "",
        user: {
          id: 0,
          login: "",
          name: "",
          imageUrl: "",
          isOnline: false,
          isVerified: false,
        },
      },
    ],
    totalCount: 0,
  },
  isLoadingMessage: false,
  isError: false,
};

// Delete message by id
export const deleteMessageById = createAsyncThunk(
  "invoiceById/get",
  async (id: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post(
        "/api/admin/Message/global-delete",
        id,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

// Get all message in list
export const getMessageList = createAsyncThunk(
  "messageList/post",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post(
        "/api/Message/global-list",
        data,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

// Reducer
export const globalChatSlice = createSlice({
  name: "globalChat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getMessageList.pending, (state) => {
        state.isLoadingMessage = true;
      })
      .addCase(getMessageList.fulfilled, (state, action) => {
        state.isLoadingMessage = false;
        state.messageList = action.payload;
      })
      .addCase(getMessageList.rejected, (state, action) => {
        state.isLoadingMessage = false;
        state.isError = true;
      });
  },
});

// export const {} = globalChatSlice.actions;
export default globalChatSlice.reducer;
