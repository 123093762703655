import React from "react";
import { useAppSelector } from "../../store";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoute = () => {
  // const { accessToken } = useAppSelector((state) => state.auth);
  const localAccessToken = localStorage.getItem("accessToken");

  return localAccessToken ? <Outlet /> : <Navigate to="/login" />;
};
