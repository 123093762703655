import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../http/apiService";

export interface IWebinar {
  id: 0;
  date: string;
  urlStream: string;
  urlRecord: string;
  imageUrl: string;
  status: {
    id: 0;
    name: string;
  };
  content: [
    {
      lang: number;
      title: string;
      desc: string;
    }
  ];
}

interface IWebinarState {
  webinars: {
    items: IWebinar[];
    totalCount: number;
  };
  isLoading: boolean;
  isError: boolean;
  message: any;
}

const initialState: IWebinarState = {
  webinars: {
    items: [],
    totalCount: 0,
  },
  isLoading: false,
  isError: false,
  message: "",
};

// Get publick webinars
export const getPublickWebinars = createAsyncThunk(
  "publickWebinars/post",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post("/api/Webinar", data, config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reducer
export const webinarSlice = createSlice({
  name: "webinar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPublickWebinars.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getPublickWebinars.fulfilled, (state, action) => {
        state.isLoading = false;
        state.webinars = action.payload;
      })
      .addCase(getPublickWebinars.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

// export const { } = webinarSlice.actions;
export default webinarSlice.reducer;
