import React, { useEffect, useState } from "react";
import Moment from "react-moment";

import $api from "../../../http/apiService";

import { useTranslation } from "react-i18next";

import { News } from "../../../Components/LK/News/News";

import { useAppDispatch } from "../../../store";
import { getUserInfo } from "../../../features/userInfo/userInfoSlice";

import { IPartner } from "../Tickets/Tickets.interface";
import { Avatar, Box, Button } from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

const itemsPerPage = 5;

type NotificationType = {
  id: number;
  createdAt: string;
  text: string;
  isRead: boolean;
  partner: IPartner;
};

const Notify = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [notification, setNotification] = useState<NotificationType[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const getNotifications = async () => {
    const rd = {
      skip: currentPage * itemsPerPage,
      take: itemsPerPage,
    };
    const res = await $api.post("api/Notification/list", rd);

    if (res?.status === 200 && res.data?.items) {
      if (!currentPage) {
        setNotification(res.data.items);
        setTotalCount(res.data.totalCount);
      } else {
        setNotification([...notification, ...res.data.items]);
        setTotalCount(res.data.totalCount);
      }
    }
  };

  useEffect(() => {
    getNotifications();
    dispatch(getUserInfo());
  }, [currentPage]);

  return (
    <>
      <Meta title="Personal account" />
      <div className="admin__content--content flex w100">
        <div className="w100 flex">
          <h3 className="admin__title--inner w100">
            <img
              className="admin__title--icon"
              src="/assets/img/notify-red.svg"
              alt="Иконка"
            />
            {t("Notifications.notifications")}
          </h3>

          <div className="content__margin flex w100">
            {notification?.length > 0 &&
              notification.map((elem) => (
                <Box key={elem.id} w="100%" mt={16}>
                  <div className="notify__item w100">
                    <div className="notify__item--img--inner flexs">
                      <Avatar
                        // className="img"
                        src={elem?.partner?.imageUrl}
                        size="xl"
                        name={
                          elem?.partner?.name
                            ? elem?.partner?.name
                            : elem?.partner?.login
                        }
                      />
                      <div
                        className="point_notify"
                        style={{
                          background: elem?.partner?.isOnline
                            ? "#61d64a"
                            : "red",
                        }}
                      ></div>
                    </div>

                    <div className="notify__item--content flex w100">
                      <div className="flexbet w100 flexw">
                        <div className="notify__name--inner flexdef mall5">
                          <img src="/assets/img/save.svg" alt="Картинка" />
                          {elem?.partner?.name
                            ? elem?.partner?.name
                            : elem?.partner?.login}
                        </div>

                        <p className="notify__date mall5">
                          <Moment format="HH:MM DD MMMM YYYY ">
                            {elem?.createdAt}
                          </Moment>
                        </p>
                      </div>

                      <div className="notify__box flex w100">
                        <p className="notify__title">{elem?.text}</p>
                      </div>
                    </div>
                  </div>
                </Box>
              ))}

            {totalCount > (currentPage + 1) * itemsPerPage && (
              <Button
                mb={4}
                mt={8}
                variant="ghost"
                size="lg"
                bg="#61d64a"
                color="white"
                boxShadow="inset 0px -3px 0px rgba(0, 0, 0, 0.1)"
                alignSelf="end"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={!(totalCount > (currentPage + 1) * itemsPerPage)}
              >
                {t("Notifications.download")}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="admin__content--right flex w100">
        <News />
      </div>
    </>
  );
};

export default Notify;
