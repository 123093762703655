import {
  ArrowUpDownIcon,
  EditIcon,
  InfoIcon,
  Search2Icon,
  SettingsIcon,
  StarIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Flex,
  Heading,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useMediaQuery,
  Wrap,
} from "@chakra-ui/react";
import React, { FC, useEffect, useRef, useState } from "react";
import "./DashBoard.scss";
import { usePagination } from "../../hooks/pagination/usePagination";
import { MdHideSource } from "react-icons/md";
import {
  FcCollaboration,
  FcCalendar,
  FcContacts,
  FcGallery,
  FcNews,
} from "react-icons/fc";
import { FcAddRow } from "react-icons/fc";
import { motion } from "framer-motion";
import { IContent, INews } from "../../features/news/newsSlice";
import { useForm, SubmitHandler } from "react-hook-form";
import { extendTheme } from "@chakra-ui/react";
import $api from "../../http/apiService";
import { AxiosResponse } from "axios";
import { CropEasy } from "../../Components/LK/CropImage/CropEasy";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-toastify";
import { useAppSelector } from "../../store";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-26px)",
};
const itemPerPage = 5;

export enum LangEnum {
  Ru = "Ru",
  Eng = "Eng",
  De = "De",
}

export const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
              fontSize: "xl",
            },
          },
        },
      },
    },
  },
});

type contentType = {
  title: string;
  preview: string;
};
type IFormInput = {
  publishedAt: string;
  expiredAt: string;
  isPublic: boolean;
  image: File[] | string;
  // Content: IContent;
  // lang: string;
  // content: contentType[];
  // "content[0].title": string;
  // "content[0].preview": string;
  // text: string;
  // "content[1].title": string;
  // "content[1].preview": string;
  // "content[2].title": string;
  // "content[2].preview": string;
};

const emptyNews = {
  "content[0].title": "",
  publishedAt: "",
  expiredAt: "",
  isPublic: true,
  image: "",
  // "content[0].preview": "",
  // text: "",
  // "content[1].title": "",
  // "content[1].preview": "",
  // "content[2].title": "",
  // "content[2].preview": "",
};

const NewsList: FC = () => {
  const { userData } = useAppSelector((state) => state.userInfo);
  const [isEditOpen, setIsEditOpen] = useState(false);
  // React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: "all",
  });

  const [myNews, setNews] = useState<INews[]>([]);
  const [chosenItem, setChosenItem] = useState<INews>();
  const [myNewsTotalCount, setMyNewsTotalCount] = useState<number>(5);
  const [textForSearch, setTextForSearch] = useState("");
  // Crop Image
  const newsfileInputRef = useRef<HTMLInputElement>(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [fileFromInput, setFileFromInput] = useState<any>(null);
  const [fileUrl, setFileUrl] = useState<any>(null);
  const [blob, setBlob] = useState<any>(null);
  const [inputImg, setInputImg] = useState("");
  const [contents, setContents] = useState<IContent[]>(
    Object.keys(LangEnum).map((e: string) => {
      const newC: IContent = { lang: e, title: "", preview: "", text: "" };
      return newC;
    })
  );
  const [editorStateArray, setEditorStateArray] = useState<EditorState[]>(
    Object.keys(LangEnum).map((e: string) => {
      return EditorState.createEmpty();
    })
  );
  const [isNeedUpdate, setIsNeedUpdate] = useState(1);
  const {
    isOpen: isOpenActionModal,
    onOpen: onOpenActionModal,
    onClose: onCloseActionModal,
  } = useDisclosure();

  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: itemPerPage,
    count: myNewsTotalCount,
  });

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");

  // Default values hook form initialize
  useEffect(() => {
    if (!chosenItem) return;
    reset(chosenItem);
    const tempContents: IContent[] = Object.keys(LangEnum).map((e: string) => {
      const newC: IContent = { lang: e, title: "", preview: "", text: "" };
      return newC;
    });
    chosenItem?.content.forEach((element) => {
      const item = tempContents.find((elem) => elem.lang === element.lang);
      if (item) {
        item.title = element?.title;
        item.preview = element?.preview;
        item.text = element?.text;
      }
    });
    setContents(tempContents);

    const tempEditorStatesArray: EditorState[] = [];
    Object.keys(LangEnum).forEach((element) => {
      const item = chosenItem?.content.find((elem) => elem.lang === element);
      if (!item) {
        tempEditorStatesArray.push(EditorState.createEmpty());
      } else if (item?.text?.length > 0) {
        try {
          JSON.parse(item.text);
          tempEditorStatesArray.push(
            EditorState.createWithContent(convertFromRaw(JSON.parse(item.text)))
          );
        } catch (e) {
          console.error(e);
          tempEditorStatesArray.push(
            EditorState.createWithContent(
              ContentState.createFromText(item.text)
            )
          );
        }
      } else {
        tempEditorStatesArray.push(EditorState.createEmpty());
      }
    });
    setEditorStateArray(tempEditorStatesArray);
  }, [chosenItem]);

  // useEffect(() => {
  //   console.log("contents", contents);
  //   console.log("editorStateArray", editorStateArray);
  // }, [chosenItem]);

  const getNews = async () => {
    const rd = {
      filter: textForSearch,
      take: itemPerPage,
      skip: 0,
    };
    if (page > 0) {
      rd.skip = (page - 1) * itemPerPage;
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response: AxiosResponse = await $api.post(
        "/api/admin/News/all",
        rd,
        config
      );
      const data: INews[] = response.data.items;
      data.forEach((elem) => {
        if (elem.expiredAt) {
          elem.expiredAt = elem.expiredAt.split("T")[0];
        }
        elem.publishedAt = elem.publishedAt.split("T")[0];
      });
      setNews(data);
      setMyNewsTotalCount(response.data.totalCount);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getNews();
  }, [isNeedUpdate, page, textForSearch]);

  const getColor = (news: INews) => {
    if (news.expiredAt && new Date(news.expiredAt) < new Date()) {
      return "#FF000033";
    } else if (new Date(news.publishedAt) > new Date()) {
      return "#FAFF004A";
    } else return "#00FF3833";
  };

  const resetForm = () => {
    reset(emptyNews);
    setChosenItem(undefined);
    setEditorStateArray(
      Object.keys(LangEnum).map((e: string) => {
        return EditorState.createEmpty();
      })
    );
    setContents(
      Object.keys(LangEnum).map((e: string) => {
        const newC: IContent = {
          lang: e,
          title: "",
          preview: "",
          text: "",
        };
        return newC;
      })
    );
    setBlob(null);
  };

  const onSubmitNewsForm: SubmitHandler<IFormInput> = async (data: any) => {
    const rdNews: any = {
      PublishedAt: data.publishedAt,
      ExpiredAt: data.expiredAt,
      IsPublic: data.isPublic,
      // Image: fileFromInput,
      Image: blob?.file,
      // "Content[0].Lang": "Ru",
      // "Content[0].Title": data.content[0].title,
      // "Content[0].Preview": data.content[0].preview,
      // "Content[0].Text": JSON.stringify(
      //   convertToRaw(editorStateRu.getCurrentContent())
      // ),
    };
    let j = 0;
    contents.forEach((element: IContent, i: number) => {
      if (element?.title?.length > 0) {
        rdNews[`Content[${j}].Lang`] = Object.keys(LangEnum)[i];
        rdNews[`Content[${j}].Title`] = element?.title;
        rdNews[`Content[${j}].Preview`] = element.preview;
        rdNews[`Content[${j}].Text`] = JSON.stringify(
          convertToRaw(editorStateArray[i].getCurrentContent())
        );
        j++;
      }
    });
    // console.log("rdNews", rdNews);

    // if (blob.file) {
    //   const newFile = new File(blob, "image.png", { type: "image/jpg", lastModified: +(new Date()) });
    //
    // }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    if (!chosenItem) {
      try {
        const response = await $api.post("/api/admin/News", rdNews, config);
        toast("Новость успешно добавлена");
        resetForm();
        setIsNeedUpdate(isNeedUpdate + 1);
        setIsEditOpen(false);
      } catch (e) {
        console.error("Ошибка добавления новости", e);
        toast.error("Ошибка добавления новости");
      } finally {
        // setIsEditOpen(false);
      }
    } else {
      try {
        if (rdNews.Image) {
          const response1 = await $api.put(
            `/api/admin/News/${chosenItem.id}/image`,
            rdNews,
            config
          );
        }
        const response2 = await $api.put(
          `/api/admin/News/${chosenItem.id}`,
          rdNews,
          config
        );
        resetForm();
        toast("Новость успешно обновлена");
        setIsNeedUpdate(isNeedUpdate + 1);
        setIsEditOpen(false);
      } catch (e) {
        console.error("Ошибка редактирования новости", e);
        toast.error("Ошибка редактирования новости");
      }
    }
  };

  // Upload avatar image
  // const onUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
  const onUploadFile = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      setFileFromInput(event.target.files[0]);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setFile(reader.result);
        setFileUrl(reader.result);
        setOpenCrop(true);
      });

      reader.readAsDataURL(event.target.files[0]);
      // setFileUrl(event.target.files[0]);
    }
  };

  const handleDelete = async () => {
    if (chosenItem) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const response = await $api.delete(
          `/api/admin/News/${chosenItem.id}`,
          config
        );
        toast("Новость успешно удалена");
        setChosenItem(undefined);
        setIsNeedUpdate(isNeedUpdate + 1);
        onCloseActionModal();
      } catch (e) {
        console.error(e);
        toast.error("Ошибка удаления новости");
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpenActionModal}
        onClose={() => {
          // resetForm();
          onCloseActionModal();
        }}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        size={"5xl"}
        isCentered
      >
        <ModalOverlay
          backdropBlur="5px"
          bg="blackAlpha.300"
          backdropFilter="blur(10px) "
        />
        <ModalContent>
          <ModalHeader fontSize={24} color="blue.500" fontWeight="bold">
            <Center>
              Удалить новость
              {` "${chosenItem?.content[0]?.title}"`}
            </Center>
          </ModalHeader>
          {/* <form onSubmit={handleSubmit(onSubmitBlockForm)}> */}
          <ModalBody fontSize={16}>
            <Text fontSize={12} color="gray.500" mt={8}>
              Превью
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              readOnly
              disabled
              value={chosenItem?.content[0]?.preview}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={handleDelete}
              type="submit"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="lg"
              bg="#3786e5"
              color="white"
            >
              Подтвердить
            </Button>
            <Button
              // colorScheme="twitter"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="lg"
              bg="gray.500"
              color="white"
              onClick={() => {
                // props.setIsAddComment(false);
                setChosenItem(undefined);
                setBlob(null);
                onCloseActionModal();
              }}
            >
              Отмена
            </Button>
          </ModalFooter>
          {/* </form> */}
        </ModalContent>
      </Modal>

      <Box
        m={8}
        fontSize={[12, 8, 10, 12, 14]}
        boxShadow="lg"
        p="8"
        rounded="md"
        bg="white"
      >
        <Wrap fontSize={[12, 8, 10, 12, 14]}>
          <Box ml={4}>
            <Text fontSize={[8, 12]}>Поиск по названию</Text>
            <Box>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  placeholder="Поиск по названию"
                  // w={[80, 60, 50]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={textForSearch}
                  onChange={(e: any) => {
                    setTextForSearch(e.target.value);
                    setPage(1);
                  }}
                />
                {textForSearch && (
                  <InputRightElement mr="10" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setTextForSearch("")}
                    />
                  </InputRightElement>
                )}
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Box>
          </Box>

          <Spacer />
        </Wrap>
      </Box>

      {/* ***************************************************************************HEADER */}

      <Flex m={8}>
        {/* <Box mt={12} color="#6484AA" fontSize={[14, 16, 20, 24, 30]}> */}
        <Heading as="h2" mt={6} size="xl" mb="4" color="#6484AA">
          Новости
        </Heading>
        {/* </Box> */}
        <Spacer />
        <Box
          boxShadow="lg"
          p="6"
          rounded="md"
          bg="white"
          width={80}
          height={20}
          alignSelf="end"
        >
          <Center>
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`Найдено: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${myNewsTotalCount} `}
            </Text>
          </Center>
        </Box>
      </Flex>

      {/* ***************************************************************************TABLE HEADER */}

      <Box
        m={8}
        color="#6484AA"
        fontSize={[12, 10, 12, 14, 16]}
        fontWeight="500"
      >
        <div className="dashBoard_content__margin dashBoard_w100 dashBoard_flex dashBoard_admin__table">
          {isLargerThan500 && (
            <div
              className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
              style={{ padding: "8px" }}
            >
              <p className="dashBoard_operation__item--column8min2">
                <Center>
                  {/* <Box mr={2}>
                  <FcBusinessContact />
                </Box> */}
                  ID
                  {/* <Box ml={2}>
                  <FcAlphabeticalSortingZa />
                </Box> */}
                </Center>
              </p>
              <p className="dashBoard_operation__item--column8">
                <Center>
                  <Box mr={2}>
                    <FcNews />
                  </Box>
                  Название
                </Center>
              </p>
              <p className="dashBoard_operation__item--column8">
                <Center>
                  <Box mr={2}>
                    <FcContacts />
                  </Box>
                  Превью
                </Center>
              </p>
              <p className="dashBoard_operation__item--column8">
                <Center>
                  <Box mr={2}>
                    <FcCollaboration />
                  </Box>
                  Публичная
                </Center>
              </p>
              <p className="dashBoard_operation__item--column8">
                <Center>
                  <Box mr={2}>
                    <FcCalendar />
                  </Box>
                  Дата публикации
                </Center>
              </p>
              <p className="dashBoard_operation__item--column8">
                <Center>
                  <Box mr={2}>
                    <FcCalendar />
                  </Box>
                  Дата создания
                  {/* <Box ml={2}>
                  <FcNumericalSorting12 />
                </Box> */}
                </Center>
              </p>
              <p className="dashBoard_operation__item--column8">
                <Center>
                  <Box mr={2}>
                    <FcCalendar />
                  </Box>
                  Срок действия до
                </Center>
              </p>
              <p className="dashBoard_operation__item--column8">
                <Center>
                  <Box mr={2}>
                    <FcGallery />
                  </Box>
                </Center>
              </p>
            </div>
          )}

          {/* ***************************************************************************DATA */}

          {myNews &&
            myNews.length > 0 &&
            myNews.map((item, index) => (
              <Box
                w="100%"
                key={item.id}
                bg={getColor(item)}
                fontSize={[12, 8, 10, 12, 14]}
                color="#000000"
                borderBottom="1px solid #dfdfdf"
                position="relative"
              >
                <div
                  key={item.id}
                  className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
                >
                  <p className="dashBoard_operation__item--column8min2">
                    <Center>
                      {!isLargerThan500 && <p>ID: &nbsp;</p>}
                      <p>{item?.id}</p>
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column8">
                    <Box
                      color="blue.500"
                      as={motion.div}
                      whileHover={{ scale: 1.1, cursor: "pointer" }}
                      onClick={() => {
                        resetForm();
                        setChosenItem(item);
                        setIsEditOpen(true);
                      }}
                      textAlign="center"
                    >
                      {item.content.find(
                        (e) => e.lang === userData?.interfaceLang
                      )?.title ??
                        item.content.find((e) => e.lang === LangEnum.Ru)?.title}
                    </Box>
                  </p>
                  <p
                    className={
                      isLargerThan500
                        ? "dashBoard_operation__item--column8min2"
                        : "myAbsoluteLeft"
                    }
                  >
                    <Center>
                      <Tooltip
                        fontSize={["12px", "16px"]}
                        label="Удалить"
                        hasArrow
                        bg="#ED64A6"
                        borderRadius="xl"
                      >
                        <Box
                          m="2"
                          as={motion.div}
                          whileHover={{ scale: 1.1, cursor: "pointer" }}
                          borderRadius="2xl"
                          bg="#ED64A6"
                          onClick={() => {
                            setChosenItem(item);
                            onOpenActionModal();
                          }}
                        >
                          {/* <MdOutlineCancel size={27} color="white" /> */}
                          <MdHideSource size={27} color="white" />
                        </Box>
                      </Tooltip>
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column8">
                    <Box textAlign="center">
                      {item.content.find(
                        (e) => e.lang === userData?.interfaceLang
                      )?.preview ??
                        item.content.find((e) => e.lang === LangEnum.Ru)
                          ?.preview}
                    </Box>
                  </p>
                  <p className="dashBoard_operation__item--column8">
                    <Center>
                      {!isLargerThan500 && <p>Публичная: &nbsp;</p>}
                      {item?.isPublic ? "Да" : "Нет"}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column8">
                    <Center>
                      {" "}
                      {!isLargerThan500 && <p>Дата публикации: &nbsp;</p>}
                      {item?.publishedAt?.split("T")[0]}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column8">
                    <Center>
                      {!isLargerThan500 && <p>Дата создания: &nbsp;</p>}
                      {item.createdAt?.split("T")[0]}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column8">
                    <Center>
                      {!isLargerThan500 && item.expiredAt && (
                        <p>Дата актуальности: &nbsp;</p>
                      )}
                      {item.expiredAt?.split("T")[0]}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column8">
                    <Center>
                      <Img src={item?.imageUrl} width="20" />
                    </Center>
                  </p>
                </div>
              </Box>
            ))}

          {myNewsTotalCount > itemPerPage && (
            <Center fontSize="12">
              <div className="pagination">
                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={prevPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === 1}
                >
                  Назад
                </Button>
                <button
                  onClick={() => setPage(1)}
                  className={`page ${page === 1 && "disabled"}`}
                >
                  1
                </button>
                {gaps.before ? "..." : null}

                {gaps.paginationGroup.map((el) => (
                  <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                  >
                    {el}
                  </button>
                ))}
                {gaps.after ? "..." : null}
                <button
                  onClick={() => setPage(totalPages)}
                  className={`page ${page === totalPages && "disabled"}`}
                >
                  {totalPages}
                </button>

                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={nextPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === totalPages}
                >
                  Вперед
                </Button>
              </div>
            </Center>
          )}
        </div>
      </Box>

      {/* *********************************************************************** NEW NEWS */}

      <Collapse in={!isEditOpen}>
        <Button
          leftIcon={<FcAddRow />}
          bg="#3786e5"
          color="white"
          ml={8}
          width={80}
          height={20}
          fontSize="3xl"
          onClick={() => setIsEditOpen(true)}
        >
          Добавить
        </Button>
      </Collapse>
      <Collapse in={isEditOpen}>
        <Box
          color="gray.600"
          ml={8}
          mr={8}
          fontSize={[12, 8, 10, 12, 14]}
          boxShadow="lg"
          rounded="md"
          bg="white"
        >
          {/* <ChakraProvider theme={theme}> */}
          <Box p={8} bg="blue.50" mb={8}>
            <Heading as="h2" size="lg" mb="4" color="#6484AA">
              {chosenItem ? "Редактирование новости" : "Добавление новости"}
            </Heading>

            <form onSubmit={handleSubmit(onSubmitNewsForm)}>
              <Tabs
                size="lg"
                // variant="soft-rounded"
                bg="white"
              >
                <TabList>
                  {Object.keys(LangEnum).map((e, i) => (
                    <Tab key={i}>{e}</Tab>
                  ))}
                </TabList>

                <TabPanels>
                  {Object.keys(LangEnum).map((e, i) => (
                    <TabPanel key={i}>
                      <Heading size="lg">Заполните на {e}</Heading>
                      <Wrap>
                        <Box w="40%">
                          <Text fontSize={12} color="gray.500" mt={8}>
                            Название {e}
                          </Text>
                          <Input
                            variant="primary"
                            fontSize={[12, 8, 10, 12, 14]}
                            h={30}
                            borderRadius="xl"
                            type="text"
                            // {...register("content[0].title", {
                            //   required: "Поле обязательно к заполнению",
                            // })}
                            value={contents?.[i]?.title}
                            onChange={(event) => {
                              if (!contents) return;
                              const newContents = [...contents];
                              const content = newContents.find(
                                (el) => el.lang === e
                              );
                              if (content) {
                                content.title = event.target.value;
                                setContents(newContents);
                              } else {
                                const content: IContent = {
                                  lang: e,
                                  title: event.target.value,
                                  preview: "",
                                  text: "",
                                };
                                newContents.push(content);
                                setContents(newContents);
                              }
                            }}
                          />
                        </Box>
                        <Box w="50%">
                          <Text fontSize={12} color="gray.500" mt={8}>
                            Краткое описание {e}
                          </Text>
                          <Input
                            type="text"
                            variant="primary"
                            fontSize={[12, 8, 10, 12, 14]}
                            h={30}
                            borderRadius="xl"
                            // {...register("content[0].preview", {
                            //   required: "Поле обязательно к заполнению",
                            // })}
                            value={contents?.[i]?.preview}
                            onChange={(event) => {
                              if (!contents) return;
                              const newContents = [...contents];
                              const content = newContents.find(
                                (el) => el.lang === e
                              );
                              if (content) {
                                content.preview = event.target.value;
                                setContents(newContents);
                              } else {
                                const content: IContent = {
                                  lang: e,
                                  preview: event.target.value,
                                  title: "",
                                  text: "",
                                };
                                newContents.push(content);
                                setContents(newContents);
                              }
                            }}
                          />
                        </Box>
                      </Wrap>
                      <Text fontSize={12} color="gray.500" mt={8}>
                        Описание {e}
                      </Text>
                      <Editor
                        editorState={editorStateArray[i]}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(editorState: any) => {
                          const newEditorStateArray = [...editorStateArray];
                          newEditorStateArray[i] = editorState;
                          setEditorStateArray(newEditorStateArray);
                        }}
                      />
                      {/* {errors?.text && (
                        <Text
                          fontSize="xl"
                          fontWeight="semibold"
                          color="red.500"
                        >
                          {errors.text.message || "Error!"}
                        </Text>
                      )} */}
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>

              <Box display="inline-block">
                <Text fontSize={12} color="gray.500" mt={8}>
                  Дата публикации*
                </Text>
                <Input
                  type="date"
                  width="auto"
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  h={30}
                  borderRadius="xl"
                  {...register("publishedAt", {
                    required: "Поле обязательно к заполнению",
                  })}
                />
                {errors?.publishedAt && (
                  <Text fontSize="xl" fontWeight="semibold" color="red.500">
                    {errors.publishedAt.message || "Error!"}
                  </Text>
                )}
              </Box>

              <Box display="inline-block" ml="4">
                <Text fontSize={12} color="gray.500" mt={8}>
                  Дата актуальности
                </Text>
                <Input
                  type="date"
                  width="auto"
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  h={30}
                  borderRadius="xl"
                  {...register("expiredAt")}
                />
              </Box>
              <Box display="inline-block" ml="4" color="gray.500">
                <Checkbox
                  size="lg"
                  color="#6484AA"
                  fontWeight="400"
                  defaultChecked
                  {...register("isPublic")}
                >
                  Публична
                </Checkbox>
              </Box>

              <Text fontSize={12} color="gray.500" mt={8}>
                Изображение*
              </Text>
              <input
                style={{ display: "none" }}
                id="image-input"
                type="file"
                accept="image/jpeg,image/png"
                multiple={false}
                ref={newsfileInputRef}
                onChange={(e) => onUploadFile(e)}
              />
              {chosenItem && !blob && <img src={chosenItem?.imageUrl} />}
              {blob && <img src={blob.url} />}
              <Flex alignItems="start">
                <Button
                  colorScheme="blue"
                  borderRadius="xl"
                  h={[16, 20]}
                  mt={4}
                  width="80"
                  fontSize={[12, 8, 10, 12, 14]}
                  onClick={() => newsfileInputRef.current?.click()}
                >
                  <Text fontSize={[12, 16]}>Загрузить</Text>
                </Button>
                {errors?.image && (
                  <Text fontSize="xl" fontWeight="semibold" color="red.500">
                    {"Загрузите файл!"}
                  </Text>
                )}
                {blob && (
                  <img className="admin__title--icon" src={blob} alt="" />
                )}

                <Spacer />
                <Button
                  onClick={handleSubmit(onSubmitNewsForm)}
                  isDisabled={!isValid}
                  type="submit"
                  mt={10}
                  width={72}
                  height={16}
                  fontSize="2xl"
                  boxShadow="lg"
                  display="block"
                  mr="2"
                  bg="#3786E5"
                  color="white"
                >
                  Сохранить
                </Button>
                <Button
                  onClick={() => {
                    resetForm();
                    setIsEditOpen(false);
                  }}
                  mt={10}
                  width={72}
                  height={16}
                  fontSize="2xl"
                  boxShadow="lg"
                  display="block"
                  bg="gray.600"
                  color="white"
                >
                  Закрыть
                </Button>
              </Flex>
            </form>
            {openCrop ? (
              <CropEasy
                {...{
                  file,
                  setFile,
                  setOpenCrop,
                  openCrop,
                  myCropShape: "rect",
                  myAspect: 2,
                  setBlob,
                }}
              />
            ) : (
              <Box></Box>
            )}
          </Box>
        </Box>
      </Collapse>
    </>
  );
};

export default NewsList;
