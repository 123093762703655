import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Collapse,
  Divider,
  Flex,
  Grid,
  GridItem,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import "./DashBoard.scss";
import { extendTheme } from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import DashBoardNavBar from "./DashBoardNavBar";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { GiHamburgerMenu } from "react-icons/gi";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../store";
import { API_URL } from "../../http/apiService";
import {
  getUserInfo,
  resetUserInfo,
} from "../../features/userInfo/userInfoSlice";
import { logoutUser } from "../../features/auth/authSlice";
// import { YMInitializer } from "react-yandex-metrika";
// import ym from "react-yandex-metrika";

const theme = extendTheme({
  fonts: {
    body: `dm-sans, sans-serif`,
  },
  components: {
    Input: {
      variants: {
        primary: {
          bg: "red.100",
          // _color: "blue.800",
          // border: "1px solid red",
          // fontSize: "16",
          // colorScheme:"green"
        },
      },
      baseStyle: {
        field: {
          border: "1px solid red", //
          // bg: "blue.50",
          borderColor: "#3786e5",
          // borderWidth: "2px",
          fontSize: "20", //
          borderRadius: "8px", //
          // _color: "blue.400", //
          // color: "blue.500",
          // textFillColor: "#c6c6c6", //
          textFillColor: "#3A3A3C",
          // boxShadow: "0 0 0px 1px #3786e5 inset",
          hight: "40", //
          // borderWidth: "4px",
          borderStyle: "solid",
          // _placeholder: { opacity: 1, color: "#3786e5" },
          // transition: "background-color 5000s ease-in-out 0s",
          _placeholder: { color: "inherit" },
          _hover: {
            bg: "blue.50",
          },
          _focus: {
            bg: "blue.50",
          },
          // This does not work
          _autofill: {
            bg: "gray.300",
          },
        },
      },
    },
  },
});

// const animationKeyframes = keyframes`
//   0% { transform: scale(1) rotate(0); border-radius: 20%; }
//   25% { transform: scale(2) rotate(0); border-radius: 20%; }
//   50% { transform: scale(2) rotate(270deg); border-radius: 50%; }
//   75% { transform: scale(1) rotate(270deg); border-radius: 50%; }
//   100% { transform: scale(1) rotate(0); border-radius: 20%; }
// `;

// const animation = `${animationKeyframes} 4s ease-in-out 2s infinite`;

const LayoutAdminDashboard: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { accessToken } = useAppSelector((state) => state.auth);
  const btnRef = useRef<HTMLButtonElement>(null);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  // const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 1224px)" });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const localRefreshToken = localStorage.getItem("refreshToken");
  const dispatch = useAppDispatch();

  // Get user data from backend when page starts
  const getFirstDataFromBackend = async () => {
    if (!accessToken) {
      return;
    }

    try {
      const data = {
        refresh_token: localRefreshToken,
      };

      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

      const response = await axios.post(
        `${API_URL}/api/App/refresh-token`,
        data,
        config
      );

      if (response.data) {
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
      }
    } catch (error: any) {
      console.log("INTESEEPTOR ERROR dashboard:", error);
      await dispatch(logoutUser());
      await dispatch(resetUserInfo());
    }

    await dispatch(getUserInfo());
  };

  useEffect(() => {
    getFirstDataFromBackend();
  }, []);

  return (
    <>
      {/* <YMInitializer accounts={[90983876]} /> */}
      <ChakraProvider theme={theme}>
        <Box
          minW={[400, 600]}
          // maxWidth={"2500px"}
          // minWidth="100%"
          // maxWidth="100vw"
          // minHeight="100%"
          // position="absolute"
          // margin="0"
          // width={[
          //   "100%", // 0-30em
          //   "50%", // 30em-48em
          //   "25%", // 48em-62em
          //   "15%", // 62em+
          // ]}
          // width={getWidth()}

          // height={{
          //   base: "100vh", // 0-48em
          //   md: "50%", // 48em-80em,
          //   xl: "25%", // 80em+
          // }}
          // bg="teal.400"
          // maxWidth={[300, 600, 2300]}
          w="100%"
          bg="#F4F9FF"
          minH="100vh"
          // minWidth={300}
          // h="100%"
        >
          {/* {isDesktopOrLaptop && <p>You are a desktop or laptop</p>}
        {isMobile && <p>You are a mobile phone</p>} */}
          {!isMobile ? (
            <Flex
              // h="100%"
              // w="100%"
              // position="relative"
              minH="100vh"
            >
              <div style={{ background: "#3786e5", position: "relative" }}>
                <Box
                  bg="#3786e5"
                  minW={[300, 300]}
                  top="0"
                  left="0"
                  position="sticky"
                >
                  <DashBoardNavBar />
                </Box>
              </div>

              <Box pl="2" bg="#F4F9FF" w="100%">
                <Outlet />
              </Box>
            </Flex>
          ) : (
            <Box
              position="relative"
              // minWidth={360}
            >
              <Button
                bg="#3786e5"
                borderRadius="3xl"
                w="20"
                h="20"
                ref={btnRef}
                onClick={onOpen}
                position="fixed"
                right={8}
                zIndex="modal"
                // top={-24}
              >
                {/* <HamburgerIcon w={20} /> */}
                <GiHamburgerMenu size={50} color={"white"} />
              </Button>
              <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
                size="lg"
              >
                <DrawerOverlay />
                <DrawerContent bg="#3786e5">
                  <DrawerCloseButton />

                  <DrawerBody>
                    <DashBoardNavBar onClose={onClose} />
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
              <Outlet />
            </Box>
          )}
        </Box>
      </ChakraProvider>
    </>
  );
};

export default LayoutAdminDashboard;
