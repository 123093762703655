import React, { FC, useEffect } from "react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Center,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import { SubmitHandler, useForm } from "react-hook-form";
import $api from "../../http/apiService";
import { toast } from "react-toastify";
import { RateType } from "./SiteSettings";

type Iprops = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  rate: RateType | undefined;
  isNeedUpdate: number;
  setIsNeedUpdate: any;
  setChosenItem: any;
  isDelete: boolean;
  setIsDelete: any;
};
type IFormInput = {
  rate: number | string;
  date: string;
  change: number | string;
};
const emptyItem: IFormInput = {
  rate: "",
  date: "",
  change: "",
};
const SiteSettingsRateModal = (props: Iprops) => {
  // React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: "all",
  });

  //   useEffect(() => {
  //     props.onOpen();
  //   }, []);

  // Default values hook form initialize
  useEffect(() => {
    // props.rate?.date = props.rate?.date.split("T")[0]
    reset(props.rate);
  }, [props.rate]);

  const onSubmitForm: SubmitHandler<IFormInput> = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const rd = {
      ...data,
    };
    if (props.rate && props.isDelete == false) {
      try {
        const response = await $api.put(
          `/api/admin/Mtb/${props.rate?.id}`,
          rd,
          config
        );
        if (response.status >= 200 && response.status < 300) {
          toast(`Курс успешно обновлен`);
          handleClose();
        }
      } catch (e) {
        console.error("Ошибка обновления курса", e);
        toast.error("Ошибка обновления курса");
      }
    } else if (props.rate && props.isDelete == true) {
      try {
        const response = await $api.delete(
          `/api/admin/Mtb/${props.rate?.id}`,
          config
        );
        if (response.status >= 200 && response.status < 300) {
          toast(`Курс успешно удален`);
          handleClose();
        }
      } catch (e) {
        console.error("Ошибка удаления курса", e);
        toast.error("Ошибка удаления курса");
      }
    }
  };

  const handleClose = () => {
    props.setChosenItem(undefined);
    reset(emptyItem);
    props.setIsDelete(false);
    props.setIsNeedUpdate(props.isNeedUpdate + 1);
    props.onClose();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      size={"5xl"}
      isCentered
    >
      <ModalOverlay
        backdropBlur="5px"
        bg="blackAlpha.300"
        backdropFilter="blur(10px) "
      />
      <ModalContent>
        <ModalHeader fontSize={24} color="blue.500" fontWeight="bold">
          <Center>
            {props.isDelete ? "Удалить курс" : "Редактирование курса"}
          </Center>
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <ModalBody fontSize={16}>
            <Text fontSize={12} color="gray.500" mt={8}>
              Курс*
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              type="number"
              disabled={props.isDelete}
              bg={props.isDelete ? "gray.300" : ""}
              {...register("rate", {
                required: "Поле обязательно к заполнению",
              })}
            />
            <Text fontSize={12} color="gray.500" mt={8}>
              Дата
            </Text>
            <Input
              type="datetime-local"
              width="auto"
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              h={30}
              borderRadius="xl"
              disabled={props.isDelete}
              bg={props.isDelete ? "gray.300" : ""}
              {...register("date", {
                required: "Поле обязательно к заполнению",
              })}
            />
            <Text fontSize={12} color="gray.500" mt={8}>
              Изменение*
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              type="number"
              disabled={props.isDelete}
              bg={props.isDelete ? "gray.300" : ""}
              {...register("change", {
                required: "Поле обязательно к заполнению",
              })}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={handleSubmit(onSubmitForm)}
              isDisabled={!isValid}
              type="submit"
              colorScheme="facebook"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="dark-lg"
            >
              {props.isDelete ? "Удалить" : "Сохранить"}
            </Button>
            <Button
              colorScheme="twitter"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="dark-lg"
              onClick={() => {
                handleClose();
              }}
            >
              Закрыть
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default SiteSettingsRateModal;
