import React, { FC, useEffect, useRef, useState } from "react";
import "./Settings.scss";

import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";

import {
  setUserData,
  deleteUserAvatar,
} from "../../../features/userInfo/userInfoSlice";

import { NewPassword } from "./NewPassword";

import { QrCode } from "../../../Components/LK/QrCode/QrCode";
import QRCode from "qrcode";
import { toast } from "react-toastify";
import { CropEasy } from "../../../Components/LK/CropImage/CropEasy";

import { UserWallets } from "./UserWallets";
import { AccountBalance } from "../../../Components/LK/AccountBalance/AccountBalance";

import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";

import {
  Divider,
  Text,
  Button,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Center,
  Avatar,
  Spinner,
  useDisclosure,
  useClipboard,
  Checkbox,
} from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

type SettingsForm = {
  name: string;
  mobilePhone: string;
  email: string;
  sessionTime: number;
  socialMediaVk: string;
  socialMediaTg: string;
  visibleTelegram: boolean;
};

const Settings: FC = () => {
  const { t } = useTranslation();

  const { userData, isLoadingUserInfo, isLoadingUserAvatar } = useAppSelector(
    (state) => state.userInfo
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { isOpen, onToggle } = useDisclosure();
  const [showButton, setShowButton] = useState(true);
  const [sendEmail, setSendEmail] = useState<string>();
  const [fullLink, setFullLink] = useState("");
  const [telegramValue, setTelegramValue] = useState("");
  const [visiblePartner, setVisiblePartner] = useState<boolean | undefined>(
    false
  );
  const [session, setSession] = useState<string | number>(60);

  const [mainBalance, setMainBalance] = useState<number>(0);

  const params = useParams();
  const { onCopy } = useClipboard(userData.masterKey);

  // Get user main balance by id
  const getMainBalance = () => {
    userData?.balances.map((bal, index) => {
      if (bal.account.id == 1) {
        setMainBalance(bal.value);
      }
    });
  };

  // Make referrals link
  useEffect(() => {
    const fullPath = window.location.href.split("");
    fullPath.splice(-14, 14);
    const fullLink = `${fullPath.join("")}register/${userData?.login}`;
    setFullLink(fullLink);

    getMainBalance();
  }, [userData]);

  // Hide button on tab 3
  useEffect(() => {
    if (params?.id && params.id === "3") {
      setShowButton(false);
    }
  }, []);

  // Crop Image
  const [openCrop, setOpenCrop] = useState(false);
  const [file, setFile] = useState<string | ArrayBuffer | null>(null);
  const [blob, setBlob] = useState(null);

  const getBlob = (blob: any) => {
    // pass blob up from the ImageCropper component
    setBlob(blob);
  };

  // Initialize react hook form
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<SettingsForm>({
    mode: "all",
    defaultValues: {
      name: "",
      mobilePhone: "",
      email: "",
      socialMediaVk: "",
      socialMediaTg: "",
      sessionTime: 30,
    },
  });

  // Default values hook form initialize
  useEffect(() => {
    reset(userData);
    setVisiblePartner(userData.visibleAsPartner);
  }, [userData]);

  // Default values hook form initialize
  useEffect(() => {
    // reset(userData);
    reset(userData.contacts);
  }, [userData.contacts]);

  // Получения времени выхода с бекенда
  useEffect(() => {
    setSession(userData.sessionTime);
  }, [userData]);

  // Submit settings form
  const onSubmit: SubmitHandler<SettingsForm> = (data) => {
    const fetchData = {
      name: data.name,
      mobilePhone: data.mobilePhone,
      email: data.email,
      socialMediaVk: data.socialMediaVk,
      socialMediaTg: data.socialMediaTg,
      sessionTime: session,
      visibleTelegram: data.visibleTelegram,
      visibleAsPartner: visiblePartner,
    };

    dispatch(setUserData(fetchData));
    reset();
  };

  // Upload avatar image
  const onUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const image = reader.result;
        setFile(image);
        setOpenCrop(true);
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  // Send Qr code to email
  const sendEmailHandler = () => {
    const generateQR = async (text: string) => {
      try {
        const image = await QRCode.toDataURL(text);

        window.open(
          `mailto:${sendEmail}?subject=Моя реферальная ссылка - ${
            userData.name ? userData.name : userData.login
          }&body=${fullLink} `
        );
      } catch (err) {
        console.error("Qr Code не сработал");
      }
    };

    generateQR(fullLink);
    setSendEmail("");
  };

  // Delete user avatar
  const deleteAvatarHandler = () => {
    dispatch(deleteUserAvatar());
  };

  // Master key
  const masterKeyHandler = () => {
    onCopy();
    toast.success("Скопировано в буфер обмена!");
    onToggle();

    setTimeout(() => {
      window.open("https://t.me/DreamsCT_bot");
    }, 1200);
  };

  return (
    <>
      <Meta title="Personal account" />

      <div className="admin__content--content flex w100">
        <div className="w100 ">
          <h3 className="admin__title--inner w100">
            <img
              className="admin__title--icon"
              src="/assets/img/settings-red.svg"
              alt="Иконка"
            />
            {t("Settings.profile_settings")}
          </h3>

          <Tabs
            mt={4}
            defaultIndex={
              params?.id && isFinite(+params.id) ? +params.id - 1 : 0
            }
            // onChange={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab
                onClick={() => setShowButton(true)}
                _selected={{
                  color: "white",
                  bg: "#3182CE",
                  borderRadius: "lg",
                }}
              >
                <Text fontSize={16} fontWeight="medium">
                  {t("Settings.all")}
                </Text>
              </Tab>
              <Tab
                onClick={() => setShowButton(true)}
                _selected={{
                  color: "white",
                  bg: "#3182CE",
                  borderRadius: "lg",
                }}
              >
                <Text fontSize={16} fontWeight="medium">
                  {t("Settings.safety")}
                </Text>
              </Tab>
              <Tab
                onClick={() => setShowButton(false)}
                _selected={{
                  color: "white",
                  bg: "#3182CE",
                  borderRadius: "lg",
                }}
              >
                <Text fontSize={16} fontWeight="medium">
                  {t("Settings.requisites")}
                </Text>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <div className="content__margin w100 flex settings__content">
                  <div className="w100 flexbet flexw  content__margin">
                    <div className="settings__top-section-email ">
                      <div className="trans__item flex">
                        <p className="setting__side--title">
                          {t("Settings.name")}
                        </p>
                        <input
                          type="text"
                          className="mt1 w98 input select trans__input"
                          placeholder={t("Settings.name")}
                          {...register("name", {
                            value: userData.name,
                            pattern: {
                              value:
                                /^[а-яА-ЯёЁa-zA-Z]+ ?[а-яА-ЯёЁa-zA-Z]+ ?[а-яА-ЯёЁa-zA-Z]+$/,
                              message: "Допустим кириллица и латинские буквы.",
                            },
                            minLength: {
                              value: 6,
                              message: "Минимум 6 символов!",
                            },
                            maxLength: {
                              value: 30,
                              message: "Максимум 30 символов!",
                            },
                          })}
                        />
                        {errors?.name && (
                          <Text
                            m={1}
                            fontSize="lg"
                            fontWeight="semibold"
                            color="red.500"
                          >
                            {errors.name.message || "Error!"}
                          </Text>
                        )}
                      </div>

                      <div className="trans__item flex">
                        <p className="setting__side--title ">
                          {t("Settings.phone")}
                        </p>
                        <PhoneInputWithCountry
                          className="mt1 w98 input select trans__input"
                          name="mobilePhone"
                          placeholder="Номер телефона"
                          control={control}
                        />
                      </div>
                    </div>

                    <div className="settings__top-section-email">
                      <div className="trans__item flex">
                        <p className="setting__side--title">
                          {t("Settings.login")}
                        </p>
                        <input
                          defaultValue={userData.login}
                          type="text"
                          readOnly
                          className="mt1 w98 input select trans__input"
                          placeholder="Логин"
                        />
                      </div>

                      <div className="trans__item flex">
                        <p className="setting__side--title ">Email</p>
                        <input
                          type="email"
                          className="mt1 w98 input select trans__input"
                          placeholder="Email"
                          {...register("email", {
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Введите верный email!",
                            },
                            minLength: {
                              value: 6,
                              message: "Минимум 6 символов!",
                            },
                            maxLength: {
                              value: 40,
                              message: "Максимум 40 символов!",
                            },
                          })}
                        />
                        {errors?.email && (
                          <Text
                            m={1}
                            fontSize="lg"
                            fontWeight="semibold"
                            color="red.500"
                          >
                            {errors.email.message || "Error!"}
                          </Text>
                        )}
                      </div>
                    </div>

                    <div className="settings__top-section-email">
                      <div className="trans__item flex">
                        <p className="setting__side--title">
                          {t("Settings.telegram")} @
                        </p>
                        <input
                          type="text"
                          className="mt1 w98 input select trans__input"
                          placeholder="Телеграм"
                          {...register("socialMediaTg", {
                            onChange: (e) => setTelegramValue(e.target.value),
                            pattern: {
                              value: /^[a-zA-Z0-9](.[a-zA-Z0-9_.]*)$/,
                              message:
                                "Вводите ник после /.Допустимы латинские буквы,цифры и _",
                            },
                            minLength: {
                              value: 5,
                              message: "Минимум 5 символов!",
                            },
                            maxLength: {
                              value: 15,
                              message: "Максимум 15 символов!",
                            },
                          })}
                        />
                        {errors?.socialMediaTg && (
                          <Text
                            m={1}
                            fontSize="lg"
                            fontWeight="semibold"
                            color="red.500"
                          >
                            {errors.socialMediaTg.message || "Error!"}
                          </Text>
                        )}
                      </div>

                      <div className="trans__item flex">
                        <p className="setting__side--title">
                          {t("Settings.vk")}
                        </p>
                        <input
                          type="text"
                          className="mt1 w98 input select trans__input"
                          placeholder="Вконтакте"
                          {...register("socialMediaVk", {
                            pattern: {
                              value: /^[a-zA-Z0-9](.[a-zA-Z0-9_:/.]*)$/,
                              message: "Допустимы латинские буквы,цифры и _:/.",
                            },
                            minLength: {
                              value: 5,
                              message: "Минимум 5 символов!",
                            },
                            maxLength: {
                              value: 30,
                              message: "Максимум 30 символов!",
                            },
                          })}
                        />
                        {errors?.socialMediaVk && (
                          <Text
                            m={1}
                            fontSize="lg"
                            fontWeight="semibold"
                            color="red.500"
                          >
                            {errors.socialMediaVk.message || "Error!"}
                          </Text>
                        )}
                      </div>
                    </div>

                    <div className="settings__top-section-email">
                      <div className="trans__item flex">
                        <Checkbox
                          colorScheme="green"
                          size="lg"
                          isDisabled={telegramValue.length < 5}
                          {...register("visibleTelegram", {})}
                        >
                          <Text
                            mx={2}
                            fontSize={"16px"}
                            color=" #fff"
                            fontWeight="500"
                          >
                            {t("Settings.visible_tg")}
                          </Text>
                        </Checkbox>
                      </div>

                      {mainBalance >= 3000 && userData.isVerified && (
                        <div className="trans__item flex">
                          <Checkbox
                            colorScheme="blue"
                            size="lg"
                            isChecked={visiblePartner}
                            onChange={(e) =>
                              setVisiblePartner(e.target.checked)
                            }
                          >
                            <Text
                              mx={2}
                              fontSize={"16px"}
                              color=" #fff"
                              fontWeight="500"
                            >
                              {t("Settings.visible_partner")}
                            </Text>
                          </Checkbox>
                        </div>
                      )}
                    </div>

                    <div className="settings__bottom-section-qr-code mt1">
                      <Flex>
                        <Center p={2} mx="auto">
                          <Avatar
                            w={["100px", "125px", "140px"]}
                            h={["100px", "125px", "140px"]}
                            name={
                              userData.name ? userData.name : userData.login
                            }
                            src={userData?.imageUrl}
                          />

                          {isLoadingUserAvatar ? (
                            <Center
                              w="100%"
                              h="100%"
                              m={4}
                              flexDirection="column"
                              p={2}
                              textAlign="center"
                            >
                              <Spinner
                                m={10}
                                thickness="14px"
                                speed="0.7s"
                                emptyColor="#61D64A"
                                color="#1c3b6a"
                                w={24}
                                h={24}
                              />
                            </Center>
                          ) : (
                            <Center
                              m={4}
                              flexDirection="column"
                              p={2}
                              textAlign="center"
                            >
                              <input
                                style={{ display: "none" }}
                                id="image-input"
                                type="file"
                                accept="image/jpeg,image/png"
                                multiple={false}
                                ref={fileInputRef}
                                onChange={(e) => onUploadFile(e)}
                              />
                              <Button
                                colorScheme="blue"
                                borderRadius="xl"
                                w={[44, 60]}
                                h={[16, 20]}
                                m={2}
                                onClick={() => fileInputRef.current?.click()}
                              >
                                <Text fontSize={[12, 16]}>
                                  {t("Settings.avatar")}
                                </Text>
                              </Button>
                              <Button
                                colorScheme="red"
                                borderRadius="xl"
                                isDisabled={userData?.imageUrl == ""}
                                w={[44, 60]}
                                h={[16, 20]}
                                m={2}
                                onClick={() => deleteAvatarHandler()}
                              >
                                <Text fontSize={[12, 16]}>
                                  {t("Settings.avatar_del")}
                                </Text>
                              </Button>
                              <Text fontSize={[16, 20, 24]} color="white">
                                {userData?.name}
                              </Text>
                            </Center>
                          )}
                        </Center>
                      </Flex>
                    </div>

                    <Flex w={["100%", "50%"]} direction="column" align="center">
                      <QrCode
                        title={t("Settings.identifier")}
                        size={192}
                        value={fullLink}
                      />
                      <Flex direction="column">
                        <input
                          value={sendEmail}
                          onChange={(e) => setSendEmail(e.target.value)}
                          type="email"
                          className="mt1 input select trans__input"
                          placeholder="Email"
                        />
                        <Center w="100%">
                          <Button
                            onClick={() => sendEmailHandler()}
                            isDisabled={!sendEmail}
                            mt={4}
                            colorScheme="green"
                            borderRadius="xl"
                            w={["50%"]}
                            h={[16, 20]}
                          >
                            <Text fontSize={[12, 16]}>
                              {t("Settings.send")}
                            </Text>
                          </Button>
                        </Center>
                      </Flex>
                    </Flex>
                  </div>
                </div>
                {openCrop ? (
                  <CropEasy
                    {...{
                      file,
                      setFile,
                      setOpenCrop,
                      openCrop,
                      myCropShape: "round",
                      myAspect: 1,
                      setBlob: { getBlob },
                    }}
                  />
                ) : (
                  <Box></Box>
                )}
              </TabPanel>

              <TabPanel>
                <div className="content__margin w100 flex settings__content">
                  <div className="w100 flexbet flexw trans__input--inner content__margin">
                    <NewPassword />
                    <div className="settings__top-section-email mt1">
                      <div className="trans__item flex ">
                        <p className="setting__side--title">
                          {t("Settings.key")}
                        </p>
                        <input
                          type="text"
                          readOnly
                          className="mt1 w98 input select trans__input"
                          defaultValue={userData.masterKey}
                        />
                      </div>

                      <div className="trans__item flex ">
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          w="100%"
                          h="100%"
                        >
                          <Button
                            onClick={masterKeyHandler}
                            colorScheme={
                              userData?.isVerified ? "green" : "blue"
                            }
                            borderRadius="xl"
                            w={["60%", "60%", "80%", "80%", "55%"]}
                            h="50px"
                          >
                            <Text
                              fontSize="18px"
                              color="white"
                              fontFamily="Graphik"
                            >
                              {userData?.isVerified
                                ? t("Settings.untie")
                                : t("Settings.bind")}
                            </Text>
                          </Button>
                        </Flex>
                      </div>
                    </div>

                    <Divider mt={12} />

                    <div className="w100 flexbet flexw  content__margin">
                      <Box w={["98%", "48%"]} h="100%">
                        <p className="setting__side--title">
                          {t("Settings.exit")}
                        </p>
                        <select
                          className="mt1 w98 input select trans__input"
                          {...register("sessionTime", {
                            onChange: (e) => setSession(e.target.value),
                          })}
                          value={session}
                        >
                          <option value={30}>30 min</option>
                          <option value={60}>60 min</option>
                          <option value={90}>90 min</option>
                        </select>
                      </Box>
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="content__margin w100 flex settings__content">
                  <div className="w100 flexbet flexw trans__input--inner content__margin">
                    <UserWallets />
                  </div>
                </div>
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Center w="100%">
            {isLoadingUserInfo ? (
              <Spinner
                m={4}
                thickness="14px"
                speed="0.7s"
                emptyColor="#61D64A"
                color="#1c3b6a"
                w={20}
                h={20}
              />
            ) : (
              showButton && (
                <Button
                  onClick={handleSubmit(onSubmit)}
                  isDisabled={!isValid}
                  colorScheme="blue"
                  borderRadius="xl"
                  w="40%"
                  h={20}
                  boxShadow="dark-lg"
                  m={4}
                >
                  <Text fontSize="18px" color="white" fontFamily="Graphik">
                    {t("Settings.save")}
                  </Text>
                </Button>
              )
            )}
          </Center>
        </div>
      </div>

      <div className="flex default__margin mt1 ml1 ">
        <div className="content__margin admin__wallet w100">
          <AccountBalance />
        </div>
      </div>
    </>
  );
};

export default Settings;
