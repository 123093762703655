import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { OrgChart } from "d3-org-chart";
import * as d3 from "d3";
import "./OrgChart.scss";

import DoorLockIcon from "../../../assets/img/door-lock-line.svg";
import QuestionAnswerIcon from "../../../assets/img/question-answer-line.svg";
// import MTBCircleIcon from "../../../assets/img/mtb-cirle.svg";
import CopyIcon from "../../../assets/img/copy-icon.svg";
import FreeAvatar from "../../../assets/img/freeavatar.png";
import DoorLockRedIcon from "../../../assets/img/door-lock-line-red.svg";
import ChatVoiceLine from "../../../assets/img/chat-voice-line.svg";
import RefreshIcon from "../../../assets/img/refresh-line.svg";
import LogOutIcon from "../../../assets/img/logout-circle-r-line.svg";
import CloseIcon from "../../../assets/img/close-icon.svg";
import MTBCircleIcon from "../../../assets/img/dollarIcon.png";


import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store";

export const OrgChartComponent = (props, ref) => {
  const d3Container = useRef(null);
  const { id } = useAppSelector((state) => state.userInfo.userData);

  let chart = null;
  const navigate = useNavigate();

  const addNode = (node) => {
    const attrs = chart.getChartState();
    const nodes = attrs.allNodes.filter(
      ({ data }) => attrs.nodeId(data) == node.id
    );
    const alreadyNode = nodes[nodes?.length - 1];
    // console.log("ADD ORG alreadyNode", alreadyNode);
    if (!alreadyNode) {
      // console.log(`ORG CHART -Node add`);
      chart.addNode(node);
    } else {
      alreadyNode.data = node;
    }
  };
  props.setClick(addNode);

  const onNodeClickHandler = async (nodeId, i, arr) => {
    const attrs = chart.getChartState();
    // console.log("onNodeClickHandler chart", chart);
    // console.log("onNodeClickHandler attrs.allNodes", attrs.allNodes);
    // console.log("onNodeClickHandler nodeId", nodeId);
    // console.log("onNodeClickHandler event.target?.id", event.target?.id);

    const nodes = attrs.allNodes.filter(
      ({ data }) => attrs.nodeId(data) == nodeId
    );
    const node = nodes[nodes?.length - 1];
    // console.log("!!!!org node", node);
    if (!node) {
      console.log(`ORG CHART -Node with id (${nodeId})  not found in the tree`);
      return;
    }
    // const nodeParent = attrs.allNodes.filter(
    //   ({ data }) => attrs.nodeId(data) == node.data.parentNodeId
    // )[0];
    // console.log("!!!!org nodeParent", nodeParent);
    if (event.target?.id == "block") {
      props.setCloneForBlock(node.data);
      // node.data = {};
      // attrs.allNodes = [];
      // console.log("onNodeClickHandler block attrs.allNodes", attrs.allNodes);

      // chart.updateNodesState();
      // console.log("onNodeClickHandler block attrs.allNodes", attrs.allNodes);
      // attrs.remove();
    } else if (event.target?.id == "message") {
      navigate(
        `/admin/tickets/${node.data?.user?.id}&${node.data?.user?.login}`
      );
    } else if (!node.data?.realId) {
      // console.log("setChosenPlace !node.data?.realId", node);
      // setChosenNodeId(nodeId);
      props.setChosenPlace(node);
      return;
      // chart.updateNodesState();
      // props.setChosenPlaceParent(nodeParent);
    } else {
      // await props.onNodeClick(nodeId);

      // console.log("org node", node);
      const childrenNodes = attrs.allNodes.filter(
        ({ data }) => attrs.parentNodeId(data) == nodeId
      );
      // если нет деток в дереве, то получить их по методу place
      if (!(childrenNodes && childrenNodes.length > 0)) {
        node.data._centered = true;
        await props.onNodeClick(node.data.realId, node.data.id);
        node.data._centered = true;
        chart.updateNodesState();
      } else if (
        // если есть дети в дереве и они открыты, то закрыть
        childrenNodes &&
        childrenNodes.length > 0 &&
        childrenNodes[0].data._expanded == true
      ) {
        // console.log(
        //   "onclick 2 childrenNodes[0].data._expanded ",
        //   childrenNodes[0].data._expanded
        // );
        // console.log("onclick 2 childrenNodes ", childrenNodes);
        childrenNodes.forEach((elem) => {
          elem.data._expanded = false;
          collapse(elem.data.id);
        });
        // node.data._centered = true;
        // console.log("onclick 2 childrenNodes after ", childrenNodes);
        // node.data._centered = true;
        chart.updateNodesState();
      } else if (
        // если есть дети в дереве и они закрыты, то открыть
        childrenNodes &&
        childrenNodes.length > 0 &&
        childrenNodes[0].data._expanded == false
      ) {
        // console.log(
        //   "onclick 3 childrenNodes[0].data._expanded ",
        //   childrenNodes[0].data._expanded
        // );
        childrenNodes.forEach((elem) => {
          elem.data._expanded = true;
          collapse(elem.data.id);
        });
        // node.data._centered = true;
        // childrenNodes[0].data._centered = true;
        // console.log("org attrs.allNodes after  collapse", attrs.allNodes);

        chart.updateNodesState();
        // setChosenNodeId(nodeId);
        // props.setChosenPlace(node);
      }
    }
    // setChosenNodeId(nodeId);
    props.setChosenPlace(node);
    // chart.updateNodesState();
    // setChosenNodeId(nodeId);
    // props.setChosenPlace(node);
    // props.setChosenPlaceParent(nodeParent);
  };

  const getIcon = (data) => {
    if (data?.data?.pType?.id == 1) {
      return MTBCircleIcon;
    } else if (data?.data?.pType?.id == 2) {
      return CopyIcon;
    } else if (data?.data?.nextPos == true) {
      // } else {
      return LogOutIcon;
    }
  };

  const getBlockIcon = (data) => {
    if (props.isNew === true){
      return null;
    } else if (data?.data?.isBlocked === true) {
      return DoorLockRedIcon;
    } else if (data?.data?.isUnderBlocked === true) {
      return CloseIcon;
    } else if (data?.data?.canBlock === true) {
      return DoorLockIcon;
    }
  };

  // Закрыть/открыть деток
  const collapse = (nodeId) => {
    const attrs = chart.getChartState();
    const childrenNodes = attrs.allNodes.filter(
      ({ data }) => attrs.parentNodeId(data) == nodeId
    );
    if (
      // если есть дети в дереве и они открыты, то закрыть
      childrenNodes &&
      childrenNodes.length > 0 &&
      childrenNodes[0].data._expanded == true
    ) {
      childrenNodes.forEach((elem) => {
        elem.data._expanded = false;
        collapse(elem.data.id);
      });
    } else if (
      // если есть дети в дереве и они закрыты, то открыть
      childrenNodes &&
      childrenNodes.length > 0 &&
      childrenNodes[0].data._expanded == false
    ) {
      childrenNodes.forEach((elem) => {
        elem.data._expanded = true;
        collapse(elem.data.id);
      });
    }
  };

  // <img src=${ArrowDownIcon}
  //   alt="" class="button_str_arrow"
  //   style="display: flex; justify-content: center; align-items: center; width: 20px; height: 20px;
  //   margin: 20px 0 0 10px;"
  //   onclick="countRabbits()"
  // />
  // onclick="event.preventDefault(); console.log('getBlockIcon'); globalThis.block = JSON.stringify(${data});"

  // We need to manipulate DOM
  useLayoutEffect(() => {
    // if (!props.data) return;
    if (!chart) {
      chart = new OrgChart();
    } else {
      // console.log("CART EXIST");
    }

    if (props?.data && d3Container.current) {
      // console.log("useLayout if props.data", props.data);
      // console.log("useLayout init CHART", chart);
      if (!chart) {
        chart = new OrgChart();
      }
      if (props.data?.length == 0) {
        // console.log("props.data?.length==0");
        d3.selectAll("g").remove();
      }
      // chart.updateNodesState();
      // d3.selectAll("text").remove();
      // d3.selectAll(".needle").remove();
      // d3.selectAll("g").remove();

      // chart.remove();
      // else {
      //   const attrs = chart.getChartState();
      //   console.log("???!!! attrs", attrs);
      //   // attrs.allNodes.forEach(d => d.data._filteredOut = true);
      //   // console.log("???!!! attrs.allNodes", attrs.allNodes);
      // }
      // const attrs = chart.getChartState();
      // console.log("???!!! attrs", attrs);

      // if(props.data == null){
      // attrs.forEach(d => d.data._filteredOut = true);
      // attrs.descendants();

      // }
      // const attrs = chart.getChartState();
      // chart.allNodes = [];
      // chart._allNodes = [];
      // chart.data = [];
      // console.log("useLayout ATTR", attrs);
      // console.log("useLayout DATA", props?.data);
      // const array = props.data;
      // if (attrs?.allNodes) {
      //   array.forEach((node) => {
      //     const nodes = attrs.allNodes.filter(
      //       ({ data }) => attrs.nodeId(data) == node.id
      //     );
      //     const alreadyNode = nodes[nodes?.length - 1];
      //     console.log("useLayout PROPS alreadyNode", alreadyNode);
      //     if (alreadyNode) {
      //       alreadyNode.data = node;
      //     }
      //   });
      // }
      chart
        // .onNodeClick(()=> console.log('click'))
        .container(d3Container.current)
        .data(props.data)
        .rootMargin(100)
        .nodeWidth((d) => 200)
        .nodeHeight((d) => 270)
        .childrenMargin((d) => 130)
        .compactMarginBetween((d) => 75)
        .compactMarginPair((d) => 80)
        .compact(false)
        // .on('mouseover', ()=> console.log('mouseover'))
        .setActiveNodeCentered(true)
        .buttonContent(
          ({ node, state }) =>
            `
          
          `
        )
        .layout("top")
        .linkUpdate(function (d, i, arr) {
          // console.log("useLayout linkUpdate d", d);
          // console.log(
          //   "useLayout linkUpdate chart.getChartState()",
          //   chart.getChartState()
          // );
          // chart.getChartState().allNodes = [];
          // chart.getChartState().data = [];
          d3.select(this)
            // .attr("svgWidth", "1200px")
            .attr("stroke", (d) => "#F45754")
            .attr("stroke-width", (d) =>
              d.data._upToTheRootHighlighted ? 5 : 1.5
            )
            .attr("stroke-dasharray", "0,0")
            // .attr("layout", "h")
            // .attr("disabled", "disabled");
            .attr("disabled", null);
          // .attr("firstDraw", (d) => "true");

          if (d.data._upToTheRootHighlighted) {
            d3.select(this).raise();
          }
        })

        // .connectionsUpdate(function (d, i, arr) {
        //   console.log("connectionsUpdate");
        //   d3.select(this).attr("disabled", null);
        // })
        // .nodeUpdate(function (d, i, arr) {
        //   console.log("nodeUpdate");
        //   d3.select(this).attr("disabled", null);
        // })
        // .defs(function (d, i, arr) {
        //   console.log("defs");
        //   d3.select(this).attr("disabled", null);
        // })
        .firstDraw(true)

        .onNodeClick((d, i, arr) => onNodeClickHandler(d, i, arr))
        // .countRabbits(()=> console.log('count!!!!!!rabbits'))

        // .setExpanded(null, true)
        // .on('click', ()=> console.log("click"))
        // .connectionsUpdate((d, i, arr) => {
        //   d3.select(this)
        //     .attr("stroke", (d) => "#152785")
        //     .attr("stroke-linecap", "round")
        //     .attr("stroke-width", (d) => "5")
        //     .attr("pointer-events", "none")
        //     .attr("marker-start", (d) => `url(#${d.from + "_" + d.to})`)
        //     .attr("marker-end", (d) => `url(#arrow-${d.from + "_" + d.to})`);
        // })

        // var topLevelData = treeData.find(function(d) { return d.name === 'Top Level'; });
        // if(!topLevelData.children) {
        //    click(topLevelData);
        // }
        // })

        //  .on('click', (d, i) => console.log("X:",d))
        // d3.select(this).style("fill", "red");
        // return

        //  .linkGroupArc(d3.linkHorizontal().x(d => d.x).y(d => d.y))
        .nodeContent((data) => {
          const colors = [
            "#6E6B6F",
            "#18A8B6",
            "#F45754",
            "#96C62C",
            "#BD7E16",
            "#802F74",
          ];
          const color = colors[data.depth % colors.length];
          const imageDim = 80;
          const lightCircleDim = 95;
          const outsideCircleDim = 110;
          // let name = '';
          // if (data.data?.user?.name){
          //   name = data.data.user.name;
          // } else {
          //   name = "Free1"
          // }
          // <div class=${data?.data?.id == chosenNodeId ? "border-body" : ""}>
          // <div class=${data?.data?.id == chosenNodeId ? "border-changing" : ""}></div>
          // <p>${!data.data?.filling && data.data?.canBuy ? `` : ``}</p>
          // <p style="color: red; font-weight:bold;">${
          //   data.data?.canBuy === true ? "Can buy" : ""
          // }</p>
          // id-${data?.data?.id} realId-${
          //   data?.data?.realId ? data?.data?.realId : "no"
          //   } pos-${data?.data?.pos}

          // hash-${data?.data?.id}
          //     hashparentId-${data?.data?.parentId}

          return `
          <div class=${
            data?.data?.id == props?.chosenPlace?.id ? "border-body" : ""
          }>
          <div class=${
            data?.data?.id == props?.chosenPlace?.id ? "border-changing" : ""
          }>
          
          


          <div 
            style="
            background: #F7FAFC;
            border-radius: 5px;
            display:flex; flex-direction : column; justify-content: center; text-align: center; "
          >

          

            <div 
              style="display: flex; 
              flex-direction : row; 
              justify-content: space-between; 
              align-items: center;   
              height: 20px; margin: 5px 5px 0px 5px;"
            >
              <div style="width: 24px;">
                <img              
                  src=${getIcon(data)}
                  alt=""
                />
              </div>
             
            
              <p style="color: ${
                data?.data?.isBlocked === true ? "#FF3158" : "#61D64A"
              }; font-weight: 500; font-size: 14px; line-height: 14px; margin: 2px;">
               ${data?.data?.isBlocked === true ? "" : ""}
              </p>

              <img              
                src=${getBlockIcon(data)}
                id="block"
                alt="" 
              />
            </div>
            
            <p style="color: #13599F; font-weight: 500; font-size: 14px; line-height: 14px;  margin: 2px;">
              Матрица-${props.chosenMatrix}
              id-${data?.data?.realId ? data?.data?.realId : "нет"} 
              
            </p>

            <p style="color: #B1B1B1; font-weight: 500; font-size: 14px; line-height: 14px;  margin: 2px;">
              ${
                data?.data?.createdAt
                  ? new Date(data?.data?.createdAt).toLocaleString()
                  : "Free"
              }
            </p>
               

            <div 
              style="background-color:#F7FAFC; margin: 0 auto; 
              display:flex; justify-content: center; text-align: center; width: 125px; hight: 125px "
            >
              <img 
                src=${
                  data?.data?.user?.imageUrl
                    ? data.data.user.imageUrl
                    : FreeAvatar
                }
                 
                style="margin-top:8px; border-radius:100px; 
                width: 125px; hight: 125px " 
              />
            </div>

            <div 
              class="card" style="margin-top:12px; height:20px; 
              
              background-color:#3AB6E3;"
            >
              <div 
                style="background-color:${color}; height:28px; text-align:center; 
                padding-top:5px; 
                color:#ffffff; font-weight:bold; font-size:16px"
              >
                 ${data.data?.user?.login ? data.data?.user?.login : "Free"}
              </div>  
            </div>
            
            <div 
              style="display: flex; 
              flex-direction : row; 
              justify-content: space-between; 
              align-items: center;   height: 20px; 
              margin: 10px 5px 5px 5px;"
            >
              <img              
                src=${data.data?.isOwnStructure ? ChatVoiceLine : RefreshIcon}
                alt="Chat voice Icon"
              /> 
              <p>${
                data.data?.filling &&
                !data.data?.canBuy &&
                data.data?.isBlocked === false &&
                data.data?.isUnderBlocked === false
                  ? `Количество мест:${ props.isNew === true? 2 - data.data?.filling : 3 - data.data?.filling}`
                  : ""
              }</p>
              <p>${
                data.data?.filling == 0 &&
                !data.data?.canBuy &&
                data.data?.isBlocked === false &&
                data.data?.isUnderBlocked === false
                  ? `Количество мест:${props.isNew === true? 2 - data.data?.filling : 3 - data.data?.filling}`
                  : ""
              }</p>
              
              
              
              
              <img              
                src=${QuestionAnswerIcon}
                alt="no"
                id="message"
                style = "display: ${
                  !data.data?.realId || data.data?.user?.id == id ? "none" : ""
                }"
              />
              <div style = "display: ${
                !data.data?.realId || data.data?.user?.id == id ? "" : "none"
              }; width: 15px;"></div>
             
              
            </div>      
          </div>


        </div>
        </div>
        
            `;
        })
        .render();
    }
  }, [props.data, props.chosenPlace, props.refresh, d3Container.current]);
  // }, [props.data, chosenNodeId, d3Container.current]);

  // const update = async () => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   const rd = {
  //     program: props.chosenProgram,
  //     matrix: props.chosenMatrix,
  //     placeId: props.parentIdForUpdate,
  //   };

  //   const attrs = chart.getChartState();
  //   console.log("org attrs.allNodes", attrs.allNodes);

  //   try {
  //     const res = await $api.post("/api/Marketing/place", rd, config);
  //     if (res.status >= 200 && res.status < 300) {
  //       const node = attrs.allNodes.filter(
  //         ({ data }) => attrs.nodeId(data) == props.parentIdForUpdate
  //       )[0];
  //       if (!node) {
  //         console.log(
  //           `ORG CHART - REMOVE - Node with id "${props.parentIdForUpdate}" not found in the tree`
  //         );
  //         return this;
  //       }

  //       // Remove all node childs
  //       // Retrieve all children nodes ids (including current node itself)
  //       // node.descendants()
  //       //     .forEach(d => d.data._filteredOut = true)

  //       const descendants = attrs.getNodeChildren(node, [], attrs.nodeId);
  //       descendants.forEach((d) => (d._filtered = true));

  //       // Filter out retrieved nodes and reassign data
  //       attrs.data = attrs.data.filter((d) => !d._filtered);
  //       chart.updateNodesState();

  //       // getMatrixFromData(res.data);
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  return <div className={"svg_container"} ref={d3Container} />;
};
