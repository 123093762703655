import "./Footer.scss";

import { useTranslation } from "react-i18next";

import TopIcon from "../../../../assets/img/top-icon.svg";
import BottomIcon from "../../../../assets/img/bottom-icon.svg";
import NumberFiveIcon from "../../../../assets/img/number-five-icon.svg";
import MTBCircleIcon from "../../../../assets/img/dollarIcon.png";
import CopyIcon from "../../../../assets/img/copy-icon.svg";
import MessageIcon from "../../../../assets/img/message-icon.svg";
import CloseIcon from "../../../../assets/img/close-icon.svg";
import DoorLockIcon from "../../../../assets/img/door-lock-line.svg";
import DoorLockRedIcon from "../../../../assets/img/door-lock-line-red.svg";
import ChatVoiceLine from "../../../../assets/img/chat-voice-line.svg";
import RefreshIcon from "../../../../assets/img/refresh-line.svg";
import LogOutIcon from "../../../../assets/img/logout-circle-r-line.svg";

export const Footer = (props) => {
  // Translation i18next
  const { t } = useTranslation();

  return (
    <div className="deposit__main--right-footer">
      <div className="deposit__main--right-footer-block">
        <div className="deposit__main--right-footer-block-container">
          <div className="deposit__main--right-footer-block-item"></div>

          <div className="deposit__main--right-footer-block-item">
            <div className="deposit__main--right-footer-block-item-unit">
              <img
                className="deposit__main--right-footer-block-item-icon"
                src={MTBCircleIcon}
                alt="Circle Icon"
              />
              <p className="deposit__main--right-footer-block-item-text">
                {t("PartnersProgramsPageFooter.purchased_place")}
              </p>
            </div>
            <div className="deposit__main--right-footer-block-item-unit">
              <img
                className="deposit__main--right-footer-block-item-icon"
                src={CloseIcon}
                alt="Close Icon"
              />
              <p className="deposit__main--right-footer-block-item-text">
                {t("PartnersProgramsPageFooter.locked_structure")}
              </p>
            </div>
            <div className="deposit__main--right-footer-block-item-unit">
              <img
                className="deposit__main--right-footer-block-item-icon"
                src={CopyIcon}
                alt="Copy Icon"
              />
              <p className="deposit__main--right-footer-block-item-text">
                {t("PartnersProgramsPageFooter.clone")}
              </p>
            </div>
            <div className="deposit__main--right-footer-block-item-unit">
              <img
                className="deposit__main--right-footer-block-item-icon"
                src={MessageIcon}
                alt="Message Icon"
              />
              <p className="deposit__main--right-footer-block-item-text">
                {t("PartnersProgramsPageFooter.write_to_a_partner")}
              </p>
            </div>
          </div>
        </div>
        <div className="deposit__main--right-footer-block-container">
          <div className="deposit__main--right-footer-block-item">
            { !props.isNew && <>
              <div className="deposit__main--right-footer-block-item-unit">
                <img
                  className="deposit__main--right-footer-block-item-icon"
                  src={DoorLockIcon}
                  alt="Door Lock Icon"
                />
                <p className="deposit__main--right-footer-block-item-text">
                  {t("PartnersProgramsPageFooter.unlocked")}
                </p>
              </div>
              <div className="deposit__main--right-footer-block-item-unit">
                <img
                  className="deposit__main--right-footer-block-item-icon"
                  src={DoorLockRedIcon}
                  alt="Door Lock Icon"
                />
                <p className="deposit__main--right-footer-block-item-text">
                  {t("PartnersProgramsPageFooter.blocked")}
                </p>
              </div>
            </>}
            <div className="deposit__main--right-footer-block-item-unit">
              <img
                className="deposit__main--right-footer-block-item-icon"
                src={ChatVoiceLine}
                alt="Chat Icon"
              />
              <p className="deposit__main--right-footer-block-item-text">
                {t("PartnersProgramsPageFooter.personal_structure")}
              </p>
            </div>
            <div className="deposit__main--right-footer-block-item-unit">
              <img
                className="deposit__main--right-footer-block-item-icon"
                src={RefreshIcon}
                alt="Refresh Icon"
              />
              <p className="deposit__main--right-footer-block-item-text">
                {t("PartnersProgramsPageFooter.overflow")}
              </p>
            </div>
          </div>
          <div className="deposit__main--right-footer-block-item">
            <div className="deposit__main--right-footer-block-item-unit">
              <p className="deposit__main--right-footer-block-item-text-green">
                online
              </p>
              <p className="deposit__main--right-footer-block-item-text">
                {t("PartnersProgramsPageFooter.online")}
              </p>
            </div>
            <div className="deposit__main--right-footer-block-item-unit">
              <p className="deposit__main--right-footer-block-item-text-red">
                ofline
              </p>
              <p className="deposit__main--right-footer-block-item-text">
                {t("PartnersProgramsPageFooter.offline")}
              </p>
            </div>
            <div className="deposit__main--right-footer-block-item-unit">
              <img
                className="deposit__main--right-footer-block-item-icon"
                src={LogOutIcon}
                alt="Logout Icon"
              />
              <p className="deposit__main--right-footer-block-item-text">
                {t("PartnersProgramsPageFooter.next_place")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
