import React, { FC, useEffect, useState, useRef } from "react";
import { Link, useLocation} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  getUserInviters,
} from "../../../features/userInfo/userInfoSlice";

import { toast } from "react-toastify";

import CrownIcon from "../../../assets/img/crown-icon.svg";
import ShieldIcon from "../../../assets/img/shield-keyhole-line.svg";

import { BiCrown } from "react-icons/bi";
import { RiVipCrownLine } from "react-icons/ri";

import {
  useClipboard,
  CircularProgress,
  CircularProgressLabel,
  Text,
  Flex,
  Center,
  Box,
  useMediaQuery,
  Divider,
  Button,
  Avatar,
} from "@chakra-ui/react";
import { CropEasy } from "../CropImage/CropEasy";
import { useTranslation } from "react-i18next";

export const AboutUserStruct: FC = () => {
  const { userData, userInviters } = useAppSelector((state) => state.userInfo);
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Translation i18next
  const { t } = useTranslation();

  const { pathname } = useLocation();

  // Get user inviters
  useEffect(() => {
    dispatch(getUserInviters());
  }, []);

  const [fullPath, setFullPath] = useState("");
  const [shortPath, setShortPath] = useState("");

  const { onCopy } = useClipboard(fullPath);

  const [isLargerThan770] = useMediaQuery("(min-width: 770px)");

  const copyToClipboard = () => {
    onCopy();
    toast.success("Скопировано в буфер обмена!");
  };

  // Crop Image
  const [openCrop, setOpenCrop] = useState(false);
  const [file, setFile] = useState<string | ArrayBuffer | null>(null);
  const [blob, setBlob] = useState(null);

  const getBlob = (blob: any) => {
    // pass blob up from the ImageCropper component
    setBlob(blob);
  };

  // Upload avatar image
  const onUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const image = reader.result;
        setFile(image);
        setOpenCrop(true);
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  // Make referral link to copy
  useEffect(() => {
    if (pathname === "/admin/struct") {
      const fullPath = window.location.href.split("");
      fullPath.splice(-12, 12);
      const fullLink = `${fullPath.join("")}register/${userData?.login}`;
      const shortLink = `${fullPath.join("")}${userData?.login}`;
      setFullPath(fullLink);
      setShortPath(shortLink);
    } else {
      const fullPath = window.location.href.split("");
      fullPath.splice(-5, 5);
      const fullLink = `${fullPath.join("")}register/${userData?.login}`;
      const shortLink = `${fullPath.join("")}${userData?.login}`;
      setFullPath(fullLink);
      setShortPath(shortLink);
    }
  }, [userData]);

  return (
    <div className="profile__content content__margin flex w100">
      <div className="profile__content--item w100 flexdef">
        <div className="profile__content--info--inner flexdef">
          <input
            style={{ display: "none" }}
            id="image-input"
            type="file"
            accept="image/jpeg,image/png"
            multiple={false}
            ref={fileInputRef}
            onChange={(e) => onUploadFile(e)}
          />
          <Avatar
            as="button"
            onClick={() => fileInputRef.current?.click()}
            size={"2xl"}
            mx="auto"
            m={4}
            name={userData.name ? userData.name : userData.login}
            src={userData.imageUrl}
          />

          {openCrop ? (
            <CropEasy
              {...{
                file,
                setFile,
                setOpenCrop,
                openCrop,
                myCropShape: "round",
                myAspect: 1,
                setBlob: { getBlob },
              }}
            />
          ) : (
            <Box></Box>
          )}

          <div className="flex">
            {!userData?.isVerified ? (
              <Link to="/admin/settings">
                <Text color="red.400">{t("AboutUser.get_verified")}</Text>
              </Link>
            ) : (
              <p className="profile__content--info--img--change flexdef">
                <img className="icon__def" src={ShieldIcon} alt="Почта" />
                {t("AboutUser.verified")}
              </p>
            )}

            <p className="profile__content--info--name">
              {userData?.name ? userData.name : userData.login}
            </p>

            <a
              href="mailto:alexanderpetrov@ya.ru"
              className="profile__content--info--mail admin flexdef"
            >
              <img
                className="icon__def"
                src="/assets/img/mail-admin.svg"
                alt="Почта"
              />
              {userData.contacts.email
                ? userData.contacts.email
                : t("AboutUser.your_mail_address")}
            </a>
          </div>
        </div>

        <Box m={2}>
          <CircularProgress
            value={userData?.profileCompleteness}
            color="#61D64A"
            thickness="10px"
            size="100px"
            trackColor="#A3ABB7"
            // capIsRound
          >
            <CircularProgressLabel>
              <Text color="white" fontSize="20px" fontWeight="bold">
                {userData?.profileCompleteness}%
              </Text>
            </CircularProgressLabel>
          </CircularProgress>
        </Box>

        {isLargerThan770 && (
          <Box ml={2}>
            <Link to="/admin/settings">
              <p className="profile__info--text">{t("AboutUser.open")}</p>
              <p className="profile__info--text">{t("AboutUser.profile")}</p>
            </Link>
          </Box>
        )}

        {userData.marketingStatus.rank == "" ? (
          <></>
        ) : (
          <Flex direction="column" textAlign="center" m={2} p={2}>
            <Center>
              <RiVipCrownLine
                size={60}
                color={
                  userData.marketingStatus.rank === "platinum"
                    ? "#892c2c"
                    : "" || userData.marketingStatus.rank === "gold"
                    ? "#FFCB00"
                    : "" || userData.marketingStatus.rank === "silver"
                    ? "#c0c0c0"
                    : ""
                }
              />
            </Center>

            <Text fontSize="14px" mt={1} color="#FFCB00">
              Статус {userData.marketingStatus.rank.toUpperCase()}
            </Text>
          </Flex>
        )}

        <div className="flexdef profile__text--wrapper">
          <p className="profile__info--text">
            {" "}
            {t("AboutUser.total_referrals")}:
          </p>
          <p className="profile__info--value">{userData.refCount}</p>
        </div>

        {/* <div className="flexdef profile__text--wrapper">
          <p className="profile__info--text">
            {t("AboutUser.interest_on_balance")}:
          </p>
          <p className="profile__info--value">
            {userData.marketingStatus.partnerRate}%
          </p>
        </div> */}
      </div>

      <Divider />

      <div className="profile__content--item w100 flexdef">
        <p className="profile__info--text profile__ref--text">
          {t("AboutUser.referral_link")}:
        </p>

        <input
          className="input profile__ref--input admin"
          value={fullPath}
          readOnly
          placeholder="Реферальная ссылка"
        />

        <Center w="100%">
          <Button
            onClick={copyToClipboard}
            colorScheme="blue"
            borderRadius="xl"
            w={["70%", "50%", "40%"]}
            h={24}
            boxShadow="dark-lg"
            m={4}
          >
            <Text fontSize="18px" color="white" fontFamily="Graphik">
              {t("AboutUser.copy")}
            </Text>
          </Button>
        </Center>
      </div>

      <Divider />

      <div className="profile__content--item w100 flexdef">
        <Flex
          direction={isLargerThan770 ? "row" : "column"}
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          mt={8}
          fontFamily="Graphik"
          fontSize="14px"
          fontWeight="medium"
          color="#ACAFBE"
        >
          <Flex
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            w={isLargerThan770 ? "45%" : "100%"}
            m={2}
          >
            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text fontSize="18px" fontWeight="bold" color="white">
                {t("AboutUser.your_curator")}:
              </Text>
              <Text fontSize="18px" fontWeight="bold" color="#61D64A">
                {userInviters[0]?.login}
              </Text>
            </Flex>

            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text color="white">{t("AboutUser.curator_mail")}:</Text>
              <Text color="white">
                {userInviters[0]?.contacts?.email
                  ? userInviters[0]?.contacts?.email
                  : "-"}
              </Text>
            </Flex>

            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text>{t("AboutUser.contact_curator")}</Text>
              <Text>
                {" "}
                {userInviters[0]?.contacts?.socialMediaTg ? (
                  <a
                    href={`https://t.me/${userInviters[0]?.contacts?.socialMediaTg}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {userInviters[0]?.contacts?.socialMediaTg}
                  </a>
                ) : (
                  "-"
                )}
              </Text>
            </Flex>

            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text>Fast Money </Text>
              <Text>
                {userInviters[0]?.fastMoney
                  ? t("AboutUser.yes")
                  : t("AboutUser.no")}
              </Text>
            </Flex>

            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text>Travel Money</Text>
              <Text>
                {userInviters[0]?.travelMoney
                  ? t("AboutUser.yes")
                  : t("AboutUser.no")}
              </Text>
            </Flex>

            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text>Auto Money</Text>
              <Text>
                {userInviters[0]?.autoMoney
                  ? t("AboutUser.yes")
                  : t("AboutUser.no")}
              </Text>
            </Flex>
          </Flex>

          <Flex
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            w={isLargerThan770 ? "45%" : "100%"}
            m={2}
          >
            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text fontSize="18px" fontWeight="bold" color="white">
                {t("AboutUser.supervisor")}
              </Text>
              <Text fontSize="18px" fontWeight="bold" color="#61D64A">
                {userInviters[1]?.login ? userInviters[1]?.login : "-"}
              </Text>
            </Flex>

            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text color="white">
                {" "}
                {t("AboutUser.senior_curator's_email")}
              </Text>
              <Text color="white">
                {userInviters[1]?.contacts.email
                  ? userInviters[1]?.contacts.email
                  : "-"}
              </Text>
            </Flex>

            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text>{t("AboutUser.contact_a_senior_curator")}</Text>
              <Text>
                {userInviters[1]?.contacts.socialMediaTg ? (
                  <a
                    href={`https://t.me/${userInviters[1]?.contacts.socialMediaTg}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {userInviters[1]?.contacts.socialMediaTg}
                  </a>
                ) : (
                  "-"
                )}
              </Text>
            </Flex>

            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text>Fast Money</Text>
              <Text>
                {userInviters[1]
                  ? userInviters[1]?.fastMoney
                    ? t("AboutUser.yes")
                    : t("AboutUser.no")
                  : "-"}
              </Text>
            </Flex>

            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text>Travel Money</Text>
              <Text>
                {userInviters[1]
                  ? userInviters[1]?.travelMoney
                    ? t("AboutUser.yes")
                    : t("AboutUser.no")
                  : "-"}
              </Text>
            </Flex>

            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text>Auto Money</Text>
              <Text>
                {userInviters[1]
                  ? userInviters[1]?.autoMoney
                    ? t("AboutUser.yes")
                    : t("AboutUser.no")
                  : "-"}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};
