import React, { useState } from "react";
import "./Settings.scss";

import md5 from "md5";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";
import { setUserNewPassword } from "../../../features/userInfo/userInfoSlice";

import { useForm, SubmitHandler } from "react-hook-form";

import { Text, Button, Flex, Center, Spinner } from "@chakra-ui/react";

type NewPasswordForm = {
  password: string;
  passwordRepeat: string;
};

export const NewPassword = () => {
  const { t } = useTranslation();

  const { isLoadingUserNewPassword } = useAppSelector(
    (state) => state.userInfo
  );

  const [passwordNew, setPasswordNew] = useState("");
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<NewPasswordForm>({
    mode: "all",
  });

  // Submit new password to redux thunk
  const onSubmit: SubmitHandler<NewPasswordForm> = (data) => {
    const fetchNewPassword = {
      password: md5(data.password),
    };

    dispatch(setUserNewPassword(fetchNewPassword));
    setPasswordNew("");
    reset();
  };

  return (
    <>
      <div className="settings__top-section-email">
        <div className="trans__item flex">
          <p className="setting__side--title">{t("Settings.password_new")}</p>
          <input
            type="password"
            placeholder="Пароль"
            className="mt1 w98 input select trans__input"
            {...register("password", {
              onChange: (e) => setPasswordNew(e.target.value),
              pattern: {
                value: /^[a-zA-Z0-9](.[a-zA-Z0-9-_!@]*)$/,
                message: "Допустимы только буквы, цифры и -_!@",
              },
              minLength: {
                value: 4,
                message: "Минимум 4 символа!",
              },
              maxLength: {
                value: 10,
                message: "Максимум 10 символов!",
              },
            })}
          />
          {errors?.password && (
            <Text m={1} fontSize="lg" fontWeight="semibold" color="red.500">
              {errors.password.message || "Error!"}
            </Text>
          )}
        </div>

        <div className="trans__item flex ">
          <p className="setting__side--title">{t("Settings.password_rep")}</p>
          <input
            type="password"
            className="mt1 w98 input select trans__input"
            placeholder="Повторить пароль"
            {...register("passwordRepeat", {
              minLength: {
                value: 4,
                message: "Минимум 4 символа!",
              },
              maxLength: {
                value: 10,
                message: "Максимум 10 символов!",
              },
              validate: (value) => {
                const { password } = getValues();
                return password === value || "Пароли должны совпадать!";
              },
            })}
          />
          {errors?.passwordRepeat && (
            <Text m={1} fontSize="lg" fontWeight="semibold" color="red.500">
              {errors.passwordRepeat.message || "Error!"}
            </Text>
          )}
        </div>
      </div>

      <div className="settings__top-section-email">
        <Flex justifyContent="center" alignItems="center" w="100%" h="100%">
          <Center w="100%">
            {isLoadingUserNewPassword ? (
              <Spinner
                thickness="14px"
                speed="0.7s"
                emptyColor="#61D64A"
                color="#1c3b6a"
                w={20}
                h={20}
              />
            ) : (
              <Button
                onClick={handleSubmit(onSubmit)}
                isDisabled={!isValid || passwordNew.length < 4}
                colorScheme="green"
                borderRadius="xl"
                w={["50%", "25%"]}
                h="50px"
              >
                <Text fontSize="18px" color="white" fontFamily="Graphik">
                {t("Settings.save")}
                </Text>
              </Button>
            )}
          </Center>
        </Flex>
      </div>
    </>
  );
};
