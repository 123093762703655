import React, { FC, useEffect } from "react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Center,
  Input,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import { SubmitHandler, useForm } from "react-hook-form";
import $api from "../../http/apiService";
import { UserTypeForAdmin } from "./UserList";
import { toast } from "react-toastify";
import md5 from "md5";

type Iprops = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  user: UserTypeForAdmin | undefined;
  isNeedUpdate: number;
  setIsNeedUpdate: any;
};
type IFormInput = {
  name: string;
  login: string;
  "contacts.email": string;
  "contacts.mobilePhone": string;
  password: string;
  editor: string;
};
type RDType = {
  name?: string;
  password?: string;
  email?: string;
  mobilePhone?: string;
};

const UserItem = (props: Iprops) => {
  //   const { onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  // React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: "all",
  });

  //   useEffect(() => {
  //     props.onOpen();
  //   }, []);

  const handleClose = () => {
    // props.
    props.onClose();

    // navigate("/dashboard");
  };

  // Default values hook form initialize
  useEffect(() => {
    reset(props.user);
  }, [props.user]);

  const onSubmitUserForm: SubmitHandler<IFormInput> = async (data: any) => {
    // console.log("data", data);
    const rd: RDType = {
      // ...props.user,
      name: data.name,
      email: data.contacts.email,
      mobilePhone: data.contacts.mobilePhone,
    };
    if (data.password) {
      rd.password = md5(data.password);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await $api.put(
        `api/admin/User/${props.user?.id}/data`,
        rd,
        config
      );
      // console.log("response", response);
      if (response.status === 200) {
        toast(`Пользователь ${props.user?.login} успешно обновлен`);
        props.setIsNeedUpdate(props.isNeedUpdate + 1);
        props.onClose();
      }
    } catch (e) {
      console.error("Ошибка редактирования пользователя", e);
      toast.error("Ошибка редактирования пользователя");
    } finally {
      // props.onClose();
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      size={"5xl"}
      isCentered
    >
      <ModalOverlay
        backdropBlur="5px"
        bg="blackAlpha.300"
        backdropFilter="blur(10px) "
      />
      <ModalContent>
        <ModalHeader fontSize={24} color="blue.500" fontWeight="bold">
          <Center>{`Редактирование пользователя ${props?.user?.name}`} </Center>
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmitUserForm)}>
          <ModalBody fontSize={16}>
            <Text fontSize={12} color="gray.500" mt={8}>
              Фио
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              // variant="filled"
              // fontSize={16}
              // placeholder="Фио"
              // bg="gray.200"
              {...register("name")}
            />
            <Text fontSize={12} color="gray.500" mt={8}>
              Логин
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              readOnly
              disabled
              // placeholder="Логин"
              bg="gray.200"
              color={"gray.700"}
              {...register("login", {
                required: "Поле обязательно к заполнению",
              })}
            />
            <Text fontSize={12} color="gray.500" mt={8}>
              Емэйл
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              // placeholder="Емэйл"
              {...register("contacts.email")}
            />
            <Text fontSize={12} color="gray.500" mt={8}>
              Телефон
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              // placeholder="Телефон"
              // bgGradient="linear(to-r, gray.200, blue.200)"
              {...register("contacts.mobilePhone")}
            />
            {/* <PhoneInputWithCountry
            defaultCountry="RU"
            //   className="mt1 w98 input select trans__input"
            name="mobilePhone"
            placeholder="Номер телефона"
            //   control={control}
            // rules={{ required: true }}
          /> */}
            <Text fontSize={12} color="gray.500" mt={8}>
              Пароль
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              // placeholder="Пароль"
              {...register("password")}
            />

            <Text fontSize={12} color="gray.500" mt={8}>
              Автор
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              type="text"
              h={30}
              disabled
              readOnly
              bg="gray.300"
              // placeholder="Комментарий"
              {...register("editor")}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={handleSubmit(onSubmitUserForm)}
              isDisabled={!isValid}
              type="submit"
              // colorScheme="blue"
              colorScheme="facebook"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="lg"
            >
              Сохранить
            </Button>
            <Button
              colorScheme="twitter"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="lg"
              onClick={handleClose}
            >
              Закрыть
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UserItem;
