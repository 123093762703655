import axios from "axios";
import { store } from "../store";
import { logoutUser } from "../features/auth/authSlice";
import { resetUserInfo } from "../features/userInfo/userInfoSlice";

// export const API_URL = "https://api.dct-trips.com"; // PRODUCTION
export const API_URL = "https://dev.dct-trips.com"; // DEVELOPMENT

const localAccessToken = localStorage.getItem("accessToken");
const localRefreshToken = localStorage.getItem("refreshToken");

// Logout
const logoutHandler = () => {
  console.log("apiservice logout");
  store.dispatch(logoutUser());
  store.dispatch(resetUserInfo());
};

const $api = axios.create({
  // withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },

  async (error) => {
    const originalRequest = error.config;
    // const dispatch = useAppDispatch();
    const localRefreshToken = await localStorage.getItem("refreshToken");

    if (
      error.response.status == 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const data = {
          refresh_token: localRefreshToken,
        };

        const config = {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        };

        const response = await axios.post(
          `${API_URL}/api/App/refresh-token`,
          data,
          config
        );

        if (response.data) {
          localStorage.setItem("accessToken", response.data.accessToken);
          localStorage.setItem("refreshToken", response.data.refreshToken);
        }

        return $api.request(originalRequest);
      } catch (error: any) {
        console.log("INTESEEPTOR ERROR", error);
        logoutHandler();
      }
    }
    throw error;
  }
);

export default $api;
