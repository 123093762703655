import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../http/apiService";

import { toast } from "react-toastify";

import { IChat } from "../../pages/LK/Tickets/Tickets.interface";

export interface IBalances {
  account: {
    id: number;
    name: string;
  };
  value: number;
  usd: number;
  rate: {
    id: number;
    rate: number;
    date: string;
    change: number;
  };
}

export interface IInvite {
  program: string;
  createdAt: string;
  activatedAt: string | null;
  parent: {
    id: number;
    login: string;
    name: string;
  };
  refCount: number;
  activeRefCount: number;
  inactiveRefCount: number;
}

export interface IUserData {
  id: number;
  login: string;
  createdAt: string;
  balances: IBalances[];
  isActive: boolean;
  refCount: number;
  activeRefCount: number;
  inactiveRefCount: number;
  masterKey: string;
  name: string;
  isBlocked: boolean;
  isChatBlocked: boolean;
  comment: string;
  isAdmin: boolean;
  lastLoginedAt: string;
  newMessagesCount: number;
  newNotificationsCount: number;
  imageUrl: string;
  marketingStatus: {
    rank: string;
    partnerRate: number;
  };
  isVerified: boolean;
  profileCompleteness: number;
  sessionTime: number;
  interfaceLang: string;
  visibleAsPartner: boolean;
  contacts: {
    email: string;
    socialMediaVk: string;
    mobilePhone: string;
    socialMediaTg: string;
    visibleTelegram: boolean;
    tgUserId: number;
  };
  wallets: {
    tron: string;
    mtb: string;
  };
  invites: IInvite[];
  editor: string;
}

export interface IUserStatistic {
  financeStat: {
    available: number;
    income: number;
    expenses: number;
    interest: number;
  };
  partnerStat: {
    refCount: number;
    active: number;
    inactive: number;
    fastMoney: number;
    travelMoney: number;
    autoMoney: number;
  };
}

export interface IUserInviters {
  id: number;
  login: string;
  contacts: {
    email: string;
    socialMediaVk: string;
    mobilePhone: string;
    socialMediaTg: string;
  };
  fastMoney: boolean;
  travelMoney: boolean;
  autoMoney: boolean;
}

export interface IUserInfoState {
  userData: IUserData;
  userStat: IUserStatistic;
  userInviters: IUserInviters[];
  isLoadingUserInfo: boolean;
  isLoadingUserNewPassword: boolean;
  isLoadingWallets: boolean;
  isLoadingUserAvatar: boolean;
  isErrorUserInfo: boolean;
  messageUserInfo: any;
  newMessagesCount: number;
  // newNotificationsCount: number;
}

const initialState: IUserInfoState = {
  userData: {
    id: -1,
    login: "",
    createdAt: "",
    balances: [],
    isActive: false,
    refCount: 0,
    activeRefCount: 0,
    inactiveRefCount: 0,
    masterKey: "",
    name: "",
    isBlocked: false,
    isChatBlocked: false,
    comment: "",
    isAdmin: false,
    lastLoginedAt: "",
    newMessagesCount: 0,
    newNotificationsCount: 0,
    imageUrl: "",
    marketingStatus: {
      rank: "",
      partnerRate: 0,
    },
    isVerified: false,
    profileCompleteness: 0,
    sessionTime: 60,
    interfaceLang: "Ru",
    visibleAsPartner: false,
    contacts: {
      email: "",
      socialMediaVk: "",
      mobilePhone: "",
      socialMediaTg: "",
      visibleTelegram: false,
      tgUserId: 0,
    },
    wallets: {
      tron: "",
      mtb: "",
    },
    invites: [],
    editor: "",
  },
  userStat: {
    financeStat: {
      income: 0,
      expenses: 0,
      interest: 0,
      available: -1,
    },
    partnerStat: {
      refCount: 0,
      active: 0,
      inactive: 0,
      fastMoney: 0,
      travelMoney: 0,
      autoMoney: 0,
    },
  },
  userInviters: [],
  isLoadingUserInfo: false,
  isLoadingUserNewPassword: false,
  isLoadingWallets: false,
  isLoadingUserAvatar: false,
  isErrorUserInfo: false,
  messageUserInfo: "",
  newMessagesCount: 0,
};

// Get all user data
export const getUserInfo = createAsyncThunk(
  "userInfo/get",
  async (_, thunkAPI) => {
    try {
      const response = await $api.get("/api/User");
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get user statistic
export const getUserStat = createAsyncThunk(
  "userStat/get",
  async (_, thunkAPI) => {
    try {
      const response = await $api.get("/api/User/stat");
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Set user data
export const setUserData = createAsyncThunk(
  "user/setData",
  async (userData: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

      const response = await $api.put("/api/User/profile", userData, config);
      toast.success("Данные успешно изменены!");
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status} - ${error.response.data.detail}!`;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Set user new password
export const setUserNewPassword = createAsyncThunk(
  "user/setNewPassword",
  async (password: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

      const response = await $api.put("/api/User/password", password, config);
      toast.success("Пароль успешно изменен!");
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status} - ${error.response.data.detail}!`;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Set user wallets
export const setUserWallets = createAsyncThunk(
  "user/setWallets",
  async (wallets: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

      const response = await $api.put("/api/User/wallets", wallets, config);
      toast.success("Кошелек успешно сохранен!");
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status} - ${error.response.data.detail}!`;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Set user avatar
export const setUserAvatar = createAsyncThunk(
  "user/setAvatar",
  async (avatar, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await $api.put("/api/User/image", avatar, config);
      toast.success("Аватар успешно изменен!");
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status} - ${error.response.data.detail}!`;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete user avatar
export const deleteUserAvatar = createAsyncThunk(
  "user/deleteAvatar",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

      const response = await $api.delete("/api/User/image", config);
      toast.success("Аватар успешно удален!");
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status} - ${error.response.data.detail}!`;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Put user lang
export const setUserLang = createAsyncThunk(
  "user/Lang",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

      const response = await $api.put("/api/User/interface-lang", data, config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status} - ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get user statistic
export const getUserInviters = createAsyncThunk(
  "userInviters/get",
  async (_, thunkAPI) => {
    try {
      const response = await $api.get("/api/User/inviters");
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get new messages
export const getNewMessagesCount = createAsyncThunk(
  "newMessagesCount/get",
  async (_, thunkAPI) => {
    try {
      let count = 0;
      const res = await $api.get("api/Message/chats");
      console.log("api/Message/chats", res?.data);
      if (res?.status === 200 && res.data?.length > 0) {
        res.data.forEach((elem: IChat) => {
          if (elem?.newMessagesCount > 0) {
            count = +elem?.newMessagesCount + count;
          }
        });
      }
      return count;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reducer
export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    resetUserInfo: (state) => {
      state.userData = initialState.userData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.isLoadingUserInfo = true;
        state.messageUserInfo = "";
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.isLoadingUserInfo = false;
        state.userData = action.payload;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.isLoadingUserInfo = false;
        state.isErrorUserInfo = true;
        state.messageUserInfo = action.payload;
      })
      .addCase(getUserStat.pending, (state) => {
        state.isLoadingUserInfo = true;
        state.messageUserInfo = "";
      })
      .addCase(getUserStat.fulfilled, (state, action) => {
        state.isLoadingUserInfo = false;
        state.userStat = action.payload;
      })
      .addCase(getUserStat.rejected, (state, action) => {
        state.isLoadingUserInfo = false;
        state.isErrorUserInfo = true;
        state.messageUserInfo = action.payload;
      })
      .addCase(setUserData.pending, (state) => {
        state.isLoadingUserInfo = true;
        state.messageUserInfo = "";
      })
      .addCase(setUserData.fulfilled, (state, action) => {
        state.isLoadingUserInfo = false;
        state.userData = action.payload;
      })
      .addCase(setUserData.rejected, (state, action) => {
        state.isLoadingUserInfo = false;
        state.isErrorUserInfo = true;
        state.messageUserInfo = action.payload;
      })
      .addCase(setUserNewPassword.pending, (state) => {
        state.isLoadingUserNewPassword = true;
        state.messageUserInfo = "";
      })
      .addCase(setUserNewPassword.fulfilled, (state, action) => {
        state.isLoadingUserNewPassword = false;
        state.userData = action.payload;
      })
      .addCase(setUserNewPassword.rejected, (state, action) => {
        state.isLoadingUserNewPassword = false;
        state.isErrorUserInfo = true;
        state.messageUserInfo = action.payload;
      })
      .addCase(setUserWallets.pending, (state) => {
        state.isLoadingWallets = true;
        state.messageUserInfo = "";
      })
      .addCase(setUserWallets.fulfilled, (state, action) => {
        state.isLoadingWallets = false;
        state.userData = action.payload;
      })
      .addCase(setUserWallets.rejected, (state, action) => {
        state.isLoadingWallets = false;
        state.isErrorUserInfo = true;
        state.messageUserInfo = action.payload;
      })
      .addCase(setUserAvatar.pending, (state) => {
        state.isLoadingUserAvatar = true;
        state.messageUserInfo = "";
      })
      .addCase(setUserAvatar.fulfilled, (state, action) => {
        state.isLoadingUserAvatar = false;
        state.userData = action.payload;
      })
      .addCase(setUserAvatar.rejected, (state, action) => {
        state.isLoadingUserAvatar = false;
        state.isErrorUserInfo = true;
        state.messageUserInfo = action.payload;
      })
      .addCase(deleteUserAvatar.pending, (state) => {
        state.isLoadingUserAvatar = true;
        state.messageUserInfo = "";
      })
      .addCase(deleteUserAvatar.fulfilled, (state, action) => {
        state.isLoadingUserAvatar = false;
        state.userData = action.payload;
      })
      .addCase(deleteUserAvatar.rejected, (state, action) => {
        state.isLoadingUserAvatar = false;
        state.isErrorUserInfo = true;
        state.messageUserInfo = action.payload;
      })
      .addCase(setUserLang.pending, (state) => {
        state.isLoadingUserInfo = true;
        state.messageUserInfo = "";
      })
      .addCase(setUserLang.fulfilled, (state, action) => {
        state.isLoadingUserInfo = false;
        state.userData = action.payload;
      })
      .addCase(setUserLang.rejected, (state, action) => {
        state.isLoadingUserInfo = false;
        state.isErrorUserInfo = true;
        state.messageUserInfo = action.payload;
      })
      .addCase(getUserInviters.pending, (state) => {
        state.isLoadingUserInfo = true;
        state.messageUserInfo = "";
      })
      .addCase(getUserInviters.fulfilled, (state, action) => {
        state.isLoadingUserInfo = false;
        state.userInviters = action.payload;
      })
      .addCase(getUserInviters.rejected, (state, action) => {
        state.isLoadingUserInfo = false;
        state.isErrorUserInfo = true;
        state.messageUserInfo = action.payload;
      })
      .addCase(getNewMessagesCount.fulfilled, (state, action) => {
        state.isLoadingUserInfo = false;
        state.newMessagesCount = action.payload;
      })
      .addCase(getNewMessagesCount.rejected, (state, action) => {
        state.isLoadingUserInfo = false;
        state.isErrorUserInfo = true;
        state.newMessagesCount = 0;
      });
  },
});

export const { resetUserInfo } = userInfoSlice.actions;
export default userInfoSlice.reducer;
