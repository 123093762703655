import React, { FC, useState, useEffect } from "react";

import { useAppSelector, useAppDispatch } from "../../../store";
import { getUserInfo } from "../../../features/userInfo/userInfoSlice";
import { getWithdrawalsHistory } from "../../../features/payment/paymentSlice";

import Moment from "react-moment";

import UsdtIcon2 from "../../../assets/img/Tether_Logo.svg.png";
import MTBIcon from "../../../assets/img/mtb-bird.png";
import PerfectIcon2 from "../../../assets/img/perfect-money-logo-vector2.svg";

import {
  MdPayment,
  MdOutlineDateRange,
  MdOutlineContactPage,
} from "react-icons/md";
import { FaRegMoneyBillAlt } from "react-icons/fa";

import { usePagination } from "../../../hooks/pagination/usePagination";
import "../../../hooks/pagination/pagination.scss";

import { toast } from "react-toastify";

import $api from "../../../http/apiService";

import { InfoOutlineIcon } from "@chakra-ui/icons";

import {
  Button,
  Flex,
  Select,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Image,
  Box,
  Center,
  Tooltip,
} from "@chakra-ui/react";
import { GlobalSpinner } from "../GlobalSpinner/GlobalSpinner";

const itemsPerPage = 10;

export const WithdrawHistory: FC = () => {
  const { withdrawals, isLoadingPayment } = useAppSelector(
    (state) => state.payment
  );
  const dispatch = useAppDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [idToWithdraw, setIdToWithdraw] = useState<string | number>("");
  const [withdrawStatus, setWithdrawStatus] = useState<string>("");

  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: itemsPerPage,
    count: withdrawals.totalCount,
  });

  // Success withdraw
  const successWithdraw = async () => {
    const data = {
      id: idToWithdraw,
    };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.put("/api/Withdrawal/cancel", data, config);

      if (response.status === 204) {
        setTimeout(() => {
          const data = {
            take: itemsPerPage,
            skip: (page - 1) * itemsPerPage,
          };
          dispatch(getWithdrawalsHistory(data));
          dispatch(getUserInfo());
          toast.success("Вывод отменен!");
        }, 1000);
        setIdToWithdraw("");
        onClose();
      }
    } catch (error: any) {
      console.log(error);
      toast.error("Что то пошло не так! Попробуйте позже!");
      setIdToWithdraw("");
      onClose();
    }
  };

  // Pagination request
  useEffect(() => {
    const data = {
      take: itemsPerPage,
      skip: (page - 1) * itemsPerPage,
      status: withdrawStatus,
    };

    dispatch(getWithdrawalsHistory(data));
  }, [withdrawStatus, page]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        size={"xl"}
        isCentered
      >
        <ModalOverlay
          backdropBlur="5px"
          bg="blackAlpha.300"
          backdropFilter="blur(10px)"
        />
        <ModalContent height={56}>
          <ModalHeader></ModalHeader>

          <ModalBody fontSize={20}>
            <Center>Подтвердите отмену вывода!</Center>
          </ModalBody>

          <ModalFooter mx="auto">
            <Button
              onClick={() => {
                setIdToWithdraw("");
                onClose();
              }}
              colorScheme="red"
              size="lg"
              mr={2}
              fontSize={14}
            >
              Закрыть
            </Button>
            <Button
              onClick={successWithdraw}
              colorScheme="green"
              size="lg"
              fontSize={14}
            >
              Отменить вывод
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex w="100%" justifyContent="end" mt={10}>
        <Select
          onChange={(e) => setWithdrawStatus(e.target.value)}
          w={80}
          h={14}
          variant="filled"
          colorScheme="blue"
        >
          <option value="">Все</option>
          <option value="Executed">Исполненные</option>
          <option value="Pending">В ожидании</option>
          <option value="Canceled">Отмененые</option>
        </Select>
      </Flex>

      {withdrawals?.items.length !== 0 && (
        <>
          <div className="content__margin w100 flex admin__last">
            <div className="w100 flexdef admin__last--item">
              <p className="admin__last--text admin__last--blue operation__item--desc">
                <Center>
                  <Box mr={2}>
                    <MdPayment size={20} />
                  </Box>{" "}
                  Платежная система
                </Center>
              </p>

              <p className="admin__last--text admin__last--blue operation__item--date">
                <Center>
                  <Box mr={2}>
                    <MdOutlineDateRange size={20} />
                  </Box>{" "}
                  Дата и время
                </Center>
              </p>

              <p className="admin__last--text admin__last--blue operation__item--in">
                <Center>
                  <Box mr={2}>
                    <FaRegMoneyBillAlt size={20} />
                  </Box>
                  Сумма в $
                </Center>
              </p>

              <p className="admin__last--text admin__last--blue operation__item--out">
                <Center>
                  <Box mr={2}>
                    <MdOutlineContactPage size={20} />
                  </Box>
                  Состояние
                </Center>
              </p>
            </div>

            {withdrawals?.items?.map((withdrawal: any, index) => (
              <div
                className="w100 flexdef admin__last--item"
                key={withdrawal.id}
              >
                <p className="admin__last--text operation__item--desc">
                  <Center>
                    <Box mr={2}>
                      {withdrawal?.externalPayment?.paymentSystem?.name ===
                        "USDT TRC20" && <Image w={12} h={12} src={UsdtIcon2} />}
                      {withdrawal?.externalPayment?.paymentSystem?.name ===
                        "MTB TRC20" && <Image w={12} h={12} src={MTBIcon} />}
                      {withdrawal?.externalPayment?.paymentSystem?.name ===
                        "Perfect Money" && (
                        <Image w={10} h={10} src={PerfectIcon2} />
                      )}
                    </Box>

                    {withdrawal?.externalPayment?.paymentSystem?.name}
                  </Center>
                </p>

                <p className="admin__last--text operation__item--date">
                  <Center h={20}>
                    <Moment format="DD MMMM YYYY, hh:mm:ss">
                      {withdrawal.statusChangedAt}
                    </Moment>
                  </Center>
                </p>

                <p className="admin__last--text operation__item--in">
                  <Center color="red.500">{withdrawal?.sum}</Center>
                </p>

                <p className="admin__last--text operation__item--out">
                  <Flex
                    w="100%"
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    {withdrawal.status === "Pending" && (
                      <Button
                        onClick={() => {
                          setIdToWithdraw(withdrawal.id);
                          onOpen();
                        }}
                        colorScheme="red"
                        w="120px"
                        h="42px"
                        borderRadius="10px"
                      >
                        <Text fontSize="18px" color="#FFF">
                          Отменить
                        </Text>
                      </Button>
                    )}

                    {withdrawal.status === "Canceled" && (
                      <Text fontSize="18px" fontWeight="medium" color="red.500">
                        Отменено
                      </Text>
                    )}

                    {withdrawal.status === "Executed" && (
                      <Text
                        fontSize="18px"
                        fontWeight="medium"
                        color="green.500"
                      >
                        Успешно
                      </Text>
                    )}

                    {withdrawal.comment && (
                      <Tooltip
                        fontSize="18px"
                        mr={2}
                        label={withdrawal.comment}
                        hasArrow
                        bg="red.500"
                      >
                        <InfoOutlineIcon ml={2} w={8} h={8} />
                      </Tooltip>
                    )}
                  </Flex>
                </p>
              </div>
            ))}

            {withdrawals.totalCount <= itemsPerPage ? (
              <Box></Box>
            ) : (
              <Center w="100%">
                <div className="pagination">
                  <Button
                    onClick={prevPage}
                    w={24}
                    h={12}
                    colorScheme="blue"
                    borderRadius="xl"
                    isDisabled={page === 1}
                  >
                    Назад
                  </Button>
                  <button
                    onClick={() => setPage(1)}
                    className={`page ${page === 1 && "disabled"}`}
                  >
                    1
                  </button>
                  {gaps.before ? "..." : null}

                  {gaps.paginationGroup.map((el) => (
                    <button
                      onClick={() => setPage(el)}
                      key={el}
                      className={`page ${page === el ? "active" : ""}`}
                    >
                      {el}
                    </button>
                  ))}
                  {gaps.after ? "..." : null}
                  <button
                    onClick={() => setPage(totalPages)}
                    className={`page ${page === totalPages && "disabled"}`}
                  >
                    {totalPages}
                  </button>

                  <Button
                    onClick={nextPage}
                    w={24}
                    h={12}
                    colorScheme="blue"
                    borderRadius="xl"
                    isDisabled={page === totalPages}
                  >
                    Вперед
                  </Button>
                </div>
              </Center>
            )}
          </div>
        </>
      )}
    </>
  );
};
