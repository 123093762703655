import { CalendarIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Text,
  Wrap,
} from "@chakra-ui/react";
import React, { FC, useEffect, useRef, useState } from "react";
import "./DashBoard.scss";
import axios from "axios";
import { AiFillCloseCircle } from "react-icons/ai";
import { FcBusinessman } from "react-icons/fc";
import { motion } from "framer-motion";

const itemPerPage = 5;

const request_id_Yandext_test = 29607208;

const YandexMetrics = () => {
  const client_id = "4c9ec958206d4dbd96dde7daf9ff38d6";
  const client_secret = "a76f1b977db74e0d9f6a5506ecf69222";
  const OAuth = "y0_AgAAAAA1RU7pAAiKXQAAAADSr_OAN2YaxmsKRWCBnzW36eOFodotp4k";
  const config = {
    headers: {
      Authorization: `OAuth  ${OAuth}`,
      // ContentType: "application/x-www-form-urlencoded",
      "Content-Type": "application/json",
      // Host: "api-metrika.yandex.net",
    },
  };

  const [dateFrom, setDateFrom] = useState<string>("");
  // new Date().toISOString().split("T")[0]
  const [dateTo, setDateTo] = useState<string>("");
  const [YMdata, setYMdata] = useState<any>(null);
  const [YMdataDim, setYMdataDim] = useState<any>(null);
  const [YMall, setYMall] = useState<any>(null);

  const getLogInfo = async () => {
    // try {
    // ****************************сырые данные*******************************************************

    // const resClean = await axios.get(
    //   `https://api-metrika.yandex.net/management/v1/counter/90983876/logrequests/clean`,
    //   config
    // );
    // console.log(
    //   "!!!resClean",
    //   resClean.data
    // );

    // *************************************1  Проверка****************************************************************

    const resEvaluate = await axios.get(
      `https://api-metrika.yandex.net/management/v1/counter/90983876/logrequests/evaluate?date1=2023-01-01&date2=2023-01-17&fields=ym:s:date,ym:s:ipAddress&source=visits`,
      config
    );
    console.log("!!!resEvaluate", resEvaluate.data);

    // *************************************2  Запрос лога (1 раз, потом закомментить и сохранить результат)****************************************************************
    // Выполните запрос методом POST https://api-metrika.yandex.net/management/v1/counter/44147844/logrequests?date1=2022-10-30&date2=2022-11-01&fields=ym:s:date,ym:s:ipAddress&source=visits на создание логов. Подробнее о методе в Справке: https://yandex.ru/dev/metrika/doc/api2/logs/queries/createlogrequest.html
    // Обязательно сохраните из результата request_id. В ответе он будет выглядеть так:
    // "log_request": {
    // "request_id": XXXXXXX,
    // "counter_id": 44147844,
    // "source": "visits",
    // ...Где, XXXXXXX - id запроса.
    try {
      const resCreateLog = await axios.post(
        `https://api-metrika.yandex.net/management/v1/counter/90983876/logrequests?date1=2023-01-01&date2=2023-01-17&fields=ym:s:date,ym:s:ipAddress&source=visits`,
        // `https://api-metrika.yandex.net/management/v1/counter/44147844/logrequests?date1=2022-12-01&date2=2022-12-26&fields=ym:s:date,ym:s:ipAddress&source=visits`,
        {},
        config
      );
      if (resCreateLog?.data) {
        console.log("!!!!resCreateLog", resCreateLog.data);
      }
      if (resCreateLog?.data?.log_request?.request_id) {
        localStorage.setItem(
          "request_id",
          resCreateLog?.data?.log_request?.request_id
        );
      }

      // const resCreateLogF = await fetch(
      //   `https://api-metrika.yandex.net/management/v1/counter/90983876/logrequests?date1=2023-01-01&date2=2023-01-17&fields=ym:s:date,ym:s:ipAddress&source=visits`,
      //   {
      //     method: "post",
      //     headers: {
      //       Authorization: `OAuth  ${OAuth}`,
      //     },
      //   }
      // );

      // const answer = await resCreateLogF.json();
      // if (answer) {
      //   console.log("!!!!resCreateLog", answer.toString());
      // }
    } catch (e) {
      console.error(e);
    }

    // *************************************3  запрос на просмотр информации лога****************************************************************
    // Методом GET выполните запрос на просмотр информации лога https://api-metrika.yandex.net/management/v1/counter/44147844/logrequest/XXXXXXX, где XXXXXXX - id запроса. Подробнее о методе в Справке https://yandex.ru/dev/metrika/doc/api2/logs/queries/getlogrequest.html.

    // const resAskForLog = await axios.get(`https://api-metrika.yandex.net/management/v1/counter/44147844/logrequest/${request_id_Yandext_test}`, config);
    //  console.log(
    //   "!!!!resAskForLog",
    //   resAskForLog.data
    // );

    // // *************************************4. Дождитесь, пока загрузка получит статус processed.****************************************************************
    // Это можно увидеть в ответе в поле status.
    // ...
    // "fields": [
    //             "ym:s:date",
    //             "ym:s:ipAddress"
    //         ],
    //         "status": "processed"
    // ...

    // *************************************5  запрос на выгрузку подготовленной части логов****************************************************************
    // После обработки запроса выполните запрос на выгрузку подготовленной части логов методом GET https://api-metrika.yandex.net/management/v1/counter/44147844/logrequest/XXXXXXXX/part/0/download, где XXXXXXXX - id запроса. Подробнее о методе https://yandex.ru/dev/metrika/doc/api2/logs/queries/download.html.

    //   const resDownloadLogTest = await axios.get(`https://api-metrika.yandex.net/management/v1/counter/44147844/logrequest/${request_id_Yandext_test}/part/0/download`, config);
    //   console.log(
    //    "!!!!resDownloadLogTest",
    //    resDownloadLogTest.data
    //  );
    // } catch (e) {
    //   console.log(e);
    // }
  };

  const getInfo = async () => {
    try {
      // ****************************для таблицы*******************************************************

      let urlMain = `https://api-metrika.yandex.net/stat/v1/data?preset=sources_summary&id=90983876`;
      // let urlMain = `https://api-metrika.yandex.net/stat/v1/data?preset=sources_summary&ids=44147844`;
      if (dateFrom) {
        urlMain += `&date1=${dateFrom.split("T")[0]}`;
      }
      if (dateTo) {
        urlMain += `&date2=${dateTo.split("T")[0]}`;
      }
      const res = await axios.get(urlMain, config);
      console.log("?preset=sources_summary&id=90983876", res.data);
      if (res.status === 200) {
        setYMdataDim(res?.data?.data);
        setYMall(res?.data);
      }

      // const resLogs = await axios.get(
      //   // `https://api-metrika.yandex.net/management/v1/counter/90983876/logrequests/evaluate?date1=2016-01-01&date2=2016-01-31&fields=ym%3Apv%3AdateTime%2Cym%3Apv%3Areferer&source=hits`,
      //   // `https://api-metrika.yandex.net/management/v1/counter/90983876/logrequests?date1=2022-10-30&date2=2022-11-01&fields=ym:pv:dateTime,ym:pv:referer&source=hits`,
      //   // `https://api-metrika.yandex.net/management/v1/counter/44147844/logrequests?date1=2022-11-01&date2=2022-11-08&fields=ym:s:date,ym:s:ipAddress&source=visits`,
      //   `https://api-metrika.yandex.net/management/v1/counter/90983876/logrequests?date1=2022-11-09&date2=2022-11-10&fields=ym:s:date,ym:s:ipAddress&source=visits`,
      //   config
      // );
      // console.log(
      //   "!!!!resLogs",
      //   resLogs.data
      // );

      let url = `https://api-metrika.yandex.net/stat/v1/data?ids=90983876&metrics=ym:s:visits,ym:s:pageviews,ym:s:users`;
      // let url1 = `https://api-metrika.yandex.net/stat/v1/data?id=90983876&metrics=ym:s:visits,ym:s:pageviews,ym:s:users?dimensions=ym:s:searchEngine`;
      if (dateFrom) {
        url += `&date1=${dateFrom.split("T")[0]}`;
      }
      if (dateTo) {
        url += `&date2=${dateTo.split("T")[0]}`;
      }
      const res6 = await axios.get(url, config);
      console.log(
        "?id=90983876&metrics=ym:s:visits,ym:s:pageviews,ym:s:users",
        res6?.data
      );
      if (res6.status === 200) {
        setYMdata(res6?.data?.data);
      }

      // const rs1 = ym:s:users
      // const res = await axios.get(
      //   `https://oauth.yandex.ru/authorize?response_type=code&client_id=4c9ec958206d4dbd96dde7daf9ff38d6&redirect_uri=https%3A%2F%2Foauth.yandex.ru%2Fverification_code`,
      // );
    } catch (e) {
      // console.error(e);
    }
  };
  useEffect(() => {
    getInfo();
  }, [dateFrom, dateTo]);
  return (
    <>
      <Box
        m={8}
        fontSize={[12, 8, 10, 12, 14]}
        boxShadow="lg"
        p="8"
        rounded="md"
        bg="white"
      >
        <Wrap fontSize={[12, 8, 10, 12, 14]}>
          <Box ml={4}>
            <Text fontSize={[8, 12]}>Дата от </Text>
            <Flex>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="date"
                  placeholder="Дата от"
                  minW={[150, 150]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                  }}
                />
                {dateFrom && (
                  <InputRightElement mr="0" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setDateFrom("")}
                    />
                  </InputRightElement>
                )}
                {!dateFrom && (
                  <InputRightElement>
                    <CalendarIcon color="blue.500" />
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Дата до </Text>
            <Flex>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="date"
                  placeholder="Дата до"
                  minW={[150, 150]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={dateTo}
                  onChange={(e) => {
                    setDateTo(e.target.value);
                  }}
                />
                {dateTo && (
                  <InputRightElement mr="0" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setDateTo("")}
                    />
                  </InputRightElement>
                )}
                {!dateTo && (
                  <InputRightElement>
                    <CalendarIcon color="blue.500" />
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
          </Box>
          <Center>
            <Button
              colorScheme="blue"
              onClick={getLogInfo}
              size="lg"
              padding={8}
              marginLeft={20}
            >
              GetLogInfo
            </Button>
          </Center>
        </Wrap>
      </Box>

      <Wrap m={8} justify="end">
        <Heading as="h2" mt={6} size="xl" mb="4" color="#6484AA">
          Yandex Metrics
        </Heading>
        <Spacer />
      </Wrap>

      <Box
        m={8}
        color="#6484AA"
        fontSize={[12, 10, 12, 14, 14]}
        fontWeight="500"
        // boxShadow="lg"
        // p="6"
        // rounded="md"
        // bg="white"
        // minWidth={220}
        // minHeight={20}
      >
        <Wrap w="100%" justifyContent="space-between">
          <Box
            boxShadow="lg"
            p="6"
            rounded="md"
            bg="white"
            minWidth={220}
            minHeight={20}
            // alignSelf="end"
          >
            <Center>
              <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
                {`Визитов: `}
              </Text>
              <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
                {` ${YMdata?.[0].metrics?.[0]} `}
              </Text>
            </Center>
          </Box>

          <Box
            boxShadow="lg"
            p="6"
            rounded="md"
            bg="white"
            minWidth={220}
            minHeight={20}
            // alignSelf="end"
          >
            <Center>
              <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
                {`Просмотров страницы: `}
              </Text>
              <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
                {` ${YMdata?.[0].metrics?.[1]} `}
              </Text>
            </Center>
          </Box>

          <Box
            boxShadow="lg"
            p="6"
            rounded="md"
            bg="white"
            minWidth={220}
            minHeight={20}
            // alignSelf="end"
          >
            <Center>
              <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
                {`Пользователей: `}
              </Text>
              <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
                {` ${YMdata?.[0].metrics?.[2]} `}
              </Text>
            </Center>
          </Box>

          <Box
            boxShadow="lg"
            p="6"
            rounded="md"
            bg="white"
            minWidth={220}
            minHeight={20}
            // alignSelf="end"
          >
            <Center>
              <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
                {`Даты: `}
              </Text>
              <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
                {` ${YMall?.query.date1} - ${YMall?.query.date2}`}
              </Text>
            </Center>
          </Box>
        </Wrap>
      </Box>

      <Box
        m={8}
        color="#6484AA"
        fontSize={[12, 10, 12, 14, 14]}
        fontWeight="500"
      >
        <div className="dashBoard_content__margin dashBoard_w100 dashBoard_flex dashBoard_admin__table">
          <div
            className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
            style={{ padding: "8px" }}
          >
            <div className="dashBoard_operation__item--column5">
              <Center>Параметр</Center>
            </div>
            <div className="dashBoard_operation__item--column5">
              <Center>Визиты</Center>
            </div>
            <div className="dashBoard_operation__item--column5">
              <Center>Пользователи</Center>
            </div>
            <div className="dashBoard_operation__item--column5">
              <Center>Отказы</Center>
            </div>
            <div className="dashBoard_operation__item--column5">
              <Center>Глубина</Center>
            </div>
            <div className="dashBoard_operation__item--column5">
              <Box textAlign="center">Продолжительность (минут)</Box>
            </div>
          </div>

          {YMdataDim?.length > 0 &&
            YMdataDim.map((item: any, index: number) => (
              <Box
                w="100%"
                key={item.id}
                fontSize={[12, 8, 10, 12, 14]}
                color="#000000"
                borderBottom="1px solid #dfdfdf"
              >
                <div
                  key={item.id}
                  className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
                >
                  <div className="dashBoard_operation__item--column5">
                    <Center
                      // color="blue.500"
                      as={motion.div}
                      // whileHover={{ scale: 1.1, cursor: "pointer" }}
                      // onClick={() => {
                      //   setChosenItem(item);
                      // }}
                    >
                      {item?.dimensions?.[0].name}
                    </Center>
                  </div>
                  <div className="dashBoard_operation__item--column5">
                    <Center as={motion.div}>{item?.metrics?.[0]}</Center>
                  </div>
                  <div className="dashBoard_operation__item--column5">
                    <Center>{item?.metrics?.[1]}</Center>
                  </div>
                  <div className="dashBoard_operation__item--column5">
                    <Center>{item?.metrics?.[2]}</Center>
                  </div>
                  <div className="dashBoard_operation__item--column5">
                    <Center>{item?.metrics?.[3]}</Center>
                  </div>
                  <div className="dashBoard_operation__item--column5">
                    <Center>
                      {item?.metrics?.[4]
                        ? (+item?.metrics?.[4] / 60).toFixed(2)
                        : "-"}
                    </Center>
                  </div>
                </div>
              </Box>
            ))}

          {/* {totalCount > itemPerPage && (
            <Center fontSize="12">
              <div className="pagination">
                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={prevPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === 1}
                >
                  Назад
                </Button>
                <button
                  onClick={() => setPage(1)}
                  className={`page ${page === 1 && "disabled"}`}
                >
                  1
                </button>
                {gaps.before ? "..." : null}

                {gaps.paginationGroup.map((el) => (
                  <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                  >
                    {el}
                  </button>
                ))}
                {gaps.after ? "..." : null}
                <button
                  onClick={() => setPage(totalPages)}
                  className={`page ${page === totalPages && "disabled"}`}
                >
                  {totalPages}
                </button>

                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={nextPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === totalPages}
                >
                  Вперед
                </Button>
              </div>
            </Center>
          )} */}
        </div>
      </Box>
    </>
  );
};

export default YandexMetrics;
