import React from "react";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import certificatePdf from "../../../assets/documents/IncorporationCertificate.pdf";
import Meta from "../../../utils/seo/Meta";

export const AboutCertificate = () => {
  return (
    <>
      <Meta
        title="Certificate"
        description="Финансовая компания работает в сфере бизнеса, который подразумевает покупку и продажу долговых обязательств, акций компаний, которые впервые выходят на рынок (IPO) и компаний,реализующих проекты в сфере блокчейна и выпускающие аналоги акций – токены (ICO)!"
      />
      <section className="section__padding start">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
          <Viewer fileUrl={certificatePdf} />
        </Worker>
      </section>
    </>
  );
};
