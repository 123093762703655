import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../http/apiService";

export interface ILastOperations {
  date: string;
  userLogin: string;
  amount: number;
}

export interface IStat {
  userTotalCount: number;
  bonusTotalSum: number;
}

interface IHomeState {
  lastOperations: ILastOperations[];
  stat: IStat;
  isLoading: boolean;
  isError: boolean;
  message: any;
}

const initialState: IHomeState = {
  lastOperations: [],
  stat: {
    userTotalCount: 0,
    bonusTotalSum: 0,
  },
  isLoading: false,
  isError: false,
  message: "",
};

// Get home stat
export const getHomeStat = createAsyncThunk(
  "homeStat/get",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.get("/api/Home/stat", config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get last operations
export const getLastOperations = createAsyncThunk(
  "lastOperations/post",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post(
        "/api/Home/last-operations",
        data,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reducer
export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHomeStat.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getHomeStat.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stat = action.payload;
      })
      .addCase(getHomeStat.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getLastOperations.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getLastOperations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lastOperations = action.payload;
      })
      .addCase(getLastOperations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

// export const { } = homeSlice.actions;
export default homeSlice.reducer;
