import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import LanguageSelect from "../../LK/LanguageSelect/LanguageSelect";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";
import { logoutUser } from "../../../features/auth/authSlice";
import {
  getUserInfo,
  getUserStat,
  resetUserInfo,
} from "../../../features/userInfo/userInfoSlice";
import { getMtbRate } from "../../../features/mtb/mtbSlice";
import { getUserNews } from "../../../features/news/newsSlice";

import { CropEasy } from "../../LK/CropImage/CropEasy";

import { BsTelegram } from "react-icons/bs";

import { API_URL } from "../../../http/apiService";
import axios from "axios";

import { Avatar, Box, Img } from "@chakra-ui/react";
import { GlobalSpinner } from "../../LK/GlobalSpinner/GlobalSpinner";
import { getLastIncomes } from "../../../features/payment/paymentSlice";

export const HeaderAdmin = () => {
  const { userData, userStat } = useAppSelector((state) => state.userInfo);
  const { accessToken } = useAppSelector((state) => state.auth);
  const { course } = useAppSelector((state) => state.mtb);
  const { userNews } = useAppSelector((state) => state.news);
  const { lastIncomes } = useAppSelector((state) => state.payment);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  // Get user data from backend when page starts
  const getFirstDataFromBackend = async () => {
    setIsLoading(true);
    if (!accessToken) {
      return;
    }
    const localRefreshToken = await localStorage.getItem("refreshToken");
    try {
      const data = {
        refresh_token: localRefreshToken,
      };

      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };

      const response = await axios.post(
        `${API_URL}/api/App/refresh-token`,
        data,
        config
      );

      if (response.data) {
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
      }
    } catch (error: any) {
      console.log("INTESEEPTOR ERROR headeradmin:", error);
      await logoutHandler();
      // await dispatch(logoutUser());
      // await dispatch(resetUserInfo());
    }
    if (userData.id === -1) {
      await dispatch(getUserInfo());
    }
    if (course.id === -1) {
      await dispatch(getMtbRate());
    }
    if (userNews.length === 0) {
      await dispatch(getUserNews());
    }
    if (userStat.financeStat.available === -1) {
      await dispatch(getUserStat());
    }
    if (lastIncomes.length === 0) {
      await dispatch(getLastIncomes());
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log("accessToken", accessToken);
    if (!accessToken) return;
    getFirstDataFromBackend();
  }, [accessToken]);

  // Translation i18next
  const { t } = useTranslation();

  // Crop Image
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [file, setFile] = useState<string | ArrayBuffer | null>(null);
  const [blob, setBlob] = useState(null);

  // Logout
  const logoutHandler = async () => {
    await dispatch(logoutUser());
    await dispatch(resetUserInfo());
    navigate("/login");
  };

  const [balanceMTB, setBalanceMTB] = useState<number | string>("");

  // Set user basic balance
  useEffect(() => {
    const userBalanceBasic = () => {
      userData?.balances.map((item) => {
        if (item.account.id === 1) {
          setBalanceMTB(item.value);
        }
      });
    };

    userBalanceBasic();
  }, [userData]);

  // Upload avatar image
  const onUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const image = reader.result;
        setFile(image);
        setOpenCrop(true);
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const getBlob = (blob: any) => {
    // pass blob up from the ImageCropper component
    setBlob(blob);
  };

  if (isLoading) {
    return <GlobalSpinner />;
  }

  return (
    <div className="admin__header">
      <div className="admin__header--inner">
        <div className="admin__header--wrapper">
          <Link to="/" className="admin__header--logo--inner">
            <img className="img" src="/assets/img/logo-admin.svg" alt="Лого" />
          </Link>

          <div className="admin__balance">
            {t("HeaderAdmin.your_balance")}{" "}
            <span className="green__color">
              {balanceMTB} $ 
              {/* 1 МТВ = {course?.rate}$ */}
            </span>
          </div>
        </div>

        <div className="admin__header--wrapper">
          <div className="admin__header--settings">
            <a
              href="https://t.me/+Nohi6R2WDIc1MGQy"
              target="_blanc"
              className="admin__control control"
            >
              <BsTelegram size="27px" color="#3182CE" />
            </a>

            <a
              href="https://www.youtube.com/channel/UCaG5gT5ZZN9rwzW6SIMo4ng"
              target="_blanc"
              className="admin__control control"
            >
              <img
                className="footer__social--icon"
                alt="Иконка"
                src="/assets/img/youtube.svg"
              />
            </a>
          </div>
        </div>

        <div className="admin__header--wrapper">
          <div className="admin__header--settings">
            {/* <LanguageSelect /> */}

            <Link
              to="/admin/notify"
              className="admin__control control"
              style={{
                position: "relative",
              }}
            >
              <img
                className="admin__logout--icon"
                src="/assets/img/notify.svg"
                alt="Иконка"
              />
              {userData?.newNotificationsCount > 0 && (
                <p className="admin__dot_message "></p>
              )}
            </Link>

            <Link
              to="/admin/tickets"
              className="admin__control control"
              style={{
                // background: userData?.newMessagesCount ? "#61d64a" : "",
                position: "relative",
              }}
            >
              <Img
                className="admin__logout--icon"
                src="/assets/img/message.svg"
                alt="Иконка"
              />
              {userData?.newMessagesCount > 0 && (
                <p className="admin__dot_message"></p>
              )}
            </Link>

            <Link to="/admin/settings" className="admin__control control">
              <img
                className="admin__logout--icon"
                src="/assets/img/settings.svg"
                alt="Иконка"
              />
            </Link>
          </div>

          <div className="admin__header--data--wrapper">
            <Link to="/admin" className="admin admin__name">
              {userData?.name ? userData.name : userData.login}
            </Link>

            <input
              style={{ display: "none" }}
              id="image-input"
              type="file"
              accept="image/jpeg,image/png"
              multiple={false}
              ref={fileInputRef}
              onChange={(e) => onUploadFile(e)}
            />

            <Avatar
              as="button"
              onClick={() => fileInputRef.current?.click()}
              mr={4}
              w={["35px", "45px"]}
              h={["35px", "45px"]}
              name={userData.name ? userData.name : userData.login}
              src={userData?.imageUrl}
            />

            {openCrop ? (
              <CropEasy
                {...{
                  file,
                  setFile,
                  setOpenCrop,
                  openCrop,
                  myCropShape: "round",
                  myAspect: 1,
                  setBlob: { getBlob },
                }}
              />
            ) : (
              <Box></Box>
            )}

            <button
              className="admin__control admin__logout"
              onClick={() => logoutHandler()}
            >
              <img
                className="admin__logout--icon"
                src="/assets/img/logout.svg"
                alt="Выйти"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
