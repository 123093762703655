import React, { FC } from "react";
import { ResponsiveLine } from "@nivo/line";
import { useTranslation } from "react-i18next";

const myData = [
  {
    id: "japan",
    color: "hsl(182, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 94,
      },
      {
        x: "helicopter",
        y: 186,
      },
      {
        x: "boat",
        y: 121,
      },
      {
        x: "train",
        y: 62,
      },
      {
        x: "subway",
        y: 48,
      },
      {
        x: "bus",
        y: 176,
      },
      {
        x: "car",
        y: 206,
      },
      {
        x: "moto",
        y: 247,
      },
      {
        x: "bicycle",
        y: 271,
      },
      {
        x: "horse",
        y: 263,
      },
      {
        x: "skateboard",
        y: 68,
      },
      {
        x: "others",
        y: 145,
      },
    ],
  },
  {
    id: "france",
    color: "hsl(292, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 222,
      },
      {
        x: "helicopter",
        y: 231,
      },
      {
        x: "boat",
        y: 160,
      },
      {
        x: "train",
        y: 2,
      },
      {
        x: "subway",
        y: 27,
      },
      {
        x: "bus",
        y: 17,
      },
      {
        x: "car",
        y: 258,
      },
      {
        x: "moto",
        y: 255,
      },
      {
        x: "bicycle",
        y: 60,
      },
      {
        x: "horse",
        y: 275,
      },
      {
        x: "skateboard",
        y: 178,
      },
      {
        x: "others",
        y: 208,
      },
    ],
  },
  {
    id: "us",
    color: "hsl(94, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 235,
      },
      {
        x: "helicopter",
        y: 189,
      },
      {
        x: "boat",
        y: 294,
      },
      {
        x: "train",
        y: 190,
      },
      {
        x: "subway",
        y: 214,
      },
      {
        x: "bus",
        y: 76,
      },
      {
        x: "car",
        y: 182,
      },
      {
        x: "moto",
        y: 79,
      },
      {
        x: "bicycle",
        y: 50,
      },
      {
        x: "horse",
        y: 44,
      },
      {
        x: "skateboard",
        y: 194,
      },
      {
        x: "others",
        y: 263,
      },
    ],
  },
  {
    id: "germany",
    color: "hsl(83, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 173,
      },
      {
        x: "helicopter",
        y: 113,
      },
      {
        x: "boat",
        y: 171,
      },
      {
        x: "train",
        y: 84,
      },
      {
        x: "subway",
        y: 6,
      },
      {
        x: "bus",
        y: 201,
      },
      {
        x: "car",
        y: 14,
      },
      {
        x: "moto",
        y: 219,
      },
      {
        x: "bicycle",
        y: 251,
      },
      {
        x: "horse",
        y: 43,
      },
      {
        x: "skateboard",
        y: 169,
      },
      {
        x: "others",
        y: 155,
      },
    ],
  },
  {
    id: "norway",
    color: "hsl(261, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 143,
      },
      {
        x: "helicopter",
        y: 155,
      },
      {
        x: "boat",
        y: 69,
      },
      {
        x: "train",
        y: 158,
      },
      {
        x: "subway",
        y: 76,
      },
      {
        x: "bus",
        y: 27,
      },
      {
        x: "car",
        y: 220,
      },
      {
        x: "moto",
        y: 49,
      },
      {
        x: "bicycle",
        y: 292,
      },
      {
        x: "horse",
        y: 127,
      },
      {
        x: "skateboard",
        y: 63,
      },
      {
        x: "others",
        y: 45,
      },
    ],
  },
];

const DiagramLine = ({data}) => {
  const { t } = useTranslation();

  return (
    <>
      <ResponsiveLine
        // data={myData}
        data={data.data}
        colors={{ scheme: "accent" }}
        lineWidth={3}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: "transportation",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t("Manager.quantity"),
          legendOffset: -40,
          legendPosition: "middle",
        }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default DiagramLine;
