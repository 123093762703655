import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineSetting } from "react-icons/ai";

import { useTranslation } from "react-i18next";
import { INewProgram } from "./Marketing";
type PropsType = {
  program: INewProgram;
};

const NewMatrixUniversal: FC<PropsType> = ({ program }: PropsType) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const redirectHandler = () => {
    navigate("/register");
  };

  return (
    <>
      <div className="container">
        <div className="mark__inner">
          <div className="mark__info--inner">
            <div className="mark__info">
              <p className="mark__info--title">{program.name}</p>

              {/* <p className="mark__info--text">{t("Auto.title1")}</p> */}

              <div className="mark__point--inner">
                {program.info.map((el) => (
                  <div className="mark__point" key={el}>
                    <img
                      src="/assets/img/check-blue.svg"
                      alt="Галочка"
                      className="mark__point--check"
                    />
                    {el}
                  </div>
                ))}
              </div>
            </div>

            <AiOutlineSetting className="mark__info--img" />
          </div>
        </div>

        <div className="mark__content">
          {program.matrixes.map((el) => (
            <div className="mark__item" key={el.id}>
              <div className="mark__wrap">
                <div className="mark__item--wrapper">
                  <div className="mark__wrapper">
                    <p className="mark__wrapper--title">
                      {el.id} {t("Auto.matrix")}
                    </p>

                    <p className="mark__wrapper--text">{program.name}</p>
                  </div>

                  {/* <p className="mark__item--wrapper--text">
                    {el.sum} $ {t("Auto.curators")}
                  </p> */}
                </div>

                <div className="mark__item--entry">
                  {t("Auto.entry")}: {el.sum} $
                </div>

                <div className="mark__item--content">
                  <img
                    src="assets/img/man1.png"
                    alt="Картинка"
                    className="mark__item--img"
                  />

                  <p className="mark__item--val big line">{el.sum}</p>

                  <div className="mark__item--box">
                    {[1, 2].map((elem) => (
                      <div className="mark__item--box--item_two" key={elem}>
                        <img
                          src="assets/img/man2.png"
                          alt="Картинка"
                          className="mark__item--img"
                        />

                        <p className="mark__item--val">{el.sum}</p>

                        <div className="mark__item--box--text--inner">
                          {el?.sumForWithdrawal && el?.sumToSponsor && (
                            <>
                              <p className="mark__item--box--text">
                                {el.sumToSponsor} $ {t("Fast.to_sponsor")}
                              </p>
                              <p className="mark__item--box--text">
                                {el.sumForWithdrawal} ${" "}
                                {t("Auto.for_withdrawal")}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  {el?.sumAccumCommon && (
                    <p className="mark__item--box--text">
                      {el?.sumAccumCommon} $ {t("Auto.accumulation")}
                    </p>
                  )}
                </div>
              </div>

              <div className="mark__item--desc"></div>

              <button
                onClick={() => redirectHandler()}
                className="button default__button mark__item--button"
              >
                {t("Auto.participate")}
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NewMatrixUniversal;
