import React from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";
import { logoutUser } from "../../../features/auth/authSlice";
import { resetUserInfo } from "../../../features/userInfo/userInfoSlice.ts";

import { AiOutlineLogin } from "react-icons/ai";
import { MdHowToReg, MdOutlineRateReview } from "react-icons/md";

import "./Header.css";
import { Box, useMediaQuery } from "@chakra-ui/react";
import { RiContactsBookLine } from "react-icons/ri";
import { BsNewspaper } from "react-icons/bs";
import { LanguageSwitcher } from "../../Home/LanguageSwitcher/LanguageSwitcher";

const Header = () => {
  const { t } = useTranslation();

  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");

  const { accessToken } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logoutUser());
    navigate("/login");
    setTimeout(() => {
      dispatch(resetUserInfo());
    }, 2000);
  };

  React.useEffect(() => {
    const close = () => {
      $("body").removeClass("scroll");
      $(".mobile__menu").removeClass("active");
    };

    $(".menu").on("click", function () {
      $("body").addClass("scroll");
      $(".mobile__menu").addClass("active");
    });

    $(".close").on("click", function () {
      close();
    });

    $(window).resize(function () {
      if ($(window).width() > 768) {
        close();
      }
    });
  }, []);

  return (
    <>
      <div className="mobile__menu">
        <img src="/assets/img/close.svg" alt="Закрыть" className="close" />

        <div className="header__nav">
          {/* <Link to="/about" className="header__nav--link">
            {t("HeaderLayout.about")}
          </Link> */}

          <Link to="/marketing" className="header__nav--link">
            {t("HeaderLayout.marketing")}
          </Link>

          <Link to="/faq" className="header__nav--link">
            {t("HeaderLayout.ques_ans")}
          </Link>

          <Link to="/adv" className="header__nav--link">
            {t("HeaderLayout.adw")}
          </Link>
        </div>

        <div className="header__social--inner mobile">
          <a
            href="https://t.me/+Nohi6R2WDIc1MGQy"
            target="_Blanc"
            className="header__social--link"
          >
            <img
              src="/assets/img/telegram-blue.svg"
              alt="Иконка"
              className="footer__link--icon"
            />
            {t("HeaderLayout.telegram")}
          </a>

          <Link to="/publick-news" className="header__social--link">
            <img
              src="/assets/img/telegram-blue.svg"
              alt="Иконка"
              className="footer__link--icon"
            />
            {t("HeaderLayout.news")}
          </Link>

          <Link to="/contact" className="header__social--link">
            {t("HeaderLayout.contacts")}
          </Link>

          <Link to="/reviews" className="header__social--link">
            <img
              src="/assets/img/telegram-blue.svg"
              alt="Иконка"
              className="footer__link--icon"
            />
            {t("HeaderLayout.review")}
          </Link>

          {/* <a href="mailto:info@dct-trips.com" className="header__social--link">
            <img
              src="/assets/img/mail.svg"
              alt="Иконка"
              className="footer__link--icon"
            />
            info@dct-trips.com
          </a> */}
        </div>
      </div>

      <header className="header">
        <div className="header__img--inner"></div>

        <img
          src="/assets/img/circle.png"
          alt="Круг"
          className="header__circle"
        />

        <div className="container">
          <div className="header__inner">
            <div className="header__top">
              <div className="header__wrapper">
                <Link to="/" className="footer__logo--inner">
                  <img
                    src="/assets/img/logo.png"
                    alt="Логотип"
                    className="img100"
                  />
                </Link>

                <div className="header__social--inner">
                  <a
                    href="https://t.me/+Nohi6R2WDIc1MGQy"
                    target="_Blanc"
                    className="header__social--link"
                  >
                    <img
                      src="/assets/img/telegram-blue.svg"
                      alt="Иконка"
                      className="footer__link--icon"
                    />
                    {t("HeaderLayout.telegram")}
                  </a>

                  <Link to="/publick-news" className="header__social--link">
                    <Box mr="1rem">
                      <BsNewspaper size={16} color="#158aff" />
                    </Box>
                    {t("HeaderLayout.news")}
                  </Link>

                  <Link to="/contact" className="header__social--link">
                    <Box mr="1rem">
                      <RiContactsBookLine size={18} color="#158aff" />
                    </Box>
                    {t("HeaderLayout.contacts")}
                  </Link>

                  <Link to="/reviews" className="header__social--link">
                    <Box mr="1rem">
                      <MdOutlineRateReview size={18} color="#158aff" />
                    </Box>
                    {t("HeaderLayout.review")}
                  </Link>

                  {/* <a
                    href="mailto:info@dct-trips.com"
                    className="header__social--link"
                  >
                    <img
                      src="/assets/img/mail.svg"
                      alt="Иконка"
                      className="footer__link--icon"
                    />
                    info@dct-trips.com
                  </a> */}
                </div>
              </div>

              <div className="header__wrapper">
                {isLargerThan1000 ? (
                  <></>
                ) : (
                  <>
                    <Link to="/login">
                      <Box>
                        <AiOutlineLogin color="white" size={32} />
                      </Box>
                    </Link>

                    <Link to="/register">
                      <Box mr={2}>
                        <MdHowToReg color="white" size={36} />
                      </Box>
                    </Link>
                  </>
                )}

                {/* <LanguageSwitcher /> */}

                <img src="/assets/img/menu.svg" alt="Меню" className="menu" />
              </div>
            </div>

            <div className="header__bottom">
              <div className="header__nav">
                {/* <Link to="/about" className="header__nav--link">
                  {t("HeaderLayout.about")}
                </Link> */}

                <Link to="/marketing" className="header__nav--link">
                  {t("HeaderLayout.marketing")}
                </Link>

                <Link to="/faq" className="header__nav--link">
                  {t("HeaderLayout.ques_ans")}
                </Link>

                <Link to="/adv" className="header__nav--link">
                  {t("HeaderLayout.adw")}
                </Link>
              </div>

              {accessToken ? (
                <div className="header__auth">
                  <Link to="/admin" className="header__auth--button">
                    {t("HeaderLayout.cabinet")}
                  </Link>

                  <button
                    className="header__auth--button"
                    onClick={() => logoutHandler()}
                  >
                    {t("HeaderLayout.exit")}
                  </button>
                </div>
              ) : (
                <div className="header__auth">
                  <Link to="/login" className="header__auth--button">
                    {t("HeaderLayout.in")}
                  </Link>

                  <Link to="/register" className="header__auth--button">
                    {t("HeaderLayout.register")}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
