import React, { FC, useEffect, useLayoutEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Captcha } from "../../../Components/Home/Captcha/Captcha";
import { Link, useNavigate } from "react-router-dom";

import { useForm, SubmitHandler } from "react-hook-form";
import { useAppSelector, useAppDispatch } from "../../../store";

import { loginUser } from "../../../features/auth/authSlice";

import md5 from "md5";

import {
  Button,
  Text,
  Fade,
  Center,
  Spinner,
  Flex,
  Tooltip,
  Checkbox,
} from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

type EnterForm = {
  login: string;
  password: string;
  // checkbox: boolean;
};

export const Login: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { accessToken, isLoading, isError, captchaError, message } =
    useAppSelector((state) => state.auth);

  // Captcha
  const [visibleCaptcha, setVisibleCaptcha] = useState(false);
  const [successCaptcha, setSuccessCaptcha] = useState(false);
  const [isCheck, setIsCheck] = useState(false);

  // Router v6
  const navigate = useNavigate();

  // React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<EnterForm>({
    mode: "all",
  });

  // Scroll to top
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openCaptcha = () => {
    setVisibleCaptcha(!visibleCaptcha);
  };

  useEffect(() => {
    // Redirect when logged in
    if (accessToken) {
      navigate("/admin");
    }
  }, [isError, accessToken, message, navigate, dispatch]);

  // Open captcha when many errors
  useEffect(() => {
    captchaError >= 3 && openCaptcha();
  }, [captchaError]);

  // Form login submit function
  const onSubmitLoginForm: SubmitHandler<EnterForm> = (data) => {
    const fetchUserLoginData = {
      Login: data.login,
      Password: md5(data.password),
    };
    dispatch(loginUser(fetchUserLoginData));
    reset();
    setIsCheck(false);
  };

  return (
    <>
      <Meta title="Sing in" description="Введите логин и пароль для входа!" />

      <section className="section__padding auth">
        <img src="/assets/img/wave.png" alt="Фон" className="faq__img" />

        <div className="container">
          <div className="auth__inner">
            <h2 className="title title__center">
              <span className="black blue">{t("Login.enter")}</span>
              {t("Login.system")}
            </h2>

            {visibleCaptcha ? (
              <Fade in={visibleCaptcha}>
                <Captcha
                  setSuccessCaptcha={setSuccessCaptcha}
                  setVisibleCaptcha={setVisibleCaptcha}
                  visibleCaptcha={visibleCaptcha}
                />
              </Fade>
            ) : (
              <></>
            )}

            <form>
              <div className="auth__content">
                <div className="auth__input--inner">
                  <input
                    placeholder="Login"
                    type="text"
                    className="input auth__input"
                    {...register("login", {
                      required: "The field is required!",
                      minLength: {
                        value: 4,
                        message: "Min 4 letters!",
                      },
                      maxLength: {
                        value: 10,
                        message: "Max 10 letters!",
                      },
                    })}
                  />
                  {errors?.login && (
                    <Text fontSize="xl" fontWeight="semibold" color="red.500">
                      {errors.login.message || "Error!"}
                    </Text>
                  )}
                </div>

                <div className="auth__input--inner">
                  <input
                    placeholder="Password"
                    type="password"
                    className="input auth__input"
                    {...register("password", {
                      required: "The field is required",
                      minLength: {
                        value: 4,
                        message: "Min 4 letters!",
                      },
                      maxLength: {
                        value: 10,
                        message: "Max 10 letters!",
                      },
                    })}
                  />
                  {errors?.password && (
                    <Text fontSize="xl" fontWeight="semibold" color="red.500">
                      {errors.password.message || "Error!"}
                    </Text>
                  )}
                </div>

                <Flex direction="row" justifyContent="center">
                  <Center h={16} m={2}>
                    <Checkbox
                      onChange={(e) => {
                        setIsCheck(e.target.checked);
                      }}
                      isChecked={isCheck}
                      type="checkbox"
                      mr={2}
                      size="lg"
                    ></Checkbox>
                  </Center>

                  <Flex direction="column" alignItems="center" h={24} mt={4}>
                    <a target="_blank" href="/public-offer">
                      <Tooltip
                        hasArrow
                        label={t("Login.agreement2")}
                        bg="gray.300"
                        color="black"
                        fontSize="2xl"
                      >
                        <Text fontSize="18px" color="#668faf">
                          {t("Login.agreement")}
                        </Text>
                      </Tooltip>
                    </a>
                  </Flex>
                </Flex>
              </div>
            </form>

            <div className="auth__links">
              <Link to="/recovery" className="auth__link">
                {t("Login.remember")}?
              </Link>

              <Link to="/register" className="auth__link">
                {t("Login.not_account")}?
              </Link>
            </div>

            <Center w="100%">
              {isLoading ? (
                <Spinner
                  mt={24}
                  w={20}
                  h={20}
                  thickness="14px"
                  speed="0.7s"
                  emptyColor="#61D64A"
                  color="#1c3b6a"
                />
              ) : (
                <Button
                  onClick={handleSubmit(onSubmitLoginForm)}
                  isDisabled={!isValid || !isCheck}
                  type="submit"
                  colorScheme="blue"
                  mt={20}
                  width={80}
                  height={24}
                  fontSize="3xl"
                  boxShadow="dark-lg"
                >
                  {t("Login.login")}
                </Button>
              )}
            </Center>
          </div>
        </div>
      </section>
    </>
  );
};
