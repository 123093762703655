import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../http/apiService";

export interface IPartner {
  id: number;
  login: string;
  createdAt: string;
  name: string;
  imageUrl: string;
  financialStat: {
    income: number;
    expenses: number;
    interest: number;
  };
  balances: [
    {
      account: {
        id: number;
        name: string;
      };
      value: number;
      usd: number;
      rate: {
        id: number;
        rate: number;
        date: string;
        change: number;
      };
    }
  ];
  contacts: {
    email: string;
    socialMediaVk: string;
    mobilePhone: string;
    socialMediaTg: string;
    visibleTelegram: true;
  };
}

interface IPartnersState {
  partners: {
    items: IPartner[];
    totalCount: number;
  };
  isLoading: boolean;
  isError: boolean;
  message: any;
}

const initialState: IPartnersState = {
  partners: {
    items: [],
    totalCount: 0,
  },
  isLoading: false,
  isError: false,
  message: "",
};

// Get publick webinars
export const getPartners = createAsyncThunk(
  "Partners/post",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post("/api/home/partners", data, config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reducer
export const partnersSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartners.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getPartners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partners = action.payload;
      })
      .addCase(getPartners.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

// export const { } = webinarSlice.actions;
export default partnersSlice.reducer;
