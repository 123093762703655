import React, { useEffect } from "react";
import { useAppSelector } from "../../store";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import $api from "../../http/apiService";

export const PrivateAdminRoute = () => {
  const { userData } = useAppSelector((state) => state.userInfo);
  const localAccessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();

  useEffect(() => {
    getIsAdmin();
  }, []);

  const getIsAdmin = async () => {
    if (userData.isAdmin === false) {
      try {
        const response = await $api.get("/api/User");
        if (response.data?.isAdmin === false) {
          navigate("/login");
        }
      } catch (error: any) {
        console.log(error);
        navigate("/login");
      }
    }
  };

  return localAccessToken ? <Outlet /> : <Navigate to="/login" />;
};
