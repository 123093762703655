import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import authReducer from "./features/auth/authSlice";
import userInfoSlice from "./features/userInfo/userInfoSlice";
import currencyRatesSlice from "./features/currencyRates/currencyRatesSlice";
import freeKassaSlice from "./features/freeKassa/freeKassaSlice";
import newsSlice from "./features/news/newsSlice";
import mtbSlice from "./features/mtb/mtbSlice";
import paymentSlice from "./features/payment/paymentSlice";
import reviewSlice from "./features/review/reviewSlice";
import homeSlice from "./features/home/homeSlice";
import webinarSlice from "./features/webinar/webinarSlice";
import partnersSlice from "./features/partners/partnersSlice";
import marketingSlice from "./features/marketing/marketingSlice";
import cryptoInvoiceSlice from "./features/cryptoInvoice/cryptoInvoiceSlice";
import globalChatSlice from "./features/globalChat/globalChatSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    userInfo: userInfoSlice,
    mtb: mtbSlice,
    freeKassa: freeKassaSlice,
    currencyRates: currencyRatesSlice,
    news: newsSlice,
    payment: paymentSlice,
    review: reviewSlice,
    home: homeSlice,
    webinar: webinarSlice,
    partner: partnersSlice,
    marketing: marketingSlice,
    cryptoInvoice: cryptoInvoiceSlice,
    globalChat: globalChatSlice,
  },
  // devTools: false,
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
