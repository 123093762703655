import React, { useEffect, useLayoutEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import axios from "axios";
import { API_URL } from "../../../http/apiService";

import { useNavigate, useParams } from "react-router-dom";

import { Captcha } from "../../../Components/Home/Captcha/Captcha";

import { useAppSelector, useAppDispatch } from "../../../store";
import {
  registerUser,
  addCaptchaErrorCount,
} from "../../../features/auth/authSlice";

import md5 from "md5";

import { useForm } from "react-hook-form";

import "react-phone-number-input/style.css";

import {
  Text,
  Checkbox,
  Tooltip,
  Button,
  Fade,
  Spinner,
  Center,
  Flex,
} from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

export const Register = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { accessToken, captchaError, isLoading, isError, message } =
    useAppSelector((state) => state.auth);

  // Captcha
  const [visibleCaptcha, setVisibleCaptcha] = useState(false);
  const [successCaptcha, setSuccessCaptcha] = useState(false);
  const [registerData, setRegisterData] = useState({});

  const [checkLogin, setCheckLogin] = useState("");
  const [checkInviter, setCheckInviter] = useState("");
  const [isBlockedInviter, setIsBlockedInviter] = useState(false);
  const [isInviterNotFound, setIsInviterNotFound] = useState(false);
  const [freeLogins, setFreeLogins] = useState([]);
  const [isFreeLogin, setIsFreeLogin] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingInviter, setLoadingInviter] = useState(false);
  const [captchaDispatch, setCaptchaDispatch] = useState(false);

  const { id } = useParams();

  // Scroll to top
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Initialize React hook form
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
  });

  // Redirect when registered
  useEffect(() => {
    if (accessToken) {
      navigate("/admin");
    }
  }, [isError, accessToken, message, navigate, dispatch, successCaptcha]);

  // Open captcha when 5 errors
  useEffect(() => {
    captchaError >= 7 && openCaptcha();
  }, [captchaError, addCaptchaErrorCount]);

  const openCaptcha = () => {
    setVisibleCaptcha(true);
  };

  // Form handler to Redux
  const onSubmit = (data) => {
    const registerData = {
      login: checkLogin,
      password: md5(data.password),
      passwordRepeat: md5(data.passwordRepeat),
      // inviter: data.inviter,
      inviter: data.inviter ? data.inviter : "admin", // 09.01.23 Временно передаем admin, если пользователь не ввел спонсора
    };
    setRegisterData(registerData);
    setCaptchaDispatch(true);
    openCaptcha();
    setFreeLogins([]);
    reset();
  };

  const successHandler = () => {
    dispatch(registerUser(registerData));
    setCaptchaDispatch(false);
  };

  useEffect(() => {
    successCaptcha & captchaDispatch && successHandler();
  }, [successCaptcha]);

  // Checking free login
  const testLoginHandler = async () => {
    setLoadingLogin(true);
    try {
      const login = {
        login: checkLogin,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${API_URL}/api/User/is-login-free`,
        login,
        config
      );

      if (response.data.free === false) {
        setFreeLogins(response.data.options);
        setIsFreeLogin(response.data.free);
      } else {
        setFreeLogins([]);
        setIsFreeLogin(response.data.free);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(addCaptchaErrorCount());
      setLoadingLogin(false);
    }
  };

  // Checking inviter is blocked
  const testInviterIsBlocked = async () => {
    setIsBlockedInviter(false);
    setIsInviterNotFound(false);
    setLoadingInviter(true);

    try {
      const inviter = {
        login: id ? id : checkInviter,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${API_URL}/api/User/inviter-info`,
        inviter,
        config
      );

      if (response.data.isBlocked === true) {
        setIsBlockedInviter(true);
      } else {
        setIsBlockedInviter(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(addCaptchaErrorCount());
      setLoadingInviter(false);
    }
  };

  // Checking inviter is exist
  const testInviterIsExist = async () => {
    setIsBlockedInviter(false);
    setIsInviterNotFound(false);
    setLoadingInviter(true);
    try {
      const inviter = {
        login: id ? id : checkInviter,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${API_URL}/api/User/inviter-exists`,
        inviter,
        config
      );

      if (response.data.exists === false) {
        setIsInviterNotFound(true);
      } else {
        setIsInviterNotFound(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(addCaptchaErrorCount());
      setLoadingInviter(false);
    }
  };

  // Testing inviter
  const testInviterHandler = () => {
    testInviterIsBlocked();
    testInviterIsExist();
  };

  // Check inviter when useParams
  useEffect(() => {
    if (id !== undefined) {
      testInviterHandler();
    }
  }, [id]);

  return (
    <>
      <Meta title="Register" description="Регистрация новых пользователей!" />

      <section className="section__padding auth">
        <img src="/assets/img/wave.png" alt="Фон" className="faq__img" />

        <div className="container">
          <div className="auth__inner">
            <h2 className="title title__center">
              <span className="black blue">{t("Register.register")}</span>
              {t("Register.system")}
            </h2>

            {visibleCaptcha ? (
              <Fade in={visibleCaptcha}>
                <Captcha
                  setSuccessCaptcha={setSuccessCaptcha}
                  setVisibleCaptcha={setVisibleCaptcha}
                  visibleCaptcha={visibleCaptcha}
                />
              </Fade>
            ) : (
              <></>
            )}

            <div className="auth__content">
              <div className="auth__input--inner">
                <input
                  placeholder="Login"
                  type="text"
                  className="input auth__input"
                  value={checkLogin}
                  {...register("login", {
                    required: "The field is required",
                    onBlur: () => testLoginHandler(),
                    onChange: (e) => setCheckLogin(e.target.value),
                    pattern: {
                      value: /^[a-zA-Z](.[a-zA-Z0-9]*)$/,
                      message:
                        "Login must start with a letter. Latin letters and numbers are allowed!",
                    },
                    minLength: {
                      value: 4,
                      message: "Min 4 letters!",
                    },
                    maxLength: {
                      value: 10,
                      message: "Max 10 letters!",
                    },
                  })}
                />
                {errors?.login && (
                  <Text fontSize="xl" fontWeight="semibold" color="red.500">
                    {errors.login.message || "Error!"}
                  </Text>
                )}

                {loadingLogin ? (
                  <Center w="100%" mt={2}>
                    <Spinner
                      size="lg"
                      thickness="6px"
                      speed="0.7s"
                      emptyColor="#61D64A"
                      color="#1c3b6a"
                    />
                  </Center>
                ) : (
                  <Center w="100%">
                    {freeLogins.length > 0 && (
                      <Text fontSize="xl" fontWeight="semibold" color="red.500">
                        {"Login is not free! Free variants:"}
                      </Text>
                    )}
                    {freeLogins.length > 0 &&
                      freeLogins?.map((login, index) => (
                        <Button
                          m={1}
                          mx={4}
                          fontSize="xl"
                          fontWeight="bold"
                          color="blue.500"
                          key={index}
                          onClick={() => {
                            setCheckLogin(login);
                            setIsFreeLogin(true);
                            setFreeLogins([]);
                          }}
                        >
                          {login}
                        </Button>
                      ))}
                  </Center>
                )}
              </div>

              <div className="auth__input--inner">
                <input
                  value={id}
                  readOnly={id ? true : false}
                  placeholder="Inviter"
                  type="text"
                  className="input auth__input"
                  {...register("inviter", {
                    // required: "The field is required!", // Закоментировано 09,01,23 на время
                    onBlur: () => testInviterHandler(),
                    onChange: (e) => setCheckInviter(e.target.value),
                    pattern: {
                      value: /^[a-zA-Z](.[a-zA-Z0-9]*)$/,
                      message:
                        "Sponsor must start with a letter. Latin letters and numbers are allowed!",
                    },
                    minLength: {
                      value: 4,
                      message: "Min 4 letters!",
                    },
                    maxLength: {
                      value: 20,
                      message: "Max 10 letters!",
                    },
                  })}
                />
                {errors?.inviter && (
                  <Text fontSize="xl" fontWeight="semibold" color="red.500">
                    {errors.inviter.message || "Error!"}
                  </Text>
                )}

                {loadingInviter && (
                  <Center w="100%" mt={2}>
                    <Spinner
                      size="lg"
                      thickness="6px"
                      speed="0.7s"
                      emptyColor="#61D64A"
                      color="#1c3b6a"
                    />
                  </Center>
                )}

                {isBlockedInviter === true && (
                  <Center w="100%">
                    <Text
                      m={1}
                      mx={4}
                      fontSize="xl"
                      fontWeight="semibold"
                      color="red.500"
                    >
                      {t("Register.blocked")}!
                    </Text>
                  </Center>
                )}

                {isInviterNotFound === true && (
                  <Center w="100%">
                    <Text
                      m={1}
                      mx={4}
                      fontSize="xl"
                      fontWeight="semibold"
                      color="red.500"
                    >
                      {t("Register.not_found")}!
                    </Text>
                  </Center>
                )}
              </div>

              <div className="auth__input--inner">
                <input
                  placeholder="Password"
                  type="password"
                  className="input auth__input"
                  {...register("password", {
                    required: "The field is required!",
                    pattern: {
                      value: /^[a-zA-Z0-9](.[a-zA-Z0-9-_!@]*)$/,
                      message: "Only letters, numbers and -_!@ are allowed",
                    },
                    minLength: {
                      value: 4,
                      message: "Min 4 letters!",
                    },
                    maxLength: {
                      value: 10,
                      message: "Max 10 letters!",
                    },
                  })}
                />
                {errors?.password && (
                  <Text fontSize="xl" fontWeight="semibold" color="red.500">
                    {errors.password.message || "Error!"}
                  </Text>
                )}
              </div>

              <div className="auth__input--inner">
                <input
                  placeholder="Repeat Password"
                  type="password"
                  className="input auth__input"
                  {...register("passwordRepeat", {
                    required: "The field is required!",
                    minLength: {
                      value: 4,
                      message: "Min 4 letters!",
                    },
                    maxLength: {
                      value: 10,
                      message: "Max 10 letters!",
                    },
                    validate: (value) => {
                      const { password } = getValues();
                      return password === value || "Passwords must match!";
                    },
                  })}
                />
                {errors?.passwordRepeat && (
                  <Text fontSize="xl" fontWeight="semibold" color="red.500">
                    {errors.passwordRepeat.message || "Error!"}
                  </Text>
                )}
              </div>
            </div>
            <Flex direction="row" justifyContent="center">
              <Center h={16} m={2}>
                <Checkbox
                  size="lg"
                  mr={2}
                  {...register("termOfUse", {
                    required: "I agree with offer!",
                  })}
                ></Checkbox>
              </Center>

              <Flex direction="column" alignItems="center" h={24} mt={4}>
                <a target="_blank" href="/public-offer">
                  <Tooltip
                    hasArrow
                    label={t("Register.agreement2")}
                    bg="gray.300"
                    color="black"
                    fontSize="2xl"
                  >
                    <Text fontSize="18px" color="#668faf">
                      {t("Register.agreement")}
                    </Text>
                  </Tooltip>
                </a>
                {errors?.termOfUse && (
                  <Text
                    fontSize="xl"
                    fontWeight="semibold"
                    color="red.500"
                    // ml={16}
                  >
                    {errors.termOfUse.message || "Error!"}
                  </Text>
                )}
              </Flex>
            </Flex>

            <Center w="100%">
              <Button
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoading}
                isDisabled={
                  !isValid ||
                  visibleCaptcha ||
                  !isFreeLogin ||
                  isBlockedInviter ||
                  isInviterNotFound
                }
                colorScheme="blue"
                type="submit"
                mt={20}
                width="96"
                height="24"
                fontSize="3xl"
                boxShadow="dark-lg"
              >
                {t("Register.sing_in")}
              </Button>
            </Center>
          </div>
        </div>
      </section>
    </>
  );
};
