import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store";

import { useTranslation } from "react-i18next";

import { Spinner, Center } from "@chakra-ui/react";
import { LangEnum } from "../../../pages/Dashboard/NewsList";

export const News: FC = () => {
  // Translation i18next
  const { t } = useTranslation();

  const { userNews, isLoading } = useAppSelector((state) => state.news);
  const { userData } = useAppSelector((state) => state.userInfo);

  if (isLoading) {
    return (
      <Center w="100%" h="100%" mt={20}>
        <Spinner
          thickness="14px"
          speed="0.7s"
          emptyColor="#61D64A"
          color="#1c3b6a"
          w={24}
          h={24}
        />
      </Center>
    );
  }

  return (
    <div className="w100 flex">
      <h3 className="admin__title--inner w100">
        <img
          className="admin__title--icon"
          src="/assets/img/gift-red.svg"
          alt="Иконка"
        />
        {t("NewsComponent.news")}
      </h3>

      <div className="flex w100 content__margin">
        {userNews[0] && (
          <Link
            to={`/admin/user-news/${userNews[0]?.id}`}
            className="admin__news--item flex w100"
          >
            <img
              className="admin__news--item--img"
              src={userNews[0]?.imageUrl}
              alt="Картинка новости"
            />

            <div className="admin__news--text--inner flex w100">
              <p className="admin__news--text--title">
                {userNews[0]?.content.find(
                  (e) => e.lang === userData?.interfaceLang
                )?.title ??
                  userNews[0]?.content.find((e) => e.lang === LangEnum.Ru)
                    ?.title}
              </p>
              <p className="admin__news--text--text">
                {userNews[0]?.content.find(
                  (e) => e.lang === userData?.interfaceLang
                )?.preview ??
                  userNews[0]?.content.find((e) => e.lang === LangEnum.Ru)
                    ?.preview}
              </p>
            </div>
          </Link>
        )}

        {userNews[1] && (
          <Link
            to={`user-news/${userNews[1]?.id}`}
            className="admin__news--item flex w100"
          >
            <img
              className="admin__news--item--img"
              src={userNews[1]?.imageUrl}
              alt="Картинка новости"
            />

            <div className="admin__news--text--inner flex w100">
              <p className="admin__news--text--title">
                {userNews[1]?.content.find(
                  (e) => e.lang === userData?.interfaceLang
                )?.title ??
                  userNews[1]?.content.find((e) => e.lang === LangEnum.Ru)
                    ?.title}
              </p>
              <p className="admin__news--text--text">
                {userNews[1]?.content.find(
                  (e) => e.lang === userData?.interfaceLang
                )?.preview ??
                  userNews[1]?.content.find((e) => e.lang === LangEnum.Ru)
                    ?.preview}
              </p>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};
