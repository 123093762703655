import React, { FC, useEffect } from "react";

import { AboutUserStruct } from "../../../Components/LK/AboutUser/AboutUserStruct";
import { ReferralStructure } from "../../../Components/LK/ReferralStructure/ReferralStructure";

import { News } from "../../../Components/LK/News/News";
import { Chat } from "../../../Components/LK/Chat/Chat";

import { useMediaQuery } from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

export const Struct: FC = () => {
  const [isLargerThan770] = useMediaQuery("(min-width: 770px)");

  return (
    <>
      <Meta title="Personal account" />
      <div className="admin__content--content flex w100">
        <div className="w100 flex">
          <h3 className="admin__title--inner w100">
            <img
              className="admin__title--icon"
              src="/assets/img/user-red.svg"
              alt="Иконка"
            />
            Структура
          </h3>
          <AboutUserStruct />
        </div>
        <ReferralStructure />
      </div>

      <div className="admin__content--right flex w100">
        <News />
        <Chat />
      </div>
    </>
  );
};
