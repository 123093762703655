import React, { useState, useEffect } from "react";

import $api from "../../../http/apiService";
import Moment from "react-moment";

import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";

import { News } from "../../../Components/LK/News/News";

import { useAppDispatch, useAppSelector } from "../../../store";
import { getUserInfo } from "../../../features/userInfo/userInfoSlice";

import { usePagination } from "../../../hooks/pagination/usePagination";
import "../../../hooks/pagination/pagination.scss";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

import { IChats, IChat, IPartner } from "./Tickets.interface";

import { Box, Button, Center } from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

export type MessageType = {
  id: string | number;
  createdAt: string;
  text: string;
  isRead: boolean;
  user: IPartner;
};

const itemsPerPage = 5;
const chatsPerPage = 10;

const Tickets = () => {
  const { t } = useTranslation();

  const { id } = useAppSelector((state) => state.userInfo.userData);

  const params = useParams();
  const paramsId = params?.id?.split("&")[0];

  const dispatch = useAppDispatch();

  const [chats, setChats] = useState<IChats>({
    items: [],
    totalCount: 0,
  });

  const [chosenChat, setChosenChat] = useState<IChat | null>(null);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [text, setText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [intervalId, setIntervalId] = useState<any>(null);
  // const [countForRerender, setCountForRerender] = useState(0);

  // Pagination
  const { nextPage, prevPage, page, gaps, setPage, totalPages } = usePagination(
    {
      contentPerPage: chatsPerPage,
      count: chats.totalCount,
    }
  );

  const getChats = async () => {
    const fetchData = {
      skip: (page - 1) * chatsPerPage,
      take: chatsPerPage,
    };

    const res = await $api.post("api/Message/chats", fetchData);

    if (paramsId) {
      const chat = res.data?.items?.find(
        (elem: IChat) => paramsId == elem.partner.id
      );

      if (chat) {
        setChats(res.data);

        setChosenChat(chat);
      } else {
        const newChat: IChat = {
          newMessagesCount: 0,
          partner: {
            id: params?.id?.split("&")[0],
            login: params?.id?.split("&")[1],
            name: "",
            imageUrl: "",
            isOnline: false,
            isVerified: true,
          },
          lastMessage: "",
          lastUpdated: "",
        };

        const arr: IChat[] = [...res.data.items];
        arr.unshift(newChat);

        setChats({
          items: arr,
          totalCount: res.data.totalCount,
        });

        setChosenChat(arr[0]);
        console.log(chosenChat);
      }
    } else {
      setChats(res.data);
      setChosenChat(res.data.items[0]);
    }
  };

  const getMessages = async () => {
    const rd = {
      partnerId: chosenChat?.partner?.id,
      skip: currentPage * itemsPerPage,
      take: itemsPerPage,
    };

    const res = await $api.post("api/Message/list", rd);

    if (res?.status === 200 && res.data?.items) {
      if (!currentPage) {
        setMessages(res.data.items);
        setTotalCount(res.data.totalCount);
      } else {
        setMessages([...messages, ...res.data.items]);
        setTotalCount(res.data.totalCount);
      }
      if (params?.id && res.data?.items?.length > 0) {
        const chat = chats.items.find(
          (elem: IChat) => elem.partner.id === params?.id?.split("&")[0]
        );
        if (chat?.partner?.login?.length == 0) {
          getChats();
        }
      }
      // dispatch(getNewMessagesCount());

      if (chosenChat) {
        // const temp: ChatType = { ...chosenChats };
        // temp.newMessagesCount = 0;
        // setChosenChart(temp);
        chosenChat.newMessagesCount = 0;
        // setCountForRerender(countForRerender + 1);
      }
      dispatch(getUserInfo());
    }
  };

  useEffect(() => {
    getChats();
  }, [page]);

  useEffect(() => {
    if (chosenChat) {
      getMessages();
    }
  }, [chosenChat, currentPage]);

  useEffect(() => {
    if (!chosenChat) return;

    if (intervalId) {
      clearInterval(intervalId);
    }
    const id = setInterval(() => {
      {
        getMessages();
      }
    }, 30000);
    setIntervalId(id);
    // return () => {
    //   clearInterval(id);
    // };
  }, [chosenChat]);

  const sendMessage = async () => {
    const rd = {
      partnerId: chosenChat?.partner.id
        ? chosenChat?.partner.id
        : params?.id?.split("&")[0],
      text: text,
    };
    const res = await $api.post("api/Message/send", rd);

    if (res?.status === 200) {
      // setMessages([...messages, res.data]);
      setCurrentPage(0);
      setText("");
      getMessages();
      if (!chosenChat) {
        getChats();
      }
    }
  };

  return (
    <>
      <Meta title="Personal account" />

      <div className="admin__content--content flex w100">
        <div className="w100 flex">
          <h3 className="admin__title--inner w100">
            <img
              className="admin__title--icon"
              src="/assets/img/ticket-red.svg"
              alt="Иконка"
            />
            {t("Tickets.tickets")}
          </h3>

          <div className="content__margin w100 ticket__content">
            <div className="w100 flex ticket__dialogs flexs">
              {chats?.items?.length > 0 &&
                chats.items.map((elem) => (
                  <div
                    className="ticket__dialogs--item w100 flexdef"
                    key={elem?.partner?.id}
                    onClick={() => setChosenChat(elem)}
                    style={{
                      // boxShadow:
                      //   elem?.partner?.id === chosenChats?.partner?.id
                      //     ? "5px 5px 5px 5px gray"
                      //     : "",
                      background:
                        elem?.partner?.id === chosenChat?.partner?.id
                          ? "linear-gradient(90deg, rgba(2,0,36,1) 0%, #1c3b6a 30%, rgba(0,212,255,1) 100%)"
                          : "",
                    }}
                  >
                    <img
                      className="ticket__dialogs--img flexs"
                      src={
                        elem?.partner?.imageUrl
                          ? elem?.partner?.imageUrl
                          : "/assets/img/freeavatar.png"
                      }
                      alt=""
                    />
                    <div className="w100 flex ticket__dialogs--text--inner">
                      <p
                        className="ticket__dialogs--title"
                        style={{
                          color:
                            elem?.partner?.id === chosenChat?.partner?.id
                              ? "#61d64a"
                              : "",
                        }}
                      >
                        {elem?.partner?.login
                          ? elem?.partner?.login
                          : `Id: ${elem?.partner?.id}`}
                      </p>

                      <p className="ticket__dialogs--text">
                        {elem?.lastMessage.slice(0, 30)}
                        {elem?.lastMessage?.length > 30 && "..."}
                      </p>
                    </div>

                    <div
                      className="ticket__dialogs--index flexs"
                      style={{
                        background:
                          elem?.newMessagesCount > 0 ? "#61d64a" : "grey",
                      }}
                    >
                      {elem?.newMessagesCount}
                    </div>
                  </div>
                ))}

              {chats.totalCount >= chatsPerPage && (
                <Center w="100%">
                  <div className="pagination">
                    <Button
                      onClick={prevPage}
                      w={12}
                      h={12}
                      colorScheme="blue"
                      borderRadius="xl"
                      isDisabled={page === 1}
                    >
                      <ChevronLeftIcon w={6} h={6} />
                    </Button>

                    <button
                      onClick={() => setPage(1)}
                      className={`page ${page === 1 && "disabled"}`}
                    >
                      1
                    </button>

                    {gaps.before ? "..." : null}

                    {gaps.paginationGroup.map((el) => (
                      <button
                        onClick={() => setPage(el)}
                        key={el}
                        className={`page ${page === el ? "active" : ""}`}
                      >
                        {el}
                      </button>
                    ))}

                    {gaps.after ? "..." : null}

                    <button
                      onClick={() => setPage(totalPages)}
                      className={`page ${page === totalPages && "disabled"}`}
                    >
                      {totalPages}
                    </button>

                    <Button
                      onClick={nextPage}
                      w={12}
                      h={12}
                      colorScheme="blue"
                      borderRadius="xl"
                      isDisabled={page === totalPages}
                    >
                      <ChevronRightIcon w={6} h={6} />
                    </Button>
                  </div>
                </Center>
              )}
            </div>

            {/* **************************************** текущий чат */}

            <div className="w100 flex ticket__wrapper">
              <div className="w100 flex ticket__dialog--content">
                {/* **************************************** сообщения */}
                {messages?.length > 0 &&
                  messages.map((elem) => (
                    <Box key={elem.id} w="100%">
                      <div
                        className={
                          elem?.user?.id === id
                            ? "w100 flexdef ticket__message--inner me"
                            : "w100 flexdef ticket__message--inner opponent"
                        }
                      >
                        <div className="ticket__ava--inner">
                          <img
                            className="ticket__ava"
                            src={
                              elem?.user?.imageUrl
                                ? elem?.user?.imageUrl
                                : "/assets/img/freeavatar.png"
                            }
                            alt="Аватарка"
                          />
                          <Box>
                            {elem?.user?.login}{" "}
                            <div
                              className={
                                elem?.user?.isOnline
                                  ? "point_online_chat_green"
                                  : "point_online_chat_red"
                              }
                            ></div>
                          </Box>
                        </div>

                        <p className="ticket__message">{elem?.text}</p>
                      </div>
                      <Box
                        textAlign={elem?.user?.id === id ? "start" : "start"}
                        fontSize={[8, 10]}
                        ml={elem?.user?.id === id ? 40 : 40}
                        mb={8}
                      >
                        <Moment format="HH:MM DD MMMM YYYY ">
                          {elem?.createdAt}
                        </Moment>
                      </Box>
                    </Box>
                  ))}
              </div>

              <div className="w100 flex ticket__send--content">
                {totalCount > (currentPage + 1) * itemsPerPage && (
                  <Button
                    mb={4}
                    variant="ghost"
                    size="lg"
                    bg="#61d64a"
                    color="white"
                    boxShadow="inset 0px -3px 0px rgba(0, 0, 0, 0.1)"
                    alignSelf="end"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={!(totalCount > (currentPage + 1) * itemsPerPage)}
                  >
                    {t("Tickets.download")}
                  </Button>
                )}
                <textarea
                  className="input ticket__input w100 admin"
                  placeholder="Message..."
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                ></textarea>

                <button
                  className="button pay__button ticket__send--button"
                  onClick={sendMessage}
                  disabled={!text}
                >
                  {t("Tickets.send")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="admin__content--right flex w100">
        <News />
      </div>
    </>
  );
};

export default Tickets;
