import React, { FC } from "react";
import { QRCodeSVG } from "qrcode.react";
import { Box } from "@chakra-ui/react";

interface IProps {
  size: number;
  value: string;
  title?: string;
}

export const QrCode: FC<IProps> = ({ size, value, title }) => {
  return (
    <div className="settings__bottom-section-qr-code">
      <p className="setting__side--title mt1">{title}</p>
      <Box p={10} m={2} bg="white" w={96} h={96}>
        <QRCodeSVG size={size} value={value} />
      </Box>
    </div>
  );
};