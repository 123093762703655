import { Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  Heading,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
  Wrap,
} from "@chakra-ui/react";
import React, { FC, useEffect, useRef, useState } from "react";
import "./DashBoard.scss";
import { usePagination } from "../../hooks/pagination/usePagination";
import { FcAddRow, FcGallery, FcKindle, FcMindMap } from "react-icons/fc";
import { motion } from "framer-motion";
import $api from "../../http/apiService";
import { AxiosResponse } from "axios";
import { AiFillCloseCircle } from "react-icons/ai";
import { FcBusinessman, FcComments } from "react-icons/fc";
import Moment from "react-moment";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { MdHideSource } from "react-icons/md";
import Raiting from "./component/Rating/Rating";
import WebinarModal from "./WebinarModal";
import { IdNameType } from "./UserList";
import { useAppSelector } from "../../store";
import { LangEnum } from "./NewsList";

const itemPerPage = 5;
enum SortType {
  CreatedAtAsc = "CreatedAtAsc",
  CreatedAtDesc = "CreatedAtDesc",
  SumAsc = "SumAsc",
  SumDesc = "SumDesc",
}
export type ContentType = {
  lang: number | string;
  title: string;
  desc: string;
};
export type WebinarType = {
  id: number;
  date: string;
  urlStream: string;
  urlRecord: string;
  imageUrl: string;
  status: IdNameType;
  content: ContentType[];
};
export type WebinarGetAllRDType = {
  take: number;
  skip: number;
  text?: string;
};
export const StatusWebinar = [
  { id: 1, name: "Онлайн" },
  { id: 2, name: "Пройден" },
  { id: 3, name: "Ожидается" },
];

const WebinarList: FC = () => {
  const { userData } = useAppSelector((state) => state.userInfo);
  const [webinars, setWebinars] = useState<WebinarType[]>([]);
  const [chosenItem, setChosenItem] = useState<WebinarType | undefined>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [textForSearch, setTextForSearch] = useState("");
  const [isNeedUpdate, setIsNeedUpdate] = useState(1);
  const [isDelete, setIsDelete] = useState(false);

  const {
    isOpen: isOpenActionModal,
    onOpen: onOpenActionModal,
    onClose: onCloseActionModal,
  } = useDisclosure();

  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: itemPerPage,
    count: totalCount,
  });
  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");

  useEffect(() => {
    getWebinars();
  }, [page, textForSearch, isNeedUpdate]);

  const getWebinars = async () => {
    const rd: WebinarGetAllRDType = {
      take: itemPerPage,
      skip: 0,
    };
    if (page > 0) {
      rd.skip = (page - 1) * itemPerPage;
    }
    if (textForSearch) {
      rd.text = textForSearch;
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response: AxiosResponse = await $api.post(
        "/api/admin/Webinar/all",
        rd,
        config
      );
      const data: WebinarType[] = response.data.items;
      // data.forEach((elem) => {
      //   elem.createdAt = elem.createdAt.split("T")[0];
      // });
      setWebinars(data);
      setTotalCount(response?.data?.totalCount);
    } catch (e) {
      console.error(e);
    }
  };

  const getColor = (item: WebinarType) => {
    if (item.status.id == StatusWebinar[1].id) {
      return "#FF000033";
    } else if (item.status.id == StatusWebinar[0].id) {
      return "#FAFF004A";
    } else return "#00FF3833";
  };

  return (
    <>
      <WebinarModal
        isOpen={isOpenActionModal}
        onOpen={onOpenActionModal}
        onCloseActionModal={onCloseActionModal}
        webinar={chosenItem}
        isNeedUpdate={isNeedUpdate}
        setIsNeedUpdate={setIsNeedUpdate}
        setChosenItem={setChosenItem}
        isDelete={isDelete}
        setIsDelete={setIsDelete}
      />
      {/* <Box
        m={8}
        fontSize={[12, 8, 10, 12, 14]}
        boxShadow="lg"
        p="8"
        rounded="md"
        bg="white"
      >
        <Wrap fontSize={[12, 8, 10, 12, 14]}>
          <Box ml={4}>
            <Text fontSize={[8, 12]}>Поиск</Text>
            <Box>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  // w={[80, 60, 50]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={textForSearch}
                  onChange={(e: any) => {
                    setPage(1);
                    setTextForSearch(e.target.value);
                  }}
                />
                {textForSearch && (
                  <InputRightElement mr="10" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setTextForSearch("")}
                    />
                  </InputRightElement>
                )}
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Box>
          </Box>
          <Spacer />
          <Button
            leftIcon={<FcAddRow />}
            // style={{ display: !isEditOpen ? "block" : "none" }}
            bg="#3786e5"
            color="white"
            // size="lg"
            // mt={6}
            ml={8}
            width={80}
            height={20}
            fontSize="3xl"
            onClick={() => onOpenActionModal()}
          >
            Добавить
          </Button>
        </Wrap>
      </Box> */}

      <Wrap my={8} justify={isLargerThan500 ? "end" : "start"}>
        <Box>
          <Heading
            as="h2"
            mt={6}
            size="xl"
            mb="4"
            color="#6484AA"
            minW={[220, 150]}
          >
            Вебинары
          </Heading>
        </Box>
        {isLargerThan500 && <Spacer />}

        <Box
          boxShadow="lg"
          p="6"
          rounded="md"
          bg="white"
          minW={[150, 220]}
          minHeight={20}
        >
          <Center>
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`Найдено: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${totalCount} `}
            </Text>
          </Center>
        </Box>
        <Button
          leftIcon={<FcAddRow />}
          // style={{ display: !isEditOpen ? "block" : "none" }}
          bg="#3786e5"
          color="white"
          minW={[170, 220]}
          ml={10}
          height={20}
          fontSize="3xl"
          onClick={() => onOpenActionModal()}
        >
          Добавить
        </Button>
      </Wrap>

      <Box color="#6484AA" fontSize={[12, 10, 12, 14, 14]} fontWeight="500">
        <div className="dashBoard_content__margin dashBoard_w100 dashBoard_flex dashBoard_admin__table">
          <div
            className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
            style={{ padding: "8px" }}
          >
            <p className="dashBoard_operation__item--column6min2">
              <Center>ID</Center>
            </p>
            <p className="dashBoard_operation__item--column6min2">
              <Center></Center>
            </p>
            <p className="dashBoard_operation__item--column6">
              <Center>Название</Center>
            </p>
            <p className="dashBoard_operation__item--column6">
              <Center>Дата</Center>
            </p>
            <p className="dashBoard_operation__item--column6">
              <Center>Изображение</Center>
            </p>
            <p className="dashBoard_operation__item--column6">
              <Center>Ссылка</Center>
            </p>
          </div>

          {webinars?.length > 0 &&
            webinars.map((item, index) => (
              <Box
                w="100%"
                key={item.id}
                fontSize={[12, 8, 10, 12, 14]}
                color="#000000"
                borderBottom="1px solid #dfdfdf"
                bg={getColor(item)}
                position="relative"
              >
                <div
                  key={item.id}
                  className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
                >
                  <p className="dashBoard_operation__item--column6min2">
                    <Center
                      color="blue.500"
                      as={motion.div}
                      whileHover={{ scale: 1.1, cursor: "pointer" }}
                      onClick={() => {
                        setChosenItem(item);
                        onOpenActionModal();
                      }}
                    >
                      {!isLargerThan500 && "ID: "}
                      {item?.id}
                    </Center>
                  </p>
                  <p
                    className={
                      isLargerThan500
                        ? "dashBoard_operation__item--column6min2"
                        : "myAbsoluteLeft"
                    }
                  >
                    <Center>
                      <Tooltip
                        fontSize={["12px", "16px"]}
                        label="Удалить"
                        hasArrow
                        bg="#ED64A6"
                        borderRadius="xl"
                      >
                        <Box
                          m="2"
                          as={motion.div}
                          whileHover={{ scale: 1.1, cursor: "pointer" }}
                          borderRadius="2xl"
                          bg="#ED64A6"
                          onClick={() => {
                            setIsDelete(true);
                            setChosenItem(item);
                            onOpenActionModal();
                          }}
                        >
                          <MdHideSource size={27} color="white" />
                        </Box>
                      </Tooltip>
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column6">
                    <Center>
                      {!isLargerThan500 && "Название: "}
                      {item.content.find(
                        (e) => e.lang === userData?.interfaceLang
                      )?.title ??
                        item.content.find((e) => e.lang === LangEnum.Ru)?.title}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column6">
                    <Center>
                      {!isLargerThan500 && "Дата: "}
                      <Moment format="DD:MM:YY">{item.date}</Moment>
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column6">
                    <Center>
                      <Img src={item?.imageUrl} alt="" width="20" />
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column6">
                    <Center>
                      {item.status.id == StatusWebinar[0].id && (
                        <a
                          style={{ color: "blue" }}
                          href={item?.urlStream}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item?.urlStream}
                        </a>
                      )}
                      {item.status.id == StatusWebinar[1].id && (
                        <a
                          style={{ color: "blue" }}
                          href={item?.urlRecord}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item?.urlRecord}
                        </a>
                      )}
                    </Center>
                  </p>
                </div>
              </Box>
            ))}

          {totalCount > itemPerPage && (
            <Center fontSize="12">
              <div className="pagination">
                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={prevPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === 1}
                >
                  Назад
                </Button>
                <button
                  onClick={() => setPage(1)}
                  className={`page ${page === 1 && "disabled"}`}
                >
                  1
                </button>
                {gaps.before ? "..." : null}

                {gaps.paginationGroup.map((el) => (
                  <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                  >
                    {el}
                  </button>
                ))}
                {gaps.after ? "..." : null}
                <button
                  onClick={() => setPage(totalPages)}
                  className={`page ${page === totalPages && "disabled"}`}
                >
                  {totalPages}
                </button>

                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={nextPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === totalPages}
                >
                  Вперед
                </Button>
              </div>
            </Center>
          )}
        </div>
      </Box>
    </>
  );
};

export default WebinarList;
