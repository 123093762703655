import React, { useEffect, useLayoutEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { News } from "../../../Components/LK/News/News";
import { Rating } from "../../../Components/LK/Rating/Rating";

import $api from "../../../http/apiService";

import { useForm } from "react-hook-form";

import { useAppSelector, useAppDispatch } from "../../../store";

import { toast } from "react-toastify";

import reviewIcon from "../../../assets/img/review_icon.png";
import { Image, Center, Text, Flex } from "@chakra-ui/react";
import { Chat } from "../../../Components/LK/Chat/Chat";
import Meta from "../../../utils/seo/Meta";

const Review = () => {
  const { t } = useTranslation();

  const { accessToken } = useAppSelector((state) => state.auth);
  const { publicReviews, isLoading } = useAppSelector((state) => state.review);
  const dispatch = useAppDispatch();

  const [rate, setRate] = useState<number>(5);

  // Scroll to top
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Initialize React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
  });

  // Form handler to Backend
  const onSubmit = async (data: any) => {
    const fetchData = {
      name: data.name,
      text: data.text,
      rate: rate,
    };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post("/api/Review/create", fetchData, config);
      reset({
        name: "",
        text: "",
      });
      toast.success("Отзыв отправлен!");
    } catch (error) {
      reset({
        name: "",
        text: "",
      });
      toast.error("Что то пошло не так. Попробуйте позже!!");
      console.log(error);
    }
  };

  return (
    <>
      <Meta title="Personal account" />

      <div className="admin__content--content flex w100">
        <div className="w100 flexcenter">
          <h3 className="admin__title--inner w100">
            <Image
              className="admin__title--icon"
              w="24px"
              h="24px"
              color="red.500"
              src={reviewIcon}
              alt="Иконка"
            />
            {t("Review.review")}
          </h3>

          <Flex mt={[10, 10, 16]}>
            <div className="contact__form">
              <p className="title">
                <span className="black blue">{t("Review.write")}:</span>
              </p>

              <div className="auth__input--inner full contact__input--inner">
                <input
                  type="text"
                  placeholder="Your name"
                  className="input auth__input"
                  {...register("name", {
                    required: "Поле обязательно к заполнению",
                    pattern: {
                      value: /^[a-zA-Za-яА-Я](.[a-zа-яA-ZА-Я0-9]*)$/,
                      message:
                        "Имя должно начинаться с буквы. Допустимы буквы и цифры!",
                    },
                    minLength: {
                      value: 4,
                      message: "Минимум 4 символа!",
                    },
                    maxLength: {
                      value: 20,
                      message: "Максимум 20 символов!",
                    },
                  })}
                />
                {errors?.name && (
                  <Text fontSize="xl" fontWeight="semibold" color="red.500">
                    {errors.name.message || "Error!"}
                  </Text>
                )}
              </div>

              <div className="auth__input--inner full contact__input--inner">
                <input
                  type="text"
                  placeholder="Your text"
                  className="input auth__input"
                  {...register("text", {
                    required: "Поле обязательно к заполнению",
                    pattern: {
                      value: /^[a-zа-яA-ZА-Я0-9](.[a-zа-яA-ZА-Я0-9. ]*)$/,
                      message: "Допустимы буквы,цифры и .",
                    },
                    minLength: {
                      value: 4,
                      message: "Минимум 4 символа!",
                    },
                    maxLength: {
                      value: 200,
                      message: "Максимум 200 символов!",
                    },
                  })}
                />
                {errors?.text && (
                  <Text fontSize="xl" fontWeight="semibold" color="red.500">
                    {errors.text.message || "Error!"}
                  </Text>
                )}
              </div>

              <Center w="100%">
                <Rating isEditable={true} rating={rate} setRating={setRate} />
              </Center>

              <button
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
                className="button default__button contact__button"
              >
                {t("Review.send")}
              </button>
            </div>
          </Flex>

          <div className="draw__content flex w100">
            <p className="draw__title">{t("Review.danger1")}:</p>

            <p className="draw__text">
              - {t("Review.danger2")};
              <br />- {t("Review.danger3")};
              <br />- {t("Review.danger4")};
              <br />- {t("Review.danger5")};
              <br />- {t("Review.danger6")};
              <br />- {t("Review.danger7")};
            </p>

            <p className="draw__text">{t("Review.contact")}.</p>
          </div>
        </div>
      </div>

      <div className="admin__content--right flex w100">
        <News />

        <Chat />
      </div>
    </>
  );
};

export default Review;
