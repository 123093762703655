import React, { useEffect, useState } from "react";
import "./Webinars.scss";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";
import { getPublickWebinars } from "../../../features/webinar/webinarSlice";

import { usePagination } from "../../../hooks/pagination/usePagination";
import "../../../hooks/pagination/pagination.scss";

import Moment from "react-moment";

import ReactPlayer from "react-player/youtube";

import { GlobalSpinner } from "../../../Components/LK/GlobalSpinner/GlobalSpinner";
import { LangEnum } from "../../Dashboard/NewsList";

import {
  Box,
  Button,
  Center,
  Flex,
  Select,
  Text,
  Link,
} from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

const webinarsPerPage = 5;

const Webinars = () => {
  const { t } = useTranslation();

  const { webinars, isLoading } = useAppSelector((state) => state.webinar);
  const { userData } = useAppSelector((state) => state.userInfo);
  const dispatch = useAppDispatch();

  const [status, setStatus] = useState("");

  // Pagination
  const { nextPage, prevPage, page, gaps, setPage, totalPages } = usePagination(
    {
      contentPerPage: webinarsPerPage,
      count: webinars.totalCount,
    }
  );

  // Pagination request
  useEffect(() => {
    const data = {
      take: webinarsPerPage,
      skip: (page - 1) * webinarsPerPage,
      status: status,
    };
    dispatch(getPublickWebinars(data));
  }, [page, status]);

  // if (isLoading) {
  //   return <GlobalSpinner />;
  // }

  return (
    <>
      <Meta title="Personal account" />

      <section className="section__padding vebinars">
        <img src="/assets/img/wave.png" alt="Фон" className="faq__img" />

        <div className="container">
          <div className="vebinars__inner">
            <h2 className="title title__center">
              <span className="black blue">{t("WebinarsPage.webinars")}</span>{" "}
              {t("WebinarsPage.instructional_videos")}
            </h2>

            <Flex w="100%" justifyContent="end" mt={10}>
              <Select
                onChange={(e) => setStatus(e.target.value)}
                w={80}
                h={14}
                variant="filled"
                bg="#87CEFA"
                borderColor="blue.100"
              >
                <option value="">{t("WebinarsPage.all")}</option>
                <option value="1">{t("WebinarsPage.online")}</option>
                <option value="2">{t("WebinarsPage.past")}</option>
                <option value="3">{t("WebinarsPage.future")}</option>
              </Select>
            </Flex>

            <div className="vebinars__content">
              {webinars?.items?.map((webinar, index) => (
                <div className="vebinars__item" key={webinar.id}>
                  <div className="vebinars__item--img--inner">
                    {webinar.status.id == 1 && (
                      <img
                        src={webinar.imageUrl}
                        alt="Превью"
                        className="vebinars__item--img"
                      />
                    )}

                    {webinar.status.id == 2 && (
                      <ReactPlayer
                        url={webinar.urlRecord}
                        className="vebinars__item--img"
                      />
                    )}

                    {webinar.status.id == 3 && (
                      <img
                        src={webinar.imageUrl}
                        alt="Превью"
                        className="vebinars__item--img"
                      />
                    )}
                  </div>

                  <div className="vebinars__item--text--inner">
                    <p className="text__name">
                      {webinar.content.find(
                        (e) => e.lang === userData?.interfaceLang
                      )?.title ??
                        webinar.content.find((e) => e.lang === LangEnum.Ru)
                          ?.title}
                    </p>
                    <p className="vector__item--title">
                      {webinar.content.find(
                        (e) => e.lang === userData?.interfaceLang
                      )?.desc ??
                        webinar.content.find((e) => e.lang === LangEnum.Ru)
                          ?.desc}
                    </p>

                    {webinar.status.id == 1 && (
                      <Text color="green.600" fontWeight="bold">
                        {webinar.status.name}
                      </Text>
                    )}
                    {webinar.status.id == 2 && (
                      <Text color="red" fontWeight="bold">
                        {webinar.status.name}
                      </Text>
                    )}
                    {webinar.status.id == 3 && (
                      <Text color="orange" fontWeight="bold">
                        {webinar.status.name}
                      </Text>
                    )}

                    <p className="text__date vebinars__text--date">
                      <img
                        src="/assets/img/date.svg"
                        alt="Календарь"
                        className="text__date--img"
                      />
                      <Moment format="DD MMMM YYYY, hh:mm:ss">
                        {webinar.date}
                      </Moment>
                    </p>

                    <div className="vebinars__item--button--inner">
                      {webinar.urlStream && (
                        <Link href={webinar.urlStream} isExternal>
                          <button className="button vebinars__item--button">
                            {t("WebinarsPage.change")}
                          </button>
                        </Link>
                      )}

                      <div className="vebinars__social--inner">
                        <Link
                          href="https://t.me/+Nohi6R2WDIc1MGQy"
                          isExternal
                          className="partners__social--link"
                        >
                          <img
                            src="/assets/img/telegram.svg"
                            alt="Telegram"
                            className="w100"
                          />
                        </Link>

                        {webinar.urlRecord && (
                          <Link
                            href={webinar.urlRecord}
                            isExternal
                            className="partners__social--link"
                          >
                            <img
                              src="/assets/img/youtube.svg"
                              alt="Youtube"
                              className="w100"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {webinars.totalCount <= webinarsPerPage ? (
              <Box></Box>
            ) : (
              <Center w={["100%"]} mt={4}>
                <div className="pagination">
                  <Button
                    onClick={prevPage}
                    w={24}
                    h={12}
                    colorScheme="blue"
                    borderRadius="xl"
                    isDisabled={page === 1}
                  >
                    {t("WebinarsPage.back")}
                  </Button>
                  <button
                    onClick={() => setPage(1)}
                    className={`page ${page === 1 && "disabled"}`}
                  >
                    1
                  </button>
                  {gaps.before ? "..." : null}

                  {gaps.paginationGroup.map((el) => (
                    <button
                      onClick={() => setPage(el)}
                      key={el}
                      className={`page ${page === el ? "active" : ""}`}
                    >
                      {el}
                    </button>
                  ))}
                  {gaps.after ? "..." : null}
                  <button
                    onClick={() => setPage(totalPages)}
                    className={`page ${page === totalPages && "disabled"}`}
                  >
                    {totalPages}
                  </button>

                  <Button
                    onClick={nextPage}
                    w={24}
                    h={12}
                    colorScheme="blue"
                    borderRadius="xl"
                    isDisabled={page === totalPages}
                  >
                    {t("WebinarsPage.next")}
                  </Button>
                </div>
              </Center>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Webinars;
