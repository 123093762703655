import {
  Avatar,
  Box,
  Button,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Text,
  useClipboard,
  useMediaQuery,
  Wrap,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./DashBoard.scss";

import { AiFillCloseCircle } from "react-icons/ai";
import { Search2Icon } from "@chakra-ui/icons";
import { AboutUser } from "../../Components/LK/AboutUser/AboutUser";
import { AccountBalance } from "../../Components/LK/AccountBalance/AccountBalance";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { RiVipCrownLine } from "react-icons/ri";
import $api from "../../http/apiService";
import { IUserData, IUserStatistic } from "../../features/userInfo/userInfoSlice";
import { IoIosRibbon } from "react-icons/io";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { FaRegClone } from "react-icons/fa";
import { PartnersPrograms } from "../LK/PartnersPrograms/PartnersPrograms";

const StructureDash: FC = () => {
  const [userData, setUserData] = useState<IUserData | null>(null);
  const [loginForSearch, setLoginForSearch] = useState("");
  const params = useParams();
  // Translation i18next
  const { t } = useTranslation();

  const [isLargerThan770] = useMediaQuery("(min-width: 770px)");

  useEffect(() => {
    if (params?.login) {
      setLoginForSearch(params?.login);
    }
  }, []);

  useEffect(() => {
    setUserData(null);
    if (loginForSearch.length > 4) {
      getUserInfo();
    }
  }, [loginForSearch]);

  useEffect(() => {
    console.log("userData.id", userData?.id);
    console.log("userData.login", userData?.login)
  }, [userData]);



  // const getUserInfo = async () => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   const rd = {
  //     userId: 3,
  //     program: 1,
  //     matrix: 1,
  //     login: loginForSearch,
  //     skip: 0,
  //     take: 5
  //   };
  //   try {
  //     const res = await $api.post("/api/admin/Marketing/search", rd, config);
  //     if (res.status === 200) {
  //       // setUserData(res.data);
  //     }
  //     const info = res.data;
  //     console.log(res);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const getUserInfo = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const rd = {
      login: loginForSearch,
    };
    try {
      const res = await $api.post("/api/admin/User/details", rd, config);
      if (res.status === 200) {
        setUserData(res.data);
      }
      const info: IUserData = res.data;
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };


  return (
    <>
      <Box
        m={8}
        fontSize={[12, 8, 10, 12, 14]}
        boxShadow="lg"
        p="8"
        rounded="md"
        bg="white"
      // font-family="DM Sans"
      >
        <Wrap fontSize={[12, 8, 10, 12, 14]}>
          <Box ml={4}>
            <Text fontSize={[8, 12]}>Поиск по логину</Text>
            <Box>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  placeholder="Поиск по логину"
                  // w={[80, 60, 50]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={loginForSearch}
                  onChange={(e) => setLoginForSearch(e.target.value)}
                />
                {loginForSearch && (
                  <InputRightElement mr="10" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setLoginForSearch("")}
                    />
                  </InputRightElement>
                )}
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Box>
          </Box>

          <Spacer />
        </Wrap>
      </Box>
      <Flex m={8}>
        <Box>
          <Heading as="h2" mt={6} size="xl" mb="4" color="#6484AA">
            Структура
          </Heading>
        </Box>
      </Flex>

      <PartnersPrograms userId={userData?.id} userLogin={userData?.login} />

    </>
  );
};

export default StructureDash;
