import React, { FC, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import { SubmitHandler, useForm } from "react-hook-form";
import $api from "../../http/apiService";
import { toast } from "react-toastify";
import { UserTypeForAdmin } from "./UserList";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Center,
  Text,
  Textarea,
} from "@chakra-ui/react";

type Iprops = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  user: UserTypeForAdmin | undefined;
  isNeedUpdate: number;
  setIsNeedUpdate: any;
  isAddComment: boolean;
  setIsAddComment: any;
};

type IFormInput = {
  comment?: string;
};

export const BlockChatModal = (props: Iprops) => {
  const navigate = useNavigate();

  // React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: "all",
  });

  //   useEffect(() => {
  //     props.onOpen();
  //   }, []);

  const handleClose = () => {
    props.onClose();
    navigate("/dashboard");
  };

  // Default values hook form initialize
  useEffect(() => {
    reset(props.user);
  }, [props.user]);

  const onSubmitBlockForm: SubmitHandler<IFormInput> = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (props.isAddComment) {
      const rd = {
        comment: data.comment,
      };
      try {
        const response = await $api.put(
          `/api/admin/User/${props.user?.id}/comment`,
          rd,
          config
        );
        if (response.status === 200) {
          toast(`Комментарий для ${props.user?.login} успешно добавлен`);
        }
      } catch (e) {
        console.error("Ошибка добавления комментария", e);
        toast.error("Ошибка добавления комментария");
      } finally {
        // props.setIsAddComment(false);
        props.onClose();
      }
    } else if (!props.user?.isChatBlocked) {
      const rd = {
        comment: data.comment,
      };

      try {
        const response = await $api.put(
          `/api/admin/User/${props.user?.id}/block-chat`,
          rd,
          config
        );

        if (response.status === 200) {
          toast(`Чат Пользователя ${props.user?.login} успешно заблокирован`);
        }
      } catch (e) {
        console.error("Ошибка блокировки", e);
        toast.error("Ошибка блокировки чата");
      } finally {
        props.onClose();
      }
    } else {
      try {
        const response = await $api.put(
          `/api/admin/User/${props.user?.id}/unblock-chat`,
          config
        );

        if (response.status === 200) {
          toast(`Чат Пользователя ${props.user?.login} успешно разблокирован`);
        }
      } catch (e) {
        console.error("Ошибка разблокировки чата", e);
        toast.error("Ошибка разблокировки чата");
      } finally {
        props.onClose();
      }
    }
    props.setIsNeedUpdate(props.isNeedUpdate + 1);
    props.setIsAddComment(false);

    // reset();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      size={"5xl"}
      isCentered
    >
      <ModalOverlay
        backdropBlur="5px"
        bg="blackAlpha.300"
        backdropFilter="blur(10px) "
      />
      <ModalContent>
        <ModalHeader fontSize={24} color="blue.500" fontWeight="bold">
          <Center>
            {props.user?.isChatBlocked
              ? `Разблокировать чат пользователя ${props?.user?.name}`
              : `Блокировать чат пользователя ${props?.user?.name}`}{" "}
          </Center>
        </ModalHeader>

        <form onSubmit={handleSubmit(onSubmitBlockForm)}>
          <ModalBody fontSize={16}>
            <Text fontSize={12} color="gray.500" mt={8}>
              Комментарий к блокировке чата
            </Text>
            <Textarea
              placeholder="Комментарий"
              size="lg"
              readOnly={props.user?.isBlocked ? true : false}
              {...register("comment", {
                required: "Поле обязательно к заполнению",
              })}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={handleSubmit(onSubmitBlockForm)}
              isDisabled={!isValid}
              type="submit"
              // colorScheme="blue"
              colorScheme="facebook"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="dark-lg"
            >
              Сохранить
            </Button>
            <Button
              colorScheme="twitter"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="dark-lg"
              onClick={() => {
                props.setIsAddComment(false);
                handleClose();
              }}
            >
              Закрыть
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
