import React, { FC } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { BsBagCheck } from "react-icons/bs";

export const TravelMoney: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const redirectHandler = () => {
    navigate("/register");
  };

  return (
    <>
      <div className="container">
        <div className="mark__inner">
          <div className="mark__info--inner">
            <div className="mark__info">
              <p className="mark__info--title">Travel money</p>

              <p className="mark__info--text">{t("Travel.title1")}</p>

              <div className="mark__point--inner">
                <div className="mark__point">
                  <img
                    src="/assets/img/check-blue.svg"
                    alt="Галочка"
                    className="mark__point--check"
                  />
                  {t("Travel.title2")}
                </div>

                <div className="mark__point">
                  <img
                    src="/assets/img/check-blue.svg"
                    alt="Галочка"
                    className="mark__point--check"
                  />
                  {t("Travel.title3")}.
                </div>

                <div className="mark__point">
                  <img
                    src="/assets/img/check-blue.svg"
                    alt="Галочка"
                    className="mark__point--check"
                  />
                  {t("Travel.title4")}
                </div>

                <div className="mark__point">
                  <img
                    src="/assets/img/check-blue.svg"
                    alt="Галочка"
                    className="mark__point--check"
                  />
                  {t("Travel.title5")}
                </div>

                <div className="mark__point">
                  <img
                    src="/assets/img/check-blue.svg"
                    alt="Галочка"
                    className="mark__point--check"
                  />
                  {t("Travel.title6")}
                </div>

                <div className="mark__point">
                  <img
                    src="/assets/img/check-blue.svg"
                    alt="Галочка"
                    className="mark__point--check"
                  />
                  {t("Travel.title7")}
                </div>
              </div>
            </div>

            <BsBagCheck className="mark__info--img" />
          </div>
        </div>

        <div className="mark__content">
          <div className="mark__item">
            <div className="mark__wrap">
              <div className="mark__item--wrapper">
                <div className="mark__wrapper">
                  <p className="mark__wrapper--title">1 {t("Travel.matrix")}</p>

                  <p className="mark__wrapper--text">Travel Money</p>
                </div>

                <p className="mark__item--wrapper--text">
                  5 $ {t("Travel.curators")}
                </p>
              </div>

              <div className="mark__item--entry">
                {t("Travel.entry")}: 120 $
              </div>

              <div className="mark__item--content">
                <img
                  src="assets/img/man1.png"
                  alt="Картинка"
                  className="mark__item--img"
                />

                <p className="mark__item--val big line">120</p>

                <div className="mark__item--box">
                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man2.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">110</p>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man3.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">110</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        330 $ - {t("Travel.transition")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man4.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">110</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mark__item--desc"></div>

            <button
              onClick={() => redirectHandler()}
              className="button default__button mark__item--button"
            >
              {t("Travel.participate")}
            </button>
          </div>

          <div className="mark__item">
            <div className="mark__wrap">
              <div className="mark__item--wrapper">
                <div className="mark__wrapper">
                  <p className="mark__wrapper--title">2 {t("Travel.matrix")}</p>

                  <p className="mark__wrapper--text">Travel Money</p>
                </div>

                <p className="mark__item--wrapper--text">
                  5 $ {t("Travel.curators")}
                </p>
              </div>

              <div className="mark__item--entry">
                {t("Travel.entry")}: 330 $
              </div>

              <div className="mark__item--content">
                <img
                  src="assets/img/man1.png"
                  alt="Картинка"
                  className="mark__item--img"
                />

                <p className="mark__item--val big line">330</p>

                <div className="mark__item--box">
                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man2.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">320</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        {t("Travel.desc25")} Travel money.
                      </p>

                      <p className="mark__item--box--text">
                        ({t("Travel.desc26")})
                      </p>

                      <p className="mark__item--box--text">
                        {t("Travel.desc27")} Fast money
                      </p>

                      <p className="mark__item--box--text">
                        ({t("Travel.desc28")})
                      </p>

                      <p className="mark__item--box--text">
                        32 $ {t("Travel.for_withdrawal")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man3.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">320</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        70 $ {t("Travel.for_withdrawal")}
                      </p>
                      <p className="mark__item--box--text">
                        250 $ {t("Travel.accumulation")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man4.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">320</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        70 $ {t("Travel.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        250 $ {t("Travel.desc29")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mark__item--desc">{t("Travel.desc30")}.</div>

            <button
              onClick={() => redirectHandler()}
              className="button default__button mark__item--button"
            >
              {t("Travel.participate")}
            </button>
          </div>

          <div className="mark__item">
            <div className="mark__wrap">
              <div className="mark__item--wrapper">
                <div className="mark__wrapper">
                  <p className="mark__wrapper--title">3 {t("Travel.matrix")}</p>

                  <p className="mark__wrapper--text">Travel Money</p>
                </div>

                <p className="mark__item--wrapper--text">
                  10 $ {t("Travel.curators")}
                </p>
              </div>

              <div className="mark__item--entry">
                {t("Travel.entry")}: 500 $
              </div>

              <div className="mark__item--content">
                <img
                  src="assets/img/man1.png"
                  alt="Картинка"
                  className="mark__item--img"
                />

                <p className="mark__item--val big line">500</p>

                <div className="mark__item--box">
                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man2.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">480</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        {t("Travel.desc1")} Travel money
                      </p>

                      <p className="mark__item--box--text">
                        ({t("Travel.desc2")})
                      </p>

                      <p className="mark__item--box--text">
                        {t("Travel.desc3")} Fast money
                      </p>

                      <p className="mark__item--box--text">
                        ({t("Travel.desc4")})
                      </p>

                      <p className="mark__item--box--text">
                        108 $ {t("Travel.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        100 $ {t("Travel.accumulation")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man3.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">480</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        160 $ {t("Travel.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        300 $ {t("Travel.accumulation")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man4.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">480</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        160 $ {t("Travel.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        300 $ {t("Travel.desc5")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mark__item--desc">{t("Travel.desc6")}.</div>

            <button
              onClick={() => redirectHandler()}
              className="button default__button mark__item--button"
            >
              {t("Travel.participate")}
            </button>
          </div>

          <div className="mark__item">
            <div className="mark__wrap">
              <div className="mark__item--wrapper">
                <div className="mark__wrapper">
                  <p className="mark__wrapper--title">4 {t("Travel.matrix")}</p>

                  <p className="mark__wrapper--text">Travel Money</p>
                </div>

                <p className="mark__item--wrapper--text">
                  10 $ {t("Travel.curators")}
                </p>
              </div>

              <div className="mark__item--entry">
                {t("Travel.entry")}: 700 $
              </div>

              <div className="mark__item--content">
                <img
                  src="assets/img/man1.png"
                  alt="Картинка"
                  className="mark__item--img"
                />

                <p className="mark__item--val big line">700</p>

                <div className="mark__item--box">
                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man2.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">680</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        {t("Travel.desc1")} Travel money
                      </p>

                      <p className="mark__item--box--text">
                        ({t("Travel.desc7")})
                      </p>
                      <p className="mark__item--box--text">
                        {t("Travel.desc8")} Fast money
                      </p>

                      <p className="mark__item--box--text">
                        ({t("Travel.desc9")})
                      </p>

                      <p className="mark__item--box--text">
                        144 $ {t("Travel.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        200 $ {t("Travel.accumulation")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man3.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">680</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        180 $ {t("Travel.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        500 $ {t("Travel.accumulation")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man4.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">680</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        180 $ {t("Travel.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        500 $ {t("Travel.desc10")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mark__item--desc">{t("Travel.desc11")}.</div>

            <button
              onClick={() => redirectHandler()}
              className="button default__button mark__item--button"
            >
              {t("Travel.participate")}
            </button>
          </div>

          <div className="mark__item">
            <div className="mark__wrap">
              <div className="mark__item--wrapper">
                <div className="mark__wrapper">
                  <p className="mark__wrapper--title">5 {t("Travel.matrix")}</p>

                  <p className="mark__wrapper--text">Travel Money</p>
                </div>

                <p className="mark__item--wrapper--text">
                  50 $ {t("Travel.curators")}
                </p>
              </div>

              <div className="mark__item--entry">
                {t("Travel.entry")}: 1200 $
              </div>

              <div className="mark__item--content">
                <img
                  src="assets/img/man1.png"
                  alt="Картинка"
                  className="mark__item--img"
                />

                <p className="mark__item--val big line">1200</p>

                <div className="mark__item--box">
                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man2.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">1100</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        {t("Travel.desc12")} Travel money
                      </p>

                      <p className="mark__item--box--text">
                        ({t("Travel.desc13")})
                      </p>
                      <p className="mark__item--box--text">
                        {t("Travel.desc14")} Fast money
                      </p>

                      <p className="mark__item--box--text">
                        ({t("Travel.desc15")})
                      </p>

                      <p className="mark__item--box--text">
                        500 $ {t("Travel.for_withdrawal")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man3.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">1100</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        {t("Travel.desc16")} Travel money
                      </p>

                      <p className="mark__item--box--text">
                        ({t("Travel.desc17")})
                      </p>

                      <p className="mark__item--box--text">
                        {t("Travel.desc18")} Fast money
                      </p>

                      <p className="mark__item--box--text">
                        ({t("Travel.desc19")})
                      </p>

                      <p className="mark__item--box--text">
                        500 $ {t("Travel.for_withdrawal")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man4.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">1100</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        {t("Travel.desc20")} Travel money
                      </p>

                      <p className="mark__item--box--text">
                        ({t("Travel.desc21")})
                      </p>
                      <p className="mark__item--box--text">
                        {t("Travel.desc22")} Fast money
                      </p>

                      <p className="mark__item--box--text">
                        ({t("Travel.desc23")})
                      </p>

                      <p className="mark__item--box--text">
                        500 $ {t("Travel.for_withdrawal")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mark__item--desc">{t("Travel.desc24")}</div>

            <button
              onClick={() => redirectHandler()}
              className="button default__button mark__item--button"
            >
              {t("Travel.participate")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
