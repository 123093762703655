import React from "react";

import { useTranslation } from "react-i18next";

import { News } from "../../../Components/LK/News/News";
import { Chat } from "../../../Components/LK/Chat/Chat";

import drawImage from "../../../assets/img/draw.png";
import Meta from "../../../utils/seo/Meta";

const Vacancies = () => {
  const { t } = useTranslation();

  return (
    <>
      <Meta title="Personal account" />
      <div className="admin__content--content flex w100">
        <div className="w100 flexcenter">
          <h3 className="admin__title--inner w100">
            <img
              className="admin__title--icon"
              src="/assets/img/gift-red.svg"
              alt="Иконка"
            />
            {t("Vacancies.сompany_offer")}
          </h3>

          <div className="draw__content flex w100">
            <img
              className="img"
              style={{
                borderRadius: "20px",
              }}
              src={drawImage}
              alt="Картинка"
            />

            <p className="draw__title">{t("Vacancies.invite")}.</p>

            <p className="draw__text">
              {t("Vacancies.try")}: <br />- {t("Vacancies.educational")}; <br />
              - {t("Vacancies.marketing")};<br /> - {t("Vacancies.promotion")};
            </p>

            <p className="draw__text">{t("Vacancies.offer")}.</p>

            <p className="draw__text">{t("Vacancies.questions")}.</p>
          </div>
        </div>
      </div>

      <div className="admin__content--right flex w100">
        <News />

        <Chat />
      </div>
    </>
  );
};

export default Vacancies;
