import {
  ArrowUpDownIcon,
  CalendarIcon,
  CheckIcon,
  EditIcon,
  InfoIcon,
  Search2Icon,
  SettingsIcon,
  StarIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Fade,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
  useMediaQuery,
  Wrap,
} from "@chakra-ui/react";
import React, { FC, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./DashBoard.scss";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { usePagination } from "../../hooks/pagination/usePagination";
import {
  MdOutlineDateRange,
  MdOutlineSubtitles,
  MdOutlineDescription,
  MdOutlinePublic,
  MdOutlineAccountBalanceWallet,
  MdPayment,
} from "react-icons/md";
import { FcAddRow } from "react-icons/fc";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { motion } from "framer-motion";
import AreaDiagram from "./component/diagrams/diagramArea";
import { INews } from "../../features/news/newsSlice";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  ChakraProvider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  extendTheme,
} from "@chakra-ui/react";
import $api from "../../http/apiService";
import { AxiosResponse } from "axios";
import { CropEasy } from "../../Components/LK/CropImage/CropEasy";
import { SketchPicker } from "react-color";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  FcDataConfiguration,
  FcBusinessContact,
  FcBusinessman,
  FcCollaboration,
  FcCalendar,
  FcMoneyTransfer,
  FcAlphabeticalSortingZa,
  FcAlphabeticalSortingAz,
  FcContacts,
  FcAssistant,
  FcNumericalSorting12,
  FcNumericalSorting21,
  FcGenericSortingDesc,
  FcComments,
  FcApprove,
} from "react-icons/fc";
import Moment from "react-moment";
import PaymentItem from "./PaymentItem";
import { UserTypeForAdmin } from "./UserList";
import PaymentMenu from "./PaymentMenu";
import PaymentTransferItem from "./PaymentTransferItem";
import getNumberWithSpaces from "../../utils/getNumberWithSpaces";
import { useAppSelector } from "../../store";
import { LangEnum } from "./NewsList";

const itemPerPage = 20;
const activeLabelStyles = {
  transform: "scale(0.85) translateY(-26px)",
};

export enum PaymentStatus {
  all = "Все",
  Executed = "Исполнен",
  Canceled = "Отменен",
  Pending = "В ожидании",
}

enum Direction {
  all = "Все",
  input = "Приход",
  output = "Расход",
}
enum SortType {
  CreatedAtAsc = "CreatedAtAsc",
  CreatedAtDesc = "CreatedAtDesc",
  SumAsc = "SumAsc",
  SumDesc = "SumDesc",
}
export type IdNameType = {
  id: number;
  name: string;
};
export type UserType = {
  id: number;
  name: string;
  login: string;
};
export type ExternalPaymentType = {
  walletId: string;
  externalTransactionId: string;
  paymentSystem: IdNameType;
};
export type DescType = {
  lang: string | number;
  desc: string;
};
export type PaymentTypeInRD = {
  id: number;
  date: string;
  sum: number;
  descriptions: DescType[];
  user: UserType;
  article: IdNameType;
  account: IdNameType;
  transactionId: string;
  comment: string;
  status: IdNameType;
  statusChangedAt: string;
  externalPayment: ExternalPaymentType;
  editor: string;
};
type PaymentTypeOutRD = {
  direction?: number | string;
  paymentStatus?: number;
  paymentAccountId?: number;
  paymentArticleId?: number;
  paymentSystemId?: number;
  login?: string;
  take?: number;
  skip?: number;
  from?: string;
  to?: string;
  exactMatch?: boolean;
  walletId?: string;
  sort?: number | string;
  onlyPaid?: boolean;
};
export const Directions = [
  { id: 0, value: "", desc: "Все" },
  { id: 1, value: 0, desc: "Приход" },
  { id: 2, value: 1, desc: "Расход" },
];
export const PaymentStatuses = [
  { id: 3, value: "", desc: "Все" },
  { id: 0, value: 1, desc: "Canceled" },
  { id: 1, value: 0, desc: "Pending" },
  { id: 2, value: 2, desc: "Executed" },
];
export type StatusType = {
  id: number;
  name: string;
  ingl: string;
};
const statuses: StatusType[] = [
  { id: 0, name: "Отменен", ingl: "Canceled" },
  { id: 1, name: "В ожидании", ingl: "Pending" },
  { id: 2, name: "Исполнен", ingl: "Executed" },
];

export const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
              fontSize: "xl",
            },
          },
        },
      },
    },
  },
});

const PaymentList: FC = () => {
  const params = useParams();
  const { userData } = useAppSelector((state) => state.userInfo);
  const [myPayments, setPayments] = useState<PaymentTypeInRD[]>([]);
  const [payment, setPayment] = useState<PaymentTypeInRD | undefined>();
  const [chosenPayment, setChosenPayment] = useState<
    PaymentTypeInRD | undefined
  >();
  const [paymentsTotalCount, setPaymentsTotalCount] = useState<number>(3);
  const [totalSum, setTotalSum] = useState<number>(0);
  const [totalIncome, setTotalIncome] = useState<number>(0);
  const [totalExpense, setTotalExpense] = useState<number>(0);
  const [accounts, setAccounts] = useState<IdNameType[]>([]);
  const [articles, setArticles] = useState<IdNameType[]>([]);
  const [methods, setMethods] = useState<IdNameType[]>([]);

  const [loginForSearch, setLoginForSearch] = useState("");
  const [direction, setDirection] = useState(-1);
  const [status, setStatus] = useState(-1);
  const [account, setAccount] = useState(-1);
  const [article, setArticle] = useState(-1);
  const [method, setMethod] = useState(-1);
  const [dateFrom, setDateFrom] = useState<string>();
  // new Date().toISOString().split("T")[0]
  const [dateTo, setDateTo] = useState("");
  const [isImportantFinance, setIsImportantFinance] = useState<boolean>(false);
  const [sort, setSort] = useState<number | SortType>(1);
  const [isNeedUpdate, setIsNeedUpdate] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenTransferModal,
    onOpen: onOpenTransferModal,
    onClose: onCloseTransferModal,
  } = useDisclosure();

  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: itemPerPage,
    count: paymentsTotalCount,
  });
  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");

  useEffect(() => {
    getInfoForFilter();
  }, []);

  useEffect(() => {
    let idT: any;
    if (params?.login) {
      idT = setTimeout(() => {
        if (params?.login) {
          setLoginForSearch(params.login);
        }
      }, 1000);
    }
    return () => {
      clearTimeout(idT);
    };
  }, [params?.login]);

  useEffect(() => {
    // console.log("page", page);
    // console.log("loginForSearch", loginForSearch);
    // console.log("direction", direction);
    // console.log("status", status);
    // console.log("account", account);
    // console.log("article", article);
    // console.log("method", method);
    // console.log("dateFrom", dateFrom);
    // console.log("dateTo", dateTo);
    // console.log("isImportantFinance", isImportantFinance);
    // console.log("sort", sort);
    // console.log("isNeedUpdate", isNeedUpdate);
    getPayments();
  }, [
    page,
    loginForSearch,
    direction,
    status,
    account,
    article,
    method,
    dateFrom,
    dateTo,
    isImportantFinance,
    sort,
    isNeedUpdate,
  ]);

  // const []

  const getPayments = async () => {
    const rd: PaymentTypeOutRD = {
      take: itemPerPage,
      skip: 0,
      from: dateFrom,

      sort: sort,
    };
    if (page > 0) {
      rd.skip = (page - 1) * itemPerPage;
    }
    if (direction >= 0) {
      rd.direction = direction;
    }
    if (status >= 0) {
      rd.paymentStatus = status;
    }
    if (account >= 0) {
      rd.paymentAccountId = account;
    }
    if (article >= 0) {
      rd.paymentArticleId = article;
    }
    if (method >= 0) {
      rd.paymentSystemId = method;
    }
    if (loginForSearch) {
      rd.login = loginForSearch;
    }
    if (dateFrom) {
      rd.from = dateFrom;
    }
    if (dateTo) {
      rd.to = dateTo;
    }
    if (isImportantFinance) {
      rd.onlyPaid = true;
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response: AxiosResponse = await $api.post(
        "/api/admin/Payment/all",
        rd,
        config
      );
      const data: PaymentTypeInRD[] = response.data?.payments;
      // console.log("response /api/Payments/all:", response);
      // console.log(`rd :${rd.login}  ${new Date().toString()}`);
      setPayments(data);
      setPaymentsTotalCount(response?.data?.totalCount);
      setTotalSum(response?.data?.totalSum);
      setTotalIncome(response?.data?.totalIncome);
      setTotalExpense(response?.data?.totalExpense);
    } catch (e) {
      console.error(e);
    }
  };

  const getInfoForFilter = async () => {
    try {
      const res = await $api.get("/api/payment-account");
      const accounts: IdNameType[] = res.data;
      setAccounts(accounts);
      const res1 = await $api.get("api/payment-article");
      const articles: IdNameType[] = res1.data;
      // console.log(articles);
      setArticles(articles);
      const res2 = await $api.get("/api/payment-system");
      const methods: IdNameType[] = res2.data;
      setMethods(methods);
      // console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   console.log("accounts", accounts);
  //   console.log("articles", articles);
  // }, [articles, accounts]);

  const getColor = (payment: PaymentTypeInRD) => {
    if (payment.sum >= 0) {
      return "#00FF3833";
    } else if (payment.sum < 0) {
      return "#FF000033";
    }
    return "green.200";
  };
  const getTdBlur = (id: number) => {
    if (!chosenPayment) {
      return "";
    } else if (id === chosenPayment.id) {
      return "";
    } else return "2px";
  };

  return (
    <>
      <PaymentItem
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        payment={payment}
        setPayment={setPayment}
        isNeedUpdate={isNeedUpdate}
        setIsNeedUpdate={setIsNeedUpdate}
        accounts={accounts}
        methods={methods}
        statuses={statuses}
        articles={articles}
      />
      <PaymentTransferItem
        isOpen={isOpenTransferModal}
        onOpen={onOpenTransferModal}
        onClose={onCloseTransferModal}
        isNeedUpdate={isNeedUpdate}
        setIsNeedUpdate={setIsNeedUpdate}
        accounts={accounts}
      />
      <Box
        m={8}
        fontSize={[12, 8, 10, 12, 14]}
        boxShadow="lg"
        p="8"
        rounded="md"
        bg="white"
      >
        <Wrap fontSize={[12, 8, 10, 12, 14]}>
          <Box ml={4}>
            <Text fontSize={[8, 12]}>Направление</Text>
            <InputGroup>
              <Select
                border="1px solid #3786e5"
                variant="primary"
                size="lg"
                color="gray.500"
                borderRadius="xl"
                fontSize={[12, 8, 10, 12, 14]}
                value={direction}
                minW={[150, 150]}
                onChange={(e) => {
                  setDirection(+e.target.value);
                  setPage(1);
                }}
              >
                {Directions.map((elem) => (
                  <option key={elem.id} value={elem.value}>
                    {elem.desc}
                  </option>
                ))}
              </Select>
              <InputRightElement>
                <CheckIcon color="blue.500" />
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Статус</Text>
            <InputGroup>
              <Select
                border="1px solid #3786e5"
                variant="primary"
                size="lg"
                color="gray.500"
                borderRadius="xl"
                fontSize={[12, 8, 10, 12, 14]}
                minW={[150, 150]}
                value={status}
                onChange={(e) => {
                  setStatus(+e.target.value);
                  setPage(1);
                }}
              >
                {PaymentStatuses.map((elem) => (
                  <option key={elem.id} value={elem.value}>
                    {elem.desc}
                  </option>
                ))}
              </Select>
              <InputRightElement>
                <CheckIcon color="blue.500" />
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Счет</Text>
            <InputGroup>
              <Select
                border="1px solid #3786e5"
                variant="primary"
                size="lg"
                color="gray.500"
                borderRadius="xl"
                fontSize={[12, 8, 10, 12, 14]}
                minW={[150, 150]}
                value={account}
                onChange={(e) => {
                  setAccount(+e.target.value);
                  setPage(1);
                }}
              >
                <option value={-1}>Все</option>
                {accounts?.length > 0 &&
                  accounts.map((elem) => (
                    <option key={elem.id} value={elem.id}>
                      {elem.name}
                    </option>
                  ))}
              </Select>
              <InputRightElement>
                <CheckIcon color="blue.500" />
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Метод оплаты</Text>
            <InputGroup>
              <Select
                border="1px solid #3786e5"
                variant="primary"
                size="lg"
                color="gray.500"
                borderRadius="xl"
                fontSize={[12, 8, 10, 12, 14]}
                minW={[150, 150]}
                value={method}
                onChange={(e) => {
                  setMethod(+e.target.value);
                  setPage(1);
                }}
              >
                <option value={-1}>Все</option>
                {methods?.length > 0 &&
                  methods.map((elem) => (
                    <option key={elem.id} value={elem.id}>
                      {elem.name}
                    </option>
                  ))}
              </Select>
              <InputRightElement>
                <CheckIcon color="blue.500" />
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Статья</Text>
            <InputGroup>
              <Select
                border="1px solid #3786e5"
                variant="primary"
                size="lg"
                color="gray.500"
                borderRadius="xl"
                fontSize={[12, 8, 10, 12, 14]}
                value={article}
                onChange={(e) => {
                  setArticle(+e.target.value);
                  setPage(1);
                }}
              >
                <option value={-1}>Все</option>
                {articles &&
                  articles.length > 0 &&
                  articles?.map((elem) => (
                    <option key={elem.id} value={elem.id}>
                      {elem.name}
                    </option>
                  ))}
              </Select>
              <InputRightElement>
                <CheckIcon color="blue.500" />
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box ml={4}>
            <Text
              fontSize={[8, 12]}
              // mb='-4'
              // zIndex="modal"
            >
              Поиск по логину
            </Text>
            <Box>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  // placeholder="Поиск по логину"
                  minW={[213, 236]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={loginForSearch}
                  onChange={(e) => {
                    setPage(1);
                    setLoginForSearch(e.target.value);
                  }}
                />
                {loginForSearch && (
                  <InputRightElement mr="10" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setLoginForSearch("")}
                    />
                  </InputRightElement>
                )}
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Box>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Дата от </Text>
            <Flex>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="date"
                  placeholder="Дата от"
                  minW={[150, 150]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                    setPage(1);
                  }}
                />
                {dateFrom && (
                  <InputRightElement mr="0" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setDateFrom("")}
                    />
                  </InputRightElement>
                )}
                {!dateFrom && (
                  <InputRightElement>
                    <CalendarIcon color="blue.500" />
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Дата до </Text>
            <Flex>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="date"
                  placeholder="Дата до"
                  minW={[150, 150]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={dateTo}
                  onChange={(e) => {
                    setDateTo(e.target.value);
                    setPage(1);
                  }}
                />
                {dateTo && (
                  <InputRightElement mr="0" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setDateTo("")}
                    />
                  </InputRightElement>
                )}
                {!dateTo && (
                  <InputRightElement>
                    <CalendarIcon color="blue.500" />
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
          </Box>

          <Center ml={4}>
            <Checkbox
              size="lg"
              color="#6484AA"
              fontWeight="400"
              onChange={(e) => {
                setIsImportantFinance(!isImportantFinance);
                setPage(1);
              }}
            >
              Финансово важные
            </Checkbox>
          </Center>

          <Spacer />
          <Center m="2">
            <Button
              size="lg"
              mr="4"
              mt="4"
              fontSize={[12, 14]}
              bg="#3786e5"
              color="white"
              onClick={() => onOpen()}
            >
              Добавить платеж
            </Button>
            <Button
              size="lg"
              fontSize={[12, 14]}
              bg="#3786e5"
              color="white"
              mt="4"
              onClick={() => onOpenTransferModal()}
            >
              Перевод средств
            </Button>
          </Center>
        </Wrap>
      </Box>

      {/* ***************************************************************************************HEADER */}

      <Wrap m={8} justify={isLargerThan500 ? "end" : "start"}>
        <Box minWidth={[220, 220]}>
          <Heading
            as="h2"
            mt={6}
            size="xl"
            mb="4"
            color="#6484AA"
            textAlign="start"
          >
            Платежи
          </Heading>
        </Box>
        <Spacer />

        <Box
          boxShadow="lg"
          p="6"
          rounded="md"
          bg="white"
          minWidth={[190, 220]}
          minHeight={20}
        >
          <Center>
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`Найдено: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${paymentsTotalCount} `}
            </Text>
          </Center>
        </Box>
        <Box
          boxShadow="lg"
          p="6"
          rounded="md"
          bg="white"
          minWidth={[190, 220]}
          minHeight={20}
          ml="4"
        >
          <Center>
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`Приход: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${getNumberWithSpaces(totalIncome)} `}
            </Text>
          </Center>
        </Box>
        <Box
          boxShadow="lg"
          p="6"
          rounded="md"
          bg="white"
          minHeight={20}
          ml="4"
          minWidth={[190, 220]}
        >
          <Center ml="4">
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`Расход: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${getNumberWithSpaces(totalExpense)} `}
            </Text>
          </Center>
        </Box>
        <Box
          boxShadow="lg"
          p="6"
          rounded="md"
          bg="white"
          minHeight={20}
          ml="4"
          minWidth={[190, 220]}
        >
          <Center ml="4">
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`Остаток: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${getNumberWithSpaces(totalSum)} `}
            </Text>
          </Center>
        </Box>
      </Wrap>

      {/* ********************************************************************************************TABLE HEADER */}

      <Box
        m={8}
        color="#6484AA"
        fontSize={[12, 10, 12, 14, 14]}
        fontWeight="500"
      >
        <div className="dashBoard_content__margin dashBoard_w100 dashBoard_flex dashBoard_admin__table">
          {isLargerThan500 && (
            <div
              className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
              style={{ padding: "8px" }}
            >
              <p className="dashBoard_operation__item--column9min2">
                <Center>
                  <Box mr={2}>
                    <FcDataConfiguration />
                  </Box>
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9min2">
                <Center>
                  {/* <Box mr={2}>
                    <FcBusinessContact />
                  </Box> */}
                  ID
                  {/* <Box ml={2}>
                  <FcAlphabeticalSortingZa />
                </Box> */}
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9">
                <Center
                  onClick={() => {
                    if (sort === 1) {
                      setSort(0);
                    } else setSort(1);
                  }}
                >
                  {/* <Box mr={2}>
                  <FcCalendar />
                </Box> */}
                  Дата
                  <Box ml={2}>
                    {sort === 1 && <FcAlphabeticalSortingZa size={20} />}
                    {sort === 0 && <FcAlphabeticalSortingAz size={20} />}
                    {(sort === SortType.SumAsc ||
                      sort === SortType.SumDesc) && (
                      <FcAlphabeticalSortingZa
                        size={20}
                        style={{ opacity: "0.5" }}
                      />
                    )}
                  </Box>
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9">
                <Center>
                  <Box mr={2}>
                    <FcBusinessman />
                  </Box>
                  Контрагент
                  {/* <Box ml={2}>
                  <FcAlphabeticalSortingAz />
                </Box> */}
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9">
                <Center>
                  <Box mr={2}>
                    <FcComments />
                  </Box>
                  Описание
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9">
                <Center>
                  <Box mr={2}>
                    <FcApprove />
                  </Box>
                  Статья
                  {/* <Box ml={2}>
                    <FcNumericalSorting12 />
                  </Box> */}
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9">
                <Center>
                  <Box mr={2}>
                    <FcApprove />
                  </Box>
                  Статус
                  {/* <Box ml={2}>
                    <FcNumericalSorting12 />
                  </Box> */}
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9">
                <Center
                  onClick={() => {
                    if (sort === SortType.SumAsc) {
                      setSort(SortType.SumDesc);
                    } else if (sort === SortType.SumDesc) {
                      setSort(SortType.SumAsc);
                    } else setSort(SortType.SumDesc);
                  }}
                >
                  <Box mr={2}>
                    <FcMoneyTransfer />
                  </Box>
                  Сумма
                  <Box ml={2}>
                    {sort === SortType.SumAsc && (
                      <FcNumericalSorting12 size={20} />
                    )}
                    {sort === SortType.SumDesc && (
                      <FcNumericalSorting21 size={20} />
                    )}
                    {(sort === 0 || sort === 1) && (
                      <FcNumericalSorting21
                        size={20}
                        style={{ opacity: "0.5" }}
                      />
                    )}
                  </Box>
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9">
                <Center>
                  {/* <Box mr={2}>
                  <FcMoneyTransfer />
                </Box> */}
                  Редактор
                </Center>
              </p>
            </div>
          )}

          {/* ************************************************************************************************DATA */}

          {myPayments &&
            myPayments.length > 0 &&
            myPayments.map((item, index) => (
              <Box
                w="100%"
                key={item.id}
                bg={getColor(item)}
                fontSize={[12, 8, 10, 12, 14]}
                color="#000000"
                borderBottom="1px solid #dfdfdf"
              >
                <div
                  key={item.id}
                  className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
                >
                  <p className="dashBoard_operation__item--column9min2">
                    <Center>
                      <PaymentMenu
                        item={item}
                        setChosenItem={setChosenPayment}
                        setItem={setPayment}
                        onOpen={onOpen}
                        setLoginForSearch={setLoginForSearch}
                      />
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column9min2">
                    <Center
                      filter="auto"
                      blur={getTdBlur(item.id)}
                      onClick={() => {
                        if (sort === 1) {
                          setSort(0);
                        } else setSort(1);
                      }}
                      fontWeight={sort == 0 || sort == 1 ? "700" : "500"}
                    >
                      {!isLargerThan500 && "ID: "}
                      {item?.id}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Дата: "}
                      <Moment format="DD:MM:YY, hh:mm:ss">{item.date}</Moment>
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Логин: "}
                      <Box
                        color="blue.500"
                        as={motion.button}
                        onClick={() => setLoginForSearch(item?.user?.login)}
                      >
                        {item?.user?.login}
                      </Box>
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Box
                      filter="auto"
                      blur={getTdBlur(item.id)}
                      textAlign="center"
                    >
                      {!isLargerThan500 && "Описание: "}
                      {item?.descriptions.find(
                        (e) => e.lang === userData?.interfaceLang
                      )?.desc ??
                        item?.descriptions.find((e) => e.lang === LangEnum.Ru)
                          ?.desc}
                    </Box>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Box
                      filter="auto"
                      blur={getTdBlur(item.id)}
                      textAlign="center"
                    >
                      {!isLargerThan500 && "Статья: "}
                      {item?.article?.name}
                    </Box>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Статус: "}
                      {item?.status?.name}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Center
                      filter="auto"
                      blur={getTdBlur(item.id)}
                      onClick={() => {
                        if (sort === SortType.SumAsc) {
                          setSort(SortType.SumDesc);
                        } else if (sort === SortType.SumDesc) {
                          setSort(SortType.SumAsc);
                        } else setSort(SortType.SumDesc);
                      }}
                      fontWeight={
                        sort == SortType.SumAsc || sort == SortType.SumDesc
                          ? "700"
                          : "500"
                      }
                    >
                      {item?.sum}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Редактор: "}
                      {item?.editor}
                    </Center>
                  </p>
                </div>
              </Box>
            ))}

          {paymentsTotalCount > itemPerPage && (
            <Center fontSize="12">
              <div className="pagination">
                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={prevPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === 1}
                >
                  Назад
                </Button>
                <button
                  onClick={() => setPage(1)}
                  className={`page ${page === 1 && "disabled"}`}
                >
                  1
                </button>
                {gaps.before ? "..." : null}

                {gaps.paginationGroup.map((el) => (
                  <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                  >
                    {el}
                  </button>
                ))}
                {gaps.after ? "..." : null}
                <button
                  onClick={() => setPage(totalPages)}
                  className={`page ${page === totalPages && "disabled"}`}
                >
                  {totalPages}
                </button>

                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={nextPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === totalPages}
                >
                  Вперед
                </Button>
              </div>
            </Center>
          )}
        </div>
      </Box>

      {/* <TableContainer w="100%">
        <Table variant="simple" fontSize={16}>
          <TableCaption>
            <Center>
              <div className="pagination">
                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={prevPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === 1}
                >
                  Назад
                </Button>
                <button
                  onClick={() => setPage(1)}
                  className={`page ${page === 1 && "disabled"}`}
                >
                  1
                </button>
                {gaps.before ? "..." : null}

                {gaps.paginationGroup.map((el) => (
                  <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                  >
                    {el}
                  </button>
                ))}
                {gaps.after ? "..." : null}
                <button
                  onClick={() => setPage(totalPages)}
                  className={`page ${page === totalPages && "disabled"}`}
                >
                  {totalPages}
                </button>

                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={nextPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === totalPages}
                >
                  Вперед
                </Button>
              </div>
            </Center>
          </TableCaption>

          <Thead>
            <Tr h={20}>
              <Th
                color="#174073"
                fontSize={[10, 12, 14]}
                overflowWrap="break-word"
              >
                <Center>
                  <Text fontWeight="bold">ID</Text>
                </Center>
              </Th>

              <Th
                color="#174073"
                fontSize={[10, 12, 14]}
                overflowWrap="break-word"
              >
                <Center>
                  <Box mr={2}>
                    <FcCalendar size={20} />
                  </Box>
                  <Text fontWeight="bold">Дата платежа</Text>
                </Center>
              </Th>

              <Th
                color="#174073"
                fontSize={[10, 12, 14]}
                overflowWrap="break-word"
              >
                <Center>
                  <Box mr={2}>
                    <FcBusinessman size={20} />
                  </Box>
                  <Text fontWeight="bold">Контрагент</Text>
                </Center>
              </Th>

              <Th
                color="#174073"
                fontSize={[10, 12, 14]}
                overflowWrap="break-word"
              >
                <Center>
                  <Box mr={2}>
                    <MdOutlinePublic size={20} />
                  </Box>
                  <Text fontWeight="bold">Описание</Text>
                </Center>
              </Th>

              <Th color="#174073" fontSize={[10, 12, 14]}>
                <Center>
                  <Box mr={2}>
                    <MdOutlineDateRange size={20} />
                  </Box>
                  Статья
                </Center>
              </Th>

              <Th color="#174073" fontSize={[10, 12, 14]}>
                <Center>
                  <Box mr={2}>
                    <MdOutlineDateRange size={20} />
                  </Box>
                  Статус
                </Center>
              </Th>

              <Th color="#174073" fontSize={[10, 12, 14]}>
                <Center>
                  <Box mr={2}>
                    <MdOutlineDateRange size={20} />
                  </Box>
                  Сумма
                </Center>
              </Th>
              <Th color="#174073" fontSize={[10, 12, 14]}>
                <Center>
                  <Box mr={2}>
                    <FaRegMoneyBillAlt size={20} />
                  </Box>
                  Редактор
                </Center>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {testData
              .slice(firstContentIndex, lastContentIndex)
              .map((payment, index) => (
                <Tr
                  h={20}
                  key={payment.id}
                  // bg={getColor(payment)}
                >
                  <Td>
                    <Center>{payment.id}</Center>
                  </Td>
                  <Td>
                    <Center>{payment.date}</Center>
                  </Td>
                  <Td color="#2b6cb0">
                    <Link to="/dashboard">
                      <Center>{payment.login}</Center>
                    </Link>
                  </Td>
                  <Td>
                    <Center>{payment.desc}</Center>
                  </Td>
                  <Td>
                    <Center>{payment.article}</Center>
                  </Td>
                  <Td>
                    <Center>{payment.status}</Center>
                  </Td>
                  <Td>
                    <Center>{payment.sum}</Center>
                  </Td>
                  <Td>
                    <Center>{payment.author}</Center>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer> */}
      {/* <SketchPicker /> */}
    </>
  );
};

export default PaymentList;
