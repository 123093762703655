import React, { useEffect, useLayoutEffect, useState } from "react";
import { News } from "../../../Components/LK/News/News";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";
import {
  getPaymentAccount,
  getPaymentArticle,
  getPaymentHistory,
} from "../../../features/payment/paymentSlice";

import { usePagination } from "../../../hooks/pagination/usePagination";
import "../../../hooks/pagination/pagination.scss";

import {
  Button,
  Text,
  Box,
  Center,
  Flex,
  Spacer,
  Wrap,
} from "@chakra-ui/react";
import { GlobalSpinner } from "../../../Components/LK/GlobalSpinner/GlobalSpinner";
import Meta from "../../../utils/seo/Meta";

const Operation = () => {
  const { paymentAccount, paymentArticle, paymentHistory, isLoadingPayment } =
    useAppSelector((state) => state.payment);
  const dispatch = useAppDispatch();

  // Translation i18next
  const { t } = useTranslation();

  // Scroll to top
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const itemsPerPage = 10;

  // Get payments article and accounts from server
  useEffect(() => {
    dispatch(getPaymentAccount());
    dispatch(getPaymentArticle());
  }, []);

  const [account, setAccount] = useState(1);
  const [article, setArticle] = useState("");
  const [fromDate, setFromDate] = useState("2022-01-01");
  const [toDate, setToDate] = useState();

  let date = new Date();
  let today =
    date.getFullYear() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0");

  // Pagination
  const { nextPage, prevPage, page, gaps, setPage, totalPages } = usePagination(
    {
      contentPerPage: itemsPerPage,
      count: paymentHistory.totalCount,
    }
  );

  // Get data by first load page
  useEffect(() => {
    const data = {
      accountId: account,
      // articleId: article,
      from: fromDate,
      to: toDate,
      take: itemsPerPage,
      skip: (page - 1) * itemsPerPage,
    };
    if (article) {
      data.articleId = article;
    }
    dispatch(getPaymentHistory(data));
  }, [page]);

  // Get data handler by button
  const historyHandler = () => {
    const data = {
      accountId: account,
      // articleId: article,
      from: fromDate,
      to: toDate,
      take: itemsPerPage,
      skip: (page - 1) * itemsPerPage,
    };
    if (article) {
      data.articleId = article;
    }
    dispatch(getPaymentHistory(data));
  };

  return (
    <>
      <Meta title="Personal account" />
      <div className="admin__content--content flex w100">
        <div className="w100 flex">
          <h3 className="admin__title--inner w100">
            <img
              className="admin__title--icon"
              src="/assets/img/operation-red.svg"
              alt="Иконка"
            />
            {t("Operation.operations")}
          </h3>

          <div className="content__margin flex w100 mall">
            <p className="operation__text">{t("Operation.account")}</p>

            <select
              placeholder="Select option"
              onChange={(e) => setAccount(e.target.value)}
              className="select operation__select admin"
            >
              {paymentAccount.map((payment, index) => (
                <option
                  key={payment.id}
                  value={payment.id}
                  selected={payment.id == 1}
                >
                  {payment.name}
                </option>
              ))}
            </select>
          </div>

          <div className="content__margin flexdefend w100 flexw operation__filter">
            <div className="flex mall">
              <p className="operation__text">{t("Operation.article")}</p>

              <select
                onChange={(e) => setArticle(e.target.value)}
                className="select operation__select admin"
              >
                <option value="" selected>
                  {t("Operation.all")}
                </option>
                {paymentArticle.map((payment, index) => (
                  <option
                    key={payment.id}
                    value={payment.id}
                    // selected={payment.id == 1}
                  >
                    {payment.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex mall">
              <p className="operation__text">{t("Operation.period_from")}:</p>

              <input
                onChange={(e) => setFromDate(e.target.value)}
                value={fromDate}
                type="date"
                className="operation__date admin"
              />
            </div>

            <div className="flex mall">
              <p className="operation__text">{t("Operation.to")}:</p>

              <input
                onChange={(e) => setToDate(e.target.value)}
                value={toDate}
                type="date"
                className="operation__date admin"
              />
            </div>

            <Button
              onClick={() => historyHandler()}
              // isDisabled={!fromDate || !toDate}
              colorScheme="green"
              w={[80, 60]}
              h="53px"
              m={4}
              borderRadius="10px"
              boxShadow="xl"
            >
              <Text fontSize="18px" color="#FFF">
                {t("Operation.show")}
              </Text>
            </Button>
          </div>

          <div className="content__margin flexdefend w100 flexw operation__filter">
            <Wrap justifyContent="space-between" w="100%">
              <Flex direction="row" ml={4}>
                <div className="operation__text">
                  {t("Operation.totalCount")}: &nbsp;
                </div>
                <div className="operation__text">
                  {` ${paymentHistory?.totalCount}`}
                </div>
              </Flex>
              <Flex direction="row" ml={4}>
                <div className="operation__text">
                  {t("Operation.totalIncome")}: &nbsp;
                </div>
                <div className="operation__text">
                  <Box color="green">{` ${paymentHistory?.totalIncome}`}</Box>
                </div>
              </Flex>
              <Flex direction="row" ml={4}>
                <div className="operation__text">
                  {t("Operation.totalExpense")}: &nbsp;
                </div>
                <div className="operation__text">
                  {" "}
                  <Box color="red">{` ${paymentHistory?.totalExpense}`}</Box>
                </div>
              </Flex>
              <Flex direction="row" ml={4}>
                <div className="operation__text">
                  {t("Operation.totalSum")}: &nbsp;
                </div>
                <div className="operation__text">
                  {" "}
                  <Box fontWeight="bold">{` ${paymentHistory?.totalSum}`}</Box>
                </div>
              </Flex>
            </Wrap>
          </div>

          <div className="content__margin w100 flex admin__last">
            <div className="w100 flexdef admin__last--item">
              <Center className="admin__last--text admin__last--blue operation__item--num1">
                №
              </Center>

              <Center className="admin__last--text admin__last--blue operation__item--date1">
                {t("Operation.date_and_time")}
              </Center>

              <Center className="admin__last--text admin__last--blue operation__item--article1">
                {t("Operation.article")}
              </Center>

              <Center className="admin__last--text admin__last--blue operation__item--desc1">
                {t("Operation.description")}
              </Center>

              <Center className="admin__last--text admin__last--blue operation__item--in1">
                {t("Operation.incoming")}
              </Center>

              <Center className="admin__last--text admin__last--blue operation__item--out1">
                {t("Operation.consumption")}
              </Center>
            </div>

            {paymentHistory.payments?.map((payment, index) => (
              <div className="w100 flexdef admin__last--item" key={payment.id}>
                <Center className="admin__last--text operation__item--num1">
                  {payment.id}
                </Center>

                <p className="admin__last--text operation__item--date1">
                  <Moment format="DD MMMM YYYY, hh:mm:ss">
                    {payment.date}
                  </Moment>
                </p>

                <p className="admin__last--text operation__item--article1">
                  {payment?.article?.name}
                </p>

                <p className="admin__last--text operation__item--desc1">
                  {payment.desc}
                </p>

                <p className="admin__last--text operation__item--in1">
                  <Center color="green.300" fontWeight="medium">
                    {payment.sum > 0 ? payment.sum : ""}
                  </Center>
                </p>

                <p className="admin__last--text operation__item--in1">
                  <Center color="red.500" fontWeight="medium">
                    {payment.sum < 0 ? payment.sum : ""}
                  </Center>
                </p>
              </div>
            ))}
          </div>

          {paymentHistory.totalCount <= itemsPerPage ? (
            <Box></Box>
          ) : (
            <Center w="100%">
              <div className="pagination">
                <Button
                  onClick={prevPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === 1}
                >
                  {t("Operation.back")}
                </Button>
                <button
                  onClick={() => setPage(1)}
                  className={`page ${page === 1 && "disabled"}`}
                >
                  1
                </button>
                {gaps.before ? "..." : null}

                {gaps.paginationGroup.map((el) => (
                  <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                  >
                    {el}
                  </button>
                ))}
                {gaps.after ? "..." : null}
                <button
                  onClick={() => setPage(totalPages)}
                  className={`page ${page === totalPages && "disabled"}`}
                >
                  {totalPages}
                </button>

                <Button
                  onClick={nextPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === totalPages}
                >
                  {t("Operation.next")}
                </Button>
              </div>
            </Center>
          )}
        </div>
      </div>

      <div className="admin__content--right flex w100">
        <News />
      </div>
    </>
  );
};

export default Operation;
