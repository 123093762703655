import React, { FC, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";
import { getCurrencyRates } from "../../../features/currencyRates/currencyRatesSlice";

import StockIcon from "../../../assets/img/stock-line.svg";

import { Box, Center, Flex, Image, Spinner, Text } from "@chakra-ui/react";

export const CurrencyRates: FC = () => {
  const { t } = useTranslation();

  const { rates, isLoading } = useAppSelector((state) => state.currencyRates);
  const dispatch = useAppDispatch();

  // Get currency rates from server
  useEffect(() => {
    if (rates.length === 0) {
      dispatch(getCurrencyRates());
    }
  }, []);

  return (
    <div className="admin__content--right flex w100">
      <div className="w100 flex">
        <h3 className="admin__title--inner w100">
          <img className="admin__title--icon" src={StockIcon} alt="Иконка" />
          {t("CurrencyRates.exchange_rates")}
        </h3>

        <div className="flex w100 content__margin">
          {isLoading ? (
            <Center w="100%" h="100%" mt={20}>
              <Spinner
                thickness="14px"
                speed="0.7s"
                emptyColor="#61D64A"
                color="#1c3b6a"
                w={24}
                h={24}
              />
            </Center>
          ) : (
            <Flex
              direction="column"
              alignItems="center"
              w="100%"
              bg="#fff"
              p={2}
              borderRadius="lg"
              boxShadow="2xl"
            >
              {rates.length === 0 ? (
                <Text fontSize={20} color="red.500" fontWeight="medium">
                  {t("CurrencyRates.wrong")}!
                </Text>
              ) : (
                rates.map((currency, index) => (
                  <Flex
                    key={index}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                    m={2}
                  >
                    <Flex direction="row" alignItems="center" ml={2}>
                      <Image
                        bg="teal.100"
                        borderRadius="md"
                        boxSize="50px"
                        src={currency.icon}
                      />

                      <Box ml={4}>
                        <Text fontSize="2xl" fontWeight="medium">
                          {currency.name}
                        </Text>
                        <Text fontSize="xl" color="#ACAFBE">
                          {currency.symbol}
                        </Text>
                      </Box>
                    </Flex>

                    <Flex
                      direction="column"
                      justifyContent="center"
                      alignItems="end"
                      mr={2}
                    >
                      <Text fontSize="2xl" fontWeight="medium">
                        {currency.price.toFixed(2)}
                      </Text>
                      <Text
                        fontSize="xl"
                        fontWeight="medium"
                        color={
                          currency.percentChange24h < 0 ? "#FF3158" : "#61D64A"
                        }
                      >
                        {currency.percentChange24h.toFixed(2)}%
                      </Text>
                    </Flex>
                  </Flex>
                ))
              )}
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
};
