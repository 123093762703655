import React, { useEffect, useState } from "react";
import { CurrencyRates } from "../../../Components/LK/CurrencyRates/CurrencyRates";
import { GlobalSpinner } from "../../../Components/LK/GlobalSpinner/GlobalSpinner";
import { ReplenishmentsHistory } from "../../../Components/LK/ReplenishmentsHistory/ReplenishmentsHistory";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";
import {
  getReplenishmentInvoice,
  getReplenishmentInvoiceList,
  resetInvoice,
} from "../../../features/cryptoInvoice/cryptoInvoiceSlice";
import {
  getFKCurrencies,
  getFKLink,
  resetFKLink,
} from "../../../features/freeKassa/freeKassaSlice";

import { toast } from "react-toastify";

import VisaIcon from "../../../assets/img/visa-icon.svg";
import UsdtIcon2 from "../../../assets/img/Tether_Logo.svg.png";
import MastercardIcon from "../../../assets/img/master_card_icon.svg";
import MirIcon from "../../../assets/img/Mir-logo.SVG.svg";

import QiwiIcon2 from "../../../assets/img/qiwi-logo.svg";
import PerfectIcon2 from "../../../assets/img/perfect-money-logo-vector2.svg";
import IoMoney2 from "../../../assets/img/io.svg";
import MTBIcon from "../../../assets/img/mtb-logo.png";

import { TimerSixtySecond } from "./TimerSixtySecond";
import { TimerSixtyMinutes } from "./TimerSixtyMinutes";

import {
  Button,
  Flex,
  Image,
  Input,
  Select,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Center,
  Divider,
  useClipboard,
} from "@chakra-ui/react";
import Moment from "react-moment";
import { ReplenishmentsInvoiceHistory } from "../../../Components/LK/ReplenishmentsInvoiceHistory/ReplenishmentsIvoiceHistory";
import Meta from "../../../utils/seo/Meta";

export const InputMoney = () => {
  // Translation i18next
  const { t } = useTranslation();

  const { userData } = useAppSelector((state) => state.userInfo);
  const { course } = useAppSelector((state) => state.mtb);
  const { currencies, isLoadingFKMethods, linkFK } = useAppSelector(
    (state) => state.freeKassa
  );
  const { invoice, isLoadingInvoice } = useAppSelector(
    (state) => state.cryptoInvoice
  );
  const dispatch = useAppDispatch();

  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [copyValue, setCopyValue] = useState("");
  const { onCopy } = useClipboard(copyValue);
  const initialRef = React.useRef(null);

  // Checking available freekassa  payment methods
  useEffect(() => {
    dispatch(getFKCurrencies());
  }, []);

  const [usdtAmount, setUsdtAmount] = useState<number | string>("");
  const [mtbUsdtAmount, setMtbUsdtAmount] = useState<number | string>("");
  const [updateInvoiceList, setUpdateInvoiceList] = useState(false);
  const [isLoadingUSDT, setIsLoadingUSDT] = useState(false);

  // console.log("isLoadingUSDT>>>", isLoadingUSDT);

  const [visaAmount, setVisaAmount] = useState<number | string>("");
  const [mtbVisaAmount, setMtbVisaAmount] = useState<number | string>("");
  const [visaIdentifier, setVisaIdentifier] = useState<number>(4);
  const [visaCurrency, setVisaCurrency] = useState<string>("RUB");

  const [masterCardAmount, setMasterCardAmount] = useState<number | string>("");
  const [mtbMasterAmount, setMtbMasterAmount] = useState<number | string>("");
  const [masterCardIdentifier, setMasterCardIdentifier] = useState<number>(8);
  const [masterCardCurrency, setMasterCardCurrency] = useState<any>("");

  const [mirAmount, setMirAmount] = useState<number | string>("");
  const [mtbMirAmount, setMtbMirAmount] = useState<number | string>("");

  const [qiwiAmount, setQiwiAmount] = useState<number | string>("");
  const [mtbQiwiAmount, setMtbQiwiAmount] = useState<number | string>("");

  const [perfectAmount, setPerfectAmount] = useState<number | string>("");
  const [mtbPerfectAmount, setMtbPerfectAmount] = useState<number | string>("");

  const [yooAmount, setYooAmount] = useState<number | string>("");
  const [mtbYooAmount, setMtbYooAmount] = useState<number | string>("");

  // Payments Link Freekassa
  const paymentHandler = (amount: number | string, i: number) => {
    dispatch(resetFKLink());
    const data = {
      amount: amount,
      i: i,
    };
    dispatch(getFKLink(data));
    setVisaAmount("");
    setMtbVisaAmount("");
    setMasterCardAmount("");
    setMtbMasterAmount("");
    setMirAmount("");
    setMtbMirAmount("");
    setQiwiAmount("");
    setMtbQiwiAmount("");
    setPerfectAmount("");
    setMtbPerfectAmount("");
    setYooAmount("");
    setMtbYooAmount("");
  };

  // Open link and reset link from backend
  useEffect(() => {
    if (linkFK !== "") {
      window.open(linkFK.link);
    }
    dispatch(resetFKLink());
  }, [paymentHandler]);

  useEffect(() => {
    switch (visaIdentifier) {
      case 4:
        setVisaCurrency("RUB");
        break;
      case 7:
        setVisaCurrency("UAH");
        break;
      case 11:
        setVisaCurrency("EUR");
        break;
      case 32:
        setVisaCurrency("USD");
        break;
      default:
        setVisaCurrency("RUB");
    }
  }, [visaIdentifier]);

  useEffect(() => {
    switch (masterCardIdentifier) {
      case 8:
        setMasterCardCurrency("RUB");
        break;
      case 9:
        setMasterCardCurrency("UAH");
        break;
      default:
        setMasterCardCurrency("RUB");
    }
  }, [masterCardIdentifier]);

  const handleModalClose = () => {
    setUsdtAmount("");
    setMtbUsdtAmount("");
    dispatch(resetInvoice());
    setUpdateInvoiceList(true);
    onClose();
  };

  // Modal wallet and sum copy handler
  const copyHandler = (value: any) => {
    window.navigator.clipboard.writeText(value);
    toast.success("Скопировано в буфер обмена!");
  };

  // Timer 1 minutes timer
  const time60Seconds = new Date();
  time60Seconds.setSeconds(time60Seconds.getSeconds() + 60);

  // Timer 60 minutes timer
  const time60Minutes = new Date();
  time60Minutes.setSeconds(time60Minutes.getSeconds() + 3600);

  // Пополнение USDT
  const usdtReplenishmentHandler = async () => {
    const invoice = {
      amount: mtbUsdtAmount,
    };

    await dispatch(getReplenishmentInvoice(invoice));

    onOpen();
    setIsLoadingUSDT(false);
    setUsdtAmount("");
  };

  // if (isLoadingFKMethods || isLoadingInvoice) {
  //   return <GlobalSpinner />;
  // }

  return (
    <>
      <Meta title="Personal account" />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        size="6xl"
        isCentered
        initialFocusRef={initialRef}
      >
        <ModalOverlay
          backdropBlur="5px"
          bg="blackAlpha.300"
          backdropFilter="blur(10px)"
        />
        <ModalContent>
          <ModalHeader
            fontSize={["3xl", "4xl"]}
            color="#61D64A"
            fontWeight="medium"
          >
            <Center>
              {t("InputMoneyPage.invoice_for_payment_with_crypto_token_№")}{" "}
              {invoice?.id}!
            </Center>
          </ModalHeader>

          <ModalBody fontSize="2xl" fontWeight="semibold">
            <Center fontSize={["xl", "3xl"]}>
              {t("InputMoneyPage.replenishment_of_the user's_personal_account")}{" "}
              &nbsp;
              <Text fontWeight="semibold" color="red.500">
                {invoice?.user?.name
                  ? invoice?.user?.name
                  : invoice?.user?.login}
              </Text>
            </Center>

            <Divider />
            <Center m={2}>
              <Text>{t("InputMoneyPage.you_can_pay_at")}&nbsp;</Text>{" "}
              <Text color="red.500" fontWeight="bold">
                {invoice.tokenName} {invoice.network}{" "}
              </Text>
            </Center>

            <Center m={2}>
              <Text fontSize={["xl", "2xl"]}>
                {t("InputMoneyPage.payment_is_made")}
              </Text>
            </Center>

            <Flex m={2} w="100%" textAlign="center" justifyContent="center">
              <Text fontSize={["xl", "2xl"]}>
                {t("InputMoneyPage.application_created")}
              </Text>
              &nbsp;
              <Text fontSize={["xl", "2xl"]} color="red.500" fontWeight="bold">
                <Moment format="DD MMMM YYYY, HH:mm:ss">
                  {invoice?.createdAt}
                </Moment>
              </Text>
            </Flex>

            <Flex m={2} w="100%" textAlign="center" justifyContent="center">
              <Text fontSize={["xl", "2xl"]}>
                {t("InputMoneyPage.application_expires")}
              </Text>
              &nbsp;
              <Text fontSize={["xl", "2xl"]} color="red.500" fontWeight="bold">
                <Moment format="DD MMMM YYYY, HH:mm:ss">
                  {invoice?.expiredAt}
                </Moment>
              </Text>
            </Flex>

            <Center m={2}>
              <Text>
                {t("InputMoneyPage.the_account_is_valid_for")} &nbsp;{" "}
              </Text>
              <TimerSixtyMinutes expiryTimestamp={time60Minutes} />
            </Center>

            <Center m={2}>
              <Text>{t("InputMoneyPage.time_of_receipt")} </Text>
            </Center>

            <Flex direction="row" m={2} alignItems="center">
              <Text>{t("InputMoneyPage.wallet")}</Text>
              <Input
                value={invoice.address}
                placeholder="USDT Wallet"
                fontSize={["xl", "2xl"]}
                color="red.500"
                fontWeight="bold"
                w={["60%", "75%"]}
                h={14}
                variant="outline"
                mx={2}
                readOnly
              />
              <Button
                onClick={() => copyHandler(invoice.address)}
                ref={initialRef}
                colorScheme="blue"
                h={14}
                fontSize="xl"
              >
                {t("InputMoneyPage.copy")}
              </Button>
            </Flex>

            <Flex direction="row" m={2} alignItems="center">
              <Text mr={6}>{t("InputMoneyPage.sum")}</Text>
              <Input
                value={invoice?.expectedTokenAmount}
                placeholder="USDT TRC20"
                fontSize={["xl", "2xl"]}
                color="red.500"
                fontWeight="bold"
                w={["60%", "75%"]}
                h={14}
                variant="outline"
                mx={2}
                readOnly
              />
              <Button
                onClick={() => copyHandler(invoice?.expectedTokenAmount)}
                colorScheme="blue"
                h={14}
                fontSize="xl"
              >
                {t("InputMoneyPage.copy")}
              </Button>
            </Flex>

            <Center m={2}>
              <Text>
                {t("InputMoneyPage.on_account_you_will_receive")} &nbsp;{" "}
                {invoice?.expectedFiatAmount} $
              </Text>
            </Center>

            <Center w="100%">
              <Text fontSize="3xl" color="black">
                {t("InputMoneyPage.checking_via")}
              </Text>
            </Center>

            <Center>
              <TimerSixtySecond expiryTimestamp={time60Seconds} />
            </Center>

            <Center>
              <Text fontSize="3xl" color="black">
                {t("InputMoneyPage.status")} &nbsp;
              </Text>
              <Text color="green.500" fontSize="3xl" fontWeight="semibold">
                {invoice?.state?.name}
              </Text>
            </Center>

            <Center m={2}>
              <Text
                color="red.500"
                fontSize={["xl", "2xl"]}
                fontWeight="semibold"
              >
                {t("InputMoneyPage.make_sure")}
                {invoice.tokenName} {invoice.network}.
                {t("InputMoneyPage.fund_lost")}
              </Text>
            </Center>

            <Center w="100%" mt={4}>
              <Button
                onClick={() => handleModalClose()}
                w={60}
                h={20}
                mx={2}
                colorScheme="red"
                fontSize="3xl"
              >
                {t("InputMoneyPage.cancel")}
              </Button>

              <Button
                onClick={() => handleModalClose()}
                w={60}
                h={20}
                mx={2}
                colorScheme="green"
                fontSize="3xl"
              >
                {t("InputMoneyPage.done")}
              </Button>
            </Center>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <div className="admin__content--content flex w100">
        <div className="w100 flexcenter">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            textAlign="center"
            w="100%"
          >
            <h3 className="admin__title--inner w100">
              <img
                className="admin__title--icon"
                src="/assets/img/wallet-red.svg"
                alt="Иконка"
              />
              {t("InputMoneyPage.top_up_account")}
            </h3>
            <Text
              fontSize={14}
              w={["80%", "50%"]}
              color="#222"
              fontWeight="500"
            >
              {t("InputMoneyPage.enter_amount")}
            </Text>
          </Flex>

          <div className="flexdef w100 content__margin pay__content">
            <Flex
              m={2}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <p className="pay__text">{t("InputMoneyPage.top_up_via")}</p>
              <Image mt={2} boxSize="50px" src={UsdtIcon2} />
            </Flex>

            <div className="flex">
              <p className="pay__text">{t("InputMoneyPage.commission")}</p>
              <p className="pay__title mt1">0%</p>
            </div>

            <Flex m={2} direction="column" justifyContent="space-between">
              <Text color="#fff" fontWeight="medium">
                {t("InputMoneyPage.currency")}
              </Text>
              <Select
                // onChange={(e) => (+e.target.value)}
                w={[80, 60]}
                h={[24, 20]}
                m={2}
                color="#000"
                bg="#fff"
                fontSize={16}
                fontWeight="normal"
              >
                <option value="4">USDT TRC20</option>
                {/* <option value="7">USDT ERC20</option> */}
              </Select>
            </Flex>

            {/* <Flex
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              m={2}
            >
              <p className="pay__text">{t("InputMoneyPage.amount_in_MTB")}</p>
              <Input
                value={mtbUsdtAmount}
                onChange={(e: any) => {
                  if (isFinite(Number(e.target.value))) {
                    setMtbUsdtAmount(e.target.value);
                    setUsdtAmount((+e.target.value * course?.rate).toFixed(2));
                  }
                }}
                w={[80, 60]}
                h={[24, 20]}
                m={2}
                color="#000"
                bg="#fff"
                fontSize={16}
                fontWeight="normal"
                placeholder="100 $"
              />
            </Flex> */}

            <Flex
              m={2}
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <p className="pay__text">{t("InputMoneyPage.amount_in_USD")}</p>
              <Flex direction={["column", "row"]}>
                <Input
                  value={usdtAmount}
                  onChange={(e) => {
                    if (isFinite(Number(e.target.value))) {
                      setUsdtAmount(e.target.value);
                      setMtbUsdtAmount(
                        (+e.target.value / course?.rate).toFixed(2)
                      );
                    }
                  }}
                  // type="number"
                  w={[80, 60]}
                  h={[24, 20]}
                  m={2}
                  color="#000"
                  bg="#fff"
                  fontSize={16}
                  fontWeight="normal"
                  placeholder="100 USD"
                />

                <Button
                  onClick={() => {
                    setIsLoadingUSDT(true);
                    usdtReplenishmentHandler();
                  }}
                  isDisabled={!usdtAmount}
                  isLoading={isLoadingUSDT}
                  w={[80, 60]}
                  h={[24, 20]}
                  m={2}
                  colorScheme="green"
                  borderRadius="10px"
                  boxShadow="2xl"
                >
                  <Text fontWeight="medium" color="#fff" fontSize={20}>
                    {t("InputMoneyPage.top_up")}
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </div>

          {currencies.includes(4) && (
            <div className="flexdef w100 content__margin pay__content">
              <Flex
                m={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <p className="pay__text">{t("InputMoneyPage.top_up_via")}</p>
                <Image w={40} h={20} src={VisaIcon} />
              </Flex>

              <div className="flex">
                <p className="pay__text">{t("InputMoneyPage.commission")}</p>
                <p className="pay__title mt1">5%</p>
              </div>

              <Flex m={2} direction="column" justifyContent="space-between">
                <Text color="#fff" fontWeight="medium">
                  {t("InputMoneyPage.currency")}
                </Text>
                <Select
                  onChange={(e) => setVisaIdentifier(+e.target.value)}
                  w={[80, 60]}
                  h={[24, 20]}
                  m={2}
                  color="#000"
                  bg="#fff"
                  fontSize={16}
                  fontWeight="normal"
                >
                  {currencies.includes(4) && (
                    <option value="4">VISA RUB</option>
                  )}

                  {currencies.includes(7) && (
                    <option value="7">VISA UAH</option>
                  )}

                  {currencies.includes(11) && (
                    <option value="11">VISA EUR</option>
                  )}

                  {currencies.includes(32) && (
                    <option value="32">VISA USD</option>
                  )}
                </Select>
              </Flex>

              {/* <Flex
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                m={2}
              >
                <p className="pay__text">{t("InputMoneyPage.amount_in_MTB")}</p>
                <Input
                  value={mtbVisaAmount}
                  onChange={(e) => {
                    if (isFinite(Number(e.target.value))) {
                      setMtbVisaAmount(e.target.value);
                      setVisaAmount(
                        (+e.target.value * course?.rate).toFixed(2)
                      );
                    }
                  }}
                  w={[80, 60]}
                  h={[24, 20]}
                  m={2}
                  color="#000"
                  bg="#fff"
                  fontSize={16}
                  fontWeight="normal"
                  placeholder="100 $"
                />
              </Flex> */}

              <Flex
                m={2}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <p className="pay__text">{t("InputMoneyPage.amount_in_USD")}</p>
                <Flex direction={["column", "row"]}>
                  <Input
                    value={visaAmount}
                    onChange={(e) => {
                      if (isFinite(Number(e.target.value))) {
                        setVisaAmount(e.target.value);
                        setMtbVisaAmount(
                          (+e.target.value / course?.rate).toFixed(2)
                        );
                      }
                    }}
                    // type="number"
                    w={[80, 60]}
                    h={[24, 20]}
                    m={2}
                    color="#000"
                    bg="#fff"
                    fontSize={16}
                    fontWeight="normal"
                    placeholder="100 USD"
                  />

                  <Button
                    onClick={() => paymentHandler(visaAmount, visaIdentifier)}
                    isDisabled={!visaAmount}
                    w={[80, 60]}
                    h={[24, 20]}
                    m={2}
                    colorScheme="green"
                    borderRadius="10px"
                    boxShadow="2xl"
                  >
                    <Text fontWeight="medium" color="#fff" fontSize={20}>
                      {t("InputMoneyPage.top_up")}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </div>
          )}

          {currencies.includes(8) && (
            <div className="flexdef w100 content__margin pay__content">
              <Flex
                m={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <p className="pay__text">{t("InputMoneyPage.top_up_via")}</p>
                <Image w={40} h={20} src={MastercardIcon} />
              </Flex>

              <div className="flex">
                <p className="pay__text">{t("InputMoneyPage.commission")}</p>
                <p className="pay__title mt1">5%</p>
              </div>

              <Flex m={2} direction="column" justifyContent="space-between">
                <Text color="#fff" fontWeight="medium">
                  {t("InputMoneyPage.currency")}
                </Text>
                <Select
                  onChange={(e) => setMasterCardIdentifier(+e.target.value)}
                  w={[80, 60]}
                  h={[24, 20]}
                  m={2}
                  color="#000"
                  bg="#fff"
                  fontSize={16}
                  fontWeight="normal"
                >
                  {currencies.includes(8) && (
                    <option value="8">MasterCard RUB</option>
                  )}

                  {currencies.includes(9) && (
                    <option value="9">MasterCard UAH</option>
                  )}
                </Select>
              </Flex>

              {/* <Flex
                m={2}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <p className="pay__text">{t("InputMoneyPage.amount_in_MTB")}</p>
                <Input
                  value={mtbMasterAmount}
                  onChange={(e) => {
                    if (isFinite(Number(e.target.value))) {
                      setMtbMasterAmount(e.target.value);
                      setMasterCardAmount(
                        (+e.target.value * course?.rate).toFixed(2)
                      );
                    }
                  }}
                  w={[80, 60]}
                  h={[24, 20]}
                  m={2}
                  color="#000"
                  bg="#fff"
                  fontSize={16}
                  fontWeight="normal"
                  placeholder="100 $"
                />
              </Flex> */}

              <Flex
                m={2}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <p className="pay__text">{t("InputMoneyPage.amount_in_USD")}</p>
                <Flex direction={["column", "row"]}>
                  <Input
                    value={masterCardAmount}
                    onChange={(e) => {
                      if (isFinite(Number(e.target.value))) {
                        setMasterCardAmount(e.target.value);
                        setMtbMasterAmount(
                          (+e.target.value / course?.rate).toFixed(2)
                        );
                      }
                    }}
                    // type="number"
                    w={[80, 60]}
                    h={[24, 20]}
                    m={2}
                    color="#000"
                    bg="#fff"
                    fontSize={16}
                    fontWeight="normal"
                    placeholder="100 USD"
                  />

                  <Button
                    onClick={() =>
                      paymentHandler(masterCardAmount, masterCardIdentifier)
                    }
                    isDisabled={!masterCardAmount}
                    w={[80, 60]}
                    h={[24, 20]}
                    m={2}
                    colorScheme="green"
                    borderRadius="10px"
                    boxShadow="2xl"
                  >
                    <Text fontWeight="medium" color="#fff" fontSize={20}>
                      {t("InputMoneyPage.top_up")}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </div>
          )}

          {currencies.includes(12) && (
            <div className="flexdef w100 content__margin pay__content">
              <Flex
                m={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <p className="pay__text">{t("InputMoneyPage.top_up_via")}</p>
                <Image w={40} h={20} src={MirIcon} />
              </Flex>

              <div className="flex">
                <p className="pay__text">{t("InputMoneyPage.commission")}</p>
                <p className="pay__title mt1">5%</p>
              </div>

              <Flex w={64}></Flex>

              {/* <Flex
                m={2}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <p className="pay__text">{t("InputMoneyPage.amount_in_MTB")}</p>
                <Input
                  value={mtbMirAmount}
                  onChange={(e) => {
                    if (isFinite(Number(e.target.value))) {
                      setMtbMirAmount(e.target.value);
                      setMirAmount((+e.target.value * course?.rate).toFixed(2));
                    }
                  }}
                  w={[80, 60]}
                  h={[24, 20]}
                  m={2}
                  color="#000"
                  bg="#fff"
                  fontSize={16}
                  fontWeight="normal"
                  placeholder="100 $"
                />
              </Flex> */}

              <Flex
                m={2}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <p className="pay__text">{t("InputMoneyPage.amount_in_USD")}</p>
                <Flex direction={["column", "row"]}>
                  <Input
                    value={mirAmount}
                    onChange={(e) => {
                      if (isFinite(Number(e.target.value))) {
                        setMirAmount(e.target.value);
                        setMtbMirAmount(
                          (+e.target.value / course?.rate).toFixed(2)
                        );
                      }
                    }}
                    // type="number"
                    w={[80, 60]}
                    h={[24, 20]}
                    m={2}
                    color="#000"
                    bg="#fff"
                    fontSize={16}
                    fontWeight="normal"
                    placeholder="100 USD"
                  />

                  <Button
                    onClick={() => paymentHandler(mirAmount, 12)}
                    disabled={!mirAmount}
                    w={[80, 60]}
                    h={[24, 20]}
                    m={2}
                    colorScheme="green"
                    borderRadius="10px"
                    boxShadow="2xl"
                  >
                    <Text fontWeight="medium" color="#fff" fontSize={20}>
                      {t("InputMoneyPage.top_up")}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </div>
          )}

          {currencies.includes(10) && (
            <div className="flexdef w100 content__margin pay__content">
              <Flex
                m={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <p className="pay__text">{t("InputMoneyPage.top_up_via")}</p>
                <Image m={2} w={40} src={QiwiIcon2} />
              </Flex>

              <div className="flex">
                <p className="pay__text">{t("InputMoneyPage.commission")}</p>
                <p className="pay__title mt1">5%</p>
              </div>

              <Flex w={64}></Flex>

              {/* <Flex
                m={2}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <p className="pay__text">{t("InputMoneyPage.amount_in_MTB")}</p>
                <Input
                  value={mtbQiwiAmount}
                  onChange={(e) => {
                    if (isFinite(Number(e.target.value))) {
                      setMtbQiwiAmount(e.target.value);
                      setQiwiAmount(
                        (+e.target.value * course?.rate).toFixed(2)
                      );
                    }
                  }}
                  w={[80, 60]}
                  h={[24, 20]}
                  m={2}
                  color="#000"
                  bg="#fff"
                  fontSize={16}
                  fontWeight="normal"
                  placeholder="100 $"
                />
              </Flex> */}

              <Flex
                m={2}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <p className="pay__text">{t("InputMoneyPage.amount_in_USD")}</p>
                <Flex direction={["column", "row"]}>
                  <Input
                    value={qiwiAmount}
                    onChange={(e) => {
                      if (isFinite(Number(e.target.value))) {
                        setQiwiAmount(e.target.value);
                        setMtbQiwiAmount(
                          (+e.target.value / course?.rate).toFixed(2)
                        );
                      }
                    }}
                    // type="number"
                    w={[80, 60]}
                    h={[24, 20]}
                    m={2}
                    color="#000"
                    bg="#fff"
                    fontSize={16}
                    fontWeight="normal"
                    placeholder="100 USD"
                  />

                  <Button
                    onClick={() => paymentHandler(qiwiAmount, 10)}
                    disabled={!qiwiAmount}
                    w={[80, 60]}
                    h={[24, 20]}
                    m={2}
                    colorScheme="green"
                    borderRadius="10px"
                    boxShadow="2xl"
                  >
                    <Text fontWeight="medium" color="#fff" fontSize={20}>
                      {t("InputMoneyPage.top_up")}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </div>
          )}

          {currencies.includes(33) && (
            <div className="flexdef w100 content__margin pay__content">
              <Flex
                m={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <p className="pay__text">{t("InputMoneyPage.top_up_via")}</p>
                <Image m={2} boxSize="50px" src={PerfectIcon2} />
              </Flex>

              <div className="flex">
                <p className="pay__text">{t("InputMoneyPage.commission")}</p>
                <p className="pay__title mt1">5%</p>
              </div>

              <Flex w={64}></Flex>

              {/* <Flex
                m={2}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <p className="pay__text">{t("InputMoneyPage.amount_in_MTB")}</p>
                <Input
                  value={mtbPerfectAmount}
                  onChange={(e) => {
                    if (isFinite(Number(e.target.value))) {
                      setMtbPerfectAmount(e.target.value);
                      setPerfectAmount(
                        (+e.target.value * course?.rate).toFixed(2)
                      );
                    }
                  }}
                  w={[80, 60]}
                  h={[24, 20]}
                  m={2}
                  color="#000"
                  bg="#fff"
                  fontSize={16}
                  fontWeight="normal"
                  placeholder="100 $"
                />
              </Flex> */}

              <Flex
                m={2}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <p className="pay__text">{t("InputMoneyPage.amount_in_USD")}</p>
                <Flex direction={["column", "row"]}>
                  <Input
                    value={perfectAmount}
                    onChange={(e) => {
                      if (isFinite(Number(e.target.value))) {
                        setPerfectAmount(e.target.value);
                        setMtbPerfectAmount(
                          (+e.target.value / course?.rate).toFixed(2)
                        );
                      }
                    }}
                    // type="number"
                    w={[80, 60]}
                    h={[24, 20]}
                    m={2}
                    color="#000"
                    bg="#fff"
                    fontSize={16}
                    fontWeight="normal"
                    placeholder="100 USD"
                  />

                  <Button
                    onClick={() => paymentHandler(perfectAmount, 33)}
                    disabled={!perfectAmount}
                    w={[80, 60]}
                    h={[24, 20]}
                    m={2}
                    colorScheme="green"
                    borderRadius="10px"
                    boxShadow="2xl"
                  >
                    <Text fontWeight="medium" color="#fff" fontSize={20}>
                      {t("InputMoneyPage.top_up")}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </div>
          )}

          {currencies.includes(6) && (
            <div className="flexdef w100 content__margin pay__content">
              <Flex
                m={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <p className="pay__text">{t("InputMoneyPage.top_up_via")}</p>
                <Image m={2} boxSize="50px" src={IoMoney2} />
              </Flex>

              <div className="flex">
                <p className="pay__text">{t("InputMoneyPage.commission")}</p>
                <p className="pay__title mt1">5%</p>
              </div>

              <Flex w={64}></Flex>

              {/* <Flex
                m={2}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <p className="pay__text">{t("InputMoneyPage.amount_in_MTB")}</p>
                <Input
                  value={mtbYooAmount}
                  onChange={(e) => {
                    if (isFinite(Number(e.target.value))) {
                      setMtbYooAmount(e.target.value);
                      setYooAmount((+e.target.value * course?.rate).toFixed(2));
                    }
                  }}
                  w={[80, 60]}
                  h={[24, 20]}
                  m={2}
                  color="#000"
                  bg="#fff"
                  fontSize={16}
                  fontWeight="normal"
                  placeholder="100 $"
                />
              </Flex> */}

              <Flex
                m={2}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <p className="pay__text">{t("InputMoneyPage.amount_in_USD")}</p>
                <Flex direction={["column", "row"]}>
                  <Input
                    value={yooAmount}
                    onChange={(e) => {
                      if (isFinite(Number(e.target.value))) {
                        setYooAmount(e.target.value);
                        setMtbYooAmount(
                          (+e.target.value / course?.rate).toFixed(2)
                        );
                      }
                    }}
                    // type="number"
                    w={[80, 60]}
                    h={[24, 20]}
                    m={2}
                    color="#000"
                    bg="#fff"
                    fontSize={16}
                    fontWeight="normal"
                    placeholder="100 USD"
                  />

                  <Button
                    onClick={() => paymentHandler(yooAmount, 6)}
                    disabled={!yooAmount}
                    w={[80, 60]}
                    h={[24, 20]}
                    m={2}
                    colorScheme="green"
                    borderRadius="10px"
                    boxShadow="2xl"
                  >
                    <Text fontWeight="medium" color="#fff" fontSize={20}>
                      {t("InputMoneyPage.top_up")}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </div>
          )}

          {/* <div className="flexdef w100 content__margin pay__content">
            <Flex
              m={2}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <p className="pay__text">{t("InputMoneyPage.top_up_via")}</p>
              <Image m={2} w={40} h={20} objectFit="scale-down" src={MTBIcon} />
            </Flex>

            <div className="flex">
              <p className="pay__text">{t("InputMoneyPage.commission")}</p>
              <p className="pay__title mt1">0%</p>
            </div>

            <Flex w={64}></Flex>

            <Flex
              m={2}
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <p className="pay__text">{t("InputMoneyPage.amount_in_MTB")}</p>
              <Flex direction={["column", "row"]}>
                <Input
                  type="number"
                  w={[80, 60]}
                  h={[24, 20]}
                  m={2}
                  color="#000"
                  bg="#fff"
                  fontSize={16}
                  fontWeight="normal"
                  placeholder="20 "
                />

                <Button
                  isDisabled
                  w={[80, 60]}
                  h={[24, 20]}
                  m={2}
                  colorScheme="green"
                  borderRadius="10px"
                  boxShadow="2xl"
                >
                  <Text fontWeight="medium" color="#fff" fontSize={20}>
                    {t("InputMoneyPage.top_up")}
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </div> */}

          <ReplenishmentsInvoiceHistory
            updateInvoiceList={updateInvoiceList}
            setUpdateInvoiceList={setUpdateInvoiceList}
          />

          <ReplenishmentsHistory />
        </div>
      </div>

      <CurrencyRates />
    </>
  );
};
