import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../http/apiService";

export interface IUser {
  login: string;
  name: string;
  imageUrl: string;
}
export interface IReview {
  id: number;
  user: IUser;
  text: string;
  createdAt: string;
  rate: number;
}

interface IReviewState {
  publicReviews: {
    items: IReview[];
    totalCount: number;
  };
  userReviews: IReview[];
  chosenReview: IReview;
  isLoading: boolean;
  isError: boolean;
  message: any;
}

const initialState: IReviewState = {
  publicReviews: {
    items: [],
    totalCount: 0,
  },
  userReviews: [],
  chosenReview: {
    id: 1,
    user: {
      login: "",
      name: "",
      imageUrl: "",
    },
    text: "",
    createdAt: "",
    rate: 0,
  },
  isLoading: false,
  isError: false,
  message: "",
};

// Get chosen news
// export const getChosenNews = createAsyncThunk(
//   "chosenNews/post",
//   async (id: Record<string, unknown>, thunkAPI) => {
//     try {
//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       };
//       const response = await $api.post("/api/News/item", id, config);
//       return response.data;
//     } catch (error: any) {
//       console.log(error);
//       const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
//       console.log(message);
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

// Get publick reviews
export const getPublickReviews = createAsyncThunk(
  "publickReviews/post",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post("/api/Review", data, config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reducer
export const reviewSlice = createSlice({
  name: "re",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPublickReviews.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getPublickReviews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.publicReviews = action.payload;
      })
      .addCase(getPublickReviews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

// export const { } = reviewSlice.actions;
export default reviewSlice.reducer;
