import React, { FC, useEffect, useState } from "react";
import { Captcha } from "../../../Components/Home/Captcha/Captcha";
import { useNavigate } from "react-router-dom";
import { Button, Text, Fade } from "@chakra-ui/react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import $api from "../../../http/apiService";
import Meta from "../../../utils/seo/Meta";

type RecoveryForm = {
  login: string;
};

const Recovery: FC = () => {
  // Captcha
  const [visibleCaptcha, setVisibleCaptcha] = useState(false);
  const [successCaptcha, setSuccessCaptcha] = useState(false);
  const [recoveryData, setRecoveryData] = useState({});

  // Router v6
  const navigate = useNavigate();

  // React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<RecoveryForm>({
    mode: "all",
  });

  const openCaptcha = () => {
    setVisibleCaptcha(!visibleCaptcha);
  };

  const onSubmitRecoveryForm: SubmitHandler<RecoveryForm> = (data) => {
    setRecoveryData(data);
    openCaptcha();
  };

  const successHandler = async () => {
    try {
      const res = await $api.post("/api/User/reset", recoveryData);

      if (res.status >= 200 && res.status < 300) {
        toast.success("Запрос на востановление отправлен в телеграм!");
      }
      navigate("/login");
    } catch (e: any) {
      console.log(e);

      if (e.response.data.title === "UserIsNotVerified") {
        toast.error(
          "Пользователь не верифицирован, востановление не возможно, обратитесь к администратору!"
        );
      }

      if (e.response.data.title === "UserNotFound") {
        toast.error("Пользователь не найден!");
      }
    }
  };

  useEffect(() => {
    successCaptcha && successHandler();
    reset({
      login: "",
    });
  }, [successCaptcha]);

  return (
    <>
      <Meta
        title="Password restore"
        description="Введите логин для востановления пароля!"
      />

      <section className="section__padding auth">
        <img src="/assets/img/wave.png" alt="Фон" className="faq__img" />

        <div className="container">
          <div className="auth__inner">
            <h2 className="title title__center">
              <span className="black blue">Восстановление</span> доступа
            </h2>

            {visibleCaptcha ? (
              <Fade in={visibleCaptcha}>
                <Captcha
                  setSuccessCaptcha={setSuccessCaptcha}
                  setVisibleCaptcha={setVisibleCaptcha}
                  visibleCaptcha={visibleCaptcha}
                />
              </Fade>
            ) : (
              <></>
            )}

            <form>
              <div className="auth__content">
                <div className="auth__input--inner full">
                  <input
                    placeholder="Логин"
                    className="input auth__input"
                    {...register("login", {
                      required: "Поле обязательно к заполнению",
                      minLength: {
                        value: 5,
                        message: "Минимум 5 символов!",
                      },
                      maxLength: {
                        value: 12,
                        message: "Максимум 12 символов!",
                      },
                    })}
                  />
                  {errors?.login && (
                    <Text fontSize="xl" fontWeight="semibold" color="red.500">
                      {errors.login.message || "Error!"}
                    </Text>
                  )}
                </div>
              </div>

              <Button
                onClick={handleSubmit(onSubmitRecoveryForm)}
                isDisabled={!isValid}
                type="submit"
                colorScheme="blue"
                mt={20}
                width="96"
                height="24"
                fontSize="3xl"
                boxShadow="dark-lg"
              >
                Восстановить
              </Button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Recovery;
