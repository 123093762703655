import React, { useState, useEffect } from "react";
import "./Settings.scss";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";
import { setUserWallets } from "../../../features/userInfo/userInfoSlice";

import { useForm, SubmitHandler } from "react-hook-form";

import { Text, Button, Flex, Center, Spinner } from "@chakra-ui/react";

type WalletsForm = {
  tron: string;
  mtb: string;
};

export const UserWallets = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { userData, isLoadingWallets } = useAppSelector(
    (state) => state.userInfo
  );

  const [mtb, setMtb] = useState("");
  const [usdtTrc20, setUsdtTrc20] = useState("");

  // Initialize react hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<WalletsForm>({
    mode: "all",
    defaultValues: {
      mtb: "",
      tron: "",
    },
  });

  // Default values hook form initialize
  useEffect(() => {
    reset(userData.wallets);
  }, [userData]);

  // Submit new password to redux thunk
  const onSubmit: SubmitHandler<WalletsForm> = (data) => {
    dispatch(setUserWallets(data));
    reset();
  };

  return (
    <>
      <div className="settings__top-section-email">
        {/* <div className="trans__item flex">
          <p className="setting__side--title">MTB</p>
          <input
            type="text"
            placeholder="Wallet MTB"
            className="mt1 w98 input select trans__input"
            {...register("mtb", {
              onChange: (e) => setMtb(e.target.value),
              pattern: {
                value: /^[T](.[a-zA-Z0-9]*)$/,
                message:
                  "Первый символ T. Допустимы только латинские буквы и цифры",
              },
              minLength: {
                value: 33,
                message: "Минимум 33 символов!",
              },
              maxLength: {
                value: 40,
                message: "Максимум 40 символов!",
              },
            })}
          />
          {errors?.mtb && (
            <Text m={1} fontSize="lg" fontWeight="semibold" color="red.500">
              {errors.mtb.message || "Error!"}
            </Text>
          )}
        </div> */}

        <div className="trans__item flex ">
          <p className="setting__side--title">USDT TRC20</p>
          <input
            type="text"
            className="mt1 w98 input select trans__input"
            placeholder="Wallet USDT TRC20"
            {...register("tron", {
              onChange: (e) => setUsdtTrc20(e.target.value),
              pattern: {
                value: /^[T](.[a-zA-Z0-9]*)$/,
                message: "Допустимы только латинские буквы и цифры",
              },
              minLength: {
                value: 33,
                message: "Минимум 33 символов!",
              },
              maxLength: {
                value: 40,
                message: "Максимум 40 символов!",
              },
            })}
          />
          {errors?.tron && (
            <Text m={1} fontSize="lg" fontWeight="semibold" color="red.500">
              {errors.tron.message || "Error!"}
            </Text>
          )}
        </div>
      </div>

      <div className="settings__top-section-email">
        <Flex justifyContent="center" alignItems="center" w="100%" h="100%">
          <Center w="100%">
            {isLoadingWallets ? (
              <Spinner
                thickness="14px"
                speed="0.7s"
                emptyColor="#61D64A"
                color="#1c3b6a"
                w={20}
                h={20}
              />
            ) : (
              <Button
                onClick={handleSubmit(onSubmit)}
                // isDisabled={isValid && mtb === ""}
                colorScheme="red"
                borderRadius="xl"
                w={["50%", "25%"]}
                h="50px"
              >
                <Text fontSize="18px" color="white" fontFamily="Graphik">
                  {t("Settings.save")}
                </Text>
              </Button>
            )}
          </Center>
        </Flex>
      </div>
    </>
  );
};
