import React, { useState, useEffect } from "react";
import { Structure } from "../../../Components/LK/PartnersStructure/Structure";
import { Footer } from "./Footer/Footer";
import "./PartnersPrograms.scss";

import $api, { API_URL } from "../../../http/apiService";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { useAppSelector, useAppDispatch } from "../../../store";
import { addCaptchaErrorCount } from "../../../features/auth/authSlice";
import {
  getMatrixes,
  getPrograms,
  buyPlace,
} from "../../../features/marketing/marketingSlice";

import BlockModal from "./BlockModal";
import { getUserInfo } from "../../../features/userInfo/userInfoSlice";

import { Captcha } from "../../../Components/Home/Captcha/Captcha";

import { GiAlarmClock } from "react-icons/gi";
import { BsBagCheck } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";

import FundsBox from "../../../assets/img/funds-box-line.svg";
import RightIcon from "../../../assets/img/right-icon.svg";

import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Text,
  Center,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Fade,
  Spinner,
  Box,
} from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const itemsPerPage = 3;

export const PartnersPrograms = (props) => {
  const { userData } = useAppSelector((state) => state.userInfo);
  const { captchaError } = useAppSelector((state) => state.auth);
  const { programs, matrixes, isLoading, isLoadingMatrix } = useAppSelector(
    (state) => state.marketing
  );

  // Translation i18next
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [partner, setPartner] = useState("");
  const [partnerIsOk, setPartnerIsOk] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [protectPartner, setProtectPartner] = useState(false);
  const [chosenMatrix, setChosenMatrix] = useState(1);
  const [chosenMatrixPrice, setChosenMatrixPrice] = useState(12);
  const [chosenBalance, setChosenBalance] = useState(1);
  const [chosenProgram, setChosenProgram] = useState(props.initProgId);
  const [buyWithPartner, setBuyWithPartner] = useState(false);
  const [byuPlace, setBuyPlace] = useState(false);
  const [isLoadingBuyPlace, setIsLoadingBuyPlace] = useState(false);

  // Captcha
  const [visibleCaptcha, setVisibleCaptcha] = useState(false);
  const [successCaptcha, setSuccessCaptcha] = useState(false);

  const [chosenPlace, setChosenPlace] = useState(null);
  // useEffect(() => {
  //   console.log("chosenPlace", chosenPlace);
  // }, [chosenPlace]);
  // const [chosenPlaceParent, setChosenPlaceParent] = useState(null);
  const [myClones, setMyClones] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [blocksCount, setBlocksCount] = useState(0);
  const [price, setPrice] = useState(0);
  const [shouldFindNext, setShouldFindNext] = useState(false);
  const [idForSearch, setIdForSearch] = useState("");
  const [posForSearch, setPosForSearch] = useState(null);
  // const [cloneForSearch, setCloneForSearch] = useState("");
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [logins, setLogins] = useState([]);
  const [loginsCount, setLoginsCount] = useState(0);
  // const [parentIdForUpdate, setParentIdForUpdate] = useState("");
  const {
    isOpen: isOpenBlockModal,
    onOpen: onOpenBlockModal,
    onClose: onCloseBlockModal,
  } = useDisclosure();
  const [cloneForBlock, setCloneForBlock] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [loginInput, setLoginInput] = useState("");
  const [cloneInput, setCloneInput] = useState("");
  const [blockInput, setBlockInput] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageBlocks, setCurrentPageBlocks] = useState(0);
  const [shouldAddBToId, setShouldAddBToId] = useState(false);
  const [dataForMatrix, setDataForMatrix] = useState(null);
  const [refresh, setRefresh] = useState(false);

  // Get marketing programs and fast money matrixes when first load page
  useEffect(() => {
    dispatch(getPrograms());
    // setChosenProgram(props?.isNew ? 4 : 1);
    // setTimeout(() => {
    //   const fetchData = {
    //     programId: 1,
    //   };
    //   dispatch(getMatrixes(fetchData));
    // }, 1200);
  }, []);

  // Get marketing programs and fast money matrixes when first load page
  useEffect(() => {
    // dispatch(getPrograms());
    // setChosenProgram(props?.isNew ? 4 : 1);
    if (programs.length === 0) return;
    const fetchData = {
      programId: props.initProgId,
    };
    dispatch(getMatrixes(fetchData));
  }, [programs, props.initProgId]);

  useEffect(() => {
    // if (cloneForBlock && cloneForBlock?.canBlock === true){
    if (cloneForBlock) {
      onOpenBlockModal();
    }
  }, [cloneForBlock]);

  useEffect(() => {
    setChosenProgram(props.initProgId);
  }, [props.initProgId]);

  // useEffect(() => {
  //   // setRefresh(!refresh);
  //   console.log("!!!!!!!!!!!!!!!props.isNew", props.isNew.toString());
  //   if ( programs?.length > 0 ) {
  //     console.log("!!!!!!!!!!!!!!!programs?.[0].id", programs?.[0].id);
  //     setChosenProgram(props?.isNew === true ? programs?.[3].id : programs?.[0].id)
  //     setChosenMatrix(1);
  //   }
  // }, [props.isNew, programs]);

  // useEffect(() => {
  //   if (globalThis?.block) {
  //     try {
  //       const obj = JSON.parse(globalThis.block);
  //       console.log("structure globalThis.block", obj);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }

  // }, [globalThis.block]);

  const getInfoForChart = async () => {
    setMyClones([]);
    setBlocks([]);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const rd = {
      program: chosenProgram,
      matrix: chosenMatrix,
    };
    // const rd2 = {
    //   program: chosenProgram,
    //   matrix: chosenMatrix,
    //   skip: 0,
    //   take: itemsPerPage,
    // };
    try {
      // const res2 = await $api.post("/api/Marketing/blocks", rd2, config);

      // if (res2.status == 200) {
      //   setBlocks(res2?.data?.items);
      //   setBlocksCount(res2?.data?.totalCount);
      // }
      await getBlocks();
      const res = await $api.post("/api/Marketing/price", rd, config);

      if (res.status === 200) {
        setPrice(res.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getBlocks = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const rd = {
      program: chosenProgram,
      matrix: chosenMatrix,
      skip: 0,
      take: itemsPerPage,
    };
    if (props?.userId) {
      rd.userId = props?.userId;
      try {
        const res = await $api.post("/api/admin/Marketing/blocks", rd, config);

        if (res.status === 200) {
          setBlocks(res?.data?.items);
          setBlocksCount(res?.data?.totalCount);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const res = await $api.post("/api/Marketing/blocks", rd, config);

        if (res.status == 200) {
          setBlocks(res?.data?.items);
          setBlocksCount(res?.data?.totalCount);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (!chosenProgram || chosenProgram === 0) return;
    getInfoForChart();
  }, [chosenProgram, chosenMatrix, props?.userId, props.isNew]);

  // Close Modal
  const handleClose = () => {
    setPartner("");
    setProtectPartner(false);
    setBuyWithPartner(false);
    setBuyPlace(false);
    setChosenBalance(1);
    onClose();
  };

  // Checking free login
  const testPartnerHandler = async () => {
    setIsLoadingBuyPlace(true);

    try {
      setLoadingLogin(true);
      const login = {
        login: partner,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await $api.post(
        `${API_URL}/api/User/is-login-free`,
        login,
        config
      );

      if (response.data.free === false) {
        setPartnerIsOk(true);
        setPartner("");
        setProtectPartner(false);
        onClose();
      } else {
        dispatch(addCaptchaErrorCount());
        setPartner("");
        toast.error("Ошибка ввода пользователя");
      }

      setLoadingLogin(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingLogin(false);
      setIsLoadingBuyPlace(false);
    }
  };

  // Open captcha when 3 errors
  useEffect(() => {
    captchaError >= 3 && handleClose() & openCaptcha();
  }, [captchaError]);

  const openCaptcha = () => {
    setVisibleCaptcha(!visibleCaptcha);
  };

  const matrixesHandler = (id) => {
    const fetchData = {
      programId: id,
    };
    dispatch(getMatrixes(fetchData));
  };

  // Check user login and partner
  useEffect(() => {
    if (partner === userData?.login) {
      setProtectPartner(true);
    }

    if (partner !== userData?.login) {
      setProtectPartner(false);
    }
  }, [partner, userData.login]);

  useEffect(() => {
    partnerIsOk && buyPlaceHandler();
    setPartnerIsOk(false);
  }, [testPartnerHandler, partnerIsOk]);

  // Set first matrix and price when load page or switch something
  // useEffect(() => {
  //   setChosenMatrix(matrixes[0]?.id);
  //   setChosenMatrixPrice(matrixes[0]?.price);
  // }, [matrixes]);

  // // Buy partner place
  // const buyPartnerPlaceHandler = async () => {
  //   const fetchData = {
  //     matrixId: chosenMatrix,
  //     partnerLogin: partner,
  //     accountId: chosenBalance,
  //     program: chosenProgram,
  //   };
  //   if (chosenPlace?.data?.realParentId) {
  //     fetchData.parentId = chosenPlace?.data?.realParentId;
  //   }
  //   if (chosenPlace?.data?.pos) {
  //     fetchData.pos = chosenPlace?.data?.pos;
  //   }
  //   if (chosenPlace?.realParentId) {
  //     fetchData.parentId = chosenPlace?.realParentId;
  //   }
  //   if (chosenPlace?.pos) {
  //     fetchData.pos = chosenPlace?.pos;
  //   }

  //   const res = await dispatch(buyPartnerPlace(fetchData));
  //   if (
  //     res?.payload?.status >= 200 &&
  //     res?.payload?.status < 300 &&
  //     chosenPlace?.data?.realParentId
  //   ) {
  //     setPosForSearch(chosenPlace?.data?.pos);
  //     setIdForSearch(chosenPlace?.data?.realParentId);
  //   } else if (res?.payload?.status >= 200 && res?.payload?.status < 300) {
  //     setShouldUpdate(true);
  //   }
  //   handleClose();
  // };

  // Buy place slot
  const buyPlaceHandler = async () => {
    setIsLoadingBuyPlace(true);
    const fetchData = {
      program: chosenProgram,
      matrix: chosenMatrix,
      accountId: chosenBalance,
    };
    if (partner) {
      fetchData.partnerLogin = partner;
    }
    if (chosenPlace?.data?.realParentId) {
      fetchData.parentId = chosenPlace?.data?.realParentId;
    }
    if (chosenPlace?.data?.pos) {
      fetchData.pos = chosenPlace?.data?.pos;
    }
    if (chosenPlace?.realParentId) {
      fetchData.parentId = chosenPlace?.realParentId;
    }
    if (chosenPlace?.pos) {
      fetchData.pos = chosenPlace?.pos;
    }

    const res = await dispatch(buyPlace(fetchData));

    if (
      res?.payload?.status >= 200 &&
      res?.payload?.status < 300 &&
      chosenPlace?.data?.realParentId
    ) {
      await dispatch(getUserInfo());
      setPosForSearch(chosenPlace?.data?.pos);
      setIdForSearch(chosenPlace?.data?.realParentId);
    } else if (res?.payload?.status >= 200 && res?.payload?.status < 300) {
      await dispatch(getUserInfo());
      setShouldUpdate(true);
    }
    setIsLoadingBuyPlace(false);
    handleClose();
    // setTimeout(() => {
    //   document.location.reload();
    //   // window.location.reload();
    // }, 1000);
  };

  const findNextPlace = () => {
    setShouldFindNext(true);
    // let startId = 0;
    // let startParentId = null;
    // if (!chosenPlace) {
    //   startId = myClones[0].realId;
    //   startParentId = null;
    // }
    //
  };

  const handleLoginChange = async (event) => {
    setLoginInput(event.target.value);
    if (event.target.value?.length >= 4) {
      const rd = {
        program: chosenProgram,
        matrix: chosenMatrix,
        login: event.target.value,
        skip: 0,
        take: itemsPerPage,
      };
      if (props?.userId) {
        rd.userId = props?.userId;
        try {
          const res = await $api.post(
            "/api/admin/Marketing/search",
            rd,
            config
          );
          if (res.status == 200 && res?.data?.items?.length > 0) {
            setLogins(res?.data?.items);
            setIsOpenLogin(true);
            setCurrentPage(currentPage + 1);
            setLoginsCount(res?.data?.totalCount);
          } else {
            setIsOpenLogin(false);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          const res = await $api.post("/api/Marketing/search", rd, config);

          if (res.status == 200 && res?.data?.items?.length > 0) {
            setLogins(res?.data?.items);
            setIsOpenLogin(true);
            setCurrentPage(currentPage + 1);
            setLoginsCount(res?.data?.totalCount);
          } else {
            setIsOpenLogin(false);
          }
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      setLogins([]);
      setIsOpenLogin(false);
    }
  };

  const handleSelectLogin = (event) => {
    console.warn("handleSelect event.target.value", event.target.value);
    if (event.target.value) {
      setIdForSearch(event.target.value);
      setLoginInput("");
      setCloneInput("");
      setBlockInput("");
    }
    setIsOpenLogin(false);
  };

  const addLogins = async () => {
    const rd = {
      program: chosenProgram,
      matrix: chosenMatrix,
      login: loginInput,
      skip: itemsPerPage * currentPage,
      take: itemsPerPage,
    };
    if (props?.userId) {
      rd.userId = props?.userId;
      try {
        const res = await $api.post("/api/admin/Marketing/search", rd, config);

        if (res.status == 200 && res?.data?.items?.length > 0) {
          setLogins([...logins, ...res.data.items]);
          setCurrentPage(currentPage + 1);
          setIsOpenLogin(true);
          setLoginsCount(res?.data?.totalCount);
        } else {
          // setIsOpenLogin(false);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const res = await $api.post("/api/Marketing/search", rd, config);

        if (res.status == 200 && res?.data?.items?.length > 0) {
          setLogins([...logins, ...res.data.items]);
          setCurrentPage(currentPage + 1);
          setIsOpenLogin(true);
          setLoginsCount(res?.data?.totalCount);
        } else {
          // setIsOpenLogin(false);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const addBlocks = async () => {
    const rd = {
      program: chosenProgram,
      matrix: chosenMatrix,
      skip: itemsPerPage * currentPageBlocks,
      take: itemsPerPage,
    };
    if (props?.userId) {
      rd.userId = props?.userId;
      try {
        const res = await $api.post("/api/admin/Marketing/blocks", rd, config);

        if (res.status == 200 && res?.data?.items?.length > 0) {
          setBlocks([...blocks, ...res.data.items]);
          setBlocksCount(res.data.totalCount);
          setCurrentPageBlocks(currentPageBlocks + 1);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const res = await $api.post("/api/Marketing/blocks", rd, config);
        if (res.status == 200 && res?.data?.items?.length > 0) {
          setBlocks([...blocks, ...res.data.items]);
          setBlocksCount(res.data.totalCount);
          setCurrentPageBlocks(currentPageBlocks + 1);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };
  useEffect(() => {
    if (idForSearch !== "") {
      getBlocks();
    }
  }, [idForSearch]);

  return (
    <>
      <Meta title="Personal account" />

      <div className="deposit__container">
        <div className="deposits__header">
          <div className="deposits__header--left">
            <img
              className="deposits__header--left--funds-icon"
              src={FundsBox}
              alt="Finds Icon"
            />
            <p className="deposits__header--left--title">
              {t("PartnersProgramsPage.partnership_programs")}
            </p>
          </div>

          {visibleCaptcha && (
            <Fade in={visibleCaptcha}>
              <Captcha
                setSuccessCaptcha={setSuccessCaptcha}
                setVisibleCaptcha={setVisibleCaptcha}
                visibleCaptcha={visibleCaptcha}
              />
            </Fade>
          )}

          <BlockModal
            isOpen={isOpenBlockModal}
            onOpen={onOpenBlockModal}
            onClose={onCloseBlockModal}
            data={cloneForBlock}
            setData={setCloneForBlock}
            setShouldUpdate={setShouldUpdate}
            shouldAddBToId={shouldAddBToId}
            setShouldAddBToId={setShouldAddBToId}
            setIdForSearch={setIdForSearch}
            dataForMatrix={dataForMatrix}
            setDataForMatrix={setDataForMatrix}
          />

          <div className="deposits__header--right">
            {programs
              ?.filter((el, index) =>
                props.isNew === true ? index >= 3 : index < 3
              )
              ?.map((program, index) => (
                <button
                  key={program.id}
                  onClick={() => {
                    setChosenProgram(program.id);
                    matrixesHandler(program.id);
                    // document.location.reload();
                  }}
                  className={
                    program.id === chosenProgram
                      ? "deposits__header--right-button-active"
                      : "deposits__header--right-button"
                  }
                >
                  <Center>
                    {program.id === 1 && <GiAlarmClock size="24px" />}
                    {program.id === 2 && <BsBagCheck size="22px" />}
                    {program.id === 3 && <AiOutlineSetting size="24px" />}

                    <Text
                      fontFamily="Graphik"
                      fontWeight="medium"
                      fontSize={["16px", "22px", "26px"]}
                    >
                      {program.name}
                    </Text>
                  </Center>
                </button>
              ))}
          </div>
        </div>

        <div className="deposit__main">
          <div className="deposit__main--left">
            {isLoadingMatrix ? (
              <Center w="100%" h="400px">
                <Spinner
                  thickness="14px"
                  speed="0.7s"
                  emptyColor="#61D64A"
                  color="#1c3b6a"
                  w={24}
                  h={24}
                />
              </Center>
            ) : (
              <div className="deposit__main--left-matrix">
                {matrixes?.map((matrix, index) => (
                  <>
                    <div
                      onClick={() => {
                        setChosenMatrix(matrix.id);
                        setChosenMatrixPrice(matrix.price);
                      }}
                      key={matrix.id}
                      className={
                        chosenMatrix == matrix.id
                          ? "deposit__main--left-matrix-item-active"
                          : "deposit__main--left-matrix-item"
                      }
                    >
                      <div className="deposit__main--left-matrix-item-number">
                        <div className="deposit__main--left-matrix-item-box">
                          <p className="deposit__main--left-matrix-item-box-number">
                            {index + 1}
                          </p>
                        </div>
                        <p className="deposit__main--left-matrix-item-number-text">
                          {matrix.name}
                        </p>
                      </div>
                      <div className="deposit__main--left-matrix-item-button">
                        <Button
                          colorScheme="green"
                          w="50px"
                          h="50px"
                          borderRadius="7px"
                          _focus={{
                            background: "#61D64A",
                          }}
                        >
                          <img
                            className="deposit__main--left-matrix-item-box-green-icon"
                            src={RightIcon}
                            alt="Right Icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            )}

            <div className="deposit__main--left-search">
              <div className="deposit__main--left-search-login-input">
                <FormControl>
                  <FormLabel
                    fontFamily={"Graphik"}
                    fontSize={18}
                    m={4}
                    htmlFor="search"
                  >
                    {t("PartnersProgramsPage.search_by_login")}
                  </FormLabel>
                  <Input
                    h="55px"
                    w="96%"
                    mx={4}
                    my={2}
                    id="search"
                    type="text"
                    border="1px"
                    borderColor="gray.400"
                    fontSize={18}
                    value={loginInput}
                    // onChange={(e) => setIdForSearch(e.target.value)}
                    onChange={handleLoginChange}
                  />
                </FormControl>
              </div>
              {isOpenLogin && (
                <Box ml={4}>
                  <datalist
                    onClick={handleSelectLogin}
                    style={{ display: isOpenLogin ? "block" : "none" }}
                  >
                    {logins.map((elem) => (
                      <option key={elem.id} value={elem.id}>
                        login:{elem?.user?.login} id:{elem?.id}
                      </option>
                    ))}
                  </datalist>
                </Box>
              )}
              {isOpenLogin && loginsCount > logins?.length && (
                <Button
                  colorScheme="facebook"
                  ml={4}
                  mr={4}
                  onClick={addLogins}
                >
                  {t("PartnersProgramsPage.show_more")}
                </Button>
              )}

              <div className="deposit__main--left-search-clone-list">
                <FormControl>
                  <FormLabel
                    fontFamily={"Graphik"}
                    fontSize={18}
                    m={4}
                    htmlFor="clones"
                  >
                    {t("PartnersProgramsPage.list_of_clones")}
                  </FormLabel>
                  <Select
                    h="55px"
                    w="96%"
                    border="1px"
                    borderColor="gray.400"
                    fontSize={18}
                    mx={4}
                    my={2}
                    id="clones"
                    placeholder={t("PartnersProgramsPage.choose_a_clone")}
                    value={cloneInput}
                    // onChange={(e) => handleSelectClone(e.target.value)}
                    onChange={(e) => {
                      setCloneInput(e.target.value);
                      setIdForSearch(e.target.value);
                      setLoginInput("");
                      setBlockInput("");
                    }}
                  >
                    {myClones.map((elem) => (
                      <option key={elem.id} value={elem.id}>
                        {elem.number} Id:{elem.id}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {/* <div className="deposit__main--left-search-blocking">
              <FormControl>
                <FormLabel
                  fontFamily={"Graphik"}
                  fontSize={18}
                  m={4}
                  htmlFor="clones"
                >
                  Блокировка моих клонов
                </FormLabel>
                <Select
                  h="55px"
                  w="96%"
                  border="1px"
                  borderColor="gray.400"
                  fontSize={18}
                  mx={4}
                  my={2}
                  id="clones"
                  placeholder="Выберите клона"
                  // onChange={(e) => block(e.target.value)}
                  onChange={(e) => {
                    setCloneForBlock(e.target.value);
                    onOpenBlockModal();
                  }}
                >
                  {myClones.map((elem) => (
                    <option
                      key={elem.realId}
                      value={JSON.stringify(elem)}
                      // value={elem}
                      style={{ color: elem?.isBlocked ? "red" : "" }}
                    >
                      {elem.number} realId:{elem.realId}{" "}
                      {elem?.isBlocked ? "Заблокирован" : ""}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div> */}

              {props.isNew === false && (
                <div className="deposit__main--left-search-blocking">
                  <FormControl>
                    <FormLabel
                      fontFamily={"Graphik"}
                      fontSize={18}
                      m={4}
                      htmlFor="clones"
                    >
                      {t("PartnersProgramsPage.locks")}
                    </FormLabel>

                    <Select
                      h="55px"
                      w="96%"
                      border="1px"
                      borderColor="gray.400"
                      fontSize={18}
                      mx={4}
                      my={2}
                      id="blocks"
                      placeholder={t("PartnersProgramsPage.select_blocking")}
                      value={blockInput}
                      onChange={(e) => {
                        setBlockInput(e.target.value);
                        setIdForSearch(e.target.value);
                        setLoginInput("");
                        setCloneInput("");
                      }}
                    >
                      {blocks.map((elem) => (
                        <option
                          key={elem.id}
                          // value={JSON.stringify(elem)}
                          value={elem?.parent.id}
                          // style={{ color: elem?.isBlocked ? "red" : "" }}
                        >
                          {elem.id} parentId:{elem?.parent.id} pos:{elem?.pos}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              {blocksCount > blocks?.length && (
                <Button
                  colorScheme="facebook"
                  ml={4}
                  mr={4}
                  onClick={addBlocks}
                >
                  {t("PartnersProgramsPage.show_more")}
                </Button>
              )}

              <div className="deposit__main--left-search-button-group">
                <Button
                  onClick={() => {
                    setBuyWithPartner(false);
                    setBuyPlace(true);
                    onOpen();
                  }}
                  colorScheme="teal"
                  h="55px"
                  w="96%"
                  mx={4}
                  my={2}
                  rounded="lg"
                  bg={"#61D64A"}
                  fontSize={18}
                  disabled={
                    chosenPlace?.data?.canBuy == true ||
                    chosenPlace?.canBuy == true ||
                    dataForMatrix?.length == 0 ||
                    myClones.length == 0
                      ? false
                      : true
                  }
                >
                  {t("PartnersProgramsPage.buy_a_place")}({price} $)
                </Button>

                <Button
                  onClick={() => {
                    setBuyPlace(false);
                    setBuyWithPartner(true);
                    onOpen();
                  }}
                  colorScheme="teal"
                  h="55px"
                  w="96%"
                  mx={4}
                  my={2}
                  rounded="lg"
                  bg={"#61D64A"}
                  fontSize={18}
                  disabled={
                    chosenPlace?.data?.canBuy == true ||
                    chosenPlace?.canBuy == true ||
                    myClones.length == 0
                      ? false
                      : true
                  }
                >
                  {t(
                    "PartnersProgramsPage.buy_a_place_at_the_expense_of_a_partner"
                  )}
                </Button>

                <Modal
                  isOpen={isOpen}
                  onClose={onClose}
                  closeOnOverlayClick={false}
                  motionPreset="slideInBottom"
                  size={"3xl"}
                  isCentered
                >
                  <ModalOverlay
                    backdropBlur="5px"
                    bg="blackAlpha.300"
                    backdropFilter="blur(10px) "
                  />
                  <ModalContent>
                    <ModalHeader
                      fontSize={20}
                      color="#61D64A"
                      fontWeight="medium"
                    >
                      <Center color="#202020">
                        {buyWithPartner
                          ? t("PartnersProgramsPage.choose_an_account_to_pay")
                          : t("PartnersProgramsPage.buy_a_place")}
                      </Center>
                    </ModalHeader>

                    <ModalBody>
                      <Center>
                        <Select
                          onClick={(e) => setChosenBalance(e.target.value)}
                          colorScheme="blue"
                          variant="outline"
                          size="lg"
                          w={"80%"}
                          h={14}
                          fontSize={20}
                          fontWeight="med"
                        >
                          <option value={1}>
                            {
                              userData?.balances.find(
                                (elem) => elem.account.id == 1
                              )?.account?.name
                            }{" "}
                            {byuPlace &&
                              `${
                                userData?.balances.find(
                                  (elem) => elem.account.id == 1
                                )?.value
                              } $`}
                          </option>
                          <option value={3}>
                            {
                              userData?.balances.find(
                                (elem) => elem.account.id == 3
                              )?.account?.name
                            }{" "}
                            {byuPlace &&
                              `${
                                userData?.balances.find(
                                  (elem) => elem.account.id == 3
                                )?.value
                              } $`}
                          </option>
                        </Select>
                      </Center>

                      {buyWithPartner && (
                        <>
                          <Center color="#202020" m={2}>
                            {t("PartnersProgramsPage.enter_username")}
                          </Center>
                          <Center>
                            <Input
                              value={partner}
                              onChange={(e) => setPartner(e.target.value)}
                              w={"80%"}
                              h={14}
                              mx={4}
                              my={2}
                              border="1px"
                              borderColor="gray.400"
                              fontSize={18}
                            />
                          </Center>
                        </>
                      )}
                    </ModalBody>

                    <ModalFooter>
                      <Center width="100%">
                        {byuPlace && (
                          <>
                            <Button
                              onClick={() => handleClose()}
                              colorScheme="red"
                              w={40}
                              h={14}
                              fontSize={16}
                              m={2}
                            >
                              {" "}
                              {t("PartnersProgramsPage.refuse")}
                            </Button>

                            <Button
                              onClick={() => buyPlaceHandler()}
                              isLoading={isLoadingBuyPlace}
                              isDisabled={
                                userData?.balances.find(
                                  (elem) => elem.account.id == chosenBalance
                                )?.value == 0
                              }
                              colorScheme="green"
                              w={40}
                              h={14}
                              m={2}
                              fontSize={16}
                            >
                              {" "}
                              {t("PartnersProgramsPage.buy")}
                            </Button>
                          </>
                        )}

                        {buyWithPartner && (
                          <>
                            <Button
                              onClick={() => handleClose()}
                              colorScheme="red"
                              w={40}
                              h={14}
                              m={2}
                              fontSize={16}
                            >
                              {t("PartnersProgramsPage.refuse")}
                            </Button>

                            <Button
                              onClick={() => testPartnerHandler()}
                              isLoading={isLoadingBuyPlace}
                              colorScheme="green"
                              w={60}
                              h={14}
                              m={2}
                              fontSize={16}
                              isDisabled={
                                partner.length < 4 ||
                                protectPartner ||
                                partner.length > 10
                              }
                            >
                              {t("PartnersProgramsPage.submit_an_request")}
                            </Button>
                          </>
                        )}
                      </Center>
                    </ModalFooter>
                  </ModalContent>
                </Modal>

                <Button
                  colorScheme="teal"
                  h="55px"
                  w="96%"
                  mx={4}
                  my={2}
                  rounded="lg"
                  bg="#5E89CC"
                  fontSize={18}
                  onClick={findNextPlace}
                >
                  {t("PartnersProgramsPage.next_place")}
                </Button>
              </div>
            </div>
          </div>

          <div className="deposit__main--right">
            <div className="deposit__main--right-top">
              <Structure
                chosenPlace={chosenPlace}
                setChosenPlace={setChosenPlace}
                // chosenPlaceParent={chosenPlaceParent}
                // setChosenPlaceParent={setChosenPlaceParent}
                myClones={myClones}
                setMyClones={setMyClones}
                shouldFindNext={shouldFindNext}
                setShouldFindNext={setShouldFindNext}
                idForSearch={idForSearch}
                setIdForSearch={setIdForSearch}
                chosenMatrix={chosenMatrix}
                chosenProgram={chosenProgram}
                // cloneForSearch={cloneForSearch}
                // setCloneForSearch={setCloneForSearch}
                // parentIdForUpdate={parentIdForUpdate}
                shouldUpdate={shouldUpdate}
                setShouldUpdate={setShouldUpdate}
                setCloneForBlock={setCloneForBlock}
                // setBlocks={setBlocks}
                posForSearch={posForSearch}
                setPosForSearch={setPosForSearch}
                shouldAddBToId={shouldAddBToId}
                setShouldAddBToId={setShouldAddBToId}
                dataForMatrix={dataForMatrix}
                setDataForMatrix={setDataForMatrix}
                userId={props?.userId}
                refresh={refresh}
                setRefresh={setRefresh}
                isNew={props?.isNew}
              />
            </div>
            <Footer isNew={props?.isNew} />
          </div>
        </div>
      </div>
    </>
  );
};
