import React, { FC, useState, useEffect, useLayoutEffect } from "react";
import { Text, Link } from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

export const PublicOffer: FC = () => {
  // Scroll to top
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [fullPath, setFullPath] = useState("");
  const [shortPath, setShortPath] = useState("");

  // Make referral link to copy
  useEffect(() => {
    const fullPath = window.location.href.split("");
    fullPath.splice(-13, 13);
    const fullLink = `${fullPath.join("")}/register/`;
    const shortLink = `${fullPath.join("")}`;
    setFullPath(fullLink);
    setShortPath(shortLink);
  }, []);

  return (
    <>
      <Meta
        title="Public offer"
        description="Настоящий договор регулирует взаимоотношения между Администрацией и конкретным лицом — по предоставлению Пользователю права пользования сайтом, информацией и инструментами предоставляемых Компанией!"
      />

      <section className="section__padding auth">
        <img src="/assets/img/wave.png" alt="Фон" className="faq__img" />

        <div className="container">
          <div className="auth__inner">
            <h2 className="title title__center">
              <span className="black blue">
                Условия соглашения (публичная оферта): <br />
                на предоставление доступа к сайту {shortPath} и информационным
                продуктам компании
              </span>{" "}
              в DREAMS СОМЕ TRUE EVENTS LTD
            </h2>

            <div className="container">
              <div className="company__inner">
                <p className="vector__item--title">1. Общие условия.</p>

                <p className="review__item--text">
                  1.1. Настоящий договор регулирует взаимоотношения между
                  Администрацией DREAMS COME TRUE EVENTS LTD (далее Компания) и
                  конкретным лицом (далее Пользователь) — по предоставлению
                  Пользователю права пользования сайтом, информацией и
                  инструментами предоставляемых Компанией.
                </p>

                <p className="review__item--text">
                  1.2. Договор вступает в силу с момента регистрации личного
                  кабинета Пользователя на сайте{" "}
                  <Link href={`${shortPath}`} isExternal>
                    {shortPath}
                  </Link>
                  . Путем заполнения регистрационной формы по адресу:{" "}
                  <Link href={`${fullPath}`} isExternal>
                    {fullPath}
                  </Link>
                </p>

                <p className="review__item--text">
                  1.3. Принятием условий оферты Пользователь подтверждает, что
                  является полностью дееспособным лицом, достигшим
                  совершеннолетнего возраста по условиям своей страны.
                </p>

                <p className="review__item--text">
                  1.4. Принятием услуг Пользователь подтверждает, что получает
                  их лично. Компания не несет ответственность за передачу
                  Пользователем доступа к личному кабинету другим лицам. Услуги
                  считаются оказанными Пользователю, под которым в данном случае
                  понимается лицо, принявшее условия оферты согласно настоящему
                  пункту.
                </p>

                <p className="review__item--text">
                  1.5. Изменение условий оферты и/или Договора возмездного
                  оказания услуг информирования об инструментах получения и/или
                  увеличения дохода подлежат обязательному, предварительному
                  опубликованию на сайте компании{" "}
                  <Link href={`${shortPath}`} isExternal>
                    {shortPath}
                  </Link>{" "}
                  и доведению до сведения Пользователя не менее чем за 1 (Один)
                  рабочий день до даты их применения.
                </p>
              </div>

              <div className="company__inner">
                <p className="vector__item--title">2. Содержание услуг.</p>

                <p className="review__item--text">
                  2.1. По Договору оказания услуг информирования об инструментах
                  получения и/или увеличения дохода Компания предоставляет
                  Пользователю информацию о вариантах открытия и ведения
                  собственного дела (бизнеса), предоставляя различные
                  инструменты и информационные технологии разработанные
                  Компанией.
                </p>
              </div>

              <div className="company__inner">
                <p className="vector__item--title">
                  3. Права и обязанности сторон.
                </p>

                <p className="review__item--text">
                  3.1. Права компании: <br />
                  - привлекать для оказания услуг третьих лиц без согласования с
                  Пользователем; <br />
                  - получать от Пользователя любую информацию, необходимую для
                  более качественного оказания услуг. В случае отказа в
                  предоставлении такой информации Пользователь соглашается с
                  качеством услуг с учетом имеющейся информации;
                  <br />
                  - приостанавливать предоставление услуг Пользователю или
                  отказываться от исполнения Договора в зависимости от
                  существенности нарушения его условий Пользователем, с
                  обязательным, предварительным не менее чем за 3 (Три) рабочих
                  дня уведомлением. Одним из оснований для приостановления
                  оказания услуг является фиктивное зачисление на лицевой счет
                  Пользователя денежных средств третьим лицом, которое взамен
                  получает от Пользователя электронную валюту Компании на свой
                  лицевой счет. Моментом отказа от исполнения Договора является
                  ограничение доступа к личному кабинету, лицевому счету
                  Пользователя;
                  <br /> - действовать по своему усмотрению при любых
                  неординарных, нестандартных, др. ситуациях, с учетом
                  добросовестности, разумности, обычаев делового оборота.
                </p>

                <p className="review__item--text">
                  2.2. Обязанности компании: <br />- обеспечить Пользователю
                  своевременное, качественное, круглосуточное оказание услуг,
                  включающих, кроме прочего, получение полных автоматизированных
                  переходов, создание дополнительных мест в системе, зачисление
                  денежных средств на лицевой счет, в том числе и для вывода с
                  лицевого счета, получение реферальных вознаграждений, бонусов.
                  Данный пункт подразумевает возможность круглосуточного доступа
                  к сайту Компании Пользователем.
                </p>

                <p className="review__item--text">
                  2.3. Права Пользователя: <br />- своевременно, качественно и в
                  полном объеме получать услуги от Компании, включающие, кроме
                  прочего, получение полных автоматизированных переходов,
                  создание дополнительных мест в системе, зачисление денежных
                  средств на лицевой счет, в том числе и для вывода с лицевого
                  счета, получение реферальных вознаграждений, бонусов. Данный
                  пункт подразумевает возможность круглосуточного доступа к
                  сайту Компании Пользователем. В случае нарушения, описанных
                  условий Пользователь может предъявлять Компании письменные
                  претензии, путем отправки письма по адресу: First Floor Suite
                  6 High Street Sedgefield, Stockton-On-Tees, England, TS21 3AR
                  . Ответ должен быть предоставлен в течение 5 (Пяти) рабочих
                  дней;
                  <br /> - определять, без согласования с Компанией, количество,
                  очередность, приглашения других Пользователей по реф. ссылке,
                  под которой понимается ссылка на определенный интернет ресурс,
                  содержащая уникальный идентификатор Пользователя (реферала),
                  разместившего ее.
                </p>
              </div>

              <div className="company__inner">
                <p className="vector__item--title">3. Персональные данные</p>

                <p className="review__item--text">
                  3.1. Под персональными данными понимается следующее, но не
                  исключительно: Ф.И.О.; номер мобильного/домашнего/рабочего
                  телефона; адрес электронной почты; фотографии; иная
                  дополнительная информация, относящаяся к прямо или косвенно к
                  Пользователю и необходимая для оказания услуг по Договору.
                </p>

                <p className="review__item--text">
                  3.1. Принимая условия оферты, Пользователь предоставляет
                  Компании право обрабатывать, в том числе с использованием
                  средств автоматизации, предоставленные Пользователем
                  персональные данные, в том числе с правом осуществлять сбор,
                  запись, систематизацию, накопление, хранение, уточнение
                  (обновление, изменение), извлечение, использование, передачу
                  (предоставление, доступ), обезличивание, блокирование,
                  удаление, уничтожение.
                </p>
              </div>

              <div className="company__inner">
                <p className="vector__item--title">4. Ответственность сторон</p>

                <p className="review__item--text">
                  4.1. Пользователь и Компания несут ответственность за
                  обработку, сохранение, передачу, использование персональных
                  данных, конфиденциальных сведений, полученных в порядке обмена
                  при исполнении условий Договора.
                </p>

                <p className="review__item--text">
                  4.2. Пользователь несет персональную ответственность и
                  принимает все последствия, за действия, совершаемые по своему
                  лицевому счету, в личном кабинете.
                </p>

                <p className="review__item--text">
                  4.3. Пользователь несет ответственность за незаконное
                  использование, включая копирование, частичное копирование,
                  распространение, передачу, работы системы Компании, самой
                  системы, которые являются интеллектуальной собственностью
                  последней.
                </p>

                <p className="review__item--text">
                  4.4. Пользователь несет персональную ответственность и
                  принимает все последствия, за недостоверные персональные
                  данные.
                </p>
              </div>

              <div className="company__inner">
                <p className="vector__item--title">
                  5. Исключение ответственности.
                </p>

                <p className="review__item--text">
                  5.1. Компания освобождается от ответственности, вызванной
                  неисправностью технических средств, включая компьютеры, др.
                  технику, сети, беспроводные сети, программное обеспечение, а
                  также действия/бездействие третьих лиц.
                </p>

                <p className="review__item--text">
                  5.2. Компания не возмещает полный или частичный ущерб,
                  причиненный Пользователю, невозможность использования личного
                  кабинета, лицевого счета
                </p>

                <p className="review__item--text">
                  5.3. Компания освобождается от ответственности, за ошибки,
                  допущенные пользователем при регистрации личного кабинета и
                  работе с ним
                </p>

                <p className="review__item--text">
                  5.4. Компания освобождается от ответственности, по
                  обязательствам пользователя перед третьими лицами, не
                  замораживает выводы средств, не участвует в разбирательствах о
                  долгах партнеров друг перед другом, не участвует в спорах
                  между пользователями, стратегиях работы в программах
                  придуманных Пользователями.
                </p>
              </div>

              <div className="company__inner">
                <p className="vector__item--title">6. Законодательство.</p>

                <p className="review__item--text">
                  6.1. Применяемым законодательством при разрешении споров, но
                  не исключая качество оказания услуг, оплату услуг, расторжение
                  договора, признание его незаключенным, недействительным, др.
                  является законодательство Англии и Уэльса.
                </p>

                <p className="review__item--text">
                  6.2. Исключительная подсудность разрешения споров, вытекающих
                  из Договора, признается сторонами за судам Англии и Уэльса.
                </p>
              </div>

              <div className="company__inner">
                <p className="vector__item--title">6. Реквизиты Компании</p>

                <p className="vector__item--title">
                  DREAMS СОМЕ TRUE EVENTS LTD.,
                </p>
                <p className="vector__item--title">
                  Company Type: Private company limited Ьу shares
                </p>
                <p className="vector__item--title">
                  Company Number 13247736 The Registrar of Companies for England
                  and Wales
                </p>
                <p className="vector__item--title">
                  Proposed Registered Office Address: STUDIO 210 134-146 CURTAIN
                  ROAD LONDON ENGLAND ЕС2А ЗАR
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
