import { Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  Heading,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
  Wrap,
} from "@chakra-ui/react";
import React, { FC, useEffect, useRef, useState } from "react";
import "./DashBoard.scss";
import { usePagination } from "../../hooks/pagination/usePagination";
import { FcAddRow, FcGallery, FcKindle, FcMindMap } from "react-icons/fc";
import { motion } from "framer-motion";
import $api from "../../http/apiService";
import { AxiosResponse } from "axios";
import { AiFillCloseCircle } from "react-icons/ai";
import { FcBusinessman, FcComments } from "react-icons/fc";
import Moment from "react-moment";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { MdHideSource } from "react-icons/md";
import Raiting from "./component/Rating/Rating";

const itemPerPage = 5;
enum SortType {
  CreatedAtAsc = "CreatedAtAsc",
  CreatedAtDesc = "CreatedAtDesc",
  SumAsc = "SumAsc",
  SumDesc = "SumDesc",
}
export type IdNameType = {
  id: number;
  name: string;
};
export type UserType = {
  id: number;
  login: string;
  name: string;
  imageUrl: string;
};
export type ReviewType = {
  id: number;
  user: UserType;
  text: string;
  createdAt: string;
  rate: number;
  status: IdNameType;
};
export type ReviewGetAllRDType = {
  take: number;
  skip: number;
};
type IFormInput = {
  id: number | string;
  "user.login": string;
  "user.name": string;
  "user.imageUrl": string;
  text: string;
  createdAt: string;
  rate: number | string;
  "status.id": number | string;
};

const emptyItem = {
  id: "",
  "user.login": "",
  "user.name": "",
  "user.imageUrl": "",
  text: "",
  createdAt: "",
  rate: "",
};

const StatusReview = [
  { id: 0, name: "Отклонен" },
  { id: 1, name: "Проверяется" },
  { id: 2, name: "Опубликован" },
];

const ReviewList: FC = () => {
  const [reviews, setReviews] = useState<ReviewType[]>([]);
  const [review, setReview] = useState<ReviewType | undefined>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [chosenItem, setChosenItem] = useState<ReviewType | undefined>();
  const [reviewsTotalCount, setReviewsTotalCount] = useState<number>(0);
  const [loginForSearch, setLoginForSearch] = useState("");
  const [sort, setSort] = useState<number | SortType>(1);
  const [isNeedUpdate, setIsNeedUpdate] = useState(1);
  const newsfileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<any>(null);
  const [fileUrl, setFileUrl] = useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoginFrom, setIsLoginFrom] = useState(false);
  const [loginFrom, setLoginFrom] = useState("");
  const [rate, setRate] = useState<number>(5);
  const {
    isOpen: isOpenActionModal,
    onOpen: onOpenActionModal,
    onClose: onCloseActionModal,
  } = useDisclosure();

  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: itemPerPage,
    count: reviewsTotalCount,
  });

  // React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: "all",
  });

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");

  useEffect(() => {
    getReviews();
  }, [page, loginForSearch, sort, isNeedUpdate]);

  const getReviews = async () => {
    const rd: ReviewGetAllRDType = {
      take: itemPerPage,
      skip: 0,
    };
    if (page > 0) {
      rd.skip = (page - 1) * itemPerPage;
    }
    if (loginForSearch) {
      // rd.login = loginForSearch;
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response: AxiosResponse = await $api.post(
        "/api/admin/Review/all",
        rd,
        config
      );
      const data: ReviewType[] = response.data.items;
      data.forEach((elem) => {
        elem.createdAt = elem.createdAt.split("T")[0];
      });
      setReviews(data);
      setReviewsTotalCount(response?.data?.totalCount);
    } catch (e) {
      console.error(e);
    }
  };

  // Default values hook form initialize
  useEffect(() => {
    // console.log("chosenItem", chosenItem);
    if (chosenItem) {
      reset(chosenItem);
      setLoginFrom(chosenItem.user.login);
      setIsLoginFrom(true);
      setRate(chosenItem.rate);
    }
  }, [chosenItem]);

  const onSubmitForm: SubmitHandler<IFormInput> = async (data: any) => {
    console.log("file", file);
    console.log("data", data);

    const rd: any = {
      // UserLogin: data.user.login,
      UserLogin: loginFrom,
      UserName: data.user.name,
      Text: data.text,
      CreatedAt:
        data.createdAt !== ""
          ? data.createdAt
          : new Date().toLocaleTimeString(),
      Rate: rate,
      statusId: data.status.id,
      // Image: data["user.imageUrl"],
    };
    if (file) {
      rd.Image = file;
    }
    console.log("rd", rd);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    if (!chosenItem) {
      try {
        const response = await $api.post("/api/admin/Review", rd, config);
        toast("Отзыв успешно добавлен");
        setIsNeedUpdate(isNeedUpdate + 1);
        handleClose();
      } catch (e) {
        console.error("Ошибка добавления отзыва", e);
        toast.error("Ошибка добавления отзыва");
      } finally {
        // setIsEditOpen(false);
      }
    } else {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log("rd.Image", rd.Image);
      if (file) {
        const rd = {
          Image: file,
        };
        try {
          const response = await $api.put(
            `/api/admin/Review/${chosenItem.id}/image`,
            rd,
            config
          );
          // toast("Отзыв успешно обновлен");
          // setIsNeedUpdate(isNeedUpdate + 1);
          // handleClose();
        } catch (e) {
          console.error("Ошибка редактирования отзыва", e);
          // toast.error("Ошибка редактирования отзыва");
        }
      }
      try {
        const response = await $api.put(
          `/api/admin/Review/${chosenItem.id}`,
          rd,
          config
        );
        toast("Отзыв успешно обновлен");
        setIsNeedUpdate(isNeedUpdate + 1);
        handleClose();
      } catch (e) {
        console.error("Ошибка редактирования отзыва", e);
        toast.error("Ошибка редактирования отзыва");
      }
    }
  };

  // const onUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
  const onUploadFile = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setFileUrl(reader.result);
        // setOpenCrop(true);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const resetForm = () => {
    reset(emptyItem);
    setChosenItem(undefined);
    setFile(null);
    setIsLoginFrom(false);
    setLoginFrom("");
    setRate(5);
  };

  const handleDelete = async () => {
    if (chosenItem) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await $api.delete(
          `/api/admin/Review/${chosenItem.id}`,
          config
        );
        toast("Отзыв успешно удален");
        setIsNeedUpdate(isNeedUpdate + 1);
        resetForm();
        onCloseActionModal();
      } catch (e) {
        console.error("Ошибка удаления отзыва", e);
        toast.error("Ошибка удаления отзыва");
      }
    }
  };

  const handleClose = () => {
    resetForm();
    setIsEditOpen(false);
  };

  const checkLogin = async (login: string) => {
    const rd = {
      login,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await $api.post(`api/User/is-login-free`, rd, config);
      if (response.status === 200) {
        setIsLoginFrom(!response.data.free);
      }
    } catch (e) {
      console.error("Ошибка проверки логина", e);
    }
  };

  const getColor = (item: ReviewType) => {
    if (item.status?.id === StatusReview[2].id) {
      return "#00FF3833";
    } else if (item.status?.id === StatusReview[1].id) {
      return "#FAFF004A";
    } else if (item.status?.id === StatusReview[0].id) {
      return "#FF000033";
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpenActionModal}
        onClose={onCloseActionModal}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        size={"5xl"}
        isCentered
      >
        <ModalOverlay
          backdropBlur="5px"
          bg="blackAlpha.300"
          backdropFilter="blur(10px) "
        />
        <ModalContent>
          <ModalHeader fontSize={24} color="blue.500" fontWeight="bold">
            <Center>
              Удалить отзыв
              {` "${chosenItem?.id}"`}
            </Center>
          </ModalHeader>
          <ModalBody fontSize={16}>
            <Text fontSize={12} color="gray.500" mt={8}>
              Текст
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              readOnly
              disabled
              value={chosenItem?.text}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={handleDelete}
              type="submit"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="lg"
              bg="#3786e5"
              color="white"
            >
              Подтвердить
            </Button>
            <Button
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="lg"
              bg="gray.500"
              color="white"
              onClick={() => {
                reset(emptyItem);
                setChosenItem(undefined);
                setIsLoginFrom(false);
                setLoginFrom("");
                onCloseActionModal();
              }}
            >
              Отмена
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <Box
        m={8}
        fontSize={[12, 8, 10, 12, 14]}
        boxShadow="lg"
        p="8"
        rounded="md"
        bg="white"
        // fontFamily="DM Sans"
      >
        <Wrap fontSize={[12, 8, 10, 12, 14]}>
          <Box ml={4}>
            <Text
              fontSize={[8, 12]}
              // mb='-4'
              // zIndex="modal"
            >
              Поиск по логину
            </Text>
            <Box>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  // w={[80, 60, 50]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={loginForSearch}
                  onChange={(e: any) => {
                    setPage(1);
                    setLoginForSearch(e.target.value);
                  }}
                />
                {loginForSearch && (
                  <InputRightElement mr="10" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setLoginForSearch("")}
                    />
                  </InputRightElement>
                )}
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Box>
          </Box>
        </Wrap>
      </Box> */}

      <Wrap my={8} justify={isLargerThan500 ? "end" : "start"}>
        <Box minW={[220, 150]}>
          <Heading
            as="h2"
            mt={6}
            size="xl"
            mb="4"
            color="#6484AA"
            textAlign="start"
          >
            Отзывы
          </Heading>
        </Box>
        <Spacer />

        <Box
          boxShadow="lg"
          p="6"
          rounded="md"
          bg="white"
          minWidth={220}
          minHeight={20}
          // alignSelf="end"
        >
          <Center>
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`Найдено: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${reviewsTotalCount} `}
            </Text>
          </Center>
        </Box>
      </Wrap>

      <Box color="#6484AA" fontSize={[12, 10, 12, 14, 14]} fontWeight="500">
        <div className="dashBoard_content__margin dashBoard_w100 dashBoard_flex dashBoard_admin__table">
          {isLargerThan500 && (
            <div
              className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
              style={{ padding: "8px" }}
            >
              <p className="dashBoard_operation__item--column9min2">
                <Center>ID</Center>
              </p>
              <p className="dashBoard_operation__item--column9min2"></p>

              <p className="dashBoard_operation__item--column9">
                <Center
                // onClick={() => {
                //   if (sort === 1) {
                //     setSort(0);
                //   } else setSort(1);
                // }}
                >
                  Дата
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9">
                <Center>
                  <Box mr={2}>
                    <FcBusinessman />
                  </Box>
                  Контрагент
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9">
                <Center>
                  <Box mr={2}>
                    <FcComments />
                  </Box>
                  Имя
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9">
                <Center>
                  <Box mr={2}>
                    <FcKindle />
                  </Box>
                  Текст
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9">
                <Center>
                  <Box mr={2}>
                    <FcMindMap />
                  </Box>
                  Рейтинг
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9">
                <Center>
                  {/* <Box mr={2}>
                  <FcMindMap />
                </Box> */}
                  Статус
                </Center>
              </p>

              <p className="dashBoard_operation__item--column9">
                <Center>
                  <Box mr={2}>
                    <FcGallery />
                  </Box>
                </Center>
              </p>
            </div>
          )}

          {reviews &&
            reviews.length > 0 &&
            reviews.map((item, index) => (
              <Box
                w="100%"
                key={item.id}
                bg={getColor(item)}
                fontSize={[12, 8, 10, 12, 14]}
                color="#000000"
                borderBottom="1px solid #dfdfdf"
                position="relative"
              >
                <div
                  key={item.id}
                  className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
                >
                  <p className="dashBoard_operation__item--column9min2">
                    <Center
                      color="blue.500"
                      as={motion.div}
                      whileHover={{ scale: 1.1, cursor: "pointer" }}
                      onClick={() => {
                        setChosenItem(item);
                        setIsEditOpen(true);
                      }}
                    >
                      {!isLargerThan500 && "ID: "}
                      {item?.id}
                    </Center>
                  </p>
                  <p
                    className={
                      isLargerThan500
                        ? "dashBoard_operation__item--column9min2"
                        : "myAbsoluteLeft"
                    }
                  >
                    <Center>
                      <Tooltip
                        fontSize={["12px", "16px"]}
                        label="Удалить"
                        hasArrow
                        bg="#ED64A6"
                        borderRadius="xl"
                      >
                        <Box
                          m="2"
                          as={motion.div}
                          whileHover={{ scale: 1.1, cursor: "pointer" }}
                          borderRadius="2xl"
                          bg="#ED64A6"
                          onClick={() => {
                            setChosenItem(item);
                            onOpenActionModal();
                          }}
                        >
                          {/* <MdOutlineCancel size={27} color="white" /> */}
                          <MdHideSource size={27} color="white" />
                        </Box>
                      </Tooltip>
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Center>
                      {!isLargerThan500 && "Дата: "}
                      <Moment format="DD:MM:YY">{item.createdAt}</Moment>
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Center>
                      {!isLargerThan500 && "Контрагент: "}
                      {item?.user?.login}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Center>
                      {!isLargerThan500 && "Имя: "}
                      {item?.user.name}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Box textAlign="center">
                      {" "}
                      {!isLargerThan500 && "Текст: "}
                      {item?.text}
                    </Box>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Center>
                      {!isLargerThan500 && "Рейтинг: "}
                      {item?.rate}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Center>
                      {!isLargerThan500 && "Статус: "}
                      {item?.status?.name}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column9">
                    <Center>
                      <Img src={item?.user.imageUrl} alt="" width="20" />
                    </Center>
                  </p>
                </div>
              </Box>
            ))}

          {reviewsTotalCount > itemPerPage && (
            <Center fontSize="12">
              <div className="pagination">
                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={prevPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === 1}
                >
                  Назад
                </Button>
                <button
                  onClick={() => setPage(1)}
                  className={`page ${page === 1 && "disabled"}`}
                >
                  1
                </button>
                {gaps.before ? "..." : null}

                {gaps.paginationGroup.map((el) => (
                  <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                  >
                    {el}
                  </button>
                ))}
                {gaps.after ? "..." : null}
                <button
                  onClick={() => setPage(totalPages)}
                  className={`page ${page === totalPages && "disabled"}`}
                >
                  {totalPages}
                </button>

                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={nextPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === totalPages}
                >
                  Вперед
                </Button>
              </div>
            </Center>
          )}
        </div>
      </Box>

      {/* <Collapse in={!isEditOpen}>
        <Button
          leftIcon={<FcAddRow />}
          bg="#3786e5"
          color="white"
          ml={8}
          width={80}
          height={20}
          fontSize="3xl"
          onClick={() => setIsEditOpen(true)}
        >
          Добавить
        </Button>
      </Collapse> */}
      <Collapse in={isEditOpen}>
        <Box
          color="gray.600"
          ml={8}
          mr={8}
          fontSize={[12, 8, 10, 12, 14]}
          boxShadow="lg"
          rounded="md"
          bg="white"
          // font-family="DM Sans"
        >
          <Box p={8}>
            <Heading as="h2" size="lg" mb="4" color="#6484AA">
              {chosenItem ? "Редактирование отзыва" : "Добавление отзыва"}
            </Heading>

            <form onSubmit={handleSubmit(onSubmitForm)}>
              <Wrap>
                <Box w="40%">
                  <Text fontSize={12} color="gray.500" mt={8}>
                    Логин*
                  </Text>
                  <Input
                    variant="primary"
                    fontSize={[12, 8, 10, 12, 14]}
                    h={30}
                    borderRadius="xl"
                    type="text"
                    {...(register("user.login"),
                    {
                      // required: "Поле обязательно к заполнению",
                      value: loginFrom,
                      onChange: (e: any) => {
                        checkLogin(e.target.value);
                        setLoginFrom(e.target.value);
                      },
                    })}
                  />
                  <Text
                    fontSize={10}
                    color={isLoginFrom ? "blue.500" : "red.500"}
                  >
                    {isLoginFrom
                      ? "Логин существует"
                      : "Введите валидный логин"}
                  </Text>
                </Box>
                <Box w="50%">
                  <Text fontSize={12} color="gray.500" mt={8}>
                    Имя*
                  </Text>
                  <Input
                    type="text"
                    variant="primary"
                    fontSize={[12, 8, 10, 12, 14]}
                    h={30}
                    borderRadius="xl"
                    {...register("user.name", {
                      required: "Поле обязательно к заполнению",
                    })}
                  />
                </Box>
              </Wrap>
              <Box w="50%">
                <Text fontSize={12} color="gray.500" mt={8}>
                  Текст*
                </Text>
                <Input
                  type="text"
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  h={30}
                  borderRadius="xl"
                  {...register("text", {
                    required: "Поле обязательно к заполнению",
                  })}
                />
              </Box>
              <Box w="50%">
                <Text fontSize={12} color="gray.500" mt={8}>
                  Рейтинг*
                </Text>
                {/* <Input
                  type="rate"
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  h={30}
                  borderRadius="xl"
                  {...register("rate", {
                    required: "Поле обязательно к заполнению",
                  })}
                /> */}
                {/* <div>
                  <StarRatingInput
                    size={5}
                    value={rate}
                    onChange={(value) => setRate(value)}
                  />
                </div> */}
                {/* <Rating
                  name="simple-controlled"
                  value={2}
                  // onChange={(event, newValue) => {
                  //   if (newValue) {
                  //     setRate(newValue);
                  //   }
                  // }}
                /> */}
                <Raiting isEditable={true} rating={rate} setRating={setRate} />
              </Box>
              <Box w="50%">
                <Text fontSize={12} color="gray.500" mt={8}>
                  Дата создания
                </Text>
                <Input
                  type="date"
                  // type="datetime-local"
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  h={30}
                  borderRadius="xl"
                  {...register("createdAt")}
                />
              </Box>

              <Text fontSize={12} color="gray.500" mt={8}>
                Статус
              </Text>
              <Select
                border="1px solid #3786e5"
                variant="primary"
                size="lg"
                color="gray.500"
                borderRadius="xl"
                fontSize={[12, 8, 10, 12, 14]}
                {...register("status.id")}
              >
                {/* <option value={-1}>Все</option> */}
                {StatusReview.map((elem) => (
                  <option key={elem.id} value={elem.id}>
                    {elem.name}
                  </option>
                ))}
              </Select>

              <Text fontSize={12} color="gray.500" mt={8}>
                Изображение*
              </Text>
              <input
                style={{ display: "none" }}
                id="image-input"
                type="file"
                accept="image/jpeg,image/png"
                multiple={false}
                ref={newsfileInputRef}
                onChange={(e) => onUploadFile(e)}
              />
              {!fileUrl && chosenItem && (
                <Img src={chosenItem?.user.imageUrl} w="200" />
              )}
              {fileUrl && <Img src={fileUrl} w="100" />}
              <Flex alignItems="start">
                <Button
                  colorScheme="blue"
                  borderRadius="xl"
                  h={[16, 20]}
                  mt={4}
                  width="80"
                  fontSize={[12, 8, 10, 12, 14]}
                  onClick={() => newsfileInputRef.current?.click()}
                >
                  <Text fontSize={[12, 16]}>Загрузить</Text>
                </Button>
                {/* {errors?.image && (
                  <Text fontSize="xl" fontWeight="semibold" color="red.500">
                    {"Загрузите файл!"}
                  </Text>
                )} */}

                <Spacer />
                <Button
                  onClick={handleSubmit(onSubmitForm)}
                  isDisabled={!isValid}
                  type="submit"
                  mt={10}
                  width={72}
                  height={16}
                  fontSize="2xl"
                  boxShadow="lg"
                  display="block"
                  mr="2"
                  bg="#3786E5"
                  color="white"
                >
                  Сохранить
                </Button>
                <Button
                  onClick={handleClose}
                  mt={10}
                  width={72}
                  height={16}
                  fontSize="2xl"
                  boxShadow="lg"
                  display="block"
                  bg="gray.600"
                  color="white"
                >
                  Закрыть
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Collapse>
    </>
  );
};

export default ReviewList;
