import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../http/apiService";

// Get user from localStorage
const localAccessToken = localStorage.getItem("accessToken");
const localRefreshToken = localStorage.getItem("refreshToken");

interface IAuthState {
  accessToken: string;
  refreshToken: string;
  isError: boolean;
  captchaError: number;
  isSuccess: boolean;
  isLoading: boolean;
  message: any;
}

const initialState: IAuthState = {
  accessToken: localAccessToken ? localAccessToken : "",
  refreshToken: localRefreshToken ? localRefreshToken : "",
  isError: false,
  captchaError: 0,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Register new user
export const registerUser = createAsyncThunk(
  "auth/register",
  async (user: any, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(
        `${API_URL}/api/App/sign-up`,
        user,
        config
      );

      if (response.data) {
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
      }
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Login user
export const loginUser = createAsyncThunk(
  "auth/login",
  async (user: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${API_URL}/api/App/authenticate`,
        user,
        config
      );
      if (response.data) {
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
      }
      return response.data;
    } catch (error: any) {
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// LogOut user
export const logoutUser = createAsyncThunk(
  "auth/logOut",
  async (_, thunkAPI) => {
    try {
      const localRefreshToken = localStorage.getItem("refreshToken");
      const token = {
        refresh_token: localRefreshToken,
      };

      const response = await axios.post(
        `${API_URL}/api/App/revoke-token`,
        token
      );

      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    } catch (error: any) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reducer
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addTokens: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    resetState: (state) => {
      state.accessToken = "";
      state.refreshToken = "";
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
    addCaptchaErrorCount: (state) => {
      state.captchaError = state.captchaError + 1;
    },
    resetCaptchaError: (state) => {
      state.captchaError = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.captchaError = state.captchaError + 1;
        state.message = action.payload;
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.captchaError = state.captchaError + 1;
        state.message = action.payload;
        state.accessToken = "";
        state.refreshToken = "";
      })
      .addCase(logoutUser.pending, (state) => {
        // state.isLoading = true;
        state.message = "";
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.accessToken = "";
        state.refreshToken = "";
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.accessToken = "";
        state.refreshToken = "";
      });
  },
});

export const {
  resetState,
  addTokens,
  resetCaptchaError,
  addCaptchaErrorCount,
} = authSlice.actions;
export default authSlice.reducer;
