import React, { FC, useEffect } from "react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Center,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Meta from "../../../utils/seo/Meta";

export const PaymentError: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    onOpen();
  }, []);

  const handleClose = () => {
    onClose();
    navigate("/admin");
  };

  return (
    <>
      <Meta title="Personal account" />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        size={"xl"}
        isCentered
      >
        <ModalOverlay
          backdropBlur="5px"
          bg="blackAlpha.300"
          backdropFilter="blur(10px) "
        />
        <ModalContent>
          <ModalHeader fontSize={20} color="#FF3158" fontWeight="medium">
            <Center>Что то пошло не так!</Center>
          </ModalHeader>

          <ModalBody fontSize={16}>
            <Center>Повторите попытку чуть позже!</Center>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} size="lg" onClick={handleClose}>
              Закрыть
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
