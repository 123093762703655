import { CalendarIcon, CheckIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
  Wrap,
} from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { motion } from "framer-motion";
import React, { FC, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  FcAlphabeticalSortingAz,
  FcAlphabeticalSortingZa,
  FcDataConfiguration,
} from "react-icons/fc";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { usePagination } from "../../hooks/pagination/usePagination";
import $api from "../../http/apiService";
import getNumberWithSpaces from "../../utils/getNumberWithSpaces";
import { SortType } from "./OutputList";
import PaymentMenu from "./PaymentMenu";
import {
  IdNameType,
  PaymentStatus,
  PaymentStatuses,
  PaymentTypeInRD,
  UserType,
} from "./PaymentsList";
import TransactionMenu from "./TransactionMenu";
import { UserTypeForAdmin } from "./UserList";

const itemPerPage = 10;

export type CryptoTokenInvoiceType = {
  actualTokenAmount: number | string;
  address: string;
  attempts: number | string;
  createdAt: string;
  errorMessage: string;
  expectedFiatAmount: number | string;
  expectedTokenAmount: number | string;
  expiredAt: string;
  id: number;
  inGasHash: string;
  network: number | string;
  outGasHash: string;
  outTokenHash: string;
  paymentAccount: {
    id: number;
    name: string;
  };
  rate: number | string;
  state: {
    id: number;
    name: string;
  };
  stateChangedAt: string;
  tokenName: string;
  user: {
    id: number;
    login: string;
    name: string;
  };
};

// {
//   id: number;
//   login: string;
//   dateFrom: string;
//   dateTo: string;
//   address: string;
//   hash: string;
//   try: string;
//   rate: string;
//   status: string;
//   error: string;
//   sum: number;
//   article?: IdNameType;
// };

export type CryptoTokenInvoiceOutType = {
  userId?: string | number;
  skip?: number;
  take?: number;
  start?: string;
  finish?: string;
  address?: string;
  state?: string | number;
};

const CryptoTokenInvoiceList: FC = () => {
  const [items, setItems] = useState<CryptoTokenInvoiceType[]>([]);
  const [totalSum, setTotalSum] = useState<number>(0);

  const [chosenItem, setChosenItem] = useState<
    CryptoTokenInvoiceType | undefined
  >();
  const [totalCount, setTotalCount] = useState<number>(3);

  const [loginForSearch, setLoginForSearch] = useState("");
  const [status, setStatus] = useState("");
  const [account, setAccount] = useState("");
  const [dateFrom, setDateFrom] = useState<string>("");
  // new Date().toISOString().split("T")[0]
  const [dateTo, setDateTo] = useState("");
  const [addressForSearch, setAddressForSearch] = useState("");
  const [isImportantFinance, setIsImportantFinance] = useState<boolean>(false);
  const [sort, setSort] = useState<number | SortType>(1);
  const [isNeedUpdate, setIsNeedUpdate] = useState(1);
  const [accounts, setAccounts] = useState<IdNameType[]>([]);
  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: itemPerPage,
    count: totalCount,
  });
  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getInfoForFilter = async () => {
    try {
      const res = await $api.get("/api/payment-account");
      const accounts: IdNameType[] = res.data;
      setAccounts(accounts);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getInfoForFilter();
  }, []);

  const getCryptoTokenInvoiceList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const rd: CryptoTokenInvoiceOutType = {
      take: itemPerPage,
      skip: 0,
    };
    if (page > 0) {
      rd.skip = (page - 1) * itemPerPage;
    }
    if (dateFrom) {
      rd.start = dateFrom;
    }
    if (dateTo) {
      rd.finish = dateTo;
    }
    // if (account) {
    // rd.paymentAccountId = +account;
    // }
    if (loginForSearch) {
      rd.userId = loginForSearch;
    }
    if (addressForSearch) {
      rd.address = addressForSearch;
    }
    if (status) {
      rd.state = +status;
    }

    try {
      const response: AxiosResponse = await $api.post(
        "/api/admin/crypto-token-invoice/all",
        rd,
        config
      );
      if (response.status >= 200 && response.status < 300) {
        const data: CryptoTokenInvoiceType[] = response.data.items;
        setItems(data);
        setTotalCount(response?.data?.totalCount);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCryptoTokenInvoiceList();
  }, [loginForSearch, dateFrom, dateTo, addressForSearch, status]);

  const getColorStatus = (id: number) => {
    if (id === 0) {
      return "yellow.300";
    }
    if (id === 1) {
      return "red.300";
    }
    if (id === 5) {
      return "green.300";
    }
  };

  const getTdBlur = (id: number) => {
    if (!chosenItem) {
      return "";
    } else if (id === chosenItem.id) {
      return "";
    } else return "2px";
  };

  return (
    <>
      <Box
        m={8}
        fontSize={[12, 8, 10, 12, 14]}
        boxShadow="lg"
        p="8"
        rounded="md"
        bg="white"
      >
        {/* ***************************************************************ПОИСК */}
        <Wrap fontSize={[12, 8, 10, 12, 14]}>
          <Box ml={4}>
            <Text
              fontSize={[8, 12]}
              // mb='-4'
              // zIndex="modal"
            >
              Поиск по логину
            </Text>
            <Box>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  // placeholder="Поиск по логину"
                  minW={[213, 236]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={loginForSearch}
                  onChange={(e) => {
                    setPage(1);
                    setLoginForSearch(e.target.value);
                  }}
                />
                {loginForSearch && (
                  <InputRightElement mr="10" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setLoginForSearch("")}
                    />
                  </InputRightElement>
                )}
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Box>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Статус</Text>
            <InputGroup>
              <Select
                border="1px solid #3786e5"
                variant="primary"
                size="lg"
                color="gray.500"
                borderRadius="xl"
                fontSize={[12, 8, 10, 12, 14]}
                minW={[150, 150]}
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  setPage(1);
                }}
              >
                {PaymentStatuses.map((elem) => (
                  <option key={elem.id} value={elem.value}>
                    {elem.desc}
                  </option>
                ))}
              </Select>
              <InputRightElement>
                <CheckIcon color="blue.500" />
              </InputRightElement>
            </InputGroup>
          </Box>

          {/* <Box ml={4}>
            <Text fontSize={[8, 12]}>Счет</Text>
            <InputGroup>
              <Select
                border="1px solid #3786e5"
                variant="primary"
                size="lg"
                color="gray.500"
                borderRadius="xl"
                fontSize={[12, 8, 10, 12, 14]}
                minW={[150, 150]}
                value={account}
                onChange={(e) => {
                  setAccount(e.target.value);
                  setPage(1);
                }}
              >
                <option value={""}>Все</option>
                {accounts?.length > 0 &&
                  accounts.map((elem) => (
                    <option key={elem.id} value={elem.id}>
                      {elem.name}
                    </option>
                  ))}
              </Select>
              <InputRightElement>
                <CheckIcon color="blue.500" />
              </InputRightElement>
            </InputGroup>
          </Box> */}

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Дата от </Text>
            <Flex>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="date"
                  placeholder="Дата от"
                  minW={[150, 150]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                    setPage(1);
                  }}
                />
                {dateFrom && (
                  <InputRightElement mr="0" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setDateFrom("")}
                    />
                  </InputRightElement>
                )}
                {!dateFrom && (
                  <InputRightElement>
                    <CalendarIcon color="blue.500" />
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Дата до </Text>
            <Flex>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="date"
                  placeholder="Дата до"
                  minW={[150, 150]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={dateTo}
                  onChange={(e) => {
                    setDateTo(e.target.value);
                    setPage(1);
                  }}
                />
                {dateTo && (
                  <InputRightElement mr="0" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setDateTo("")}
                    />
                  </InputRightElement>
                )}
                {!dateTo && (
                  <InputRightElement>
                    <CalendarIcon color="blue.500" />
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
          </Box>

          <Box ml={4}>
            <Text
              fontSize={[8, 12]}
              // mb='-4'
              // zIndex="modal"
            >
              Адрес / хеш
            </Text>
            <Box>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  // placeholder="Поиск по логину"
                  //   minW={[213, 236]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={addressForSearch}
                  onChange={(e) => {
                    setPage(1);
                    setAddressForSearch(e.target.value);
                  }}
                />
                {loginForSearch && (
                  <InputRightElement mr="10" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setAddressForSearch("")}
                    />
                  </InputRightElement>
                )}
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Box>
          </Box>
        </Wrap>
      </Box>

      {/* ***************************************************************ИТОГ */}

      <Wrap m={8} justify={isLargerThan500 ? "end" : "start"}>
        <Box minWidth={[220, 220]}>
          <Heading
            as="h2"
            mt={6}
            size="xl"
            mb="4"
            color="#6484AA"
            textAlign="start"
          >
            Крипто-транзакции USDT
          </Heading>
        </Box>
        <Spacer />

        <Box
          boxShadow="lg"
          p="6"
          rounded="md"
          bg="white"
          minWidth={[190, 220]}
          minHeight={20}
        >
          <Center>
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`Найдено: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${totalCount} `}
            </Text>
          </Center>
        </Box>

        {/* <Box
          boxShadow="lg"
          p="6"
          rounded="md"
          bg="white"
          minHeight={20}
          ml="4"
          minWidth={[190, 220]}
        >
          <Center ml="4">
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`Остаток: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${getNumberWithSpaces(totalSum)} `}
            </Text>
          </Center>
        </Box> */}
      </Wrap>

      <Box
        m={8}
        color="#6484AA"
        fontSize={[12, 10, 12, 14, 14]}
        fontWeight="500"
      >
        {/* ***************************************************************ТАБЛИЦА ЗАГОЛОВОК */}
        <div className="dashBoard_content__margin dashBoard_w100 dashBoard_flex dashBoard_admin__table">
          {isLargerThan500 && (
            <div
              className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
              style={{ padding: "8px" }}
            >
              <p className="dashBoard_operation__item--column12min2">
                <Center>
                  <Box mr={2}>
                    <FcDataConfiguration />
                  </Box>
                </Center>
              </p>

              <p className="dashBoard_operation__item--column12min2">
                <Center>ID</Center>
              </p>

              <p className="dashBoard_operation__item--column12m">
                <Center>Контрагент</Center>
              </p>

              <p className="dashBoard_operation__item--column12m">
                <Center
                  onClick={() => {
                    if (sort === 1) {
                      setSort(0);
                    } else setSort(1);
                  }}
                >
                  Создана
                  <Box ml={2}>
                    {sort === 1 && <FcAlphabeticalSortingZa size={20} />}
                    {sort === 0 && <FcAlphabeticalSortingAz size={20} />}
                    {(sort === SortType.SumAsc ||
                      sort === SortType.SumDesc) && (
                      <FcAlphabeticalSortingZa
                        size={20}
                        style={{ opacity: "0.5" }}
                      />
                    )}
                  </Box>
                </Center>
              </p>
              <p className="dashBoard_operation__item--column12m">
                <Center>Истекает</Center>
              </p>

              <p className="dashBoard_operation__item--column12m">
                <Center>Адрес</Center>
              </p>

              <p className="dashBoard_operation__item--column12m">
                <Center>Хеш</Center>
              </p>

              <p className="dashBoard_operation__item--column12m">
                <Center>Попытка</Center>
              </p>

              <p className="dashBoard_operation__item--column12m">
                <Center>Курс</Center>
              </p>

              <p className="dashBoard_operation__item--column12m">
                <Center>Состояние</Center>
              </p>

              <p className="dashBoard_operation__item--column12max2">
                <Center>Ошибка</Center>
              </p>

              <p className="dashBoard_operation__item--column12max2">
                <Center>Суммы</Center>
              </p>
            </div>
          )}

          {/* ***************************************************************ТАБЛИЦА ДАННЫЕ */}
          {items &&
            items.length > 0 &&
            items.map((item, index) => (
              <Box
                w="100%"
                key={item.id}
                bg={getColorStatus(item.state.id)}
                fontSize={[12, 8, 10, 12, 14]}
                color="#000000"
                borderBottom="1px solid #dfdfdf"
              >
                <div
                  key={item.id}
                  className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
                >
                  <p className="dashBoard_operation__item--column12min2">
                    <Center>
                      <TransactionMenu
                        item={item}
                        setChosenItem={setChosenItem}
                      />
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column12min2">
                    <Center
                      filter="auto"
                      blur={getTdBlur(item.id)}
                      onClick={() => {
                        if (sort === 1) {
                          setSort(0);
                        } else setSort(1);
                      }}
                      fontWeight={sort == 0 || sort == 1 ? "700" : "500"}
                    >
                      {!isLargerThan500 && "ID: "}
                      {item?.id}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column12m">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Логин: "}
                      {item?.user?.login}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column12m">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Создана: "}
                      <Moment format="DD/MM/YY, HH:mm:ss">
                        {item?.createdAt}
                      </Moment>
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column12m">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Истекает: "}
                      <Moment format="DD/MM/YY, HH:mm:ss">
                        {item?.expiredAt}
                      </Moment>
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column12m">
                    <Center
                      filter="auto"
                      blur={getTdBlur(item.id)}
                      onClick={() => {
                        window.navigator.clipboard.writeText(
                          item?.address ? item?.address : "-"
                        );
                        toast(
                          `Адрес транзакции скопирован в буфер обмена! 
                            `,
                          {
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            style: { width: "400px", background: "blue" },
                          }
                        );
                      }}
                    >
                      {!isLargerThan500 && item?.inGasHash && "Адрес: "}
                      &nbsp;
                      {item?.address ? (
                        <Tooltip
                          label={item?.address}
                          color="blue.400"
                          bg="gray.100"
                          fontSize="16"
                        >
                          <Box
                            color="blue.400"
                            as={motion.div}
                            whileHover={{ scale: 1.1, cursor: "pointer" }}
                          >
                            {`${item.address.slice(
                              0,
                              3
                            )}...${item.address.slice(
                              item.address.length - 4,
                              item.address.length - 1
                            )}`}
                          </Box>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column12m">
                    <Center
                      filter="auto"
                      blur={getTdBlur(item.id)}
                      onClick={() => {
                        window.navigator.clipboard.writeText(
                          item?.inGasHash ? item?.inGasHash : "-"
                        );
                        toast(
                          `Hash транзакции скопирован в буфер обмена! 
                            `,
                          {
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            style: { width: "400px", background: "blue" },
                          }
                        );
                      }}
                    >
                      {!isLargerThan500 && item?.inGasHash && "Хеш: "}
                      &nbsp;
                      {item?.inGasHash ? (
                        <Tooltip
                          label={item?.inGasHash}
                          color="blue.400"
                          bg="gray.100"
                          fontSize="16"
                        >
                          <Box
                            color="blue.400"
                            as={motion.div}
                            whileHover={{ scale: 1.1, cursor: "pointer" }}
                          >
                            {`${item.inGasHash.slice(
                              0,
                              3
                            )}...${item.inGasHash.slice(
                              item.inGasHash.length - 4,
                              item.inGasHash.length - 1
                            )}`}
                          </Box>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column12m">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Попытка: "}
                      {item?.attempts}
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column12m">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Курс: "}
                      {item?.rate}
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column12m">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Состояние: "}

                      {item?.state?.name}
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column12max2">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Ошибка: "}
                      {item?.errorMessage ? item?.errorMessage : "-"}
                    </Center>
                  </p>

                  <p className="dashBoard_operation__item--column12max2">
                    <Center
                      filter="auto"
                      blur={getTdBlur(item.id)}
                      fontSize="12"
                    >
                      {!isLargerThan500 && "Суммы: "}
                      Ожидается в крипте:
                      {item?.expectedTokenAmount}
                      <br />
                      Ожидается в $: {item?.expectedFiatAmount}
                      <br />
                      Поступило в крипте: {item?.actualTokenAmount}
                    </Center>
                  </p>
                </div>
              </Box>
            ))}

          {totalCount > itemPerPage && (
            <Center fontSize="12">
              <div className="pagination">
                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={prevPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === 1}
                >
                  Назад
                </Button>
                <button
                  onClick={() => setPage(1)}
                  className={`page ${page === 1 && "disabled"}`}
                >
                  1
                </button>
                {gaps.before ? "..." : null}

                {gaps.paginationGroup.map((el) => (
                  <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                  >
                    {el}
                  </button>
                ))}
                {gaps.after ? "..." : null}
                <button
                  onClick={() => setPage(totalPages)}
                  className={`page ${page === totalPages && "disabled"}`}
                >
                  {totalPages}
                </button>

                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={nextPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === totalPages}
                >
                  Вперед
                </Button>
              </div>
            </Center>
          )}
        </div>
      </Box>
    </>
  );
};

export default CryptoTokenInvoiceList;
