import React, { useEffect } from "react";
import "./Partners.scss";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";
import { getPartners } from "../../../features/partners/partnersSlice";

import { motion } from "framer-motion";

import Moment from "react-moment";
import { Avatar, Button, Center } from "@chakra-ui/react";

import { usePagination } from "../../../hooks/pagination/usePagination";
import "../../../hooks/pagination/pagination.scss";
import { GlobalSpinner } from "../../../Components/LK/GlobalSpinner/GlobalSpinner";
import { getMtbRate } from "../../../features/mtb/mtbSlice";
import Meta from "../../../utils/seo/Meta";

const partnersPerPage = 8;

const Partners = () => {
  const { t } = useTranslation();

  const { partners, isLoading } = useAppSelector((state) => state.partner);
  const { course } = useAppSelector((state) => state.mtb);
  const dispatch = useAppDispatch();

  // Get MTB Course
  useEffect(() => {
    dispatch(getMtbRate());
  }, []);

  //Framer motion
  const listAnimation = {
    hidden: {
      y: 300,
      opacity: 0,
    },
    visible: (custom) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: custom * 0.3,
        duration: 0.5,
        type: "just",
      },
    }),
  };

  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: partnersPerPage,
    count: partners.totalCount,
  });

  // Pagination request
  useEffect(() => {
    const data = {
      take: partnersPerPage,
      skip: (page - 1) * partnersPerPage,
    };
    dispatch(getPartners(data));
  }, [page]);

  // if (isLoading) {
  //   return <GlobalSpinner />;
  // }

  return (
    <>
      <Meta title="Personal account" />

      <section className="section__padding partners">
        <img src="/assets/img/wave.png" alt="Фон" className="faq__img" />

        <div className="container">
          <div className="partners__inner">
            <h2 className="title title__center">
              <span className="black blue">{t("PartnersPage.our")}</span>
              {t("PartnersPage.partners")}
            </h2>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: "10px", once: true }}
              className="partners__content"
            >
              {partners?.items?.map((partner, index) => (
                <motion.div
                  variants={listAnimation}
                  custom={index}
                  key={partner.id}
                  className="partners__item"
                >
                  <div className="partners__item--img--inner">
                    <Avatar
                      w={["300px", "175px", "225px"]}
                      h="320px"
                      src={partner.imageUrl}
                      borderRadius={"none"}
                      alt={partner.name ? partner.name : partner.login}
                    />
                  </div>

                  <div className="partners__item--text--inner">
                    <p className="text__name">
                      {partner.name ? partner.name : partner.login}
                    </p>

                    <p className="text__date partners__text--date">
                      <img
                        src="/assets/img/date.svg"
                        alt="Календарь"
                        className="text__date--img"
                      />
                      <Moment format="DD MMMM YYYY">{partner.createdAt}</Moment>
                    </p>

                    <p className="partners__text--text">
                      {t("PartnersPage.income")}:
                    </p>

                    <p className="partners__text--money">
                      {(+partner.balances[2].value * course.rate).toFixed()} $
                    </p>

                    {partner.contacts.socialMediaTg != "" && (
                      <div className="partners__social--inner">
                        <a
                          href={`https://t.me/${partner.contacts.socialMediaTg}`}
                          target="_blanc"
                          className="partners__social--link"
                        >
                          <img
                            src="/assets/img/telegram.svg"
                            alt="Telegram"
                            className="w100"
                          />
                        </a>
                      </div>
                    )}
                  </div>
                </motion.div>
              ))}
            </motion.div>

            {partners.totalCount >= partnersPerPage && (
              <Center w={["100%"]} mt={4}>
                <div className="pagination">
                  <Button
                    onClick={prevPage}
                    w={24}
                    h={12}
                    colorScheme="blue"
                    borderRadius="xl"
                    isDisabled={page === 1}
                  >
                    {t("PartnersPage.back")}
                  </Button>
                  <button
                    onClick={() => setPage(1)}
                    className={`page ${page === 1 && "disabled"}`}
                  >
                    1
                  </button>
                  {gaps.before ? "..." : null}

                  {gaps.paginationGroup.map((el) => (
                    <button
                      onClick={() => setPage(el)}
                      key={el}
                      className={`page ${page === el ? "active" : ""}`}
                    >
                      {el}
                    </button>
                  ))}
                  {gaps.after ? "..." : null}
                  <button
                    onClick={() => setPage(totalPages)}
                    className={`page ${page === totalPages && "disabled"}`}
                  >
                    {totalPages}
                  </button>

                  <Button
                    onClick={nextPage}
                    w={24}
                    h={12}
                    colorScheme="blue"
                    borderRadius="xl"
                    isDisabled={page === totalPages}
                  >
                    {t("PartnersPage.next")}
                  </Button>
                </div>
              </Center>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Partners;
