import {
  CalendarIcon,
  CheckIcon,
  Search2Icon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
  useMediaQuery,
  Wrap,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import "./DashBoard.scss";
import { usePagination } from "../../hooks/pagination/usePagination";
import {
  FcBusinessman,
  FcCollaboration,
  FcCalendar,
  FcMoneyTransfer,
  FcAlphabeticalSortingZa,
  FcAlphabeticalSortingAz,
  FcContacts,
  FcNumericalSorting12,
  FcNumericalSorting21,
} from "react-icons/fc";
import { AiFillCloseCircle } from "react-icons/ai";
import { motion } from "framer-motion";
import UserItem from "./UserItem";
import BlockModal from "./BlockModal";
import { BlockChatModal } from "./BlockChatModal";
import $api from "../../http/apiService";
import { toast } from "react-toastify";
import UserMenu from "./UserMenu";
import Moment from "react-moment";
import PaymentTransferItem from "./PaymentTransferItem";
import getNumberWithSpaces from "../../utils/getNumberWithSpaces";
import DiagramLine from "./component/diagrams/diagramLine";
import { useTranslation } from "react-i18next";

const userPerPage = 20;

export type ContactsType = {
  email: string;
  mobilePhone?: string;
  socialMediaTg?: string;
  socialMediaVk?: string;
  tgUserId: number;
  visibleTelegram: boolean;
};

export type AccountType = {
  id: number;
  name: string;
};

export type IdNameType = {
  id: number;
  name: string;
};

export type BalancesType = {
  account: AccountType;
  rate: {
    change: number;
    date: string;
    id: number;
    rate: number;
  };
  usd: number;
  value: number;
};

export type ParentType = {
  id: number;
  login: string;
  name: string;
};

export type InviteType = {
  activatedAt: string;
  activeRefCount: number;
  createdA: string;
  inactiveRefCount: number;
  parent: ParentType;
  program: string;
  refCount: number;
};

export type UserTypeForAdmin = {
  activeRefCount: number;
  balances?: BalancesType[];
  comment?: string;
  contacts: ContactsType;
  createdAt: string;
  editor: string;
  id: number;
  imageUrl?: string;
  inactiveRefCount: number;
  interfaceLang: string;
  invites: InviteType[];
  isActive?: boolean;
  isAdmin?: boolean;
  isBlocked?: boolean;
  isChatBlocked?: boolean;
  isVerified?: boolean;
  lastLoginedAt?: string;
  login: string;
  marketingStatus?: {
    partnerRate: number;
    rank: string;
  };
  masterKey?: string;
  name: string;
  newMessagesCount: number;
  newNotificationsCount: number;
  profileCompleteness: number;
  refCount: number;
  sessionTime: number;
  visibleAsPartner: boolean;
  wallets?: {
    tron: string;
    mtb: string;
  };
};

export type rdType = {
  login?: string;
  take: number;
  skip: number;
  sort?: number | string;
  exactMatch?: boolean;
  from?: string;
  to?: string;
  structure?: number;
  onlyActive?: boolean;
  inviterLogin?: string;
  id?: number;
  program?: string;
  onlyBlocked?: boolean;
};
const DiagramValues = [
  { value: "week", ru: "Неделя" },
  { value: "month", ru: "Месяц" },
  { value: "halfAYear", ru: "Полгода" },
  { value: "year", ru: "Год" },
];
export enum DiagramEnum {
  week = "week",
  month = "month",
  halfAYear = "halfAYear",
  year = "year",
}
type DiagramDataType = {
  x: string;
  y: number;
};

enum SortEnum {
  CreatedAtAsc = "CreatedAtAsc",
  CreatedAtDesc = "CreatedAtDesc",
  LoginAsc = "LoginAsc",
  LoginDesc = "LoginDesc",
  NameAsc = "NameAsc",
  NameDesc = "NameDesc",
  MainBalanceAsc = "MainBalanceAsc",
  MainBalanceDesc = "MainBalanceDesc",
  BonusBalanceAsc = "BonusBalanceAsc",
  BonusBalanceDesc = "BonusBalanceDesc",
  CloneBalanceAsc = "CloneBalanceAsc",
  CloneBalanceDesc = "CloneBalanceDesc",
}

const UserList: FC = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState<UserTypeForAdmin>();
  const [users, setUsers] = useState<UserTypeForAdmin[]>([]);
  const [usersTotalCount, setUsersTotalCount] = useState<number>(0);
  const [totalBalanceMain, setTotalBalanceMain] = useState<number>(0);
  const [totalBalanceBonus, setTotalBalanceBonus] = useState<number>(0);
  const [totalBalanceClone, setTotalBalanceClone] = useState<number>(0);

  const [loginForSearch, setLoginForSearch] = useState("");
  const [inviterLoginForSearch, setInviterLoginForSearch] = useState("");
  const [isExactForSearch, setIsExactForSearch] = useState(false);
  const [isActiveForSearch, setIsActiveForSearch] = useState(false);
  const [programForSearch, setProgramForSearch] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [chosenUser, setChosenUser] = useState<UserTypeForAdmin>();
  const [isBlockForSearch, setIsBlockForSearch] = useState(false);
  const [sort, setSort] = useState<string | number>(1);
  const [isNeedUpdate, setIsNeedUpdate] = useState(1);
  const [isAddComment, setIsAddComment] = useState(false);
  const [programs, setPrograms] = useState<IdNameType[]>([]);
  const [loginForTransfer, setLoginForTransfer] = useState<string>("");
  const [accounts, setAccounts] = useState<IdNameType[]>([]);
  const [valueForDiagram, setValueForDiagram] = useState<string>(
    DiagramEnum.week
  );
  const [dataForDiagram, setDataForDiagram] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");

  const getCommonInfo = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await $api.get("/api/Marketing/programs", config);
      const data: IdNameType[] = response?.data;
      setPrograms(data);
      const res = await $api.get("/api/payment-account");
      const accounts: IdNameType[] = res.data;
      setAccounts(accounts);
    } catch (e) {
      console.error("Ошибка получения программ", e);
    }
  };

  useEffect(() => {
    getCommonInfo();
  }, []);

  useEffect(() => {
    console.log("valueForDiagram", valueForDiagram);
    getDiagramData();
  }, [valueForDiagram]);

  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: userPerPage,
    count: usersTotalCount,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenBlockModal,
    onOpen: onOpenBlockModal,
    onClose: onCloseBlockModal,
  } = useDisclosure();

  const {
    isOpen: isOpenBlockChatModal,
    onOpen: onOpenBlockChatModal,
    onClose: onCloseBlockChatModal,
  } = useDisclosure();

  const {
    isOpen: isOpenTransferModal,
    onOpen: onOpenTransferModal,
    onClose: onCloseTransferModal,
  } = useDisclosure();

  useEffect(() => {
    if (isAddComment) {
      onOpenBlockModal();
    }
  }, [isAddComment]);

  const getAllUsers = async () => {
    const rd: rdType = {
      take: userPerPage,
      skip: 0,
      sort: SortEnum.MainBalanceDesc,
      // sort: "ASC",
    };
    if (page > 0) {
      rd.skip = (page - 1) * userPerPage;
    }
    if (loginForSearch && Number.isFinite(+loginForSearch[0])) {
      rd.id = +loginForSearch;
    } else {
      rd.login = loginForSearch;
    }
    if (inviterLoginForSearch) {
      rd.inviterLogin = inviterLoginForSearch;
    }
    // if (isExactForSearch) {
    rd.exactMatch = Boolean(isExactForSearch);
    // }
    rd.onlyActive = isActiveForSearch;
    if (dateFrom) {
      rd.from = dateFrom;
    }
    if (dateTo) {
      rd.to = dateTo;
    }
    if (programForSearch) {
      rd.program = programForSearch;
    }
    if (isBlockForSearch) {
      rd.onlyBlocked = isBlockForSearch;
    }
    rd.sort = sort;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await $api.post("/api/admin/User/all", rd, config);
      setUsers(response?.data?.users);
      setUsersTotalCount(response?.data?.totalCount);
      setTotalBalanceMain(response?.data?.totalBalanceMain);
      setTotalBalanceBonus(response?.data?.totalBalanceBonus);
      setTotalBalanceClone(response?.data?.totalBalanceClone);
    } catch (e) {
      console.error("Ошибка получения пользователей", e);
      toast.error("Ошибка получения пользователей");
    } finally {
      //   setIsEditOpen(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [
    page,
    loginForSearch,
    inviterLoginForSearch,
    isExactForSearch,
    isActiveForSearch,
    programForSearch,
    dateFrom,
    dateTo,
    isBlockForSearch,
    sort,
    isNeedUpdate,
  ]);

  const getTdColor = (user: UserTypeForAdmin) => {
    if (!chosenUser) {
      if (user.isBlocked) {
        return "#FF000033";
      } else return user.isActive ? "green.100" : "";
    } else if (user.login === chosenUser.login) {
      if (user.isBlocked) {
        return "#FF000033";
      } else return user.isActive ? "green.100" : "gray.100";
    } else if (user.isActive) {
      return "green.100";
    } else return "gray.300";
  };
  const getTdBlur = (login: string) => {
    if (!chosenUser) {
      return "";
    } else if (login === chosenUser.login) {
      return "";
    } else return "2px";
  };
  const getDiagramData = async () => {
    setIsLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const rd: any = {};

    const array: DiagramDataType[] = [];
    const arrayActive: DiagramDataType[] = [];
    if (valueForDiagram === DiagramEnum.week) {
      try {
        const today = new Date();
        for (let i = 0; i < 7; i++) {
          const nextTime = new Date(
            +today - i * 24 * 60 * 60 * 1000
          ).toISOString();
          rd.to = nextTime.toString();
          rd.from = new Date(
            +new Date(nextTime) - 1 * 24 * 60 * 60 * 1000
          ).toISOString();
          rd.onlyActive = false;
          const response: any = await $api.post(
            "/api/admin/User/all",
            rd,
            config
          );
          array.push({
            x: isLargerThan500
              ? nextTime.toString().split("T")[0]
              : nextTime.toString().split("T")[0].slice(5),
            y: response?.data?.totalCount,
          });
          rd.onlyActive = true;
          const responseActive: any = await $api.post(
            "/api/admin/User/all",
            rd,
            config
          );
          arrayActive.push({
            x: isLargerThan500
              ? nextTime.toString().split("T")[0]
              : nextTime.toString().split("T")[0].slice(5),
            y: responseActive?.data?.totalCount,
          });
        }
      } catch (e) {
        toast.error("Ошибка получения пользователей. Невалидные даты.");
        console.error("Ошибка получения пользователей", e);
      }
    } else if (valueForDiagram === DiagramEnum.month) {
      try {
        const today = new Date();
        for (let i = 0; i < 4; i++) {
          const nextTime = new Date(
            +today - i * 7 * 24 * 60 * 60 * 1000
          ).toISOString();
          rd.to = nextTime.toString();
          rd.from = new Date(
            +new Date(nextTime) - 7 * 24 * 60 * 60 * 1000
          ).toISOString();
          rd.onlyActive = false;
          const response: any = await $api.post(
            "/api/admin/User/all",
            rd,
            config
          );
          array.push({
            x: nextTime.toString().split("T")[0],
            y: response?.data?.totalCount,
          });
          rd.onlyActive = true;
          const responseActive: any = await $api.post(
            "/api/admin/User/all",
            rd,
            config
          );

          arrayActive.push({
            x: nextTime.toString().split("T")[0],
            y: responseActive?.data?.totalCount,
          });
        }
      } catch (e) {
        toast.error("Ошибка получения пользователей. Невалидные даты.");
        console.error("Ошибка получения пользователей", e);
      }
    } else if (valueForDiagram === DiagramEnum.halfAYear) {
      try {
        const today = new Date();
        for (let i = 0; i < 6; i++) {
          const nextTime = new Date(
            +today - i * 30.5 * 24 * 60 * 60 * 1000
          ).toISOString();
          rd.to = nextTime.toString();
          rd.from = new Date(
            +new Date(nextTime) - 30.5 * 24 * 60 * 60 * 1000
          ).toISOString();
          rd.onlyActive = false;
          const response: any = await $api.post(
            "/api/admin/User/all",
            rd,
            config
          );
          array.push({
            x: isLargerThan500
              ? nextTime.toString().split("T")[0]
              : nextTime.toString().slice(5, 7),
            y: response?.data?.totalCount,
          });
          rd.onlyActive = true;
          const responseActive: any = await $api.post(
            "/api/admin/User/all",
            rd,
            config
          );
          arrayActive.push({
            x: isLargerThan500
              ? nextTime.toString().split("T")[0]
              : nextTime.toString().slice(5, 7),
            y: responseActive?.data?.totalCount,
          });
        }
      } catch (e) {
        toast.error("Ошибка получения пользователей. Невалидные даты.");
        console.error("Ошибка получения пользователей", e);
      }
    } else if (valueForDiagram === DiagramEnum.year) {
      try {
        const today = new Date();
        for (let i = 0; i < 12; i++) {
          const nextTime = new Date(
            +today - i * 30.5 * 24 * 60 * 60 * 1000
          ).toISOString();
          rd.to = nextTime.toString();
          rd.from = new Date(
            +new Date(nextTime) - 30.5 * 24 * 60 * 60 * 1000
          ).toISOString();
          rd.onlyActive = false;
          const response: any = await $api.post(
            "/api/admin/User/all",
            rd,
            config
          );
          array.push({
            x: isLargerThan500
              ? nextTime.toString().split("T")[0]
              : nextTime.toString().slice(5, 7),
            y: response?.data?.totalCount,
          });
          rd.onlyActive = true;
          const responseActive: any = await $api.post(
            "/api/admin/User/all",
            rd,
            config
          );
          arrayActive.push({
            x: isLargerThan500
              ? nextTime.toString().split("T")[0]
              : nextTime.toString().slice(5, 7),
            y: responseActive?.data?.totalCount,
          });
        }
      } catch (e) {
        toast.error("Ошибка получения пользователей. Невалидные даты.");
        console.error("Ошибка получения пользователей", e);
      }
    }
    if (array?.length > 0) {
      const myDiagramData: any = [
        {
          id: t("Manager.active"),
          color: "hsl(280, 70%, 50%)",
          data: arrayActive.reverse(),
        },
        {
          id: t("Manager.registrations"),
          color: "hsl(291, 70%, 50%)",
          data: array.reverse(),
        },
      ];
      console.log("myDiagramData", myDiagramData);
      setDataForDiagram(myDiagramData);
      setIsLoading(false);
    }
  };

  return (
    <>
      <UserItem
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        user={user}
        isNeedUpdate={isNeedUpdate}
        setIsNeedUpdate={setIsNeedUpdate}
      />

      <BlockModal
        isOpen={isOpenBlockModal}
        onOpen={onOpenBlockModal}
        onClose={onCloseBlockModal}
        user={user}
        isNeedUpdate={isNeedUpdate}
        setIsNeedUpdate={setIsNeedUpdate}
        isAddComment={isAddComment}
        setIsAddComment={setIsAddComment}
      />

      <BlockChatModal
        isOpen={isOpenBlockChatModal}
        onOpen={onOpenBlockChatModal}
        onClose={onCloseBlockChatModal}
        user={user}
        isNeedUpdate={isNeedUpdate}
        setIsNeedUpdate={setIsNeedUpdate}
        isAddComment={isAddComment}
        setIsAddComment={setIsAddComment}
      />

      <PaymentTransferItem
        isOpen={isOpenTransferModal}
        onOpen={onOpenTransferModal}
        onClose={onCloseTransferModal}
        isNeedUpdate={isNeedUpdate}
        setIsNeedUpdate={setIsNeedUpdate}
        accounts={accounts}
        loginForTransfer={loginForTransfer}
        setLoginForTransfer={setLoginForTransfer}
      />
      <Box w="100%">
        <Box
          m={8}
          fontSize={[12, 8, 10, 12, 14]}
          boxShadow="lg"
          p="8"
          rounded="md"
          bg="white"
          // font-family="DM Sans"
        >
          <Wrap>
            <Flex>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  placeholder={t("Manager.search_by_login")}
                  minW={[200, 150]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={loginForSearch}
                  onChange={(e) => {
                    setLoginForSearch(e.target.value);
                    setPage(1);
                  }}
                />
                {loginForSearch && (
                  <InputRightElement mr="10" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setLoginForSearch("")}
                    />
                  </InputRightElement>
                )}
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
              {/* <Box
                alignSelf="center"
                ml="2"
                mr="2"
                color="#3786e5"
                onClick={() => setLoginForSearch("")}
              >
                <AiFillCloseCircle size={20} />
              </Box> */}
            </Flex>

            <Flex>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  placeholder={t("Manager.search_by_sponsor")}
                  minW={[200, 150]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={inviterLoginForSearch}
                  onChange={(e) => {
                    setInviterLoginForSearch(e.target.value);
                    setPage(1);
                  }}
                />
                {inviterLoginForSearch && (
                  <InputRightElement mr="10" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setInviterLoginForSearch("")}
                    />
                  </InputRightElement>
                )}
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Flex>

            <Flex ml={4}>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="date"
                  placeholder="Дата от"
                  style={{ color: "red" }}
                  color="red"
                  minW={[150, 150]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                    setPage(1);
                  }}
                />
                {dateFrom && (
                  <InputRightElement mr="0" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setDateFrom("")}
                    />
                  </InputRightElement>
                )}
                {!dateFrom && (
                  <InputRightElement>
                    <CalendarIcon color="blue.500" />
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
            {/* <input
              type="date"
              placeholder="Дата от"
              color="red"
              style={{ color: "red" }}
            /> */}

            <Flex ml={4}>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="date"
                  placeholder="Дата до"
                  minW={[150, 150]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={dateTo}
                  onChange={(e) => {
                    setDateTo(e.target.value);
                    setPage(1);
                  }}
                />
                {dateTo && (
                  <InputRightElement mr="0" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setDateTo("")}
                    />
                  </InputRightElement>
                )}
                {!dateTo && (
                  <InputRightElement>
                    <CalendarIcon color="blue.500" />
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>

            <Box ml={4}>
              <InputGroup>
                <Select
                  border="1px solid #3786e5"
                  // borderWidth="2px"
                  variant="primary"
                  size="lg"
                  color="gray.500"
                  borderRadius="xl"
                  minW={[200, 150]}
                  fontSize={[12, 8, 10, 12, 14]}
                  onChange={(e) => {
                    setProgramForSearch(e.target.value);
                    setPage(1);
                  }}
                >
                  <option value="">{t("Manager.all")}</option>
                  {programs.map((elem) => (
                    <option key={elem.id} value={elem.name}>
                      {elem.name}
                    </option>
                  ))}
                  {/* <option value="">Все</option>
                  <option value="Fast money">Fast money</option>
                  <option value="Travel money">Travel money</option>
                  <option value="Auto money"> Auto money</option> */}
                </Select>
                <InputRightElement>
                  <CheckIcon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Box>

            <Flex ml={4} direction={isLargerThan500 ? "column" : "row"}>
              <Checkbox
                size="lg"
                color="#6484AA"
                fontWeight="400"
                onChange={(e) => {
                  setIsExactForSearch(!isExactForSearch);
                  setPage(1);
                }}
              >
                {t("Manager.exact_match")}
              </Checkbox>

              <Checkbox
                size="lg"
                color="#6484AA"
                fontWeight="400"
                ml={isLargerThan500 ? "0" : "4px"}
                onChange={(e) => {
                  setIsActiveForSearch(!isActiveForSearch);
                  setPage(1);
                }}
              >
                {t("Manager.active")}
              </Checkbox>
            </Flex>
            <Flex ml={4} direction="column">
              <Checkbox
                size="lg"
                color="#6484AA"
                fontWeight="400"
                // backgroundColor="#4D9FFF"
                onChange={(e) => {
                  setIsBlockForSearch(!isBlockForSearch);
                  setPage(1);
                }}
              >
                {t("Manager.blocked")}
              </Checkbox>
            </Flex>

            <Spacer />
          </Wrap>
        </Box>

        {/* </Box> */}

        <Wrap m={8} justify={isLargerThan500 ? "end" : "start"}>
          <Box minW={[200, 100]}>
            <Heading
              as="h2"
              mt={6}
              size="xl"
              mb="4"
              color="#6484AA"
              textAlign="start"
            >
              {t("Manager.partners")}
            </Heading>
          </Box>
          <Spacer />
          <Box
            boxShadow="lg"
            p="6"
            rounded="md"
            bg="white"
            minWidth={[180, 220]}
            minHeight={20}
          >
            <Center>
              <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
                {`${t("Manager.found_users")}: `}
              </Text>
              <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
                {` ${usersTotalCount} `}
              </Text>
            </Center>
          </Box>
          <Box
            boxShadow="lg"
            p="6"
            rounded="md"
            bg="white"
            minWidth={[195, 220]}
            minHeight={20}
            ml="4"
          >
            <Center>
              <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
                {`${t("Manager.total_bonuses")}: `}
              </Text>
              <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
                {` ${getNumberWithSpaces(totalBalanceBonus)} `}
              </Text>
            </Center>
          </Box>
          <Box
            boxShadow="lg"
            p="6"
            rounded="md"
            bg="white"
            minHeight={20}
            alignSelf="end"
            ml="4"
            minWidth={[180, 220]}
          >
            <Center ml="4">
              <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
                {`${t("Manager.total_clones")}: `}
              </Text>
              <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
                {` ${getNumberWithSpaces(totalBalanceClone)} `}
              </Text>
            </Center>
          </Box>
          <Box
            boxShadow="lg"
            p="6"
            rounded="md"
            bg="white"
            minHeight={20}
            ml="4"
            minWidth={[180, 220]}
            // direction="row"
          >
            <Center ml="4">
              <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
                {`${t("Manager.total_main")}: `}
              </Text>
              <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
                {` ${getNumberWithSpaces(totalBalanceMain)} `}
              </Text>
            </Center>
          </Box>
        </Wrap>

        <Box
          m={8}
          color="#6484AA"
          fontSize={[12, 10, 12, 14, 16]}
          fontWeight="500"
        >
          <div className="dashBoard_content__margin dashBoard_w100 dashBoard_flex dashBoard_admin__table">
            {isLargerThan500 && (
              <div
                className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
                style={{ padding: "4px" }}
              >
                <p className="dashBoard_operation__item--column9min2">
                  <Center>
                    {/* <Box mr={2}>
                    <FcDataConfiguration />
                  </Box> */}
                    {t("Manager.menu")}
                  </Center>
                </p>
                <p className="dashBoard_operation__item--column9min2">
                  <Center
                    onClick={() => {
                      if (sort == 1) {
                        setSort(0);
                      } else {
                        setSort(1);
                      }
                    }}
                  >
                    ID
                    <Box ml={2}>
                      {sort === 1 && <FcAlphabeticalSortingZa size={20} />}
                      {sort === 0 && <FcAlphabeticalSortingAz size={20} />}
                      {sort !== 0 &&
                        sort !== 1 &&
                        sort !== SortEnum.CreatedAtAsc &&
                        sort !== SortEnum.CreatedAtDesc && (
                          <FcAlphabeticalSortingZa
                            size={20}
                            style={{ opacity: "0.5" }}
                          />
                        )}
                    </Box>
                  </Center>
                </p>

                <p className="dashBoard_operation__item--column9">
                  <Center
                    onClick={() => {
                      if (sort == SortEnum.LoginAsc) {
                        setSort(SortEnum.LoginDesc);
                      } else {
                        setSort(SortEnum.LoginAsc);
                      }
                    }}
                  >
                    <Box mr={2}>
                      <FcBusinessman />
                    </Box>
                    {t("Manager.login")}
                    <Box ml={2}>
                      {sort === SortEnum.LoginAsc && (
                        <FcAlphabeticalSortingAz size={20} />
                      )}
                      {sort === SortEnum.LoginDesc && (
                        <FcAlphabeticalSortingZa size={20} />
                      )}
                      {sort !== SortEnum.LoginAsc &&
                        sort !== SortEnum.LoginDesc && (
                          <FcAlphabeticalSortingAz
                            size={20}
                            style={{ opacity: "0.5" }}
                          />
                        )}
                    </Box>
                  </Center>
                </p>
                <p className="dashBoard_operation__item--column9">
                  <Center
                    onClick={() => {
                      if (sort == SortEnum.NameAsc) {
                        setSort(SortEnum.NameDesc);
                      } else {
                        setSort(SortEnum.NameAsc);
                      }
                    }}
                  >
                    <Box mr={2}>
                      <FcContacts />
                    </Box>
                    {t("Manager.name")}
                    <Box ml={2}>
                      {sort === SortEnum.NameAsc && (
                        <FcAlphabeticalSortingAz size={20} />
                      )}
                      {sort === SortEnum.NameDesc && (
                        <FcAlphabeticalSortingZa size={20} />
                      )}
                      {sort !== SortEnum.NameAsc &&
                        sort !== SortEnum.NameDesc && (
                          <FcAlphabeticalSortingAz
                            size={20}
                            style={{ opacity: "0.5" }}
                          />
                        )}
                    </Box>
                  </Center>
                </p>
                <p className="dashBoard_operation__item--column9">
                  <Center>
                    <Box mr={2}>
                      <FcCollaboration />
                    </Box>
                    {t("Manager.sponsor")}
                  </Center>
                </p>
                <p className="dashBoard_operation__item--column9">
                  <Center>
                    <Box mr={2}>
                      <FcCalendar />
                    </Box>
                    {t("Manager.registration_date")}
                  </Center>
                </p>

                <p className="dashBoard_operation__item--column9">
                  <Center
                    onClick={() => {
                      if (sort == SortEnum.BonusBalanceDesc) {
                        setSort(SortEnum.BonusBalanceAsc);
                      } else {
                        setSort(SortEnum.BonusBalanceDesc);
                      }
                    }}
                  >
                    <Box mr={2}>
                      <FcMoneyTransfer />
                    </Box>
                    {t("Manager.bonuses")}
                    <Box ml={2}>
                      {sort === SortEnum.BonusBalanceAsc && (
                        <FcNumericalSorting12 size={20} />
                      )}
                      {sort === SortEnum.BonusBalanceDesc && (
                        <FcNumericalSorting21 size={20} />
                      )}
                      {sort !== SortEnum.BonusBalanceAsc &&
                        sort !== SortEnum.BonusBalanceDesc && (
                          <FcNumericalSorting21
                            size={20}
                            style={{ opacity: "0.5" }}
                          />
                        )}
                    </Box>
                  </Center>
                </p>

                <p className="dashBoard_operation__item--column9">
                  <Center
                    onClick={() => {
                      if (sort == SortEnum.CloneBalanceDesc) {
                        setSort(SortEnum.CloneBalanceAsc);
                      } else {
                        setSort(SortEnum.CloneBalanceDesc);
                      }
                    }}
                  >
                    <Box mr={2}>
                      <FcMoneyTransfer />
                    </Box>
                    {t("Manager.clones")}
                    <Box ml={2}>
                      {sort === SortEnum.CloneBalanceAsc && (
                        <FcNumericalSorting12 size={20} />
                      )}
                      {sort === SortEnum.CloneBalanceDesc && (
                        <FcNumericalSorting21 size={20} />
                      )}
                      {sort !== SortEnum.CloneBalanceAsc &&
                        sort !== SortEnum.CloneBalanceDesc && (
                          <FcNumericalSorting21
                            size={20}
                            style={{ opacity: "0.5" }}
                          />
                        )}
                    </Box>
                  </Center>
                </p>

                <p className="dashBoard_operation__item--column9">
                  <Center
                    onClick={() => {
                      if (sort == SortEnum.MainBalanceDesc) {
                        setSort(SortEnum.MainBalanceAsc);
                      } else {
                        setSort(SortEnum.MainBalanceDesc);
                      }
                    }}
                  >
                    <Box mr={2}>
                      <FcMoneyTransfer />
                    </Box>
                    {t("Manager.main")}
                    <Box ml={2}>
                      {sort === SortEnum.MainBalanceAsc && (
                        <FcNumericalSorting12 size={20} />
                      )}
                      {sort === SortEnum.MainBalanceDesc && (
                        <FcNumericalSorting21 size={20} />
                      )}
                      {sort !== SortEnum.MainBalanceAsc &&
                        sort !== SortEnum.MainBalanceDesc && (
                          <FcNumericalSorting21
                            size={20}
                            style={{ opacity: "0.5" }}
                          />
                        )}
                    </Box>
                  </Center>
                </p>
                <p className="dashBoard_operation__item--column9">
                  <Center>{t("Manager.editor")}</Center>
                </p>
              </div>
            )}

            {/* *****************************************************************************DATA */}

            {users &&
              users.length > 0 &&
              users.map((user, index) => (
                <Box
                  w="100%"
                  minH="30px"
                  key={user.id}
                  bg={getTdColor(user)}
                  fontSize={[12, 8, 10, 12, 14]}
                  color="#000000"
                  borderBottom="1px solid #dfdfdf"
                >
                  <div
                    key={user.id}
                    className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
                  >
                    <p className="dashBoard_operation__item--column9min2">
                      <Center>
                        <UserMenu
                          user={user}
                          setChosenUser={setChosenUser}
                          setUser={setUser}
                          onOpen={onOpen}
                          onOpenBlockModal={onOpenBlockModal}
                          onOpenBlockChatModal={onOpenBlockChatModal}
                          setIsAddComment={setIsAddComment}
                          onOpenTransferModal={onOpenTransferModal}
                          setLoginForTransfer={setLoginForTransfer}
                        />
                      </Center>
                    </p>
                    <p className="dashBoard_operation__item--column9min2">
                      <Center
                        filter="auto"
                        blur={getTdBlur(user.login)}
                        fontWeight={sort == 0 || sort == 1 ? "700" : "500"}
                        onClick={() => {
                          if (sort == 1) {
                            setSort(0);
                          } else {
                            setSort(1);
                          }
                        }}
                      >
                        {!isLargerThan500 && "ID: "}
                        {user?.id ? user.id : "id"}
                      </Center>
                    </p>
                    <p className="dashBoard_operation__item--column9">
                      <Center
                        filter="auto"
                        blur={getTdBlur(user.login)}
                        onClick={() => {
                          if (sort == SortEnum.LoginAsc) {
                            setSort(SortEnum.LoginDesc);
                          } else {
                            setSort(SortEnum.LoginAsc);
                          }
                        }}
                        fontWeight={
                          sort == SortEnum.LoginAsc ||
                          sort == SortEnum.LoginDesc
                            ? "700"
                            : "500"
                        }
                      >
                        {!isLargerThan500 && "Логин: "}
                        <Text color={user.isChatBlocked ? "yellow.500" : ""}>
                          {user.login}
                        </Text>
                      </Center>
                    </p>
                    <p className="dashBoard_operation__item--column9">
                      <Center
                        filter="auto"
                        blur={getTdBlur(user.login)}
                        onClick={() => {
                          if (sort == SortEnum.NameAsc) {
                            setSort(SortEnum.NameDesc);
                          } else {
                            setSort(SortEnum.NameAsc);
                          }
                        }}
                        fontWeight={
                          sort == SortEnum.NameAsc || sort == SortEnum.NameDesc
                            ? "700"
                            : "500"
                        }
                      >
                        {!isLargerThan500 && <p>Имя: &nbsp;</p>}
                        {user.name}
                      </Center>
                    </p>

                    <p className="dashBoard_operation__item--column9">
                      <Center filter="auto" blur={getTdBlur(user.login)}>
                        {!isLargerThan500 && user.invites[0].parent?.login && (
                          <p>Спонсор: &nbsp;</p>
                        )}
                        {user.invites[0].parent?.login}
                      </Center>
                    </p>
                    <p className="dashBoard_operation__item--column9">
                      <Center filter="auto" blur={getTdBlur(user.login)}>
                        {!isLargerThan500 && <p>Дата регистрации: &nbsp;</p>}
                        <Moment format="DD-MM-YY, hh:mm:ss">
                          {user.createdAt}
                        </Moment>
                      </Center>
                    </p>

                    <p className="dashBoard_operation__item--column9">
                      <Center
                        filter="auto"
                        blur={getTdBlur(user.login)}
                        onClick={() => {
                          if (sort == SortEnum.BonusBalanceDesc) {
                            setSort(SortEnum.BonusBalanceAsc);
                          } else {
                            setSort(SortEnum.BonusBalanceDesc);
                          }
                        }}
                        fontWeight={
                          sort == SortEnum.BonusBalanceAsc ||
                          sort == SortEnum.BonusBalanceDesc
                            ? "700"
                            : "500"
                        }
                      >
                        {!isLargerThan500 && "Бонусы: "}
                        {user?.balances?.[1]?.value}{" "}
                      </Center>
                    </p>

                    <p className="dashBoard_operation__item--column9">
                      <Center
                        filter="auto"
                        blur={getTdBlur(user.login)}
                        onClick={() => {
                          if (sort == SortEnum.CloneBalanceDesc) {
                            setSort(SortEnum.CloneBalanceAsc);
                          } else {
                            setSort(SortEnum.CloneBalanceDesc);
                          }
                        }}
                        fontWeight={
                          sort == SortEnum.CloneBalanceAsc ||
                          sort == SortEnum.CloneBalanceDesc
                            ? "700"
                            : "500"
                        }
                      >
                        {!isLargerThan500 && "Клоны: "}
                        {user?.balances?.[2]?.value}{" "}
                      </Center>
                    </p>

                    <p className="dashBoard_operation__item--column9">
                      <Center
                        filter="auto"
                        blur={getTdBlur(user.login)}
                        onClick={() => {
                          if (sort == SortEnum.MainBalanceDesc) {
                            setSort(SortEnum.MainBalanceAsc);
                          } else {
                            setSort(SortEnum.MainBalanceDesc);
                          }
                        }}
                        fontWeight={
                          sort == SortEnum.MainBalanceAsc ||
                          sort == SortEnum.MainBalanceDesc
                            ? "700"
                            : "500"
                        }
                      >
                        {!isLargerThan500 && "Основной: "}
                        {user?.balances?.[0]?.value}{" "}
                      </Center>
                    </p>

                    <p className="dashBoard_operation__item--column9">
                      <Center filter="auto" blur={getTdBlur(user.login)}>
                        {!isLargerThan500 && <p>Редактор: &nbsp;</p>}
                        {user?.editor}
                      </Center>
                    </p>
                  </div>
                </Box>
              ))}

            {usersTotalCount > userPerPage && (
              <Center fontSize="12">
                <div className="pagination">
                  <Button
                    as={motion.div}
                    whileHover={{ scale: 1.1 }}
                    onClick={prevPage}
                    w={24}
                    h={12}
                    colorScheme="blue"
                    borderRadius="xl"
                    isDisabled={page === 1}
                  >
                    Назад
                  </Button>
                  <button
                    onClick={() => setPage(1)}
                    className={`page ${page === 1 && "disabled"}`}
                  >
                    1
                  </button>
                  {gaps.before ? "..." : null}

                  {gaps.paginationGroup.map((el) => (
                    <button
                      onClick={() => setPage(el)}
                      key={el}
                      className={`page ${page === el ? "active" : ""}`}
                    >
                      {el}
                    </button>
                  ))}
                  {gaps.after ? "..." : null}
                  <button
                    onClick={() => setPage(totalPages)}
                    className={`page ${page === totalPages && "disabled"}`}
                  >
                    {totalPages}
                  </button>

                  <Button
                    as={motion.div}
                    whileHover={{ scale: 1.1 }}
                    onClick={nextPage}
                    w={24}
                    h={12}
                    colorScheme="blue"
                    borderRadius="xl"
                    isDisabled={page === totalPages}
                  >
                    Вперед
                  </Button>
                </div>
              </Center>
            )}
          </div>
        </Box>

        <Box
          h={500}
          w="100%"
          pb="12"
          // maxWidth={["400", "700", "700", "1000", "1600"]}
        >
          <Select
            border="1px solid #3786e5"
            variant="primary"
            size="lg"
            color="gray.500"
            borderRadius="xl"
            fontSize={[12, 8, 10, 12, 14]}
            w="80"
            ml="8"
            onChange={(e) => setValueForDiagram(e.target.value)}
          >
            {/* {DiagramValues.map((elem) => (
              <option key={elem.value} value={elem.value}>
                {t(`Manager.${elem.value}`)}
              </option>
            ))} */}
            {Object.keys(DiagramEnum).map((elem) => (
              <option key={elem} value={elem}>
                {t(`Manager.${elem}`)}
              </option>
            ))}
          </Select>
          {/* <AreaDiagram data={{ data: dataForDiagram }} /> */}
          {/* <DiagramBump data={{ data: dataForDiagram }} /> */}
          {!isLoading ? (
            <DiagramLine data={{ data: dataForDiagram }} />
          ) : (
            <Center w="100%" h="100%" mt={20}>
              <Spinner
                thickness="14px"
                speed="0.7s"
                emptyColor="#61D64A"
                color="#1c3b6a"
                w={24}
                h={24}
              />
            </Center>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UserList;
