import { Select } from "@chakra-ui/react";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdArrowDropDown } from "react-icons/md";
import { setUserLang } from "../../../features/userInfo/userInfoSlice";
import { useAppSelector, useAppDispatch } from "../../../store";

const LanguageSelect: FC = () => {
  const { userData } = useAppSelector((state) => state.userInfo);
  const dispatch = useAppDispatch();
  // Translation i18next
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  // Change language in local storage and backend
  useEffect(() => {
    const setLangToLocalStorage = () => {
      switch (userData.interfaceLang) {
        case "Ru":
          changeLanguage("ru");
          break;
        case "Eng":
          changeLanguage("en");
          break;
        case "De":
          changeLanguage("de");
          break;

        default:
          break;
      }
    };

    setLangToLocalStorage();
  }, [userData.interfaceLang]);

  // Initialize React hook form
  const { register, handleSubmit, reset } = useForm();

  // Change language in frontend and backend
  const onSubmit = (lang: any) => {
    switch (lang) {
      case "Ru":
        changeLanguage("ru");
        break;
      case "Eng":
        changeLanguage("en");
        break;
      case "De":
        changeLanguage("de");
        break;

      default:
        break;
    }

    const fetchData = {
      lang: lang,
    };
    dispatch(setUserLang(fetchData));

    setTimeout(() => {
      document.location.reload();
    }, 1200);
  };

  // Default values hook form initialize
  useEffect(() => {
    reset(userData);
  }, [userData]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Select
          {...register("interfaceLang")}
          onChange={(e) => onSubmit(e.target.value)}
          icon={<MdArrowDropDown color="red.500" />}
          w={40}
          h={"50px"}
          borderRadius="full"
          variant="outline"
          border="2px"
          borderColor="#9ca2b8"
          fontSize={20}
          color="#fff"
          focusBorderColor="#fff"
          cursor="pointer"
        >
          <option style={{ backgroundColor: "#1c3b6a" }} value="Ru">
            RU
          </option>
          <option style={{ backgroundColor: "#1c3b6a" }} value="Eng">
            EN
          </option>
          <option style={{ backgroundColor: "#1c3b6a" }} value="De">
            DE
          </option>
        </Select>
      </form>
    </>
  );
};

export default LanguageSelect;
