import React, { useEffect, useState } from "react";
import { WithdrawHistory } from "../../../Components/LK/WithdrawHistory/WithdrawHistory";
import { CurrencyRates } from "../../../Components/LK/CurrencyRates/CurrencyRates";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";
import { getUserInfo } from "../../../features/userInfo/userInfoSlice";
import {
  getWithdrawalsHistory,
  sendPaymentToWithdraw,
  getWithdrawMethods,
} from "../../../features/payment/paymentSlice";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import UsdtIcon2 from "../../../assets/img/Tether_Logo.svg.png";
import MTBIcon from "../../../assets/img/mtb-logo.png";

import { Button, Flex, Image, Input, Select, Text } from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

const OutputMoney = () => {
  // Translation i18next
  const { t } = useTranslation();

  const { userData } = useAppSelector((state) => state.userInfo);
  const { withdrawMethods } = useAppSelector((state) => state.payment);
  const { course } = useAppSelector((state) => state.mtb);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Get actual payment systems to withdraw to redux
  useEffect(() => {
    dispatch(getWithdrawMethods());
  }, []);

  const [usdtAmount, setUsdtAmount] = useState<any>("");
  const [mtbUsdtAmount, setMtbUsdtAmount] = useState<number | string>("");

  const [mtbAmount, setMtbAmount] = useState<any>("");
  const [balanceUsdt, setBalanceUsdt] = useState<number | string>("");
  const [balanceMTB, setBalanceMTB] = useState<number | string>("");

  const replaceSymbolToDots = (login: string) => {
    const firstLetters = login.split("").slice(0, 4);
    const lastLetters = login.split("").slice(-4);
    const arr = [...firstLetters, "....", ...lastLetters];
    return arr.join("");
  };

  // Set user basic balance
  useEffect(() => {
    const userBalanceBasic = () => {
      userData?.balances.map((item, index) => {
        if (item.account.id === 1) {
          setBalanceUsdt(item.usd);
          setBalanceMTB(item.value);
        }
      });
    };

    userBalanceBasic();
  }, [userData]);

  interface IProps {
    wallet: string;
    paymentSystemId: number;
    amount: number;
  }

  const withdrawHandler = async ({
    wallet,
    paymentSystemId,
    amount,
  }: IProps) => {
    const data = {
      paymentAccountId: 1,
      paymentSystemId: paymentSystemId,
      amount: amount,
      walletId: wallet,
    };

    // Protect withdraw
    if (paymentSystemId == 1 && amount > balanceMTB) {
      setUsdtAmount("");
      setMtbUsdtAmount("");
      setMtbAmount("");
      return toast.error("Недостаточно средств!");
    }
    if (paymentSystemId == 2 && amount > balanceUsdt) {
      setUsdtAmount("");
      setMtbUsdtAmount("");
      setMtbAmount("");
      return toast.error("Недостаточно средств!");
    }

    dispatch(sendPaymentToWithdraw(data));
    setUsdtAmount("");
    setMtbUsdtAmount("");
    setMtbAmount("");
    setTimeout(() => {
      const data = {
        take: 10,
        skip: 0,
      };
      dispatch(getWithdrawalsHistory(data));
      dispatch(getUserInfo());
    }, 1500);
  };

  return (
    <>
      <Meta title="Personal account" />
      <div className="admin__content--content flex w100">
        <div className="w100 flexcenter">
          <h3 className="admin__title--inner w100">
            <img
              className="admin__title--icon"
              src="/assets/img/output-red.svg"
              alt="Иконка"
            />
            {t("OutputMoneyPage.withdrawals")}
          </h3>

          <div className="flexdef w100 content__margin pay__content">
            {userData.wallets?.tron ? (
              <>
                <Flex
                  m={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <p className="pay__text">
                    {" "}
                    {t("OutputMoneyPage.output_via")}:
                  </p>
                  <Image mt={2} boxSize="50px" src={UsdtIcon2} />
                </Flex>

                <div className="flex">
                  <p className="pay__text">
                    {" "}
                    {t("OutputMoneyPage.commission")}:
                  </p>
                  <p className="pay__title mt1">0%</p>
                </div>

                <Flex direction="column" justifyContent="space-between" h={32}>
                  <Text color="#fff" fontWeight="medium">
                    {t("OutputMoneyPage.choose_wallet")}:
                  </Text>
                  <Select
                    // onChange={(e) => setVisaIdentifier(e.target.value)}
                    w={80}
                    h={20}
                    color="#000"
                    bg="#fff"
                    fontSize={16}
                    fontWeight="normal"
                  >
                    <option value={userData.wallets.tron}>
                      {replaceSymbolToDots(userData?.wallets?.tron)}
                    </option>
                  </Select>
                </Flex>

                {/* <Flex
                  direction="column"
                  justifyContent="space-between"
                  m={[2, 0]}
                >
                  <p className="pay__text">
                    {" "}
                    {t("OutputMoneyPage.amount_in_MTB")}:
                  </p>
                  <Input
                    value={mtbUsdtAmount}
                    onChange={(e: any) => {
                      if (isFinite(Number(e.target.value))) {
                        setMtbUsdtAmount(e.target.value);
                        setUsdtAmount(
                          (+e.target.value * course?.rate).toFixed(2)
                        );
                      }
                    }}
                    w={[80, 60]}
                    h={[24, 20]}
                    m={2}
                    color="#000"
                    bg="#fff"
                    fontSize={16}
                    fontWeight="normal"
                    placeholder="100 $"
                  />
                </Flex> */}

                <div className="flex">
                  <Flex justifyContent="space-between" m={[2, 0]} w="100%">
                    <p className="pay__text">
                      {t("OutputMoneyPage.amount_in_USDT")}:
                    </p>
                    <Button
                      onClick={() => {
                        setMtbUsdtAmount(balanceMTB);
                        setUsdtAmount((+balanceMTB * course?.rate).toFixed(2));
                      }}
                      isDisabled={!userData.balances[1].usd}
                      ml={[6, 16]}
                      colorScheme="red"
                    >
                      {t("OutputMoneyPage.total")}
                    </Button>
                  </Flex>

                  <div className="flexdef  pay__wrapper">
                    <Input
                      value={usdtAmount}
                      onChange={(e) => {
                        if (isFinite(Number(e.target.value))) {
                          setUsdtAmount(e.target.value);
                          setMtbUsdtAmount(
                            (+e.target.value / course?.rate).toFixed(2)
                          );
                        }
                      }}
                      // type="number"
                      w={[80, 60]}
                      h={[24, 20]}
                      m={2}
                      color="#000"
                      bg="#fff"
                      fontSize={16}
                      fontWeight="normal"
                      placeholder="100 USD"
                    />

                    <Button
                      onClick={() =>
                        withdrawHandler({
                          wallet: userData.wallets.tron,
                          paymentSystemId: withdrawMethods[1]?.id,
                          amount: +mtbUsdtAmount,
                        })
                      }
                      isDisabled={!usdtAmount}
                      m={2}
                      w={[80, 60]}
                      h={[24, 20]}
                      colorScheme="green"
                      borderRadius="10px"
                      boxShadow="2xl"
                    >
                      <Text fontWeight="medium" color="#fff" fontSize={20}>
                        {t("OutputMoneyPage.withdraw")}
                      </Text>
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Flex
                  m={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <p className="pay__text">
                    {t("OutputMoneyPage.output_via")}:
                  </p>
                  <Image mt={2} boxSize="50px" src={UsdtIcon2} />
                </Flex>

                <div className="flex">
                  <p className="pay__title mt1">
                    {t("OutputMoneyPage.withdrawal_not_available")}!
                  </p>
                </div>
                <div className="flex">
                  <p className="pay__text">
                    {t("OutputMoneyPage.need_to_add_details")}!
                  </p>

                  <Button
                    mt={4}
                    w="100%"
                    h={12}
                    colorScheme="green"
                    onClick={() => navigate("/admin/settings/3")}
                  >
                    {t("OutputMoneyPage.add_wallet")}
                  </Button>
                </div>
              </>
            )}
          </div>

          {/* <div className="flexdef w100 content__margin pay__content">
            {userData.wallets?.mtb ? (
              <>
                <Flex
                  // m={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <p className="pay__text">
                    {t("OutputMoneyPage.output_via")}:
                  </p>
                  <Image
                    m={2}
                    w={40}
                    h={20}
                    objectFit="scale-down"
                    src={MTBIcon}
                  />
                </Flex>

                <div className="flex">
                  <p className="pay__text">
                    {t("OutputMoneyPage.commission")}:
                  </p>

                  <p className="pay__title mt1">0%</p>
                </div>
                <Flex direction="column" justifyContent="space-between" h={32}>
                  <Text color="#fff" fontWeight="medium">
                    {t("OutputMoneyPage.choose_wallet")}:
                  </Text>
                  <Select
                    // onChange={(e) => setVisaIdentifier(e.target.value)}
                    w={80}
                    h={20}
                    color="#000"
                    bg="#fff"
                    fontSize={16}
                    fontWeight="normal"
                  >
                    <option value={userData.wallets.mtb}>
                      {replaceSymbolToDots(userData?.wallets?.mtb)}
                    </option>
                  </Select>
                </Flex>

                <Flex
                  w={[80, 60]}
                  m={2}
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                ></Flex>

                <div className="flex">
                  <Flex justifyContent="space-between" m={[2, 0]} w="100%">
                    <p className="pay__text">
                      {t("OutputMoneyPage.amount_in_MTB")}:
                    </p>
                    <Button
                      onClick={() => setMtbAmount(balanceMTB)}
                      isDisabled={!userData.balances[1].value}
                      ml={[6, 20]}
                      colorScheme="red"
                    >
                      {t("OutputMoneyPage.total")}:
                    </Button>
                  </Flex>

                  <div className="flexdef pay__wrapper">
                    <Input
                      onChange={(e) => setMtbAmount(e.target.value)}
                      value={mtbAmount}
                      w={[80, 60]}
                      h={[24, 20]}
                      m={2}
                      color="#000"
                      bg="#fff"
                      fontSize={16}
                      fontWeight="normal"
                      placeholder="100 $"
                    />

                    <Button
                      onClick={() =>
                        withdrawHandler({
                          wallet: userData.wallets.mtb,
                          paymentSystemId: withdrawMethods[0]?.id,
                          amount: mtbAmount,
                        })
                      }
                      isDisabled={!mtbAmount}
                      m={2}
                      w={[80, 60]}
                      h={[24, 20]}
                      colorScheme="green"
                      borderRadius="10px"
                      boxShadow="2xl"
                    >
                      <Text fontWeight="medium" color="#fff" fontSize={20}>
                        {t("OutputMoneyPage.withdraw")}
                      </Text>
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Flex
                  m={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <p className="pay__text">
                    {t("OutputMoneyPage.output_via")}:
                  </p>
                  <Image
                    m={2}
                    w={40}
                    h={20}
                    objectFit="scale-down"
                    src={MTBIcon}
                  />
                </Flex>
                <div className="flex">
                  <p className="pay__title mt1">
                    {" "}
                    {t("OutputMoneyPage.withdrawal_not_available")}!
                  </p>
                </div>
                <div className="flex">
                  <p className="pay__text">
                    {t("OutputMoneyPage.need_to_add_details")}!
                  </p>

                  <Button
                    mt={4}
                    w="100%"
                    h={12}
                    colorScheme="green"
                    onClick={() => navigate("/admin/settings")}
                  >
                    {t("OutputMoneyPage.add_wallet")}
                  </Button>
                </div>
              </>
            )}
          </div> */}

          <WithdrawHistory />
        </div>
      </div>

      <CurrencyRates />
    </>
  );
};

export default OutputMoney;
