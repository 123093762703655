import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import $api from "../../http/apiService";

interface ICurrencies {
  type: string;
  currencies: [];
}

interface IFreeKassaState {
  currencies: number[];
  linkFK: any;
  isLoadingFKMethods: boolean;
  isLoadingFKLink: boolean;
  isErrorFreeKassa: boolean;
  message: any;
}

const initialState: IFreeKassaState = {
  currencies: [4, 6, 7, 8, 9, 10, 11, 12, 32, 33, 35],
  linkFK: "",
  isLoadingFKMethods: false,
  isLoadingFKLink: false,
  isErrorFreeKassa: false,
  message: "",
};

// Get all Freekassa methods
export const getFKCurrencies = createAsyncThunk(
  "fkCurrencies/get",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };
      const response = await $api.get("/api/Payment/fk-currencies", config);
      const arr: any = [];
      response.data.currencies.map((item: any) => {
        arr.push(item.id);
      });
      return arr;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getFKLink = createAsyncThunk(
  "fkLink/post",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      console.log(data);
      const config = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };
      const response = await $api.post("/api/Payment/fk-link", data, config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reducer
export const freeKassaSlice = createSlice({
  name: "freeKassa",
  initialState,
  reducers: {
    resetFKLink: (state) => {
      state.linkFK = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFKCurrencies.pending, (state) => {
        state.isLoadingFKMethods = true;
        state.message = "";
      })
      .addCase(getFKCurrencies.fulfilled, (state, action) => {
        state.isLoadingFKMethods = false;
        state.currencies = action.payload;
      })
      .addCase(getFKCurrencies.rejected, (state, action) => {
        state.isLoadingFKMethods = false;
        state.isErrorFreeKassa = true;
        state.message = action.payload;
      })
      .addCase(getFKLink.pending, (state) => {
        state.isLoadingFKLink = true;
        state.message = "";
      })
      .addCase(getFKLink.fulfilled, (state, action) => {
        state.isLoadingFKLink = false;
        state.linkFK = action.payload;
      })
      .addCase(getFKLink.rejected, (state, action) => {
        state.isLoadingFKLink = false;
        state.isErrorFreeKassa = true;
        state.message = action.payload;
      });
  },
});

export const { resetFKLink } = freeKassaSlice.actions;
export default freeKassaSlice.reducer;
