import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useMediaQuery,
  Wrap,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./DashBoard.scss";
import { usePagination } from "../../hooks/pagination/usePagination";
import { MdHideSource, MdOutlineDone } from "react-icons/md";
import { motion } from "framer-motion";

import { extendTheme } from "@chakra-ui/react";
import $api from "../../http/apiService";
import { AxiosResponse } from "axios";
import {
  FcDataConfiguration,
  FcAlphabeticalSortingZa,
  FcAlphabeticalSortingAz,
  FcNumericalSorting12,
  FcNumericalSorting21,
} from "react-icons/fc";
import { AiFillCloseCircle } from "react-icons/ai";
import OutputItem from "./OutputItem";
import { CheckIcon, Search2Icon } from "@chakra-ui/icons";
import { CgMathPercent } from "react-icons/cg";
import OutputMenu from "./OutputMenu";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import { ExternalPaymentType, UserType } from "./PaymentsList";
import getNumberWithSpaces from "../../utils/getNumberWithSpaces";
import { useAppSelector } from "../../store";

const itemPerPage = 10;

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-26px)",
};

export enum StatusEnum {
  all = "Все",
  successful = "Исполнен",
  cancelled = "Отменен",
  onHold = "В ожидании",
}
export enum SortType {
  CreatedAtAsc = "CreatedAtAsc",
  CreatedAtDesc = "CreatedAtDesc",
  SumAsc = "SumAsc",
  SumDesc = "SumDesc",
}

export const OutputStatuses = [
  { id: 0, value: "Отменен", ingl: "Canceled" },
  { id: 1, value: "В ожидании", ingl: "Pending" },
  { id: 2, value: "Исполнен", ingl: "Executed" },
];

export type OutputType = {
  id: number;
  creationDate: string;
  editDate: string;
  login: string; // контрагент
  comments: string; // комментарии
  status: string; // статус
  account: string; //счет
  paymentSystem: string; //Платежная система
  wallet: string; //кошелек
  sum: number; //сумма
};

export type IdNameType = {
  id: number;
  name: string;
};

export const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label":
            {
              ...activeLabelStyles,
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
              fontSize: "xl",
            },
          },
        },
      },
    },
  },
});
export type DescriptionType = {
  lang: number | string;
  desc: string;
};
export type WithdrawalInType = {
  id: number;
  date?: string;
  sum?: number;
  desc?: DescriptionType;
  user?: UserType;
  article?: IdNameType;
  account?: IdNameType;
  transactionId?: string;
  comment?: string;
  status?: IdNameType;
  statusChangedAt?: string;
  externalPayment: ExternalPaymentType;
  editor: string;
  rate: number;
  fiatSum: number;
  // walletId?: string;
  // externalTransactionId?: string;
  // paymentSystem?: IdNameType;
};
type WithdrawalOutType = {
  paymentStatus?: number;
  paymentAccountId?: number;
  paymentSystemId?: number;
  login?: string;
  take: number;
  skip: number;
  from?: string;
  to?: string;
  exactMatch?: boolean;
  walletId?: string;
  sort?: number | SortType;
};
enum ReasonsForModal {
  cancelWithdrawal = "cancelWithdrawal",
  payWithdrawal = "payWithdrawal",
  payPartlyWithdrawal = "payPartlyWithdrawal",
}
const reasons = [
  { id: ReasonsForModal.payWithdrawal, name: "Оплата заявки" },
  { id: ReasonsForModal.payPartlyWithdrawal, name: "Частичная оплата заявки" },
  { id: ReasonsForModal.cancelWithdrawal, name: "Отмена заявки" },
];

const OutputList = () => {
  const params = useParams();
  const { userData } = useAppSelector((state) => state.userInfo);

  const [output, setOutput] = useState<WithdrawalInType>();
  const [chosenOutput, setChosenOutput] = useState<WithdrawalInType>();
  const [myOutputs, setOutputs] = useState<WithdrawalInType[]>([]);
  const [outputsTotalCount, setOutputsTotalCount] = useState<number>(3);
  const [outputsTotalSum, setOutputsTotalSum] = useState<number>(0);
  const [accounts, setAccounts] = useState<IdNameType[]>([]);
  const [articles, setArticles] = useState<IdNameType[]>([]);
  const [methods, setMethods] = useState<IdNameType[]>([]);
  const [loginForSearch, setLoginForSearch] = useState("");
  const [statusForSearch, setStatusForSearch] = useState(1);
  const [accountForSearch, setAccauntForSearch] = useState(-1);
  const [methodForSearch, setMethodForSearch] = useState(-1);
  const [walletForSearch, setWalletForSearch] = useState("");
  const [dateFrom, setDateFrom] = useState<string>(
    // new Date().toISOString().split("T")[0]
    ""
  );
  const [dateTo, setDateTo] = useState("");
  const [sort, setSort] = useState<number | SortType>(1);
  const [reasonForModal, setReasonForModal] = useState<ReasonsForModal>();
  const [commenRequired, setCommenRequired] = useState<any>({});
  const [isNeedUpdate, setIsNeedUpdate] = useState(1);
  const [cryptoBalanceNative, setCryptoBalanceNative] = useState(0);
  const [cryptoBalanceToken, setCryptoBalanceToken] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenActionModal,
    onOpen: onOpenActionModal,
    onClose: onCloseActionModal,
  } = useDisclosure();
  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: itemPerPage,
    count: outputsTotalCount,
  });
  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");

  useEffect(() => {
    if (params?.login) {
      setLoginForSearch(params?.login);
    }
  }, []);
  useEffect(() => {
    getOutputLists();
  }, [
    loginForSearch,
    statusForSearch,
    accountForSearch,
    methodForSearch,
    dateFrom,
    dateTo,
    walletForSearch,
    page,
    sort,
    isNeedUpdate,
  ]);

  const getOutputLists = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const rd: WithdrawalOutType = {
      take: itemPerPage,
      skip: 0,
      sort: sort,
    };
    if (page > 0) {
      rd.skip = (page - 1) * itemPerPage;
    }
    if (statusForSearch >= 0) {
      rd.paymentStatus = statusForSearch;
    }
    if (accountForSearch >= 0) {
      rd.paymentAccountId = accountForSearch;
    }
    if (methodForSearch >= 0) {
      rd.paymentSystemId = methodForSearch;
    }
    if (loginForSearch) {
      rd.login = loginForSearch;
    }
    if (dateFrom) {
      rd.from = dateFrom;
    }
    if (dateTo) {
      rd.to = dateTo;
    }
    if (walletForSearch) {
      rd.walletId = walletForSearch;
    }
    try {
      const response: AxiosResponse = await $api.post(
        "/api/admin/Withdrawal/all",
        rd,
        config
      );
      const data: WithdrawalInType[] = response.data.items;
      setOutputs(data);
      setOutputsTotalCount(response?.data?.totalCount);
      setOutputsTotalSum(response?.data?.totalSum);
    } catch (e) {
      console.error(e);
    }
  };

  const payWithdrawal = async (data: any) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const rd: any = {
      sum: data?.sum > 0 ? data.sum : data.sum * -1,
    };
    if (data.comment) {
      rd.comment = data.comment;
    }
    try {
      const response: AxiosResponse | any = await $api.put(
        `/api/admin/Withdrawal/${output?.id}/pay`,
        rd,
        config
      );
      console.log("response", response);
      if (response.status >= 200 && response.status <= 300) {
        toast("Платеж успешно осуществлен");
        // setCommenRequired({});
      } else if (response.status >= 400 && response.data.title) {
        toast.error(`Ошибка проведения платежа: ${response.data.title}`);
      }
    } catch (e: any) {
      console.error(e);
      toast.error(`Ошибка проведения платежа: ${e?.message} `);
    } finally {
      onCloseActionModal();
    }
  };

  const cancelWithdrawal = async (data: any) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const rd: any = {};
    if (data.comment) {
      rd.comment = data.comment;
    }

    try {
      const response: AxiosResponse | any = await $api.put(
        `/api/admin/Withdrawal/${output?.id}/cancel`,
        rd,
        config
      );
      console.log("response", response);
      if (response.status >= 200 && response.status <= 300) {
        toast("Платеж успешно отменен");
        setCommenRequired({});
      } else if (response.status >= 400 && response.data.title) {
        toast.error(`Ошибка отмены платежа: ${response.data.title}`);
      }
    } catch (e: any) {
      console.error(e);
      toast.error(`Ошибка отмены платежа: ${e?.message} `);
    } finally {
      onCloseActionModal();
    }
  };

  const getInfoForFilter = async () => {
    try {
      const res = await $api.get("/api/payment-account");
      const accounts: IdNameType[] = res.data;
      setAccounts(accounts);
      const res1 = await $api.get("api/payment-article");
      const articles: IdNameType[] = res1.data;
      // console.log(articles);
      setArticles(articles);
      const res2 = await $api.get("/api/payment-system");
      const methods: IdNameType[] = res2.data;
      setMethods(methods);
      // console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  const getColor = (output: WithdrawalInType) => {
    if (output.status?.name === StatusEnum.successful) {
      return "#00FF3833";
    } else if (output.status?.name === StatusEnum.onHold) {
      return "#FAFF004A";
    } else if (output.status?.name === StatusEnum.cancelled) {
      return "#FF000033";
    }
  };
  const getTdBlur = (id: number) => {
    if (!chosenOutput) {
      return "";
    } else if (id === chosenOutput.id) {
      return "";
    } else return "2px";
  };

  useEffect(() => {
    // getPayments();
    getInfoForFilter();
  }, []);

  type IFormInput = {
    comment?: string;
    sum?: number;
  };
  // React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: "all",
  });
  // Default values hook form initialize
  useEffect(() => {
    reset(output);
  }, [output]);

  const onSubmitBlockForm: SubmitHandler<IFormInput> = async (data) => {
    if (
      reasonForModal === ReasonsForModal.payWithdrawal ||
      reasonForModal === ReasonsForModal.payPartlyWithdrawal
    ) {
      payWithdrawal(data);
    } else if (reasonForModal === ReasonsForModal.cancelWithdrawal) {
      cancelWithdrawal(data);
    }
    setTimeout(() => setIsNeedUpdate(isNeedUpdate + 1), 5000);
    // setIsNeedUpdate(isNeedUpdate + 1);
  };


  const getCryptoBalance = async () => {
    try {
      const res = await $api.get("/api/admin/crypto-token-invoice/tron-balance");
      console.log("/api/admin/crypto-token-invoice/tron-balance", res);
      if (res?.status>=200 && res.status<300){
        setCryptoBalanceNative(res.data?.native);
        setCryptoBalanceToken(res?.data?.token);
      }

    } catch (e) {
      console.error(e);
    }
  }

  useEffect(()=>{
    getCryptoBalance();
  }, [userData])

  return (
    <Box
      w="100%"
    // minWidth={[360, 600]}
    >
      <Modal
        isOpen={isOpenActionModal}
        onClose={onCloseActionModal}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        size={"5xl"}
        isCentered
      >
        <ModalOverlay
          backdropBlur="5px"
          bg="blackAlpha.300"
          backdropFilter="blur(10px) "
        />
        <ModalContent>
          <ModalHeader fontSize={24} color="blue.500" fontWeight="bold">
            <Center>
              {reasons.find((elem) => elem.id === reasonForModal)?.name}
              {` №${output?.id}`}
              {/* {props.user?.isBlocked
              ? `Разблокировать пользователя ${props?.user?.name}`
              : `Блокировать пользователя ${props?.user?.name}`}{" "} */}
              {/* {getTitle()} */}
            </Center>
          </ModalHeader>
          <form onSubmit={handleSubmit(onSubmitBlockForm)}>
            <ModalBody fontSize={16}>
              <Text fontSize={12} color="gray.500" mt={8}>
                Сумма
              </Text>
              <Input
                placeholder="Сумма"
                variant="primary"
                fontSize={[12, 8, 10, 12, 14]}
                type="text"
                h={30}
                borderRadius="xl"
                readOnly={
                  reasonForModal === ReasonsForModal.payPartlyWithdrawal
                    ? false
                    : true
                }
                disabled={
                  reasonForModal === ReasonsForModal.payPartlyWithdrawal
                    ? false
                    : true
                }
                {...register("sum", {
                  required: "Поле обязательно к заполнению",
                })}
              />
              <Text fontSize={12} color="gray.500" mt={8}>
                Комментарий
              </Text>
              <Textarea
                placeholder="Комментарий"
                size="lg"
                // variant="primary"
                fontSize={[12, 8, 10, 12, 14]}
                borderRadius="xl"
                border="1px solid #3786e5"
                // readOnly={props.user?.isBlocked ? true : false}
                {...register("comment", {
                  ...commenRequired,
                })}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={handleSubmit(onSubmitBlockForm)}
                isDisabled={!isValid}
                type="submit"
                // colorScheme="blue"
                // colorScheme="facebook"
                ml={10}
                width={60}
                height={16}
                fontSize="2xl"
                boxShadow="lg"
                bg="#3786e5"
                color="white"
              >
                Подтвердить
              </Button>
              <Button
                // colorScheme="twitter"
                ml={10}
                width={60}
                height={16}
                fontSize="2xl"
                boxShadow="lg"
                bg="gray.500"
                color="white"
                onClick={() => {
                  // props.setIsAddComment(false);
                  setOutput(undefined);
                  setReasonForModal(undefined);
                  onCloseActionModal();
                }}
              >
                Отмена
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
{/* ***************************************************************ПОИСК */}
      <Box
        m={8}
        fontSize={[12, 8, 10, 12, 14]}
        boxShadow="lg"
        p="8"
        rounded="md"
        bg="white"
      >
        <Wrap fontSize={[12, 8, 10, 12, 14]}>
          <Box ml={4}>
            <Text fontSize={[8, 12]}>Статус</Text>
            <InputGroup>
              <Select
                border="1px solid #3786e5"
                // borderWidth="2px"
                variant="primary"
                size="lg"
                color="gray.500"
                borderRadius="xl"
                // w={80}
                minW={[200, 150]}
                fontSize={[12, 8, 10, 12, 14]}
                value={statusForSearch}
                onChange={(e) => {
                  setStatusForSearch(+e.target.value);
                  setPage(1);
                }}
              >
                <option value={-1}>Все</option>
                {OutputStatuses?.length > 0 &&
                  OutputStatuses.map((elem) => (
                    <option key={elem.id} value={elem.id}>
                      {elem.value}
                    </option>
                  ))}
              </Select>
              <InputRightElement>
                <CheckIcon color="blue.500" />
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Счет</Text>
            <InputGroup>
              <Select
                border="1px solid #3786e5"
                // borderWidth="2px"
                variant="primary"
                size="lg"
                color="gray.500"
                borderRadius="xl"
                minW={[200, 150]}
                fontSize={[12, 8, 10, 12, 14]}
                value={accountForSearch}
                onChange={(e) => {
                  setAccauntForSearch(+e.target.value);
                  setPage(1);
                }}
              >
                <option value={-1}>Все</option>
                {accounts.length > 0 &&
                  accounts.map((elem) => (
                    <option key={elem.id} value={elem.id}>
                      {elem.name}
                    </option>
                  ))}
              </Select>
              <InputRightElement>
                <CheckIcon color="blue.500" />
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Метод оплаты</Text>
            <InputGroup>
              <Select
                border="1px solid #3786e5"
                // borderWidth="2px"
                variant="primary"
                size="lg"
                color="gray.500"
                borderRadius="xl"
                minW={[200, 150]}
                fontSize={[12, 8, 10, 12, 14]}
                value={methodForSearch}
                onChange={(e) => {
                  setMethodForSearch(+e.target.value);
                  setPage(1);
                }}
              >
                <option value={-1}>Все</option>
                {methods.length > 0 &&
                  methods.map((elem) => (
                    <option key={elem.id} value={elem.id}>
                      {elem.name}
                    </option>
                  ))}
              </Select>
              <InputRightElement>
                <CheckIcon color="blue.500" />
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box ml={4}>
            <Text
              fontSize={[8, 12]}
            // mb='-4'
            // zIndex="modal"
            >
              Поиск по логину{" "}
            </Text>
            <Box>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  placeholder="Поиск по логину"
                  minW={[200, 150]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={loginForSearch}
                  onChange={(e) => {
                    setLoginForSearch(e.target.value);
                    setPage(1);
                  }}
                />
                {loginForSearch && (
                  <InputRightElement mr="10" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setLoginForSearch("")}
                    />
                  </InputRightElement>
                )}
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Box>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Дата от </Text>
            <Flex>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="date"
                  placeholder="Дата от"
                  minW={[150, 150]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                    setPage(1);
                  }}
                />
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Flex>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Дата до </Text>
            <Flex>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="date"
                  placeholder="Дата до"
                  minW={[150, 150]}
                  h={30}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={dateTo}
                  onChange={(e) => {
                    setDateTo(e.target.value);
                    setPage(1);
                  }}
                />
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Flex>
          </Box>

          <Box ml={4}>
            <Text fontSize={[8, 12]}>Кошелек</Text>
            <Box>
              <InputGroup>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  placeholder="Кошелек"
                  h={30}
                  minW={[200, 150]}
                  borderRadius="xl"
                  _placeholder={{ opacity: 1, color: "blue.500" }}
                  value={walletForSearch}
                  onChange={(e) => {
                    setWalletForSearch(e.target.value);
                    setPage(1);
                  }}
                />
                {walletForSearch && (
                  <InputRightElement mr="10" color="#3786e5">
                    <AiFillCloseCircle
                      size={15}
                      onClick={() => setWalletForSearch("")}
                    />
                  </InputRightElement>
                )}
                <InputRightElement>
                  <Search2Icon color="blue.500" />
                </InputRightElement>
              </InputGroup>
            </Box>
          </Box>

          <Spacer />
        </Wrap>
      </Box>
{/* ***************************************************************ИТОГ */}
      <Wrap m={8}>
        <Box minWidth={[220, 220]}>
          <Heading as="h2" mt={6} size="xl" mb="4" color="#6484AA">
            Заявки на вывод
          </Heading>
        </Box>
        <Spacer />
        <Box
          boxShadow="lg"
          // p="6"
          pt={2}
          rounded="md"
          bg="white"
          minWidth={[190, 220]}
          minHeight={20}
          alignSelf="end"
        >
          <Center>
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`TRX: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${cryptoBalanceNative} `}
            </Text>
          </Center>
          <Center>
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`USDT: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${cryptoBalanceToken} `}
            </Text>
          </Center>
        </Box>
        <Box
          boxShadow="lg"
          p="6"
          rounded="md"
          bg="white"
          minWidth={[190, 220]}
          minHeight={20}
          alignSelf="end"
        >
          <Center>
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`Найдено: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${outputsTotalCount} `}
            </Text>
          </Center>
        </Box>

        <Box
          boxShadow="lg"
          p="6"
          rounded="md"
          bg="white"
          minWidth={[190, 220]}
          minHeight={20}
          alignSelf="end"
          ml="4"
        >
          <Center>
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`Итого: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${getNumberWithSpaces(outputsTotalSum)} `}
            </Text>
          </Center>
        </Box>
      </Wrap>

      <OutputItem
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        output={output}
        accounts={accounts}
        methods={methods}
        isNeedUpdate={isNeedUpdate}
        setIsNeedUpdate={setIsNeedUpdate}
      />

      {/* ***************************************************************ТАБЛИЦА ЗАГОЛОВОК */}

      <Box
        m={8}
        color="#6484AA"
        fontSize={[12, 10, 12, 14, 14]}
        fontWeight="500"
      >
        <div className="dashBoard_content__margin dashBoard_w100 dashBoard_flex dashBoard_admin__table">
          {isLargerThan500 && (
            <div
              className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
              style={{ padding: "5px" }}
            >
              <p className="dashBoard_operation__item--column11min2">
                <Center>
                  <Box mr={2}>
                    <FcDataConfiguration />
                  </Box>
                </Center>
              </p>
              <p className="dashBoard_operation__item--column11min2">
                <Center
                // onClick={()=>}
                >
                  {/* <Box mr={2}>
                    <FcBusinessContact />
                  </Box> */}
                  ID
                </Center>
              </p>

              <p className="dashBoard_operation__item--column11">
                <Center
                  onClick={() => {
                    if (sort === 1) {
                      setSort(0);
                    } else setSort(1);
                  }}
                >
                  {/* <Box mr={2}>
                  <FcCalendar />
                </Box> */}
                  Дата
                  <Box ml={2}>
                    {sort === 1 && <FcAlphabeticalSortingZa size={20} />}
                    {sort === 0 && <FcAlphabeticalSortingAz size={20} />}
                    {(sort === SortType.SumAsc ||
                      sort === SortType.SumDesc) && (
                        <FcAlphabeticalSortingZa
                          size={20}
                          style={{ opacity: "0.5" }}
                        />
                      )}
                  </Box>
                </Center>
              </p>
              <p className="dashBoard_operation__item--column11">
                <Center>
                  {/* <Box mr={2}>
                  <FcBusinessman />
                </Box> */}
                  Контрагент
                  {/* <Box ml={2}>
                  <FcAlphabeticalSortingAz />
                </Box> */}
                </Center>
              </p>
              <p className="dashBoard_operation__item--column11">
                <Center>
                  {/* <Box mr={2}>
                  <FcComments />
                </Box> */}
                  Комментарии
                </Center>
              </p>
              <p className="dashBoard_operation__item--column11">
                <Center>
                  {/* <Box mr={2}>
                  <FcApprove />
                </Box> */}
                  Статус
                  {/* <Box ml={2}>
                    <FcNumericalSorting12 />
                  </Box> */}
                </Center>
              </p>

              <p className="dashBoard_operation__item--column11">
                <Center>
                  {/* <Box mr={2}>
                  <MdOutlineAccountBalanceWallet />
                </Box> */}
                  Счет
                </Center>
              </p>

              <p className="dashBoard_operation__item--column11">
                <Center>
                  {/* <Box mr={2}>
                  <MdPayment />
                </Box> */}
                  Метод
                </Center>
              </p>
              <p className="dashBoard_operation__item--column11">
                <Center>
                  {/* <Box mr={2}>
                  <BsWallet2 />
                </Box> */}
                  Кошелек
                </Center>
              </p>
              <p className="dashBoard_operation__item--column11">
                <Center
                  onClick={() => {
                    if (sort === SortType.SumAsc) {
                      setSort(SortType.SumDesc);
                    } else if (sort === SortType.SumDesc) {
                      setSort(SortType.SumAsc);
                    } else setSort(SortType.SumDesc);
                  }}
                >
                  {/* <Box mr={2}>
                  <FcMoneyTransfer />
                </Box> */}
                  Сумма
                  <Box ml={2}>
                    {sort === SortType.SumAsc && (
                      <FcNumericalSorting12 size={20} />
                    )}
                    {sort === SortType.SumDesc && (
                      <FcNumericalSorting21 size={20} />
                    )}
                    {(sort === 0 || sort === 1) && (
                      <FcNumericalSorting21
                        size={20}
                        style={{ opacity: "0.5" }}
                      />
                    )}
                  </Box>
                </Center>
              </p>
              <p className="dashBoard_operation__item--column11">
                <Center>
                  {/* <Box mr={2}>
                  <FcMoneyTransfer />
                </Box> */}
                  Действия
                </Center>
              </p>
            </div>
          )}

          {myOutputs &&
            myOutputs.length > 0 &&
            myOutputs.map((item, index) => (
              <Box
                w="100%"
                key={item.id}
                bg={getColor(item)}
                fontSize={[12, 8, 10, 12, 14]}
                color="#000000"
                borderBottom="1px solid #dfdfdf"
              >
                <div
                  key={item.id}
                  className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
                >
                  <p className="dashBoard_operation__item--column12min2">
                    <Center>
                      <OutputMenu
                        item={item}
                        setChosenItem={setChosenOutput}
                        setItem={setOutput}
                        onOpen={onOpen}
                      />
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column11min2">
                    <Center
                      filter="auto"
                      blur={getTdBlur(item?.id)}
                      onClick={() => {
                        if (sort === 1) {
                          setSort(0);
                        } else setSort(1);
                      }}
                      fontWeight={sort == 0 || sort == 1 ? "700" : "500"}
                    >
                      {!isLargerThan500 && "ID: "}
                      {item?.id}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column11">
                    <Center filter="auto" blur={getTdBlur(item?.id)} ml="12">
                      <div style={{ margin: "0 avto" }}>
                        {!isLargerThan500 && "Дата: "}
                        <Moment format="DD:MM:YY, hh:mm:ss">
                          {item?.date}
                        </Moment>
                      </div>
                    </Center>
                  </p>
                  {/* <p className="dashBoard_operation__item--column12">
                      <Center filter="auto" blur={getTdBlur(item.id)}>
                        <Moment format="DD:MM:YY, hh:mm:ss">
                          {item.editDate}
                        </Moment>
                      </Center>
                    </p> */}
                  <p className="dashBoard_operation__item--column11">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Логин: "}
                      {item?.user?.login}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column11">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && item?.comment && "Комментарий: "}
                      {item?.comment}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column11">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Статус: "}
                      {item?.status?.name}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column11">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Счет: "}
                      {item.account?.name}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column11">
                    <Center filter="auto" blur={getTdBlur(item.id)}>
                      {!isLargerThan500 && "Метод: "}
                      {item.externalPayment?.paymentSystem?.name}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column11">
                    <Center
                      filter="auto"
                      blur={getTdBlur(item.id)}
                      onClick={() => {
                        window.navigator.clipboard.writeText(
                          item?.externalPayment?.walletId
                            ? item?.externalPayment?.walletId
                            : "-"
                        );
                        toast(
                          `Кошелек скопирован в буфер обмена! 
                            `,
                          {
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            style: { width: "400px", background: "blue" },
                          }
                          // ${item?.externalPayment?.walletId}!
                        );
                        // onCopy("nea")
                        // setWalletForClipboard(
                        //   item?.walletId ? item?.walletId : "nea"
                        // );
                        // setTimeout(() => {
                        //   copyToClipboard();
                        // }, 1000);
                      }}
                    >
                      {!isLargerThan500 &&
                        item.externalPayment?.walletId &&
                        "Кошелек: "}
                      &nbsp;
                      {item?.externalPayment?.walletId ? (
                        <Tooltip
                          label={item?.externalPayment?.walletId}
                          color="blue.400"
                          bg="gray.100"
                          fontSize="16"
                        // aria-label="A tooltip"
                        >
                          <Box
                            color="blue.400"
                            as={motion.div}
                            whileHover={{ scale: 1.1, cursor: "pointer" }}
                          >
                            {`${item.externalPayment?.walletId.slice(
                              0,
                              3
                            )}...${item.externalPayment?.walletId.slice(
                              item.externalPayment?.walletId.length - 4,
                              item.externalPayment?.walletId.length - 1
                            )}`}
                          </Box>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column11">
                    <Center
                      filter="auto"
                      blur={getTdBlur(item.id)}
                      onClick={() => {
                        if (sort === SortType.SumAsc) {
                          setSort(SortType.SumDesc);
                        } else if (sort === SortType.SumDesc) {
                          setSort(SortType.SumAsc);
                        } else setSort(SortType.SumDesc);
                      }}
                      fontWeight={
                        sort == SortType.SumAsc || sort == SortType.SumDesc
                          ? "700"
                          : "500"
                      }
                    >
                      {item.sum}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column11">
                    {item?.status?.name !== StatusEnum.successful && (
                      <Center>
                        <Flex
                          direction="row"
                          filter="auto"
                          alignItems="end"
                          blur={getTdBlur(item.id)}
                        >
                          <Tooltip
                            fontSize={["12px", "16px"]}
                            label="Оплатить"
                            hasArrow
                            bg="#319795"
                            borderRadius="xl"
                          >
                            <Box
                              m="2"
                              as={motion.div}
                              whileHover={{ scale: 1.1, cursor: "pointer" }}
                              borderRadius="2xl"
                              bg="#319795"
                              onClick={() => {
                                setOutput(item);
                                setReasonForModal(
                                  ReasonsForModal.payWithdrawal
                                );
                                onOpenActionModal();
                                // payWithdrawal(item);
                              }}
                            >
                              <MdOutlineDone size={27} color="white" />
                            </Box>
                          </Tooltip>

                          <Tooltip
                            fontSize={["12px", "16px"]}
                            label="Отменить"
                            hasArrow
                            bg="#ED64A6"
                            borderRadius="xl"
                          >
                            <Box
                              m="2"
                              as={motion.div}
                              whileHover={{ scale: 1.1, cursor: "pointer" }}
                              borderRadius="2xl"
                              bg="#ED64A6"
                              onClick={() => {
                                setOutput(item);
                                setReasonForModal(
                                  ReasonsForModal.cancelWithdrawal
                                );
                                setCommenRequired({
                                  required: "Поле обязательно к заполнению",
                                });
                                onOpenActionModal();
                                // cancelWithdrawal(item);
                              }}
                            >
                              {/* <MdOutlineCancel size={27} color="white" /> */}
                              <MdHideSource size={27} color="white" />
                            </Box>
                          </Tooltip>
                          <Tooltip
                            fontSize={["12px", "16px"]}
                            label="Частичная оплата"
                            hasArrow
                            bg="#63b3ed"
                            borderRadius="xl"
                          >
                            <Box
                              m="2"
                              as={motion.div}
                              whileHover={{ scale: 1.1, cursor: "pointer" }}
                              borderRadius="2xl"
                              bg="#63b3ed"
                              onClick={() => {
                                setOutput(item);
                                setReasonForModal(
                                  ReasonsForModal.payPartlyWithdrawal
                                );
                                onOpenActionModal();
                                // payWithdrawal(item)
                              }}
                            >
                              <CgMathPercent size={27} color="white" />
                            </Box>
                          </Tooltip>

                          {/* <Button m="1" h="7" bg="green.500" color="white">
                          Оплатить
                        </Button>
                        <Button m="1" h="7" bg="red.500" color="white">
                          Отменить
                        </Button>
                        <Button m="1" h="7" bg="blue.500" color="white">
                          Частичная оплата
                        </Button> */}
                        </Flex>
                      </Center>
                    )}
                  </p>
                </div>
              </Box>
            ))}

          {outputsTotalCount > itemPerPage && (
            <Center fontSize="12">
              <div className="pagination">
                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={prevPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === 1}
                >
                  Назад
                </Button>
                <button
                  onClick={() => setPage(1)}
                  className={`page ${page === 1 && "disabled"}`}
                >
                  1
                </button>
                {gaps.before ? "..." : null}

                {gaps.paginationGroup.map((el) => (
                  <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                  >
                    {el}
                  </button>
                ))}
                {gaps.after ? "..." : null}
                <button
                  onClick={() => setPage(totalPages)}
                  className={`page ${page === totalPages && "disabled"}`}
                >
                  {totalPages}
                </button>

                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1, cursor: "pointer" }}
                  onClick={nextPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === totalPages}
                >
                  Вперед
                </Button>
              </div>
            </Center>
          )}
        </div>
      </Box>
      {/* </Box> */}

      {/* <Box
        m={8}
        mt={12}
        boxShadow="lg"
        p="6"
        rounded="md"
        bg="white"
        
      > */}
      {/* <TableContainer
          w="100%"
          // bg="gray.100"
        >
          <Table variant="simple" fontSize={16}>
            <TableCaption>
              <Center>
                <div className="pagination">
                  <Button
                    as={motion.div}
                    whileHover={{ scale: 1.1 }}
                    onClick={prevPage}
                    w={24}
                    h={12}
                    colorScheme="blue"
                    borderRadius="xl"
                    isDisabled={page === 1}
                  >
                    Назад
                  </Button>
                  <button
                    onClick={() => setPage(1)}
                    className={`page ${page === 1 && "disabled"}`}
                  >
                    1
                  </button>
                  {gaps.before ? "..." : null}

                  {gaps.paginationGroup.map((el) => (
                    <button
                      onClick={() => setPage(el)}
                      key={el}
                      className={`page ${page === el ? "active" : ""}`}
                    >
                      {el}
                    </button>
                  ))}
                  {gaps.after ? "..." : null}
                  <button
                    onClick={() => setPage(totalPages)}
                    className={`page ${page === totalPages && "disabled"}`}
                  >
                    {totalPages}
                  </button>

                  <Button
                    as={motion.div}
                    whileHover={{ scale: 1.1 }}
                    onClick={nextPage}
                    w={24}
                    h={12}
                    colorScheme="blue"
                    borderRadius="xl"
                    isDisabled={page === totalPages}
                  >
                    Вперед
                  </Button>
                </div>
              </Center>
            </TableCaption>

            <Thead>
              <Tr h={20}>
                <Th
                  color="#174073"
                  fontSize={[10, 12, 14]}
                  overflowWrap="break-word"
                >
                  <Center>
                    <Text fontWeight="bold">ID</Text>
                  </Center>
                </Th>

                <Th
                  color="#174073"
                  fontSize={[10, 12, 14]}
                  overflowWrap="break-word"
                >
                  <Center>
                    <Box mr={2}>
                      <FcCalendar size={20} />
                    </Box>
                    <Text fontWeight="bold">Дата создания</Text>
                    <Box ml={2}>
                      <FcGenericSortingDesc />
                    </Box>
                  </Center>
                </Th>

                <Th
                  color="#174073"
                  fontSize={[10, 12, 14]}
                  overflowWrap="break-word"
                >
                  <Center>
                    <Box mr={2}>
                      <FcCalendar size={20} />
                    </Box>
                    <Text fontWeight="bold">Дата редактирования</Text>
                  </Center>
                </Th>

                <Th
                  color="#174073"
                  fontSize={[10, 12, 14]}
                  overflowWrap="break-word"
                >
                  <Center>
                    <Box mr={2}>
                      <FcBusinessman size={20} />
                    </Box>
                    <Text fontWeight="bold">Контрагент</Text>
                    <Box ml={2}>
                      <FcAlphabeticalSortingAz />
                    </Box>
                  </Center>
                </Th>

                <Th
                  color="#174073"
                  fontSize={[10, 12, 14]}
                  overflowWrap="break-word"
                >
                  <Center>
                    <Box mr={2}>
                      <BiCommentDetail size={20} />
                    </Box>
                    <Text fontWeight="bold">Комментарии</Text>
                  </Center>
                </Th>

                <Th color="#174073" fontSize={[10, 12, 14]}>
                  <Center>
                    <Box mr={2}>
                      <GrStatusGood size={20} />
                    </Box>
                    Статус
                  </Center>
                </Th>

                <Th color="#174073" fontSize={[10, 12, 14]}>
                  <Center>
                    <Box mr={2}>
                      <MdOutlineAccountBalanceWallet size={20} />
                    </Box>
                    Счет
                  </Center>
                </Th>

                <Th color="#174073" fontSize={[10, 12, 14]}>
                  <Center>
                    <Box mr={2}>
                      <MdPayment size={20} />
                    </Box>
                    Платежная система
                  </Center>
                </Th>
                <Th color="#174073" fontSize={[10, 12, 14]}>
                  <Center>
                    <Box mr={2}>
                      <BsWallet2 size={20} />
                    </Box>
                    Кошелек
                  </Center>
                </Th>
                <Th color="#174073" fontSize={[10, 12, 14]}>
                  <Center>
                    <Box mr={2}>
                      <FaRegMoneyBillAlt size={20} />
                    </Box>
                    Сумма
                    <Box ml={2}>
                      <FcNumericalSorting12 />
                    </Box>
                  </Center>
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {testData
                // .slice(firstContentIndex, lastContentIndex)
                .map((output, index) => (
                  <Tr h={20} key={output.id} bg={getColor(output)}>
                    <Td color="#2b6cb0">
                      <Center
                        as={motion.div}
                        whileHover={{ scale: 1.1, cursor: "pointer" }}
                        onClick={() => {
                          setOutput(output);
                          onOpen();
                        }}
                      >
                        {output.id}
                      </Center>
                    </Td>
                    <Td>
                      <Center>{output?.creationDate}</Center>
                    </Td>
                    <Td>
                      <Center>{output?.editDate}</Center>
                    </Td>
                    <Td color="#2b6cb0">
                      <Center
                        as={motion.div}
                        whileHover={{ scale: 1.1, cursor: "pointer" }}
                      >
                        {output?.login}
                      </Center>
                    </Td>
                    <Td>
                      <Center>{output?.comments}</Center>
                    </Td>
                    <Td>
                      <Center>{output?.status}</Center>
                    </Td>
                    <Td>
                      <Center>{output?.account}</Center>
                    </Td>
                    <Td>
                      <Center>{output?.paymentSystem}</Center>
                    </Td>
                    <Td>
                      <Center>{output?.wallet}</Center>
                    </Td>
                    <Td>
                      <Center>{output?.sum}</Center>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer> */}
    </Box>
  );
};

export default OutputList;
