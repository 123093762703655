import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../http/apiService";

interface IState {
  course: {
    id: number;
    rate: number;
    date: string;
    change: number;
  };
  isLoading: boolean;
  isError: boolean;
  message: any;
}

const initialState: IState = {
  course: {
    id: -1,
    rate: 1,
    date: "",
    change: 0,
  },
  isLoading: false,
  isError: false,
  message: "",
};

// Get user news
export const getMtbRate = createAsyncThunk("mtb/get", async (_, thunkAPI) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await $api.get("/api/Mtb/info", config);
    return response.data;
  } catch (error: any) {
    console.log(error);
    const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});

// Reducer
export const mtbSlice = createSlice({
  name: "mtb",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMtbRate.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getMtbRate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.course = action.payload;
      })
      .addCase(getMtbRate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

// export const { } = newsSlice.actions;
export default mtbSlice.reducer;
