import {
  ExternalLinkIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CryptoTokenInvoiceType } from "./CryptoTokenInvoiceList";

type Iprops = {
  item: CryptoTokenInvoiceType;
  setChosenItem: any;
};

const TransactionMenu = (props: Iprops) => {
  const navigate = useNavigate();
  return (
    <Box
    // filter="auto" blur="" zIndex="1000000"
    >
      <Menu
        onClose={() => {
          props.setChosenItem(undefined);
        }}
      >
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
          _hover={{ bg: "gray.400" }}
          _expanded={{ bg: "blue.400" }}
          onClick={() => {
            props.setChosenItem(props.item);
          }}
        />
        <MenuList ml="12">
          {/* <MenuItem
            icon={<EditIcon />}
            onClick={() => {
              props.setItem(props.item);
              props.onOpen();
            }}
          >
            Редактировать платеж
          </MenuItem> */}

          <Box
            ml="3"
            fontSize={[12, 8, 10, 12, 14]}
            fontFamily="dm-sans, sans-serif"
          >
            <ExternalLinkIcon mr="2" />
            <a
              target="_blank"
              rel="noreferrer"
              href={`${window.location.href.split("dashboard")[0]
                }dashboard/payments/${props?.item?.user?.login}`}
              style={{ fontSize: "14px", fontFamily: "dm-sans, sans-serif" }}
            >
              Ведомость партнера
            </a>
          </Box>

          <Box
            ml="3"
            fontSize={[12, 8, 10, 12, 14]}
            fontFamily="dm-sans, sans-serif"
          >
            <ExternalLinkIcon mr="2" />
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://tronscan.org/#/address/${props?.item?.address}`}
              style={{ fontSize: "14px", fontFamily: "dm-sans, sans-serif" }}
            >
              Обозревать адрес
            </a>
          </Box>

          <Box
            ml="3"
            fontSize={[12, 8, 10, 12, 14]}
            fontFamily="dm-sans, sans-serif"
          >
            <ExternalLinkIcon mr="2" />
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://tronscan.org/#/transaction/${props?.item?.inGasHash}`}
              style={{ fontSize: "14px", fontFamily: "dm-sans, sans-serif" }}
            >
              Обозревать hash
            </a>
          </Box>


        </MenuList>



      </Menu>
    </Box>
  );
};

export default TransactionMenu;
