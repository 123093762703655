import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../http/apiService";

export interface IInvoice {
  id: number;
  user: {
    id: number;
    login: string;
    name: string;
  };
  createdAt: string;
  expiredAt: string;
  address: string;
  expectedTokenAmount: number;
  expectedFiatAmount: number;
  rate: number;
  tokenName: string;
  paymentAccount: {
    id: number;
    name: string;
  };
  network: number;
  inGasHash: string;
  outTokenHash: string;
  outGasHash: string;
  attempts: number;
  errorMessage: string;
  state: {
    id: number;
    name: string;
  };
  stateChangedAt: string;
  actualTokenAmount: number;
}

interface IState {
  invoice: IInvoice;
  invoiceList: {
    items: IInvoice[];
    totalCount: number;
  };
  isLoadingInvoice: boolean;
  isErrorPayment: boolean;
  message: any;
}

const initialState: IState = {
  invoice: {
    id: 0,
    user: {
      id: 0,
      login: "",
      name: "",
    },
    createdAt: "",
    expiredAt: "",
    address: "",
    expectedTokenAmount: 0,
    expectedFiatAmount: 0,
    rate: 0,
    tokenName: "",
    paymentAccount: {
      id: 0,
      name: "",
    },
    network: 0,
    inGasHash: "",
    outTokenHash: "",
    outGasHash: "",
    attempts: 0,
    errorMessage: "",
    state: {
      id: 0,
      name: "",
    },
    stateChangedAt: "",
    actualTokenAmount: 0,
  },
  invoiceList: {
    items: [],
    totalCount: 0,
  },
  isLoadingInvoice: false,
  isErrorPayment: false,
  message: "",
};

// Get invoice
export const getReplenishmentInvoice = createAsyncThunk(
  "replenishmentInvoice/post",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post(
        "/api/crypto-token-invoice/usdt-trc20",
        data,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get invoice by id
export const getInvoiceById = createAsyncThunk(
  "invoiceById/get",
  async (id: number, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.get(
        `/api/crypto-token-invoice/${id}`,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all invoices in list
export const getReplenishmentInvoiceList = createAsyncThunk(
  "invoiceList/post",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post(
        "/api/crypto-token-invoice/list",
        data,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reducer
export const cryptoInvoiceSlice = createSlice({
  name: "cryptoInvoice",
  initialState,
  reducers: {
    resetInvoice: (state) => {
      state.invoice = initialState.invoice;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReplenishmentInvoice.pending, (state) => {
        state.isLoadingInvoice = true;
        state.message = "";
      })
      .addCase(getReplenishmentInvoice.fulfilled, (state, action) => {
        state.isLoadingInvoice = false;
        state.invoice = action.payload;
      })
      .addCase(getReplenishmentInvoice.rejected, (state, action) => {
        state.isLoadingInvoice = false;
        state.isErrorPayment = true;
        state.message = action.payload;
      })
      .addCase(getInvoiceById.pending, (state) => {
        state.isLoadingInvoice = true;
        state.message = "";
      })
      .addCase(getInvoiceById.fulfilled, (state, action) => {
        state.isLoadingInvoice = false;
        state.invoice = action.payload;
      })
      .addCase(getInvoiceById.rejected, (state, action) => {
        state.isLoadingInvoice = false;
        state.isErrorPayment = true;
        state.message = action.payload;
      })
      .addCase(getReplenishmentInvoiceList.pending, (state) => {
        state.isLoadingInvoice = true;
        state.message = "";
      })
      .addCase(getReplenishmentInvoiceList.fulfilled, (state, action) => {
        state.isLoadingInvoice = false;
        state.invoiceList = action.payload;
      })
      .addCase(getReplenishmentInvoiceList.rejected, (state, action) => {
        state.isLoadingInvoice = false;
        state.isErrorPayment = true;
        state.message = action.payload;
      });
  },
});

export const { resetInvoice } = cryptoInvoiceSlice.actions;
export default cryptoInvoiceSlice.reducer;
