import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import $api from "../../http/apiService";

export interface IMatrix {
  id: number;
  price: number;
  name: string;
}

export interface IProgram {
  id: number;
  name: string;
}

interface IMarketingState {
  programs: IProgram[];
  matrixes: IMatrix[];
  isLoading: boolean;
  isLoadingMatrix: boolean;
  isError: boolean;
  message: any;
}

const initialState: IMarketingState = {
  programs: [],
  matrixes: [],
  isLoading: false,
  isLoadingMatrix: false,
  isError: false,
  message: "",
};

// Get matrixes of programs
export const getPrograms = createAsyncThunk(
  "getPrograms/get",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.get("/api/Marketing/programs", config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get matrixes of programs
export const getMatrixes = createAsyncThunk(
  "matrixes/post",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post("/api/Marketing/matrixes", data, config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Buy place
export const buyPlace = createAsyncThunk(
  "buyPlace/post",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post(
        "/api/Marketing/buy-place",
        data,
        config
      );
      toast.success("Заявка на покупку места зарегистрирована");
      return response;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Buy place width another user
export const buyPartnerPlace = createAsyncThunk(
  "buyPartnerPlace/post",
  async (data: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post(
        "/api/Marketing/buy-place",
        data,
        config
      );
      toast.success(
        "Заявка на покупку места за счет партнера зарегистрирована"
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      toast.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reducer
export const marketingSlice = createSlice({
  name: "partners Programs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPrograms.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getPrograms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.programs = action.payload;
      })
      .addCase(getPrograms.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getMatrixes.pending, (state) => {
        state.isLoadingMatrix = true;
        state.message = "";
      })
      .addCase(getMatrixes.fulfilled, (state, action) => {
        state.isLoadingMatrix = false;
        state.matrixes = action.payload;
      })
      .addCase(getMatrixes.rejected, (state, action) => {
        state.isLoadingMatrix = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(buyPlace.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(buyPlace.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(buyPlace.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(buyPartnerPlace.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(buyPartnerPlace.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(buyPartnerPlace.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

// export const { } = marketingSlice.actions;
export default marketingSlice.reducer;
