import React, { useEffect, useState } from "react";
import "./Adv.scss";

import { useTimer } from "react-timer-hook";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import banner240 from "../../../assets/banners/banner240_400.png";
import banner480 from "../../../assets/banners/banner480_600.png";
import banner720 from "../../../assets/banners/banner720_800.png";
import banner1080_1 from "../../../assets/banners/banner1080_1920_green-light.png";
import banner1080_2 from "../../../assets/banners/banner1080_1920_open-door.png";
import banner1080_3 from "../../../assets/banners/banner1080_1920_partner.png";

import { Text, useClipboard } from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

const Adv = () => {
  const { t } = useTranslation();

  const [login, setLogin] = useState("");
  const [banner, setBanner] = useState("");
  const [imageSize, setImageSize] = useState(
    "static/media/banner240_400.9f784c623605ead02454.png"
  );
  const [fullPath, setFullPath] = useState("");
  const [shortPath, setShortPath] = useState("");

  const { onCopy } = useClipboard(banner);

  // Make referral link to copy
  useEffect(() => {
    const fullPath = window.location.href.split("");
    fullPath.splice(-3, 3);
    const fullLink = `${fullPath.join("")}register/`;
    const shortLink = `${fullPath.join("")}`;
    setShortPath(shortLink);
    setFullPath(fullLink);
  }, []);

  useEffect(() => {
    setBanner(
      `<a href="${fullPath}${login}"><image src="${shortPath}${imageSize}"/></a>`
    );
  }, [login, imageSize, fullPath]);

  const copyHandler = () => {
    onCopy();
    toast.success("Скопировано в буфер обмена!");
  };

  return (
    <>
      <Meta
        title="Adv"
        description="Набор рекламных материалов, которые позволят участникам эффективнее приглашать новых партнеров!"
      />

      <section className="section__padding adv">
        <img src="/assets/img/wave.png" alt="Фон" className="faq__img" />

        <div className="container">
          <div className="adv__inner">
            <h2 className="title title__center">
              <span className="black blue">{t("Adw.advertising")}</span>{" "}
              {t("Adw.materials")}
            </h2>

            <p className="medium__text adv__text">{t("Adw.option1")}.</p>

            <p className="medium__text adv__text">{t("Adw.option2")}</p>

            <p className="medium__text adv__text">{t("Adw.option3")}.</p>

            <div className="adv__content">
              <p className="adv__suptext">{t("Adw.login")}</p>

              <input
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                type="text"
                className="input adv__input"
              />
            </div>

            <div className="adv__content adv__generate">
              <div className="adv__size--inner">
                <div className="adv__size--select--wrapper">
                  <select
                    onChange={(e) => setImageSize(e.target.value)}
                    className="select adv__size--select"
                  >
                    <option
                      value={
                        "static/media/banner240_400.9f784c623605ead02454.png"
                      }
                    >
                      240 x 400
                    </option>

                    <option
                      value={
                        "static/media/banner480_600.7b3f4f289412cc49c7bd.png"
                      }
                    >
                      480 x 600
                    </option>

                    <option
                      value={
                        "static/media/banner720_800.a3375310bb4acf770692.png"
                      }
                    >
                      720 x 800
                    </option>

                    <option
                      value={
                        "static/media/banner1080_1920_green-light.6ba8c972f0464d9b6877.png"
                      }
                    >
                      1080 x 1920 Green light
                    </option>

                    <option
                      value={
                        "static/media/banner1080_1920_open-door.eab73552a2f24a78a307.png"
                      }
                    >
                      1080 x 1920 Open the door
                    </option>

                    <option
                      value={
                        "static/media/banner1080_1920_partner.4a5d8d9b54ae2f6d9d2d.png"
                      }
                    >
                      1080 x 1920 Reliable partner
                    </option>
                  </select>
                </div>

                <p className="adv__size--text">{t("Adw.size")}</p>
              </div>

              <div className="adv__code--inner">
                <input
                  value={banner}
                  type="text"
                  readOnly
                  className="input adv__code--input"
                />

                <button
                  disabled={login.length < 4}
                  onClick={() => copyHandler()}
                  className="button adv__code--button"
                >
                  {t("Adw.copy")}
                </button>
              </div>
            </div>

            <div className="adv__img--inner">
              {imageSize ==
                "static/media/banner240_400.9f784c623605ead02454.png" && (
                <img className="adv__img" src={banner240} alt="Banner 240px" />
              )}

              {imageSize ==
                "static/media/banner480_600.7b3f4f289412cc49c7bd.png" && (
                <img className="adv__img" src={banner480} alt="Banner 480px" />
              )}

              {imageSize ==
                "static/media/banner720_800.a3375310bb4acf770692.png" && (
                <img className="adv__img" src={banner720} alt="Banner 720px" />
              )}

              {imageSize ==
                "static/media/banner1080_1920_green-light.6ba8c972f0464d9b6877.png" && (
                <img
                  className="adv__img"
                  src={banner1080_1}
                  alt="Banner 1080px"
                />
              )}

              {imageSize ==
                "static/media/banner1080_1920_open-door.eab73552a2f24a78a307.png" && (
                <img
                  className="adv__img"
                  src={banner1080_2}
                  alt="Banner 1080px"
                />
              )}

              {imageSize ==
                "static/media/banner1080_1920_partner.4a5d8d9b54ae2f6d9d2d.png" && (
                <img
                  className="adv__img"
                  src={banner1080_3}
                  alt="Banner 1080px"
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Adv;
