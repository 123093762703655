import React, { FC, useState } from "react";
import { motion } from "framer-motion";

import { MdOutlineStar } from "react-icons/md";
import { Box, Flex } from "@chakra-ui/react";

export interface RatingProps {
  isEditable?: boolean;
  rating: number;
  setRating: (rating: number) => void;
}

export const Rating = ({
  isEditable = false,
  rating,
  setRating,
}: RatingProps) => {
  const array = [];
  for (let i = 1; i <= 5; i++) {
    array.push(i);
  }

  return (
    <>
      <Flex>
        {array.map((elem, index) => (
          <Box
            key={index}
            as={motion.div}
            whileHover={{ cursor: "pointer", scale: 1.2 }}
          >
            <MdOutlineStar
              color={elem <= rating ? "#13599f" : "gray"}
              size={40}
              onClick={() => {
                if (isEditable) {
                  setRating(elem);
                }
              }}
            />
          </Box>
        ))}
      </Flex>
    </>
  );
};
