import React, { FC, useEffect } from "react";
import Moment from "react-moment";

import { useAppSelector, useAppDispatch } from "../../../store";
import { getReplenishmentsHistory } from "../../../features/payment/paymentSlice";

import freeKassaIcon from "../../../assets/img/freekassa2.png";

import { useTranslation } from "react-i18next";

import {
  MdPayment,
  MdOutlineDateRange,
  MdOutlineContactPage,
} from "react-icons/md";
import { FaRegMoneyBillAlt } from "react-icons/fa";

import { usePagination } from "../../../hooks/pagination/usePagination";
import "../../../hooks/pagination/pagination.scss";

import { Button, Text, Image, Box, Center, Flex } from "@chakra-ui/react";
import { GlobalSpinner } from "../GlobalSpinner/GlobalSpinner";

const itemsPerPage = 10;

export const ReplenishmentsHistory: FC = () => {
  const { replenishments, isLoadingPayment } = useAppSelector(
    (state) => state.payment
  );
  const dispatch = useAppDispatch();

  // Translation i18next
  const { t } = useTranslation();

  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: itemsPerPage,
    count: replenishments.totalCount,
  });

  // Pagination request
  useEffect(() => {
    const data = {
      take: 10,
      skip: (page - 1) * itemsPerPage,
    };
    dispatch(getReplenishmentsHistory(data));
  }, [page]);

  // if (isLoadingPayment) {
  //   return <GlobalSpinner />;
  // }

  return (
    <>
      {replenishments?.items.length !== 0 && (
        <div className="content__margin w100 flex admin__last">
          <Flex w="100%" justifyContent="center" my={[2, 2, 4]}>
            <Text fontSize="18px" fontWeight="medium" color="#174073">
              {t("ReplenishmentsHistoryComponent.history_of_replenishment")}
            </Text>
          </Flex>
          <div className="w100 flexdef admin__last--item">
            <p className="admin__last--text admin__last--blue operation__item--desc">
              <Center>
                <Box mr={2}>
                  <MdPayment size={20} />
                </Box>{" "}
                {t("ReplenishmentsHistoryComponent.payment_system")}
              </Center>
            </p>

            <p className="admin__last--text admin__last--blue operation__item--date">
              <Center>
                <Box mr={2}>
                  <MdOutlineDateRange size={20} />
                </Box>{" "}
                {t("ReplenishmentsHistoryComponent.date")}
              </Center>
            </p>

            <p className="admin__last--text admin__last--blue operation__item--in">
              <Center>
                <Box mr={2}>
                  <FaRegMoneyBillAlt size={20} />
                </Box>
                {t("ReplenishmentsHistoryComponent.sum")}
              </Center>
            </p>

            <p className="admin__last--text admin__last--blue operation__item--out">
              <Center>
                <Box mr={2}>
                  <MdOutlineContactPage size={20} />
                </Box>
                {t("ReplenishmentsHistoryComponent.status")}
              </Center>
            </p>
          </div>

          {replenishments?.items?.map((replensment, index) => (
            <div
              className="w100 flexdef admin__last--item"
              key={replensment.id}
            >
              <p className="admin__last--text operation__item--desc">
                <Center>
                  <Box mr={2}>
                    {replensment?.externalPayment?.paymentSystem?.name ===
                      "Free kassa" && <Image w={16} src={freeKassaIcon} />}
                  </Box>

                  {replensment?.externalPayment?.paymentSystem?.name}
                </Center>
              </p>

              <p className="admin__last--text operation__item--date">
                <Center>
                  <Moment format="DD MMMM YYYY, hh:mm:ss">
                    {replensment.statusChangedAt}
                  </Moment>
                </Center>
              </p>

              <p className="admin__last--text operation__item--in">
                <Center>{replensment?.sum}</Center>
              </p>

              <p className="admin__last--text operation__item--out">
                <Center>
                  {replensment.status === "Pending" && (
                    <Text
                      fontSize="18px"
                      fontWeight="medium"
                      color="yellow.700"
                    >
                      {t("ReplenishmentsHistoryComponent.pending")}
                    </Text>
                  )}

                  {replensment.status === "Canceled" && (
                    <Text fontSize="18px" fontWeight="medium" color="red.500">
                      {t("ReplenishmentsHistoryComponent.canceled")}
                    </Text>
                  )}

                  {replensment.status === "Executed" && (
                    <Text fontSize="18px" fontWeight="medium" color="green.700">
                      {t("ReplenishmentsHistoryComponent.successfully")}
                    </Text>
                  )}
                </Center>
              </p>
            </div>
          ))}

          {replenishments.totalCount <= itemsPerPage ? (
            <Box></Box>
          ) : (
            <Center w={["100%"]}>
              <div className="pagination">
                <Button
                  onClick={prevPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === 1}
                >
                  {t("ReplenishmentsHistoryComponent.back")}
                </Button>
                <button
                  onClick={() => setPage(1)}
                  className={`page ${page === 1 && "disabled"}`}
                >
                  1
                </button>
                {gaps.before ? "..." : null}

                {gaps.paginationGroup.map((el) => (
                  <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                  >
                    {el}
                  </button>
                ))}
                {gaps.after ? "..." : null}
                <button
                  onClick={() => setPage(totalPages)}
                  className={`page ${page === totalPages && "disabled"}`}
                >
                  {totalPages}
                </button>

                <Button
                  onClick={nextPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === totalPages}
                >
                  {t("ReplenishmentsHistoryComponent.forward")}
                </Button>
              </div>
            </Center>
          )}
        </div>
      )}
    </>
  );
};
