import React from "react";
import {
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  Center,
} from "@chakra-ui/react";

export const GlobalSpinner = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      isCentered
    >
      <ModalOverlay bg="blackAlpha.500" backdropFilter="blur(12px)">
        <Center w="100%" h="100%">
          <Spinner
            thickness="17px"
            speed="0.7s"
            emptyColor="#61D64A"
            color="#1c3b6a"
            w={24}
            h={24}
          />
        </Center>
      </ModalOverlay>
    </Modal>
  );
};
