import React, { FC, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { News } from "../../../Components/LK/News/News";
import { AboutUser } from "../../../Components/LK/AboutUser/AboutUser";
import { AccountBalance } from "../../../Components/LK/AccountBalance/AccountBalance";
import { Chat } from "../../../Components/LK/Chat/Chat";
import { useAppSelector } from "../../../store";
import Moment from "react-moment";
import { Box, Button, Center, Text } from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

export const Admin: FC = () => {
  const { lastIncomes } = useAppSelector((state) => state.payment);
  const navigate = useNavigate();

  // Translation i18next
  const { t } = useTranslation();

  // Scroll to top
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Meta title="Personal account" />
      <div className="admin__content--content flex w100">
        <div className="w100 flex">
          <div className="admin__title--inner w100">
            <img
              className="admin__title--icon"
              src="/assets/img/user-red.svg"
              alt="Иконка"
            />
            {t("Admin.personal_area")}
          </div>
          <AboutUser />
        </div>

        <AccountBalance />

        {lastIncomes.length !== 0 && (
          <div className="w100 flex default__margin">
            <div className="admin__title--inner w100">
              <img
                className="admin__title--icon"
                src="/assets/img/last-red.svg"
                alt="Иконка"
              />
              {t("Admin.latest_arrivals")}
            </div>

            <div className="content__margin w100 flex admin__last">
              <div className="w100 flexdef admin__last--item">
                <div className="admin__last--text admin__last--blue operation__item--date">
                  {t("Admin.date_and_time")}
                </div>

                <div className="admin__last--text admin__last--blue operation__item--desc">
                  {t("Admin.description")}
                </div>

                <div className="admin__last--text admin__last--blue operation__item--desc">
                  {t("Admin.article")}
                </div>

                <div
                  className="admin__last--text admin__last--blue operation__item--out"
                  style={{ textAlign: "center" }}
                >
                  <Center w="100%">{t("Admin.sum")}</Center>
                </div>
              </div>

              {lastIncomes?.map((income, index) => (
                <div className="w100 flexdef admin__last--item" key={income.id}>
                  <div className="admin__last--text operation__item--date">
                    <Moment format="DD MMMM YYYY, hh:mm:ss">
                      {income.statusChangedAt}
                    </Moment>
                  </div>

                  <p className="admin__last--text operation__item--desc">
                    {income?.article?.name}
                  </p>

                  <p className="admin__last--text operation__item--desc">
                    {income?.desc}
                  </p>

                  <div className="admin__last--text operation__item--in">
                    <Box color="green.400" textAlign="center">
                      + {income?.sum} $
                    </Box>
                  </div>
                </div>
              ))}
            </div>

            <Center w="100%" mt={8}>
              <Button
                onClick={() => navigate("/admin/operation")}
                bg="#61D64A"
                _hover={{ bg: "green.700" }}
                w="150px"
                h="42px"
                borderRadius="10px"
              >
                <Text fontSize="18px" color="#FFF">
                  {t("Admin.show_all")}
                </Text>
              </Button>
            </Center>
          </div>
        )}
      </div>

      <div className="admin__content--right flex w100">
        <News />
        <Chat />
      </div>
    </>
  );
};
