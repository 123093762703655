import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../store";

import Moment from "react-moment";
import $api from "../../../http/apiService";

import { ReferralStructureUniversal } from "./ReferralStructureUniversal";

import { GlobalSpinner } from "../GlobalSpinner/GlobalSpinner";

import { motion } from "framer-motion";

import { GiAlarmClock } from "react-icons/gi";
import { BsBagCheck } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";
import { UpDownIcon } from "@chakra-ui/icons";
import QuestionAnswerIcon from "../../../assets/img/question-answer-line.svg";
import StartIcon from "../../../assets/img/StartIcon.png";
import fastIcon from "../../../assets/img/fastIcon.png";
import sprintIcon from "../../../assets/img/sprintIcon.png";
import jackpotIcon from "../../../assets/img/jackpotIcon.png";

import {
  Flex,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  Collapse,
  Avatar,
  Center,
  Tooltip,
  Select,
  Button,
  useMediaQuery,
  Img,
} from "@chakra-ui/react";

export const takeCountReferrals = 10;

export const ReferralStructure = () => {
  // Translation i18next
  const { t } = useTranslation();

  const { userData } = useAppSelector((state) => state.userInfo);
  const { accessToken } = useAppSelector((state) => state.auth);
  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
  const navigate = useNavigate();

  const [onlyActivated, setOnlyActivated] = useState(false);
  const [fetchReferrals, setFetchReferrals] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [page, setPage] = useState(1);
  const [userLogin, setUserLogin] = useState("");
  const [isPageReset, setIsPageReset] = useState(false);
  const [level, setLevel] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchHandler = async () => {
    if (!accessToken) {
      return;
    }

    setLoading(true);

    const data = {
      parentLogin: userData?.login,
      programId: 3,
      take: takeCountReferrals,
      skip: 0,
      onlyActivated: onlyActivated,
    };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post(
        "/api/Marketing/structure",
        data,
        config
      );
      setFetchReferrals(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Fetch referrals first one when load page
  useEffect(() => {
    setFetchReferrals([]);
    setReferrals([]);
    setPage(1);
    setIsPageReset(true);
    fetchHandler();
  }, [onlyActivated]);

  // Добавляем всем рефералам флаг для отображения дочерних рефералов
  useEffect(() => {
    if (fetchReferrals?.items?.length > 0) {
      const tempReferrals = [...fetchReferrals.items];
      for (let i = 0; i < fetchReferrals?.items.length; i++) {
        tempReferrals[i].isOpen = false;
      }
      setReferrals(tempReferrals);
    }
  }, [fetchReferrals]);

  const handleClick = async ({ item }) => {
    setUserLogin(item.login);
    if (!(item?.children || item?.children?.items?.length > 0)) {
      const data = {
        parentLogin: item.login,
        programId: 3,
        take: takeCountReferrals,
        skip: 0,
        onlyActivated: onlyActivated,
      };
      setLoading(true);

      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await $api.post(
          "/api/Marketing/structure",
          data,
          config
        );
        const addingReferrals = response.data;
        for (let i = 0; i < addingReferrals?.items.length; i++) {
          addingReferrals.items[i].isOpen = false;
        }
        const newReferrals = [...referrals];
        newReferrals.forEach((elem) => {
          if (elem.login === item.login) {
            elem.children = addingReferrals;
            elem.isOpen = !elem.isOpen;
          }
        });
        setReferrals(newReferrals);
        setIsPageReset(false);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      const newReferrals = [...referrals];
      newReferrals.forEach((elem) => {
        if (elem.login === item.login) {
          elem.children = undefined;
          elem.isOpen = !elem.isOpen;
        }
      });
      setReferrals(newReferrals);
      setIsPageReset(true);
      setLoading(false);
    }
  };

  // Load more referrals
  const loadMoreHandler = async () => {
    setLoading(true);
    const data = {
      parentLogin: userData.login,
      programId: 3,
      take: takeCountReferrals,
      skip: takeCountReferrals * page,
      onlyActivated: onlyActivated,
    };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post(
        "/api/Marketing/structure",
        data,
        config
      );
      const addingReferrals = response.data.items;
      for (let i = 0; i < addingReferrals?.length; i++) {
        addingReferrals[i].isOpen = false;
      }
      setReferrals([...referrals, ...addingReferrals]);
      setPage(page + 1);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="w100 flex default__margin">
        <Flex w="100%" justifyContent="end" mt={10}>
          <h3 className="admin__title--inner w100">
            <img
              className="admin__title--icon"
              src="/assets/img/partners-red.svg"
              alt="Иконка"
            />
            {t("StructPage.your_partners")}
          </h3>

          <Select
            onChange={(e) => setOnlyActivated(e.target.value)}
            w={80}
            h={14}
            variant="filled"
            colorScheme="blue"
          >
            <option value="false">{t("StructPage.all")}</option>
            <option value="true">{t("StructPage.active")}</option>
          </Select>
        </Flex>

        {loading ? (
          <GlobalSpinner />
        ) : (
          <div className="content__margin w100 flex admin__last">
            <Flex direction="row" w="100%">
              <Grid
                templateRows={"repeat(1, 1fr)"}
                templateColumns={"repeat(12, 1fr)"}
                p={1}
                textAlign="center"
                alignItems="center"
                fontSize={["sm", "md", "lg", "xl", "2xl"]}
                color="#174073"
                h="50px"
                w="100%"
              >
                <GridItem colStart={1} colEnd={[7, 7, 4]} textAlign="center">
                  <Text fontWeight="medium">{t("StructPage.partner")}</Text>
                </GridItem>

                <GridItem
                  colStart={[7, 7, 4]}
                  colEnd={[13, 13, 6]}
                  textAlign="center"
                >
                  <Text ml={1} fontWeight="medium">
                    {t("StructPage.earned")}
                  </Text>
                </GridItem>

                <GridItem
                  colStart={[1, 1, 6]}
                  colEnd={[7, 7, 7]}
                  textAlign="center"
                >
                  <Center>
                    <Text ml={1} fontWeight="medium">
                      {t("StructPage.telegram")}
                    </Text>
                  </Center>
                </GridItem>

                <GridItem
                  colStart={[7, 7, 7]}
                  colEnd={[13, 13, 9]}
                  textAlign="center"
                >
                  <Text fontWeight="medium">{t("StructPage.status")}</Text>
                </GridItem>

                <GridItem
                  colStart={[1, 1, 9]}
                  colEnd={[7, 7, 11]}
                  textAlign="center"
                >
                  <Text fontWeight="medium">
                    {t("StructPage.registration")}
                  </Text>
                </GridItem>

                <GridItem colStart={[7, 7, 11]} colEnd={13} textAlign="center">
                  {<UpDownIcon width="22px" h="16px" />}
                </GridItem>
              </Grid>
            </Flex>

            <>
              {referrals?.map((item, index) => (
                <>
                  <Box
                    key={item.id}
                    p={2}
                    mx="auto"
                    w="100%"
                    color="black"
                    fontSize={["lg", "2xl"]}
                    textAlign="center"
                    borderTop="1px"
                    borderColor="#DFDFDF"
                    bgColor={item.isActive && "green.100"}
                  >
                    <Grid
                      key={item.id}
                      templateRows={"repeat(1, 1fr)"}
                      templateColumns={"repeat(13, 1fr)"}
                      gap={1}
                      textAlign="center"
                    >
                      <GridItem colStart={1} colEnd={[3, 3, 2]} my="auto">
                        <Center h="100%" w="100%">
                          <Avatar
                            ml={4}
                            size={isLargerThan480 ? "xl" : "lg"}
                            name={item.name ? item.login : item.login}
                            src={item.imageUrl}
                          />
                        </Center>
                      </GridItem>

                      <GridItem
                        colStart={[3, 3, 2]}
                        colEnd={[8, 8, 4]}
                        textAlign="center"
                      >
                        <Center w="100%" h="100%">
                          <Text fontWeight="medium">{item?.login}</Text>
                        </Center>
                      </GridItem>

                      <GridItem colStart={[8, 8, 4]} colEnd={[13, 13, 6]}>
                        <Center w="100%" h="100%">
                          <Text fontWeight="medium">
                            {item?.financialStat?.interest}
                          </Text>
                        </Center>
                      </GridItem>

                      <GridItem colStart={[1, 1, 6]} colEnd={[7, 7, 7]}>
                        <Center w="100%" h="100%">
                          <Text fontSize={["xl", "2xl"]} fontWeight="medium">
                            {item.contacts.socialMediaTg === "" ? (
                              ""
                            ) : (
                              <a
                                href={`https://t.me/${item?.contacts.socialMediaTg}`}
                                target="_Blanc"
                                className="header__social--link"
                              >
                                @{item?.contacts.socialMediaTg}
                              </a>
                            )}
                          </Text>
                        </Center>
                      </GridItem>

                      <GridItem
                        colStart={[7, 7, 7]}
                        colEnd={[13, 13, 9]}
                        my="auto"
                      >
                        <Center w="100%">
                          <Text fontWeight="medium">
                            {item.invites[0].activatedAt === null &&
                              item.invites[1].activatedAt === null &&
                              item.invites[2].activatedAt === null &&
                              ""}
                          </Text>

                          {item.invites[0].activatedAt !== null && (
                            <Text color="blue.500" m={2}>
                              <Tooltip
                                fontSize="18px"
                                mr={2}
                                label={item.invites[0].program}
                                hasArrow
                                bg="blue.500"
                              >
                                <Box>
                                  <GiAlarmClock
                                    size={24}
                                    alt={item.invites[0].program}
                                  />
                                </Box>
                              </Tooltip>
                            </Text>
                          )}

                          {item.invites[1].activatedAt !== null && (
                            <Text color="green.500" m={2}>
                              <Tooltip
                                fontSize="18px"
                                mr={2}
                                label={item.invites[1].program}
                                hasArrow
                                bg="green.500"
                              >
                                <Box>
                                  <BsBagCheck 
                                  size={24} 
                                  />
                                </Box>
                              </Tooltip>
                            </Text>
                          )}

                          {item.invites[2].activatedAt !== null && (
                            <Text color="red.500" m={2}>
                              <Tooltip
                                fontSize="18px"
                                mr={2}
                                label={item.invites[2].program}
                                hasArrow
                                bg="red.500"
                              >
                                <Box>
                                  <AiOutlineSetting 
                                  size={24} 
                                  />
                                </Box>
                              </Tooltip>
                            </Text>
                          )}

                          {item.invites[3].activatedAt !== null && (
                            <Text color="purple.500" m={2}>
                              <Tooltip
                                fontSize="18px"
                                mr={2}
                                label={item.invites[3].program}
                                hasArrow
                                bg="purple.500"
                              >
                                <Box>
                                  <img src={StartIcon} alt="" style={{minWidth: "12px", maxWidth: "20px"}}/>
                                </Box>
                              </Tooltip>
                            </Text>
                          )}
                           {item.invites[4].activatedAt !== null && (
                            <Text color="purple.500" m={2}>
                              <Tooltip
                                fontSize="18px"
                                mr={2}
                                label={item.invites[4].program}
                                hasArrow
                                bg="purple.500"
                              >
                                <Box>
                                  <img src={fastIcon} alt="" style={{minWidth: "12px", maxWidth: "20px"}}/>
                                </Box>
                              </Tooltip>
                            </Text>
                          )}
                          {item.invites[5].activatedAt !== null && (
                            <Text color="purple.500" m={2}>
                              <Tooltip
                                fontSize="18px"
                                mr={2}
                                label={item.invites[5].program}
                                hasArrow
                                bg="purple.500"
                              >
                                <Box>
                                  <img src={sprintIcon} alt="" style={{minWidth: "12px", maxWidth: "20px"}}/>
                                </Box>
                              </Tooltip>
                            </Text>
                          )}
                          {item.invites[6].activatedAt !== null && (
                            <Text color="purple.500" m={2}>
                              <Tooltip
                                fontSize="18px"
                                mr={2}
                                label={item.invites[6].program}
                                hasArrow
                                bg="purple.500"
                              >
                                <Box>
                                  <img src={jackpotIcon} alt="" style={{minWidth: "12px", maxWidth: "20px"}}/>
                                </Box>
                              </Tooltip>
                            </Text>
                          )} 
                        </Center>
                      </GridItem>

                      <GridItem
                        colStart={[1, 1, 10]}
                        colEnd={[7, 7, 11]}
                        my="auto"
                      >
                        <Text fontWeight="medium">
                          <Moment format="DD/MM/YYYY">{item.createdAt}</Moment>
                        </Text>
                      </GridItem>

                      <GridItem colStart={[7, 7, 11]} colEnd={12} my="auto">
                        <Center
                          as={motion.div}
                          whileHover={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(
                              `/admin/tickets/${item?.id}&${item?.login}`
                            );
                          }}
                        >
                          <Img src={QuestionAnswerIcon} alt="" />
                        </Center>
                      </GridItem>

                      <GridItem colStart={[8, 8, 12]} colEnd={14} my="auto">
                        {item?.refCount !== 0 && (
                          <IconButton
                            onClick={() => handleClick({ item })}
                            colorScheme="blue"
                            boxShadow="xl"
                            h="38px"
                            w="43px"
                            borderRadius="10px"
                            aria-label="Open structure"
                            icon={<UpDownIcon width="18px" h="12px" />}
                          />
                        )}
                      </GridItem>
                    </Grid>
                  </Box>

                  <GridItem colStart={1} colEnd={13} my="auto" w="100%">
                    <Collapse in={item.isOpen} animateOpacity w="100%">
                      <ReferralStructureUniversal
                        data={item?.children}
                        onlyActivated={onlyActivated}
                        login={userLogin}
                        isPageReset={isPageReset}
                        level={level}
                      />
                    </Collapse>
                  </GridItem>
                </>
              ))}
            </>
          </div>
        )}

        {fetchReferrals?.totalCount > referrals.length && (
          <Center w="100%" mt={4}>
            <Button
              onClick={() => loadMoreHandler()}
              colorScheme="blue"
              boxShadow="xl"
              h="38px"
              w={["40%", "20%"]}
              borderRadius="10px"
              fontSize={["md", "md", "xl", "2xl"]}
            >
              {t("StructPage.show_more")}
            </Button>
          </Center>
        )}
      </div>
    </>
  );
};
