import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store";

import { useTranslation } from "react-i18next";

import { CurrencyRates } from "../../../Components/LK/CurrencyRates/CurrencyRates";
import { GlobalSpinner } from "../../../Components/LK/GlobalSpinner/GlobalSpinner";
import Moment from "react-moment";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Button, Center, Flex } from "@chakra-ui/react";
import { LangEnum } from "../../Dashboard/NewsList";
import Meta from "../../../utils/seo/Meta";
import $api from "../../../http/apiService";
import { INews } from "../../../features/news/newsSlice";

const itemsPerPage = 6;

const AdminNews = () => {
  // Translation i18next
  const { t } = useTranslation();

  // const { userNews, isLoading } = useAppSelector((state) => state.news);

  const { userData } = useAppSelector((state) => state.userInfo);
  const [userNews, setUserNews] = useState<INews[]>([]);
  // const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isFull, setIsFull] = useState(false);

  const getNews = async () => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.get(
        `/api/News?Skip=${currentPage * itemsPerPage}&Take=${itemsPerPage}`,
        config
      );
      console.log("response", response);
      if (response.status === 200) {
        if (response?.data?.length > 0) {
          setUserNews([...userNews, ...response.data]);
        } else {
          setIsFull(true);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNews();
  }, [currentPage]);

  if (isLoading) {
    return <GlobalSpinner />;
  }

  return (
    <>
      <Meta title="Personal account" />
      <div className="admin__content--content flex w100">
        <div className="w100 flex">
          <h3 className="admin__title--inner w100">
            <img
              className="admin__title--icon"
              src="/assets/img/operation-red.svg"
              alt="Иконка"
            />
            {t("UserNewsPage.news")}
          </h3>

          <div className="presents__content content__margin flex w100">
            {userNews.map((item, index) => (
              <div className="presents__item flex" key={item.id}>
                <div className="anews__img--inner">
                  <img
                    className="anews__img"
                    src={item?.imageUrl}
                    alt="Картинка новости"
                  />
                </div>

                <div className="admin__news--text--inner flex w100">
                  <p className="admin__news--text--title">
                    {item?.content.find(
                      (e) => e.lang === userData?.interfaceLang
                    )?.title ??
                      item?.content.find((e) => e.lang === LangEnum.Ru)?.title}
                  </p>

                  <Flex w="100%" justifyContent="space-between">
                    <div className="flexdef">
                      <img
                        className="mr1"
                        src="/assets/img/date.svg"
                        alt="Дата"
                      />
                      <Moment format="DD MMMM YYYY">{item?.publishedAt}</Moment>
                    </div>

                    <Link to={`${item?.id}`}>
                      <Button colorScheme="green" borderRadius="2xl">
                        <ArrowForwardIcon w={8} h={6} />
                      </Button>
                    </Link>
                  </Flex>
                </div>
              </div>
            ))}
          </div>
          {!isFull && (
            <Center width={"100%"} mt={4}>
              <Button
                mb={4}
                variant="ghost"
                size="xl"
                bg="#61d64a"
                color="white"
                boxShadow="inset 0px -3px 0px rgba(0, 0, 0, 0.1)"
                h="50px"
                width="200px"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                {t("Tickets.download")}
              </Button>
            </Center>
          )}
        </div>
      </div>

      <div className="admin__content--right flex w100">
        <CurrencyRates />
      </div>
    </>
  );
};

export default AdminNews;
