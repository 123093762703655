import React from "react";
import { MobileSidebar } from "./MobileSidebar";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { useAppSelector } from "../../../store";

import { useMediaQuery, Box, Text, Flex } from "@chakra-ui/react";

const Sidebar = () => {
  const [isLargerThan770] = useMediaQuery("(min-width: 770px)");
  // Translation i18next
  const { t } = useTranslation();

  const { userData, userStat } = useAppSelector((state) => state.userInfo);

  return (
    <div className="admin__sidebar">
      {isLargerThan770 ? (
        <>
          <div className="admin__nav--item">
            <img
              className="admin__nav--icon"
              src="/assets/img/user.svg"
              alt="Иконка"
            />

            <div className="admin__nav--wrapper">
              <Link to="/admin" className="admin__nav--link admin big">
                {t("Sidebar.personal_area")}
              </Link>
            </div>
          </div>

          <div className="admin__nav--item">
            <img
              className="admin__nav--icon"
              src="/assets/img/platform.svg"
              alt="Иконка"
            />

            <div className="admin__nav--wrapper">
              <div className="admin__nav--link admin big">
                {t("Sidebar.programme")}
              </div>

              <Link
                to="/admin/presentation"
                className="admin__nav--link admin"
                activeclassname="active"
              >
                {t("Sidebar.presentation")}
              </Link>

              <Link
                to="/admin/partners-programs"
                className="admin__nav--link admin"
              >
                {t("Sidebar.partnership_programs")}
              </Link>

              <Link
                to="/admin/partners-programs-new"
                className="admin__nav--link admin"
              >
                {t("Sidebar.partnership_programs_new")}
              </Link>

              <Link to="/admin/struct" className="admin__nav--link admin">
                {t("Sidebar.structure")}
              </Link>
            </div>
          </div>

          <div className="admin__nav--item">
            <img
              className="admin__nav--icon"
              src="/assets/img/wallet.svg"
              alt="Иконка"
            />
            <div className="admin__nav--wrapper">
              <div className="admin__nav--link admin big">
                {t("Sidebar.finance")}
              </div>

              <Link to="/admin/input-money" className="admin__nav--link admin">
                {t("Sidebar.top_up_account")}
              </Link>

              <Link to="/admin/output-money" className="admin__nav--link admin">
                {t("Sidebar.withdrawals")}
              </Link>

              <Link to="/admin/operation" className="admin__nav--link admin">
                {t("Sidebar.operations_history")}
              </Link>
            </div>
          </div>

          <div className="admin__nav--item">
            <img
              className="admin__nav--icon"
              src="/assets/img/program.svg"
              alt="Иконка"
            />
            <div className="admin__nav--wrapper">
              <div className="admin__nav--link admin big">
                {t("Sidebar.platform")}
              </div>

              <Link to="/admin/user-news" className="admin__nav--link admin">
                {t("Sidebar.news")}
              </Link>

              <Link to="/webinars" className="admin__nav--link admin">
                {t("Sidebar.webinars")}
              </Link>

              <Link to="/partners" className="admin__nav--link admin">
                {t("Sidebar.our_partners")}
              </Link>

              <Link to="/admin/vacancies" className="admin__nav--link admin">
                {t("Sidebar.jobs")}
              </Link>

              <Link to="/admin/review" className="admin__nav--link admin">
                {t("Sidebar.review")}
              </Link>

              <Link to="/adv" className="admin__nav--link admin">
                {t("Sidebar.promotional_materials")}
              </Link>

              {/* <Link to="/admin/travel" className="admin__nav--link admin">
                {t("Sidebar.spend_bonuses")}
              </Link> */}

              <Link to="/faq" className="admin__nav--link admin">
                {t("Sidebar.question_answer")}
              </Link>
            </div>
          </div>

          <div className="admin__nav--item">
            <img
              className="admin__nav--icon"
              src="/assets/img/filter.svg"
              alt="Иконка"
            />

            <div className="admin__nav--wrapper">
              <div className="admin__nav--link admin big">
                {t("Sidebar.control")}
              </div>

              <Link to="/admin/settings" className="admin__nav--link admin">
                {t("Sidebar.cabinet_settings")}
              </Link>

              <Link to="/admin/tickets" className="admin__nav--link admin">
                {t("Sidebar.those_support")}
              </Link>

              {/* <Link
                to="/admin/payment-details"
                className="admin__nav--link admin"
              >
                {t("Sidebar.requisites")}
              </Link> */}
            </div>
          </div>

          {userData.isAdmin && (
            <div className="admin__nav--item">
              <img
                className="admin__nav--icon"
                src="/assets/img/program.svg"
                alt=""
              />
              {/* <GrUserAdmin sz={20} className="admin__nav--icon"/> */}
              <div className="admin__nav--wrapper">
                <div className="admin__nav--link admin big">
                  <Link to="/dashboard">AdminDasboard</Link>
                </div>
              </div>
            </div>
          )}

          <div className="admin__nav--item">
            <Box
              position="relative"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              bg="#61D64A"
              w="90%"
              h="100px"
              borderRadius="10px"
              p={8}
              fontFamily="Graphik"
              fontSize="16px"
              fontWeight="400"
              color="#FFFFFF"
              zIndex={5}
            >
              <Box zIndex={10}>
                <Flex justifyContent="space-around">
                  <Text fontSize={["12px", "14px", "16px"]} fontWeight="700">
                    {t("Sidebar.earned")}
                  </Text>
                  <Text> {userStat?.financeStat?.interest}$</Text>
                </Flex>

                <Flex justifyContent="space-around">
                  <Text fontSize={["12px", "14px", "16px"]} fontWeight="700">
                    {t("Sidebar.brought_out")}
                  </Text>
                  <Text> {userStat?.financeStat?.expenses}$</Text>
                </Flex>
              </Box>

              <Box
                position="absolute"
                // left={"14%"}
                top={"10%"}
                bg="rgba(124, 177, 63, 0.3)"
                opacity={0.75}
                width="90%"
                height="100px"
                borderRadius="10px"
                zIndex={-10}
              >
                <Box
                  position="absolute"
                  left={8}
                  top={6}
                  bg="rgba(124, 177, 63, 0.3)"
                  opacity={0.5}
                  width="80%"
                  height="90px"
                  borderRadius="10px"
                  zIndex={-5}
                ></Box>
              </Box>
            </Box>
          </div>
        </>
      ) : (
        <MobileSidebar />
      )}

      <p className="copy">
        © 2020-{new Date().getFullYear()} {t("Sidebar.all_rights_reserved")}
      </p>
    </div>
  );
};

export default Sidebar;
