import { Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  Heading,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import React, { FC, useEffect, useRef, useState } from "react";
import "./DashBoard.scss";
import { usePagination } from "../../hooks/pagination/usePagination";
import { FcAddRow, FcGallery, FcKindle, FcMindMap } from "react-icons/fc";
import { motion } from "framer-motion";
import $api from "../../http/apiService";
import { AxiosResponse } from "axios";
import { AiFillCloseCircle } from "react-icons/ai";
import { FcBusinessman, FcComments } from "react-icons/fc";
import Moment from "react-moment";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { MdHideSource } from "react-icons/md";
import Raiting from "./component/Rating/Rating";

const itemPerPage = 5;
export type FeedbackType = {
  id: number | string;
  email: string;
  name: string;
  message: string;
  createdAt: string;
};
export type FeedbackAllRDType = {
  take: number;
  skip: number;
};

const FeedbackList: FC = () => {
  const [feedbacks, setFeedbacks] = useState<FeedbackType[]>([]);
  const [chosenItem, setChosenItem] = useState<FeedbackType | undefined>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isNeedUpdate, setIsNeedUpdate] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Pagination
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: itemPerPage,
    count: totalCount,
  });

  useEffect(() => {
    getFeedbacks();
  }, [page, isNeedUpdate]);

  const getFeedbacks = async () => {
    const rd: FeedbackAllRDType = {
      take: itemPerPage,
      skip: 0,
    };
    if (page > 0) {
      rd.skip = (page - 1) * itemPerPage;
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response: AxiosResponse = await $api.post(
        "/api/admin/Feedback/all",
        rd,
        config
      );
      const data: FeedbackType[] = response.data.items;
      // data.forEach((elem) => {
      //   elem.createdAt = elem.createdAt.split("T")[0];
      // });
      setFeedbacks(data);
      setTotalCount(response?.data?.totalCount);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDelete = async () => {
    if (chosenItem) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await $api.delete(
          `/api/admin/Feedback/${chosenItem.id}`,
          config
        );
        toast("Заявка на обратную связь успешно удалена");
        setIsNeedUpdate(isNeedUpdate + 1);
        onClose();
      } catch (e) {
        console.error("Ошибка удаления заявки на обратную связь", e);
        toast.error("Ошибка удаления заявки на обратную связь");
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        size={"5xl"}
        isCentered
      >
        <ModalOverlay
          backdropBlur="5px"
          bg="blackAlpha.300"
          backdropFilter="blur(10px) "
        />
        <ModalContent>
          <ModalHeader fontSize={24} color="blue.500" fontWeight="bold">
            <Center>
              Удалить заявку на обратную связь
              {` "${chosenItem?.id}"`}
            </Center>
          </ModalHeader>
          <ModalBody fontSize={16}>
            <Text fontSize={12} color="gray.500" mt={8}>
              Текст
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              type="text"
              h={30}
              borderRadius="xl"
              disabled
              value={chosenItem?.message}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={handleDelete}
              type="submit"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="lg"
              bg="#3786e5"
              color="white"
            >
              Подтвердить
            </Button>
            <Button
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="lg"
              bg="gray.500"
              color="white"
              onClick={() => {
                setChosenItem(undefined);
                onClose();
              }}
            >
              Отмена
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <Box
        m={8}
        fontSize={[12, 8, 10, 12, 14]}
        boxShadow="lg"
        p="8"
        rounded="md"
        bg="white"
        // fontFamily="DM Sans"
      >
        <Wrap fontSize={[12, 8, 10, 12, 14]}>
          <Box ml={4}>         
          </Box>
        </Wrap>
      </Box> */}

      <Wrap m={8} justify="end">
        <Heading as="h2" mt={6} size="xl" mb="4" color="#6484AA">
          Заявки на обратную связь
        </Heading>
        <Spacer />

        <Box
          boxShadow="lg"
          p="6"
          rounded="md"
          bg="white"
          minWidth={220}
          minHeight={20}
          // alignSelf="end"
        >
          <Center>
            <Text fontSize={[10, 12]} fontWeight="700" color="gray.400">
              {`Найдено: `}
            </Text>
            <Text fontSize={[10, 12]} fontWeight="700" color="#6484AA" ml={2}>
              {` ${totalCount} `}
            </Text>
          </Center>
        </Box>
      </Wrap>

      <Box
        m={8}
        color="#6484AA"
        fontSize={[12, 10, 12, 14, 14]}
        fontWeight="500"
      >
        <div className="dashBoard_content__margin dashBoard_w100 dashBoard_flex dashBoard_admin__table">
          <div
            className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
            style={{ padding: "8px" }}
          >
            <p className="dashBoard_operation__item--column7">
              <Center>ID</Center>
            </p>
            <p className="dashBoard_operation__item--column7"></p>

            <p className="dashBoard_operation__item--column5">
              <Center>email</Center>
            </p>
            <p className="dashBoard_operation__item--column5">
              <Center>
                <Box mr={2}>
                  <FcBusinessman />
                </Box>
                Имя
              </Center>
            </p>
            <p className="dashBoard_operation__item--column5">
              <Center>Сообщение</Center>
            </p>
            <p className="dashBoard_operation__item--column5">
              <Center>Дата</Center>
            </p>
          </div>

          {feedbacks?.length > 0 &&
            feedbacks.map((item, index) => (
              <Box
                w="100%"
                key={item.id}
                fontSize={[12, 8, 10, 12, 14]}
                color="#000000"
                borderBottom="1px solid #dfdfdf"
              >
                <div
                  key={item.id}
                  className="dashBoard_w100 dashBoard_flexdef dashBoard_admin__table--item"
                >
                  <p className="dashBoard_operation__item--column7">
                    <Center
                      color="blue.500"
                      as={motion.div}
                      // whileHover={{ scale: 1.1, cursor: "pointer" }}
                      onClick={() => {
                        setChosenItem(item);
                      }}
                    >
                      {item?.id}
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column7">
                    <Center>
                      <Tooltip
                        fontSize={["12px", "16px"]}
                        label="Удалить"
                        hasArrow
                        bg="#ED64A6"
                        borderRadius="xl"
                      >
                        <Box
                          m="2"
                          as={motion.div}
                          whileHover={{ scale: 1.1, cursor: "pointer" }}
                          borderRadius="2xl"
                          bg="#ED64A6"
                          onClick={() => {
                            setChosenItem(item);
                            onOpen();
                          }}
                        >
                          <MdHideSource size={27} color="white" />
                        </Box>
                      </Tooltip>
                    </Center>
                  </p>
                  <p className="dashBoard_operation__item--column5">
                    <Center>{item.email}</Center>
                  </p>
                  <p className="dashBoard_operation__item--column5">
                    <Center>{item.name}</Center>
                  </p>
                  <p className="dashBoard_operation__item--column5">
                    <Center>{item.message}</Center>
                  </p>
                  <p className="dashBoard_operation__item--column5">
                    <Center>
                      <Moment format="DD:MM:YY">{item.createdAt}</Moment>
                    </Center>
                  </p>
                </div>
              </Box>
            ))}

          {totalCount > itemPerPage && (
            <Center fontSize="12">
              <div className="pagination">
                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={prevPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === 1}
                >
                  Назад
                </Button>
                <button
                  onClick={() => setPage(1)}
                  className={`page ${page === 1 && "disabled"}`}
                >
                  1
                </button>
                {gaps.before ? "..." : null}

                {gaps.paginationGroup.map((el) => (
                  <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                  >
                    {el}
                  </button>
                ))}
                {gaps.after ? "..." : null}
                <button
                  onClick={() => setPage(totalPages)}
                  className={`page ${page === totalPages && "disabled"}`}
                >
                  {totalPages}
                </button>

                <Button
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  onClick={nextPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === totalPages}
                >
                  Вперед
                </Button>
              </div>
            </Center>
          )}
        </div>
      </Box>
    </>
  );
};

export default FeedbackList;
