import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../http/apiService";

interface IRate {
  name: "string";
  symbol: "string";
  icon: "string";
  rank: "string";
  price: number;
  volume24hUsd: number;
  marketCapUsd: number;
  percentChange24h: any;
  circulatingSupply: number;
}

interface ICurrencyRatesState {
  rates: IRate[];
  isLoading: boolean;
  isError: boolean;
  message: any;
}

const initialState: ICurrencyRatesState = {
  rates: [],
  isLoading: false,
  isError: false,
  message: "",
};

// Get all currency rates
export const getCurrencyRates = createAsyncThunk(
  "currencyRates/get",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.get(
        "https://lk.pride.anyusecase.com/api/Main/currency-rates",
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reducer
export const currencyRatesSlice = createSlice({
  name: "currencyRates",
  initialState,
  reducers: {
    resetCurrencyRates: (state) => {
      state.rates = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrencyRates.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getCurrencyRates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rates = action.payload;
      })
      .addCase(getCurrencyRates.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resetCurrencyRates } = currencyRatesSlice.actions;
export default currencyRatesSlice.reducer;
