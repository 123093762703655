import React, { useEffect } from "react";
import Moment from "react-moment";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store";
import {
  getInvoiceById,
  getReplenishmentInvoiceList,
  resetInvoice,
} from "../../../features/cryptoInvoice/cryptoInvoiceSlice";

import UsdtIcon2 from "../../../assets/img/Tether_Logo.svg.png";

import {
  MdPayment,
  MdOutlineDateRange,
  MdOutlineContactPage,
} from "react-icons/md";
import { FaRegMoneyBillAlt } from "react-icons/fa";

import { usePagination } from "../../../hooks/pagination/usePagination";
import "../../../hooks/pagination/pagination.scss";

import { toast } from "react-toastify";

import {
  Button,
  Box,
  Flex,
  Image,
  Input,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Center,
  Divider,
} from "@chakra-ui/react";

const itemsPerPage = 10;

export const ReplenishmentsInvoiceHistory = ({
  updateInvoiceList,
  setUpdateInvoiceList,
}: any) => {
  const { invoice, invoiceList } = useAppSelector(
    (state) => state.cryptoInvoice
  );

  // Translation i18next
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Pagination
  const { nextPage, prevPage, page, gaps, setPage, totalPages } = usePagination(
    {
      contentPerPage: itemsPerPage,
      count: invoiceList.totalCount,
    }
  );

  // Pagination request
  useEffect(() => {
    const data = {
      take: 10,
      skip: (page - 1) * itemsPerPage,
    };
    dispatch(getReplenishmentInvoiceList(data));

    setTimeout(() => {
      setUpdateInvoiceList(false);
    }, 3000);
  }, [page, updateInvoiceList]);

  // Modal wallet and sum copy handler
  const copyHandler = (value: string) => {
    window.navigator.clipboard.writeText(value);
    toast.success("Скопировано в буфер обмена!");
  };

  const handleModalClose = () => {
    dispatch(resetInvoice());
    onClose();
  };

  // Просмотр заявок в модалке
  const invoiceVisionHandler = (value: number) => {
    dispatch(getInvoiceById(value));
    setTimeout(() => {
      onOpen();
    }, 800);
  };

  // if (isLoadingInvoice) {
  //   return <GlobalSpinner />;
  // }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        size="6xl"
        isCentered
      >
        <ModalOverlay
          backdropBlur="5px"
          bg="blackAlpha.300"
          backdropFilter="blur(10px)"
        />
        <ModalContent>
          <ModalHeader
            fontSize={["3xl", "4xl"]}
            color="#61D64A"
            fontWeight="medium"
          >
            <Center>
              {t(
                "ReplenishmentsComponent.invoice_for_payment_with_crypto_token_№"
              )}{" "}
              {invoice?.id}!
            </Center>
          </ModalHeader>

          <ModalBody fontSize="2xl" fontWeight="semibold">
            <Center fontSize={["xl", "3xl"]}>
              {t(
                "ReplenishmentsComponent.replenishment_of_the user's_personal_account"
              )}{" "}
              <Text fontWeight="semibold" color="red.500">
                {" "}
                {invoice?.user?.name
                  ? invoice?.user?.name
                  : invoice?.user?.login}
              </Text>
            </Center>
            <Divider />
            <Center m={2}>
              <Text>{t("ReplenishmentsComponent.you_can_pay_at")}</Text>{" "}
              <Text color="red.500" fontWeight="bold">
                {" "}
                {invoice.tokenName} {invoice.network} &nbsp;
              </Text>
            </Center>
            <Center m={2}>
              <Text fontSize={["xl", "2xl"]}>
                {t("ReplenishmentsComponent.payment_is_made")}
              </Text>
            </Center>{" "}
            <Flex m={2} w="100%" textAlign="center" justifyContent="center">
              <Text fontSize={["xl", "2xl"]}>
                {t("ReplenishmentsComponent.application_created")}
              </Text>
              &nbsp;
              <Text fontSize={["xl", "2xl"]} color="red.500" fontWeight="bold">
                <Moment format="DD MMMM YYYY, HH:mm:ss">
                  {invoice?.createdAt}
                </Moment>
              </Text>
            </Flex>
            <Flex m={2} w="100%" textAlign="center" justifyContent="center">
              <Text fontSize={["xl", "2xl"]}>
                {" "}
                {t("ReplenishmentsComponent.application_expires")}
              </Text>
              &nbsp;
              <Text fontSize={["xl", "2xl"]} color="red.500" fontWeight="bold">
                <Moment format="DD MMMM YYYY, HH:mm:ss">
                  {invoice?.expiredAt}
                </Moment>
              </Text>
            </Flex>
            <Center m={2}>
              <Text>{t("ReplenishmentsComponent.time_of_receipt")}</Text>
            </Center>
            <Flex direction="row" m={2} alignItems="center">
              <Text>{t("ReplenishmentsComponent.wallet")}</Text>
              <Input
                value={invoice.address}
                placeholder="USDT Wallet"
                fontSize={["xl", "2xl"]}
                color="red.500"
                fontWeight="bold"
                w={["60%", "75%"]}
                h={14}
                variant="outline"
                mx={2}
                readOnly
              />
              <Button
                onClick={() => copyHandler(invoice.address)}
                colorScheme="blue"
                h={14}
                fontSize="xl"
              >
                {t("ReplenishmentsComponent.copy")}
              </Button>
            </Flex>
            <Flex direction="row" m={2} alignItems="center">
              <Text mr={6}>{t("ReplenishmentsComponent.sum")}</Text>
              <Input
                value={Number(invoice?.expectedTokenAmount).toFixed(2)}
                placeholder="USDT TRC20"
                fontSize={["xl", "2xl"]}
                color="red.500"
                fontWeight="bold"
                w={["60%", "75%"]}
                h={14}
                variant="outline"
                mx={2}
                readOnly
              />
              <Button
                onClick={() =>
                  copyHandler(Number(invoice?.expectedTokenAmount).toFixed(2))
                }
                colorScheme="blue"
                h={14}
                fontSize="xl"
              >
                {t("ReplenishmentsComponent.copy")}
              </Button>
            </Flex>
            <Center m={2}>
              <Text>
                {t("ReplenishmentsComponent.on_account_you_will_receive")}{" "}
                &nbsp; {invoice?.expectedFiatAmount} $
              </Text>
            </Center>
            <Center>
              <Text fontSize="3xl" color="black">
                {t("ReplenishmentsComponent.status")} &nbsp;
              </Text>
              <Text color="green.500" fontSize="3xl" fontWeight="semibold">
                {invoice?.state.name}
              </Text>
            </Center>
            <Center m={2}>
              <Text
                color="red.500"
                fontSize={["xl", "2xl"]}
                fontWeight="semibold"
                textAlign="justify"
              >
                {t("ReplenishmentsComponent.make_sure")}
                {invoice.tokenName} {invoice.network}.
                {t("ReplenishmentsComponent.fund_lost")}
              </Text>
            </Center>
            <Center w="100%" mt={4}>
              <Button
                onClick={() => handleModalClose()}
                w={["50%", "50%", "30%"]}
                h={20}
                mx={2}
                colorScheme="red"
                fontSize="3xl"
              >
                {t("ReplenishmentsComponent.close")}
              </Button>
            </Center>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      {invoiceList?.items.length !== 0 && (
        <div className="content__margin w100 flex admin__last">
          <Flex w="100%" justifyContent="center" my={[2, 2, 4]}>
            <Text fontSize="18px" fontWeight="medium" color="#174073">
              {t("ReplenishmentsComponent.history_of_replenishment")}
            </Text>
          </Flex>
          <div className="w100 flexdef admin__last--item">
            <p className="admin__last--text admin__last--blue operation__item--desc">
              <Center>
                <Box mr={2}>
                  <MdPayment size={20} />
                </Box>{" "}
                {t("ReplenishmentsComponent.payment_system")}
              </Center>
            </p>

            <p className="admin__last--text admin__last--blue operation__item--date">
              <Center>
                <Box mr={2}>
                  <MdOutlineDateRange size={20} />
                </Box>{" "}
                {t("ReplenishmentsComponent.expires")}
              </Center>
            </p>

            <p className="admin__last--text admin__last--blue operation__item--in">
              <Center>
                <Box mr={2}>
                  <FaRegMoneyBillAlt size={20} />
                </Box>
                {t("ReplenishmentsComponent.sum")}
              </Center>
            </p>

            <p className="admin__last--text admin__last--blue operation__item--out">
              <Center>
                <Box mr={2}>
                  <MdOutlineContactPage size={20} />
                </Box>
                {t("ReplenishmentsComponent.status")}
              </Center>
            </p>
          </div>

          {invoiceList?.items?.map((invoice, index) => (
            <div className="w100 flexdef admin__last--item" key={invoice.id}>
              <p className="admin__last--text operation__item--desc">
                <Center>
                  <Box mr={2}>
                    {invoice?.tokenName === "USDT" && (
                      <Image mr={2} w={12} h={12} src={UsdtIcon2} />
                    )}
                  </Box>

                  {invoice?.tokenName}
                </Center>
              </p>

              <p className="admin__last--text operation__item--date">
                <Center>
                  <Moment format="DD MMMM YYYY, hh:mm:ss">
                    {invoice.expiredAt}
                  </Moment>
                </Center>
              </p>

              <p className="admin__last--text operation__item--in">
                <Center>{(+invoice?.expectedTokenAmount).toFixed(2)}</Center>
              </p>

              <p className="admin__last--text operation__item--out">
                <Center>
                  {invoice.state.id === -1 && (
                    <Text fontSize="18px" fontWeight="medium" color="red.500">
                      {invoice.state.name}
                    </Text>
                  )}

                  {invoice.state.id === 0 && (
                    <Button
                      onClick={() => invoiceVisionHandler(invoice.id)}
                      colorScheme="green"
                      w={48}
                      h={14}
                      fontSize="18px"
                      fontWeight="medium"
                    >
                      {invoice.state.name}
                    </Button>
                  )}

                  {invoice.state.id === 1 && (
                    <Text fontSize="18px" fontWeight="medium" color="red.500">
                      {invoice.state.name}
                    </Text>
                  )}

                  {invoice.state.id === 2 && (
                    <Text
                      fontSize="18px"
                      fontWeight="medium"
                      color="purple.500"
                    >
                      {invoice.state.name}
                    </Text>
                  )}

                  {invoice.state.id === 3 && (
                    <Text
                      fontSize="18px"
                      fontWeight="medium"
                      color="purple.500"
                    >
                      {invoice.state.name}
                    </Text>
                  )}

                  {invoice.state.id === 4 && (
                    <Text
                      fontSize="18px"
                      fontWeight="medium"
                      color="purple.500"
                    >
                      {invoice.state.name}
                    </Text>
                  )}

                  {invoice.state.id === 5 && (
                    <Text
                      fontSize="18px"
                      fontWeight="medium"
                      color="purple.500"
                    >
                      {invoice.state.name}
                    </Text>
                  )}
                </Center>
              </p>
            </div>
          ))}

          {invoiceList.totalCount <= itemsPerPage ? (
            <Box></Box>
          ) : (
            <Center w={["100%"]}>
              <div className="pagination">
                <Button
                  onClick={prevPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === 1}
                >
                  {t("ReplenishmentsComponent.back")}
                </Button>
                <button
                  onClick={() => setPage(1)}
                  className={`page ${page === 1 && "disabled"}`}
                >
                  1
                </button>
                {gaps.before ? "..." : null}

                {gaps.paginationGroup.map((el) => (
                  <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                  >
                    {el}
                  </button>
                ))}
                {gaps.after ? "..." : null}
                <button
                  onClick={() => setPage(totalPages)}
                  className={`page ${page === totalPages && "disabled"}`}
                >
                  {totalPages}
                </button>

                <Button
                  onClick={nextPage}
                  w={24}
                  h={12}
                  colorScheme="blue"
                  borderRadius="xl"
                  isDisabled={page === totalPages}
                >
                  {t("ReplenishmentsComponent.forward")}
                </Button>
              </div>
            </Center>
          )}
        </div>
      )}
    </>
  );
};
