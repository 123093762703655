import {
  AddIcon,
  DragHandleIcon,
  EditIcon,
  ExternalLinkIcon,
  HamburgerIcon,
  InfoOutlineIcon,
  RepeatIcon,
  TriangleUpIcon,
  UnlockIcon,
} from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import exp from "constants";
import React, { FC, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserTypeForAdmin } from "./UserList";

type Iprops = {
  user: UserTypeForAdmin;
  // setChosenUser: (user: UserTypeForAdmin)=> void
  setChosenUser: any;
  setUser: any;
  onOpen: any;
  onOpenBlockModal: any;
  onOpenBlockChatModal: any;
  setIsAddComment: any;
  onOpenTransferModal: any;
  setLoginForTransfer: any;
};

const UserMenu = (props: Iprops) => {
  const navigate = useNavigate();
  console.log(
    "window.location.href",
    window.location.href.split("dashboard")[0]
  );
  return (
    <Box
    // filter="auto" blur="" zIndex="1000000"
    >
      <Menu
        onClose={() => {
          props.setChosenUser(undefined);
        }}
      >
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
          _hover={{ bg: "gray.400" }}
          _expanded={{ bg: "blue.400" }}
          // _focus={{ boxShadow: "outline" }}
          onClick={() => {
            props.setChosenUser(props.user);
          }}
        />
        <MenuList ml="12">
          <MenuItem
            icon={<EditIcon />}
            onClick={() => {
              props.setUser(props.user);
              props.onOpen();
            }}
            //   command="⌘T"
          >
            Редактировать партнера
          </MenuItem>
          {/* <MenuItem
            icon={<ExternalLinkIcon />}
            onClick={() => {
              navigate(`/dashboard/payments/${props?.user.login}`);
            }}
          >
            <a target="_blank" rel="noreferrer" href={`${window.location.href.split("dashboard")[0]}dashboard/payments/${props?.user.login}`} >Ведомость партнера</a>

          </MenuItem> */}
          <Box
            ml="3"
            fontSize={[12, 8, 10, 12, 14]}
            fontFamily="dm-sans, sans-serif"
          >
            <ExternalLinkIcon mr="2" />
            <a
              target="_blank"
              rel="noreferrer"
              href={`${
                window.location.href.split("dashboard")[0]
              }dashboard/payments/${props?.user.login}`}
              style={{ fontSize: "14px", fontFamily: "dm-sans, sans-serif" }}
            >
              Ведомость партнера
            </a>
          </Box>
          {/* <MenuItem
            icon={<ExternalLinkIcon />}
            onClick={() => {
              props.user?.invites[0]?.parent?.login
                ? navigate(
                  `/dashboard/payments/${props?.user?.invites[0]?.parent?.login}`
                )
                : navigate(`/dashboard/payments`);
            }}
          >
            Ведомость спонсора
          </MenuItem> */}
          <Box
            ml="3"
            mt="1"
            fontSize={[12, 8, 10, 12, 14]}
            fontFamily="dm-sans, sans-serif"
          >
            <ExternalLinkIcon mr="2" />
            <a
              target="_blank"
              rel="noreferrer"
              href={`${
                window.location.href.split("dashboard")[0]
              }dashboard/payments/${props?.user?.invites[0]?.parent?.login}`}
              style={{ fontSize: "14px", fontFamily: "dm-sans, sans-serif" }}
            >
              Ведомость спонсора
            </a>
          </Box>
          <MenuItem
            icon={<DragHandleIcon />}
            onClick={() => {
              navigate(`/dashboard/referals/${props?.user.login}`);
            }}
          >
            Показать матрицу
          </MenuItem>

          <MenuItem
            icon={<UnlockIcon />}
            onClick={() => {
              props.setUser(props.user);
              props.onOpenBlockModal();
            }}
          >
            {props.user.isBlocked ? "Разблокировать" : "Заблокировать"}
          </MenuItem>

          <MenuItem
            icon={<UnlockIcon />}
            onClick={() => {
              props.setUser(props.user);
              props.onOpenBlockChatModal();
            }}
          >
            {props.user.isChatBlocked
              ? "Разблокировать чат"
              : "Заблокировать чат"}
          </MenuItem>

          <MenuItem
            icon={<EditIcon />}
            onClick={() => {
              props.setUser(props.user);
              props.setIsAddComment(true);
            }}
          >
            Комментарий
          </MenuItem>
          <MenuItem
            icon={<InfoOutlineIcon />}
            onClick={() => {
              navigate(`/dashboard/profile/${props?.user.login}`);
            }}
          >
            Полная информация
          </MenuItem>
          <MenuItem
            icon={<TriangleUpIcon />}
            onClick={() => {
              props.setLoginForTransfer(props.user.login);
              props.onOpenTransferModal();
            }}
          >
            Перевести средства
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default UserMenu;
