import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineSetting } from "react-icons/ai";

import { useTranslation } from "react-i18next";

export const AutoMoney: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const redirectHandler = () => {
    navigate("/register");
  };

  return (
    <>
      <div className="container">
        <div className="mark__inner">
          <div className="mark__info--inner">
            <div className="mark__info">
              <p className="mark__info--title">Auto money</p>

              <p className="mark__info--text">{t("Auto.title1")}</p>

              <div className="mark__point--inner">
                <div className="mark__point">
                  <img
                    src="/assets/img/check-blue.svg"
                    alt="Галочка"
                    className="mark__point--check"
                  />
                  {t("Auto.title2")}
                </div>

                <div className="mark__point">
                  <img
                    src="/assets/img/check-blue.svg"
                    alt="Галочка"
                    className="mark__point--check"
                  />
                  {t("Auto.title3")}.
                </div>

                <div className="mark__point">
                  <img
                    src="/assets/img/check-blue.svg"
                    alt="Галочка"
                    className="mark__point--check"
                  />
                  {t("Auto.title4")}
                </div>

                <div className="mark__point">
                  <img
                    src="/assets/img/check-blue.svg"
                    alt="Галочка"
                    className="mark__point--check"
                  />
                  {t("Auto.title5")}
                </div>

                <div className="mark__point">
                  <img
                    src="/assets/img/check-blue.svg"
                    alt="Галочка"
                    className="mark__point--check"
                  />
                  {t("Auto.title6")}
                </div>

                <div className="mark__point">
                  <img
                    src="/assets/img/check-blue.svg"
                    alt="Галочка"
                    className="mark__point--check"
                  />
                  {t("Auto.title7")}
                </div>
              </div>
            </div>

            <AiOutlineSetting className="mark__info--img" />
          </div>
        </div>

        <div className="mark__content">
          <div className="mark__item">
            <div className="mark__wrap">
              <div className="mark__item--wrapper">
                <div className="mark__wrapper">
                  <p className="mark__wrapper--title">1 {t("Auto.matrix")}</p>

                  <p className="mark__wrapper--text">Auto Money</p>
                </div>

                <p className="mark__item--wrapper--text">
                  50 $ {t("Auto.curators")}
                </p>
              </div>

              <div className="mark__item--entry">
                {t("Auto.entry")}: 1100 $
              </div>

              <div className="mark__item--content">
                <img
                  src="assets/img/man1.png"
                  alt="Картинка"
                  className="mark__item--img"
                />

                <p className="mark__item--val big line">1100</p>

                <div className="mark__item--box">
                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man2.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">1000</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        200 $ {t("Auto.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        800 $ {t("Auto.accumulation")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man3.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">1000</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        200 $ {t("Auto.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        800 $ {t("Auto.accumulation")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man4.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">1000</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        200 $ {t("Auto.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        800 $ {t("Auto.transition")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mark__item--desc"></div>

            <button
              onClick={() => redirectHandler()}
              className="button default__button mark__item--button"
            >
              {t("Auto.participate")}
            </button>
          </div>

          <div className="mark__item">
            <div className="mark__wrap">
              <div className="mark__item--wrapper">
                <div className="mark__wrapper">
                  <p className="mark__wrapper--title">2 {t("Auto.matrix")}</p>

                  <p className="mark__wrapper--text">Auto Money</p>
                </div>

                <p className="mark__item--wrapper--text">
                  50 $ {t("Auto.curators")}
                </p>
              </div>

              <div className="mark__item--entry">
                {t("Auto.entry")}: 2400 $
              </div>

              <div className="mark__item--content">
                <img
                  src="assets/img/man1.png"
                  alt="Картинка"
                  className="mark__item--img"
                />

                <p className="mark__item--val big line">2400</p>

                <div className="mark__item--box">
                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man2.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">2300</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        500 $ {t("Auto.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        300 $ {t("Auto.balance_clones")}
                      </p>

                      <p className="mark__item--box--text">
                        1500 $ {t("Auto.accumulation")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man3.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">2300</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        500 $ {t("Auto.for_withdrawal")}
                      </p>
                      <p className="mark__item--box--text">
                        300 $ {t("Auto.balance_clones")}
                      </p>
                      <p className="mark__item--box--text">
                        1500 $ {t("Auto.accumulation")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man4.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">2300</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        500 $ {t("Auto.for_withdrawal")}
                      </p>
                      <p className="mark__item--box--text">
                        300 $ {t("Auto.balance_clones")}
                      </p>
                      <p className="mark__item--box--text">
                        1500 $ {t("Auto.transition3")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mark__item--desc">{t("Auto.desc1")}.</div>

            <button
              onClick={() => redirectHandler()}
              className="button default__button mark__item--button"
            >
              {t("Auto.participate")}
            </button>
          </div>

          <div className="mark__item">
            <div className="mark__wrap">
              <div className="mark__item--wrapper">
                <div className="mark__wrapper">
                  <p className="mark__wrapper--title">3 {t("Auto.matrix")}</p>

                  <p className="mark__wrapper--text">Auto Money</p>
                </div>

                <p className="mark__item--wrapper--text">
                  100 $ {t("Auto.curators")}
                </p>
              </div>

              <div className="mark__item--entry">
                {t("Auto.entry")}: 4500 $
              </div>

              <div className="mark__item--content">
                <img
                  src="assets/img/man1.png"
                  alt="Картинка"
                  className="mark__item--img"
                />

                <p className="mark__item--val big line">4500</p>

                <div className="mark__item--box">
                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man2.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">4300</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        1000 $ {t("Auto.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        1000 $ {t("Auto.balance_clones")}
                      </p>

                      <p className="mark__item--box--text">
                        2 300 $ {t("Auto.accumulation")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man3.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">4300</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        1000 $ {t("Auto.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        1000 $ {t("Auto.balance_clones")}
                      </p>

                      <p className="mark__item--box--text">
                        2 300 $ {t("Auto.accumulation")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man4.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">4300</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        1000 $ {t("Auto.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        1000 $ {t("Auto.balance_clones")}
                      </p>

                      <p className="mark__item--box--text">
                        2 300 $ {t("Auto.transition4")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mark__item--desc">{t("Auto.desc2")}.</div>

            <button
              onClick={() => redirectHandler()}
              className="button default__button mark__item--button"
            >
              {t("Auto.participate")}
            </button>
          </div>

          <div className="mark__item">
            <div className="mark__wrap">
              <div className="mark__item--wrapper">
                <div className="mark__wrapper">
                  <p className="mark__wrapper--title">4 {t("Auto.matrix")}</p>

                  <p className="mark__wrapper--text">Auto Money</p>
                </div>

                <p className="mark__item--wrapper--text">
                  200 $ {t("Auto.curators")}
                </p>
              </div>

              <div className="mark__item--entry">
                {t("Auto.entry")}: 6900 $
              </div>

              <div className="mark__item--content">
                <img
                  src="assets/img/man1.png"
                  alt="Картинка"
                  className="mark__item--img"
                />

                <p className="mark__item--val big line">6900</p>

                <div className="mark__item--box">
                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man2.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">6500</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        5 000 $ {t("Auto.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        1300 $ {t("Auto.balance_clones")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man3.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">6500</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        5 000 $ {t("Auto.for_withdrawal")}
                      </p>

                      <p className="mark__item--box--text">
                        1300 $ {t("Auto.balance_clones")}
                      </p>
                    </div>
                  </div>

                  <div className="mark__item--box--item">
                    <img
                      src="assets/img/man4.png"
                      alt="Картинка"
                      className="mark__item--img"
                    />

                    <p className="mark__item--val">6500</p>

                    <div className="mark__item--box--text--inner">
                      <p className="mark__item--box--text">
                        6 900 $ {t("Auto.desc3")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mark__item--desc">{t("Auto.desc4")}.</div>

            <button
              onClick={() => redirectHandler()}
              className="button default__button mark__item--button"
            >
              {t("Auto.participate")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
