import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Center,
  Text,
} from "@chakra-ui/react";
import $api from "../../../http/apiService";
import { toast } from "react-toastify";

type Iprops = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  data: any;
  setData: React.Dispatch<React.SetStateAction<any>>;
  setShouldUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  shouldAddBToId: any;
  setShouldAddBToId: any;
  setIdForSearch: React.Dispatch<React.SetStateAction<any>>;
  dataForMatrix: any[];
  setDataForMatrix: any;
};

const BlockModal = (props: Iprops) => {
  const handleClose = () => {
    props.setData(null);
    props.onClose();
  };

  const checkChildren = (id: number) => {
    const childrenIds: number[] = [];
    props.dataForMatrix.forEach((el: any) => {
      if (el.parentId === id) {
        childrenIds.push(el.id);
        const nextChildren = checkChildren(el.id);
        if (nextChildren?.length > 0) {
          childrenIds.push(...nextChildren);
        }
      }
    });
    // console.log(childrenIds);
    return childrenIds;
  };

  const updateData = (block: boolean) => {
    props.setDataForMatrix([]);
    const allChildrenIds: number[] = checkChildren(props.data.id);
    const newDataForMatrix: any[] = [];
    props.dataForMatrix.forEach((el) => {
      if (!allChildrenIds.includes(el.id)) {
        newDataForMatrix.push(el);
      }
      if (el.id === props.data.id) {
        el.isBlocked = block;
        el.canBlock = !block;
      }
    });

    // console.log("newDataForMatrix", newDataForMatrix);
    props.setDataForMatrix(newDataForMatrix);
  };

  const block = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // const rd = {
    //   parentId: item?.realParentId, /// todo
    //   pos: item?.pos,
    // };
    const rd = {
      parentId: props.data?.realParentId, /// todo
      pos: props.data?.pos,
    };

    if (props.data.isBlocked === false) {
      try {
        const res = await $api.post("/api/Marketing/block", rd, config);

        if (res.status >= 200 && res.status < 300) {
          toast(`Клон заблокирован ${props.data?.number}`);
          updateData(true);
        }
        handleClose();
      } catch (e: any) {
        console.error(e);
        toast.error(e?.response?.data?.detail);
      }
    } else if (props.data.isBlocked === true) {
      try {
        const res = await $api.post("/api/Marketing/unblock", rd, config);

        if (res.status >= 200 && res.status < 300) {
          toast(`Клон разблокирован ${props.data?.number}`);
          updateData(false);
        }
        handleClose();
      } catch (e) {
        console.error(e);
        toast.error("Ошибка разблокировки");
      }
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      size={"5xl"}
      isCentered
    >
      <ModalOverlay
        backdropBlur="5px"
        bg="blackAlpha.300"
        backdropFilter="blur(10px) "
      />
      <ModalContent>
        <ModalHeader fontSize={24} color="blue.500" fontWeight="bold">
          <Center>
            {props?.data?.isBlocked
              ? `Разблокировать клон №: ${
                  props?.data?.realId ? props?.data?.realId : "no id"
                }`
              : `Блокировать клон №: ${
                  props?.data?.realId ? props?.data?.realId : "no id"
                }`}
          </Center>
        </ModalHeader>
        <ModalBody fontSize={16}>
          {/* <Text fontSize={12} color="gray.500" mt={8}>
            Подтвердите действие
          </Text> */}
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={block}
            colorScheme="facebook"
            ml={10}
            width={60}
            height={16}
            fontSize="2xl"
            boxShadow="dark-lg"
          >
            {props?.data?.isBlocked ? `Разблокировать` : `Блокировать`}
          </Button>
          <Button
            colorScheme="twitter"
            ml={10}
            width={60}
            height={16}
            fontSize="2xl"
            boxShadow="dark-lg"
            onClick={() => {
              handleClose();
            }}
          >
            Закрыть
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BlockModal;
