import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../http/apiService";

export interface IContent {
  lang: string;
  title: string;
  preview: string;
  text: string;
}
export interface INews {
  id: number;
  publishedAt: any;
  expiredAt: string;
  imageUrl: string;
  createdAt: string;
  isPublic: boolean;
  content: IContent[];
}

interface INewsState {
  userNews: INews[];
  chosenNews: {
    news: INews;
    isFresh: boolean;
  };
  publicNews: INews[];
  isLoading: boolean;
  isError: boolean;
  message: any;
}

const initialState: INewsState = {
  userNews: [],
  chosenNews: {
    news: {
      id: 1,
      publishedAt: "",
      expiredAt: "",
      imageUrl: "",
      createdAt: "",
      isPublic: false,
      content: [
        {
          lang: "",
          title: "",
          preview: "",
          text: "",
        },
      ],
    },
    isFresh: false,
  },
  publicNews: [],
  isLoading: false,
  isError: false,
  message: "",
};

// Get user news
export const getUserNews = createAsyncThunk(
  "userNews/get",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.get("/api/News", config);
      return response.data;
      // const newsArr: any = [];
      // response.data.map((news: any, index: number) => {
      //   if (news.isPublic === false) {
      //     newsArr.push(news);
      //   }
      // });
      // return newsArr;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get chosen news
export const getChosenNews = createAsyncThunk(
  "chosenNews/post",
  async (id: Record<string, unknown>, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.post("/api/News/item", id, config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get publick news
export const getPublickNews = createAsyncThunk(
  "publickNews/get",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await $api.get("/api/News", config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      const message = `Status ${error.response.data.status}! ${error.response.data.detail}!`;
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reducer
export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserNews.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getUserNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userNews = action.payload;
      })
      .addCase(getUserNews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getChosenNews.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getChosenNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chosenNews = action.payload;
      })
      .addCase(getChosenNews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPublickNews.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getPublickNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.publicNews = action.payload;
      })
      .addCase(getPublickNews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

// export const { } = newsSlice.actions;
export default newsSlice.reducer;
