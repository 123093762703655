import React, { FC, useLayoutEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DreamsLogo from "../../../assets/img/Dreams Come True 2 1.svg";
import FlagRu from "../../../assets/img/flag-ru.jpg";
import FlagEn from "../../../assets/img/flag-england.jpg";
import FlagGe from "../../../assets/img/flag-germany.jpg";
import FlagKz from "../../../assets/img/flag-kazahstan.jpg";
import FlagKr from "../../../assets/img/flag-kirgizija.jpg";
import FlagTr from "../../../assets/img/flag-turtsija.jpg";
import FlagUz from "../../../assets/img/flag-uzbekistan.jpg";
import FlagUa from "../../../assets/img/flag-ukraina.jpg";
import FlagFr from "../../../assets/img/flag-frantsija.jpg";

import presentsEN from "../../../assets/documents/EN.pdf";
import presentsFR from "../../../assets/documents/FR.pdf";
import presentsUA from "../../../assets/documents/UA.pdf";
import presentsUZ from "../../../assets/documents/UZ.pdf";
import presentsTR from "../../../assets/documents/TR.pdf";
import presentsKZ from "../../../assets/documents/KZ.pdf";
import presentsKG from "../../../assets/documents/KG.pdf";
import presentsDE from "../../../assets/documents/DE.pdf";
import presentsRU from "../../../assets/documents/RU.pdf";
import presentsNewMatrix from "../../../assets/documents/Dreams_Come_True_2_compressed.pdf";
import presentsNew from "../../../assets/documents/Dreams_Come_True_22_compressed.pdf";

import { Button, Flex, Text, Box, Image } from "@chakra-ui/react";
import Meta from "../../../utils/seo/Meta";

const Presents: FC = () => {
  // Scroll to top
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Translation i18next
  const { t } = useTranslation();

  // Framer Motion
  const MotionBox = motion(Box);
  const variants = {
    hidden: {
      scale: 0,
      opacity: 0,
    },
    visible: (custom: number) => ({
      scale: 1,
      opacity: 1,
      transition: { duration: custom + 0.4 },
    }),
  };

  const presentations = [
    {
      id: 0,
      title: "Презентация на русском языке",
      text: " Матрицы – это партнерская программа, с механизмом взаимовыгодного сотрудничества между ее участниками (партнерами), которая работает по принципу взаимопомощи.",
      link: "ru",
      href: presentsNew,
    },
    {
      id: 1,
      title: "Презентация smart matrix на русском языке",
      text: " Матрицы – это партнерская программа, с механизмом взаимовыгодного сотрудничества между ее участниками (партнерами), которая работает по принципу взаимопомощи.",
      link: "ru_matrix",
      href: presentsNewMatrix,
    },
  ];

  return (
    <>
      <Meta title="Personal account" />
      <div className="admin__content--content flex w100">
        <div className="w100 flex">
          <h3 className="admin__title--inner w100">
            <img
              className="admin__title--icon"
              src="/assets/img/user-red.svg"
              alt="Иконка"
            />
            {t("Presentation.marketing_presentation")}
          </h3>

          <div className="presents__content content__margin flex w100">
            {presentations.map((elem) => (
              <MotionBox
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.1, once: true }}
                variants={variants}
                custom={0}
                className="presents__item flex flexbet"
                key={elem.id}
              >
                <Flex
                  direction="column"
                  w="100%"
                  h="200px"
                  bgGradient="linear(to-br, #0328a6, #78a0ec)"
                >
                  <Box m={4}>
                    <Image src={DreamsLogo} />
                  </Box>

                  <Flex direction="row" justifyContent="end">
                    <Box w={48} m={4}>
                      <Image src={FlagRu} />
                    </Box>
                  </Flex>
                </Flex>

                <div className="admin__news--text--inner flex w100">
                  <p className="admin__news--text--title">{elem.title}</p>

                  <p className="admin__news--text--text">{elem.text}</p>
                </div>
                <Flex alignItems="center" mx="auto">
                  <Link to={elem.link}>
                    <Button
                      colorScheme="blue"
                      borderRadius="xl"
                      w={[48, 60]}
                      h={20}
                      m={2}
                      boxShadow="2xl"
                    >
                      <Text fontSize="18px" color="white" fontFamily="Graphik">
                        Oткрыть
                      </Text>
                    </Button>
                  </Link>

                  <a href={elem.href} download>
                    <Button
                      colorScheme="blue"
                      borderRadius="xl"
                      w={[48, 60]}
                      h={20}
                      m={2}
                      boxShadow="2xl"
                    >
                      <Text fontSize="18px" color="white" fontFamily="Graphik">
                        Скачать
                      </Text>
                    </Button>
                  </a>
                </Flex>
              </MotionBox>
            ))}

            {/* <MotionBox
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "100px", once: true }}
              variants={variants}
              custom={0.1}
              className="presents__item flex flexbet"
            >
              <Flex
                direction="column"
                w="100%"
                h="200px"
                bgGradient="linear(to-br, #0328a6, #78a0ec)"
              >
                <Box m={4}>
                  <Image src={DreamsLogo} />
                </Box>

                <Flex direction="row" justifyContent="end">
                  <Box w={48} m={4}>
                    <Image src={FlagEn} />
                  </Box>
                </Flex>
              </Flex>

              <div className="admin__news--text--inner flex w100">
                <p className="admin__news--text--title">
                  Presentation in English
                </p>

                <p className="admin__news--text--text">
                  Matrices is an affiliate program, with a mechanism for
                  mutually beneficial cooperation between its presence (by
                  partners), which works on the principle of mutual assistance.
                </p>
              </div>
              <Flex alignItems="center" mx="auto">
                <Link to="en">
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Open
                    </Text>
                  </Button>
                </Link>

                <a href={presentsEN} download>
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Download
                    </Text>
                  </Button>
                </a>
              </Flex>
            </MotionBox>

            <MotionBox
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "100px", once: true }}
              variants={variants}
              custom={0.2}
              className="presents__item flex flexbet"
            >
              <Flex
                direction="column"
                w="100%"
                h="200px"
                bgGradient="linear(to-br, #0328a6, #78a0ec)"
              >
                <Box m={4}>
                  <Image src={DreamsLogo} />
                </Box>

                <Flex direction="row" justifyContent="end">
                  <Box w={48} m={4}>
                    <Image src={FlagGe} />
                  </Box>
                </Flex>
              </Flex>

              <div className="admin__news--text--inner flex w100">
                <p className="admin__news--text--title">
                  Präsentation auf Deutsch
                </p>

                <p className="admin__news--text--text">
                  Matrices ist ein Partnerprogramm mit einem Mechanismus für
                  eine gegenseitig vorteilhafte Zusammenarbeit zwischen seine
                  Präsenz (durch Partner), die nach dem Prinzip der
                  gegenseitigen Hilfeleistung funktioniert.
                </p>
              </div>
              <Flex alignItems="center" mx="auto">
                <Link to="de">
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Offen
                    </Text>
                  </Button>
                </Link>

                <a href={presentsDE} download>
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[52, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Herunterladen
                    </Text>
                  </Button>
                </a>
              </Flex>
            </MotionBox>

            <MotionBox
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "100px", once: true }}
              variants={variants}
              custom={0.3}
              className="presents__item flex flexbet"
            >
              <Flex
                direction="column"
                w="100%"
                h="200px"
                bgGradient="linear(to-br, #0328a6, #78a0ec)"
              >
                <Box m={4}>
                  <Image src={DreamsLogo} />
                </Box>

                <Flex direction="row" justifyContent="end">
                  <Box w={48} m={4}>
                    <Image src={FlagFr} />
                  </Box>
                </Flex>
              </Flex>

              <div className="admin__news--text--inner flex w100">
                <p className="admin__news--text--title">
                  Présentation en français
                </p>

                <p className="admin__news--text--text">
                  Matrices est un programme d&lsquo;affiliation, avec un
                  mécanisme de coopération mutuellement bénéfique entre sa
                  présence (par partenaires), qui fonctionne sur le principe de
                  l&lsquo;entraide.
                </p>
              </div>
              <Flex alignItems="center" mx="auto">
                <Link to="fr">
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Ouvert
                    </Text>
                  </Button>
                </Link>

                <a href={presentsFR} download>
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Télécharger
                    </Text>
                  </Button>
                </a>
              </Flex>
            </MotionBox>

            <MotionBox
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "100px", once: true }}
              variants={variants}
              custom={0.4}
              className="presents__item flex flexbet"
            >
              <Flex
                direction="column"
                w="100%"
                h="200px"
                bgGradient="linear(to-br, #0328a6, #78a0ec)"
              >
                <Box m={4}>
                  <Image src={DreamsLogo} />
                </Box>

                <Flex direction="row" justifyContent="end">
                  <Box w={48} m={4}>
                    <Image src={FlagTr} />
                  </Box>
                </Flex>
              </Flex>

              <div className="admin__news--text--inner flex w100">
                <p className="admin__news--text--title">Türkçe sunum</p>

                <p className="admin__news--text--text">
                  Matrisler, aralarında karşılıklı olarak yararlı işbirliği için
                  bir mekanizmaya sahip bir ortaklık programıdır. karşılıklı
                  yardım ilkesine göre çalışan varlığı (ortaklar tarafından).
                </p>
              </div>
              <Flex alignItems="center" mx="auto">
                <Link to="tr">
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Açık
                    </Text>
                  </Button>
                </Link>

                <a href={presentsTR} download>
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      İndirmek
                    </Text>
                  </Button>
                </a>
              </Flex>
            </MotionBox>

            <MotionBox
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "100px", once: true }}
              variants={variants}
              custom={0.5}
              className="presents__item flex flexbet"
            >
              <Flex
                direction="column"
                w="100%"
                h="200px"
                bgGradient="linear(to-br, #0328a6, #78a0ec)"
              >
                <Box m={4}>
                  <Image src={DreamsLogo} />
                </Box>

                <Flex direction="row" justifyContent="end">
                  <Box w={48} m={4}>
                    <Image src={FlagUa} />
                  </Box>
                </Flex>
              </Flex>

              <div className="admin__news--text--inner flex w100">
                <p className="admin__news--text--title">
                  Презентація українською мовою
                </p>

                <p className="admin__news--text--text">
                  Матриці – це партнерська програма, з механізмом
                  взаємовигідного співробітництва між її присутність
                  (партнерами), що працює за принципом взаємодопомоги.
                </p>
              </div>
              <Flex alignItems="center" mx="auto">
                <Link to="ua">
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Відкрити
                    </Text>
                  </Button>
                </Link>

                <a href={presentsUA} download>
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Скачати
                    </Text>
                  </Button>
                </a>
              </Flex>
            </MotionBox>

            <MotionBox
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "100px", once: true }}
              variants={variants}
              custom={0.6}
              className="presents__item flex flexbet"
            >
              <Flex
                direction="column"
                w="100%"
                h="200px"
                bgGradient="linear(to-br, #0328a6, #78a0ec)"
              >
                <Box m={4}>
                  <Image src={DreamsLogo} />
                </Box>

                <Flex direction="row" justifyContent="end">
                  <Box w={48} m={4}>
                    <Image src={FlagKz} />
                  </Box>
                </Flex>
              </Flex>

              <div className="admin__news--text--inner flex w100">
                <p className="admin__news--text--title">
                  Неміс тілінде презентация
                </p>

                <p className="admin__news--text--text">
                  Матрицалар – серіктестік бағдарламасы, оның арасында өзара
                  тиімді ынтымақтастық механизмі бар оның қатысуы (серіктестер
                  тарапынан), ол өзара көмек қағидаты бойынша жұмыс істейді.
                </p>
              </div>
              <Flex alignItems="center" mx="auto">
                <Link to="kz">
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Ашық
                    </Text>
                  </Button>
                </Link>

                <a href={presentsKZ} download>
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Жүктеп алу
                    </Text>
                  </Button>
                </a>
              </Flex>
            </MotionBox>

            <MotionBox
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "100px", once: true }}
              variants={variants}
              custom={0.7}
              className="presents__item flex flexbet"
            >
              <Flex
                direction="column"
                w="100%"
                h="200px"
                bgGradient="linear(to-br, #0328a6, #78a0ec)"
              >
                <Box m={4}>
                  <Image src={DreamsLogo} />
                </Box>

                <Flex direction="row" justifyContent="end">
                  <Box w={48} m={4}>
                    <Image src={FlagKr} />
                  </Box>
                </Flex>
              </Flex>

              <div className="admin__news--text--inner flex w100">
                <p className="admin__news--text--title">
                  Кыргыз тилинде презентация
                </p>

                <p className="admin__news--text--text">
                  Matrices - бул өнөктөштүк программасы, анын ортосунда өз ара
                  пайдалуу кызматташуу механизми бар анын катышуусу (өнөктөштөр
                  тарабынан), ал өз ара жардам көрсөтүү принцибинде иштейт.
                </p>
              </div>
              <Flex alignItems="center" mx="auto">
                <Link to="kg">
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Ачык
                    </Text>
                  </Button>
                </Link>

                <a href={presentsKG} download>
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Жүктөп алуу
                    </Text>
                  </Button>
                </a>
              </Flex>
            </MotionBox>

            <MotionBox
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "100px", once: true }}
              variants={variants}
              custom={0.7}
              className="presents__item flex flexbet"
            >
              <Flex
                direction="column"
                w="100%"
                h="200px"
                bgGradient="linear(to-br, #0328a6, #78a0ec)"
              >
                <Box m={4}>
                  <Image src={DreamsLogo} />
                </Box>

                <Flex direction="row" justifyContent="end">
                  <Box w={48} m={4}>
                    <Image src={FlagUz} />
                  </Box>
                </Flex>
              </Flex>

              <div className="admin__news--text--inner flex w100">
                <p className="admin__news--text--title">
                  Taqdimot ozbek tilida
                </p>

                <p className="admin__news--text--text">
                  Matritsalar o&lsquo;zaro manfaatli hamkorlik mexanizmiga ega
                  bo&lsquo;lgan hamkorlik dasturidir o&lsquo;zaro yordam
                  tamoyili asosida ishlaydigan uning mavjudligi (hamkorlar
                  tomonidan).
                </p>
              </div>
              <Flex alignItems="center" mx="auto">
                <Link to="uz">
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Ochiq
                    </Text>
                  </Button>
                </Link>

                <a href={presentsUZ} download>
                  <Button
                    colorScheme="blue"
                    borderRadius="xl"
                    w={[48, 60]}
                    h={20}
                    m={2}
                    boxShadow="2xl"
                  >
                    <Text fontSize="18px" color="white" fontFamily="Graphik">
                      Yuklab olish
                    </Text>
                  </Button>
                </a>
              </Flex>
            </MotionBox> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Presents;
