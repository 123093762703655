import React, { FC, useEffect, useState } from "react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Center,
  Input,
  Text,
  Select,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import { SubmitHandler, useForm } from "react-hook-form";
import $api from "../../http/apiService";
import { IdNameType } from "./UserList";
import { toast } from "react-toastify";

type Iprops = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  isNeedUpdate: number;
  setIsNeedUpdate: any;
  accounts: IdNameType[];
  loginForTransfer?: string;
  setLoginForTransfer?: any;
};
type IFormInput = {
  fromLogin: string;
  toLogin: string;
  amount: number | string;
  fromAccount: number | string;
  toAccount: number | string;
  desc: string;
};

const PaymentTransferItem = (props: Iprops) => {
  // React hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: "all",
  });
  const emptyItem: IFormInput = {
    fromLogin: props.loginForTransfer ?? "",
    toLogin: "",
    amount: "",
    fromAccount: "",
    toAccount: "",
    desc: "",
  };

  const [isLoginFrom, setIsLoginFrom] = useState(false);
  const [isLoginTo, setIsLoginTo] = useState(false);
  useEffect(() => {
    reset(emptyItem);
    if (props.loginForTransfer) {
      setIsLoginFrom(true);
    }
  }, [props.loginForTransfer]);
  useEffect(() => {
    const obj = {
      Content: [
        { lang: 1, title: "test" },
        { lang: 2, title: "test2" },
      ],
    };
    console.log(JSON.stringify(obj));
  }, []);

  // Default values hook form initialize
  // useEffect(() => {
  //   if (props.loginForTransfer) {
  //     emptyItem.loginFrom = props.loginForTransfer;
  //     reset(emptyItem);
  //   }
  // }, [props.loginForTransfer]);

  const handleClose = async () => {
    reset(emptyItem);
    if (props.setLoginForTransfer) {
      props.setLoginForTransfer("");
    }
    setIsLoginFrom(false);
    setIsLoginTo(false);
    props.onClose();
  };

  const checkLogin = async (login: string, id: number) => {
    const rd = {
      login,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await $api.post(`api/User/is-login-free`, rd, config);
      // console.log("response", response);
      if (response.status === 200) {
        if (id === 0) {
          setIsLoginFrom(!response.data.free);
        } else {
          setIsLoginTo(!response.data.free);
        }
      }
    } catch (e) {
      console.error("Ошибка проверки логина", e);
    }
  };

  const onSubmitForm: SubmitHandler<IFormInput> = async (data) => {
    console.log("data", data);
    const rd: any = { ...data };
    rd.fromAccount = +rd.fromAccount;
    rd.toAccount = +rd.toAccount;
    rd.amount = +rd.amount;
    rd.descriptions = [
      {
        lang: 1,
        desc: data.desc,
      },
    ];
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await $api.post(
        "/api/admin/Payment/transfer",
        rd,
        config
      );
      // console.log("response", response);
      if (response.status >= 200 && response.status < 300) {
        toast.success(`Перевод успешно добавлен`);
        props.setIsNeedUpdate(props.isNeedUpdate + 1);
        handleClose();
      }
    } catch (e) {
      console.error("Ошибка перевода средств", e);
      toast.error("Ошибка перевода средств");
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={handleClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      size={"5xl"}
      isCentered
    >
      <ModalOverlay
        backdropBlur="5px"
        bg="blackAlpha.300"
        backdropFilter="blur(10px) "
      />
      <ModalContent>
        <ModalHeader fontSize={24} color="blue.500" fontWeight="bold">
          <Center>Перевод средств</Center>
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <ModalBody fontSize={16}>
            <Flex>
              <Flex direction="column">
                <Text fontSize={12} color="gray.500" mt={8}>
                  Логин отправителя*
                </Text>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  h={30}
                  borderRadius="xl"
                  w="200"
                  // readOnly
                  // disabled
                  // bg="gray.100"
                  {...register("fromLogin", {
                    required: "Поле обязательно к заполнению",
                    onChange: (e) => checkLogin(e.target.value, 0),
                  })}
                />
                <Text
                  fontSize={10}
                  color={isLoginFrom ? "blue.500" : "red.500"}
                >
                  {isLoginFrom ? "Логин существует" : "Введите валидный логин"}
                </Text>
              </Flex>

              <Flex direction="column" ml="8">
                <Text fontSize={12} color="gray.500" mt={8}>
                  Счет отправителя*
                </Text>
                <Select
                  border="1px solid #3786e5"
                  variant="primary"
                  size="lg"
                  color="gray.500"
                  borderRadius="xl"
                  fontSize={[12, 8, 10, 12, 14]}
                  minW="80"
                  {...register("fromAccount")}
                >
                  {/* <option value={-1}>Все</option> */}
                  {props?.accounts?.length > 0 &&
                    props.accounts.map((elem) => (
                      <option key={elem.id} value={elem.id}>
                        {elem.name}
                      </option>
                    ))}
                </Select>
              </Flex>
            </Flex>

            <Flex>
              <Flex direction="column">
                <Text fontSize={12} color="gray.500" mt={8}>
                  Логин получателя*
                </Text>
                <Input
                  variant="primary"
                  fontSize={[12, 8, 10, 12, 14]}
                  type="text"
                  h={30}
                  borderRadius="xl"
                  w="100%"
                  minW="80"
                  {...register("toLogin", {
                    required: "Поле обязательно к заполнению",
                    onChange: (e) => checkLogin(e.target.value, 1),
                  })}
                />
                <Text fontSize={10} color={isLoginTo ? "blue.500" : "red.500"}>
                  {isLoginTo ? "Логин существует" : "Введите валидный логин"}
                </Text>
              </Flex>
              <Flex direction="column" ml="8">
                <Text fontSize={12} color="gray.500" mt={8}>
                  Счет получателя*
                </Text>
                <Select
                  border="1px solid #3786e5"
                  variant="primary"
                  size="lg"
                  color="gray.500"
                  borderRadius="xl"
                  fontSize={[12, 8, 10, 12, 14]}
                  minW="80"
                  {...register("toAccount")}
                >
                  {/* <option value={-1}>Все</option> */}
                  {props?.accounts?.length > 0 &&
                    props.accounts.map((elem) => (
                      <option key={elem.id} value={elem.id}>
                        {elem.name}
                      </option>
                    ))}
                </Select>
              </Flex>
            </Flex>

            <Text fontSize={12} color="gray.500" mt={8}>
              Сумма*
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              w="80"
              type="number"
              {...register("amount", {
                required: "Поле обязательно к заполнению",
              })}
            />

            <Text fontSize={12} color="gray.500" mt={8}>
              Описание*
            </Text>
            <Input
              variant="primary"
              fontSize={[12, 8, 10, 12, 14]}
              borderRadius="xl"
              h={30}
              // w="80"
              type="text"
              {...register("desc", {
                required: "Поле обязательно к заполнению",
              })}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={handleSubmit(onSubmitForm)}
              isDisabled={!isValid}
              type="submit"
              colorScheme="facebook"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="dark-lg"
            >
              Сохранить
            </Button>
            <Button
              colorScheme="twitter"
              ml={10}
              width={60}
              height={16}
              fontSize="2xl"
              boxShadow="dark-lg"
              onClick={handleClose}
            >
              Закрыть
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default PaymentTransferItem;
